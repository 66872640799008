import api from "@/api";
import { Gender } from "@/controllers/people";

export const enum SubmittedPreparatoryClassApplicationStatus {
  pending = "pending",
  approved = "approved",
}

export interface PublicSubmittedPreparatoryClassApplication {
  address1: string;
  address2: string;
  birthDate: string;
  city: string;
  congregationId: number;
  emailAddress: string;
  fatherFirstName: string;
  firstName: string;
  acceptsCpsPolicy: boolean;
  ministerConfirmation: boolean;
  gender: Gender;
  lastName: string;
  middleName: string;
  ministerEmail: string;
  ministerName: string;
  ministerNote: string;
  ministerPhoneNumber: string;
  motherFirstName: string;
  phoneNumber: string;
  stateProvinceId: number;
  travelPlans: string;
  zipCode: string;
  firstChoiceId: number;
  secondChoiceId: number;
  thirdChoiceId: number;
  fourthChoiceId: number;
}

function formParamsToApiParams(
  formParams: PublicSubmittedPreparatoryClassApplication,
) {
  // strip out the zeros
  const pcIds = [
    formParams.firstChoiceId,
    formParams.secondChoiceId,
    formParams.thirdChoiceId,
    formParams.fourthChoiceId,
  ].reduce((accum: number[], item) => {
    if (item === 0) {
      accum;
    } else {
      accum.push(item);
    }
    return accum;
  }, []);

  return {
    submitted_preparatory_class_application: {
      accepts_cps_policy: formParams.acceptsCpsPolicy,
      address2: formParams.address2,
      address: formParams.address1,
      birth_date: formParams.birthDate,
      city: formParams.city,
      congregation_id: formParams.congregationId,
      email_address: formParams.emailAddress,
      father_first_name: formParams.fatherFirstName,
      first_name: formParams.firstName,
      gender: formParams.gender,
      last_name: formParams.lastName,
      middle_name: formParams.middleName,
      minister_confirmation: formParams.ministerConfirmation,
      minister_email_address: formParams.ministerEmail,
      minister_name: formParams.ministerName,
      minister_note: formParams.ministerNote,
      minister_phone_number: formParams.ministerPhoneNumber,
      mother_first_name: formParams.motherFirstName,
      phone_number: formParams.phoneNumber,
      preparatory_class_ids: pcIds,
      state_id: formParams.stateProvinceId,
      status: SubmittedPreparatoryClassApplicationStatus.pending,
      submitted_date: "",
      travel_plans: formParams.travelPlans,
      zip: formParams.zipCode,
    },
  };
}

async function create(formParams: PublicSubmittedPreparatoryClassApplication) {
  const apiParams = formParamsToApiParams(formParams);

  return api.postPublicSubmittedPreparatoryClassApplication(apiParams);
}

export const publicSubmittedPreparatoryClassApplicationController = {
  create: create,
};
