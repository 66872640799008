import { ApiPeopleListItem } from "@/controllers/peopleList";

interface PeopleListState {
  males: ApiPeopleListItem[];
  females: ApiPeopleListItem[];
  all: ApiPeopleListItem[];
}

const state: PeopleListState = {
  males: [],
  females: [],
  all: [],
};

const mutations = {
  LOAD_PEOPLE_MALES(state: PeopleListState, payload: ApiPeopleListItem[]) {
    state.males = payload;
  },
  LOAD_PEOPLE_FEMALES(state: PeopleListState, payload: ApiPeopleListItem[]) {
    state.females = payload;
  },
};

const actions = {
  loadPeopleMales(context: any, names: ApiPeopleListItem[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PEOPLE_MALES", names);
      resolve(names);
    });
  },
  loadPeopleFemales(context: any, names: ApiPeopleListItem[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PEOPLE_FEMALES", names);
      resolve(names);
    });
  },
};

const getters = {
  peopleFemales: (state: PeopleListState) => {
    return state.females;
  },
  peopleMales: (state: PeopleListState) => {
    return state.males;
  },
};

const peopleListModule = {
  state,
  mutations,
  actions,
  getters,
};

export default peopleListModule;
