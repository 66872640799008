import {
  defineComponent,
  ref,
  onMounted,
  reactive,
} from "@vue/composition-api";

import VueTrix from "vue-trix";
import LoadingIndicator from "@/components/Loading/Loading.vue";

import { NamedRts } from "@/router";
import {
  emailBuildersController,
  EmailBuilderRecipient,
  EmailBuilderPreparatoryClass,
} from "@/controllers/emailBuilders";
import { SendEmailMode } from "@/controllers/placementPacket";
import { TOAST_OPTIONS } from "@/constants";

interface hasSelected {
  selected: boolean;
  emailAddress: string;
}

export default defineComponent({
  name: "emailBuilderForm",
  components: {
    VueTrix,
    LoadingIndicator,
  },
  setup(_props, ctx) {
    const editorContent = ref("");
    const subject = ref("");
    const files = ref<any[]>([]);

    const dataLoaded = ref(false);

    const submitting = ref(false);
    const submitButtonText = ref("Send");

    const instructors = reactive<EmailBuilderRecipient[]>([]);

    const courtesyCommitteeMembers = reactive<EmailBuilderRecipient[]>([]);
    const preparatoryClasses = reactive<EmailBuilderPreparatoryClass[]>([]);

    const cancelRoute = {
      name: NamedRts.coordinatorDashboard,
    };

    const filesChanged = (evt: any) => {
      files.value = evt.target.files;
    };

    const selectAllApplications = (pc: EmailBuilderPreparatoryClass) => {
      pc.applicants.map(item => (item.selected = true));
    };

    const selectAllMinisters = (pc: EmailBuilderPreparatoryClass) => {
      pc.ministers.map(item => (item.selected = true));
    };

    const selectAllSelectable = (selectable: EmailBuilderRecipient[]) => {
      selectable.map(item => (item.selected = true));
    };

    onMounted(() => {
      emailBuildersController.needEmailBuilders().then(response => {
        response.instructors.map(item => instructors.push(item));
        response.courtesyCommitteeMembers.map(item =>
          courtesyCommitteeMembers.push(item),
        );
        response.preparatoryClasses.map(item => preparatoryClasses.push(item));
        dataLoaded.value = true;
      });
    });

    const toggleSelected = (r: { selected: boolean }) => {
      r.selected = !r.selected;
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();

      submitButtonText.value = "Sending...";
      submitting.value = true;

      const filterIfSelected = (item: { selected: boolean }) => {
        return item.selected;
      };

      // get the list of recipients
      const selectedInstructors = instructors.filter(filterIfSelected);

      const selectedCourtesyCommitteeMembers = courtesyCommitteeMembers.filter(
        filterIfSelected,
      );

      const selectedApplicants = preparatoryClasses.reduce((accum, item) => {
        const partialApplicants = item.applicants.reduce(
          (innerAccum, innerItem: hasSelected) => {
            if (innerItem.selected) {
              return innerAccum.concat([innerItem]);
            } else {
              return innerAccum;
            }
          },
          [] as hasSelected[],
        );
        return accum.concat(partialApplicants);
      }, [] as hasSelected[]);

      const selectedMinisters = preparatoryClasses.reduce((accum, item) => {
        const partialMinisters = item.ministers.reduce(
          (innerAccum, innerItem: hasSelected) => {
            if (innerItem.selected) {
              return innerAccum.concat([innerItem]);
            } else {
              return innerAccum;
            }
          },
          [] as hasSelected[],
        );
        return accum.concat(partialMinisters);
      }, [] as hasSelected[]);

      const trixEditor: any = (document as any)
        .getElementById("trix-editor")
        .getElementsByTagName("trix-editor")[0];

      let selectedPeople: hasSelected[] = selectedInstructors.concat(
        selectedCourtesyCommitteeMembers,
      );

      selectedPeople = selectedPeople.concat(selectedApplicants);
      selectedPeople = selectedPeople.concat(selectedMinisters);

      const emailAddresses = selectedPeople.map(item => {
        return item.emailAddress;
      });

      const formData = new FormData();
      formData.append("recipients", JSON.stringify(emailAddresses));
      formData.append("editor_content", editorContent.value);
      formData.append(
        "content_text",
        trixEditor.editor.getDocument().toString(),
      );
      formData.append("subject", subject.value);
      formData.append("mode", SendEmailMode.production);
      if (files.value.length > 0) {
        Array.from(Array(files.value.length).keys()).map(x => {
          formData.append("attachments", files.value[x], files.value[x].name);
        });
      }

      emailBuildersController.sendEmailBuilder(formData).then(response => {
        submitButtonText.value = "Send";
        submitting.value = false;

        const message = response.message;
        ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
      });
    };

    return {
      dataLoaded,

      editorContent,
      subject,

      onSubmit,
      submitting,
      submitButtonText,
      cancelRoute,

      instructors,
      courtesyCommitteeMembers,
      preparatoryClasses,

      toggleSelected,
      selectAllApplications,
      selectAllMinisters,
      selectAllSelectable,

      filesChanged,
    };
  },
});
