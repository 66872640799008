import { UserSetting, UserSettingName } from "@/store/userSettings";
import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiUserSettingParams {
  internal_name: UserSettingName;
  integer_value: number;
  string_value: string;
}

export interface ApiUserSetting {
  internal_name: string;
  integer_value: number;
  string_value: string;
}

async function createUserSetting(u: UserSetting) {
  const uParams: ApiUserSettingParams = {
    internal_name: u.internalName,
    integer_value: u.integerValue,
    string_value: u.stringValue,
  };
  return api.postUserSetting(uParams).then((response: ApiUserSetting) => {
    if (u.internalName === UserSettingName.currentPreparatoryClassSeason) {
      return store.dispatch(
        StoreActions.setCurrentPreparatoryClassSeason,
        u.integerValue,
      );
    } else {
      return response;
    }
  });
}

async function needUserSetting(internalName: UserSettingName) {
  return api.getUserSetting(internalName).then((response: ApiUserSetting) => {
    // for more settings, this is going to need to be changed
    return store.dispatch(
      StoreActions.setCurrentPreparatoryClassSeason,
      response.integer_value,
    );
  });
}

export const userSettingsController = {
  needUserSetting,
  createUserSetting,
};
