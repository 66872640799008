import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";

import { NamedRts } from "@/router";

import {
  buildUnitApplicationPlacementStatuses,
  buildUnitApplicationPlacement,
  buildSubmitButton,
  buildDeleteButton,
} from "@/utilities/formHelpers";

import { prettifyUAPStatus } from "@/utilities/displayHelpers";

import {
  unitApplicationPlacementForDisplayController,
  UAPLevel,
} from "@/controllers/unitApplicationPlacements";

import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "unitApplicationPlacementFormPartial",
  components: {
    LoadingIndicator,
    FormErrors,
  },
  props: {
    personId: {
      type: String,
      required: true,
    },
    unitApplicationPlacementId: {
      type: String,
      required: true,
    },
    unitApplicationId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;

    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const { submitting, submitButtonText } = buildSubmitButton();
    const { deleting, deleteButtonText } = buildDeleteButton();

    const {
      unitApplicationPlacementStatuses,
      loadUnitApplicationPlacementStatusesIntoForm,
    } = buildUnitApplicationPlacementStatuses();

    const {
      unitApplicationPlacement,
      loadUnitApplicationPlacementIntoForm,
    } = buildUnitApplicationPlacement();

    const apiUnitApplicationPlacement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const apiUnitApplicationReplaces = computed(() => {
      return $store.getters
        .unitApplicationReplacesForDisplay as UnitApplicationPlacementForDisplay;
    });

    const cancelRoute = {
      name: NamedRts.personDashboardUnitApplication,
      params: {
        personId: props.personId,
        unitApplicationId: props.unitApplicationId,
      },
    };

    onMounted(() => {
      loadUnitApplicationPlacementStatusesIntoForm(
        unitApplicationPlacementStatuses,
      );

      const dataPromises = [];

      if (props.unitApplicationPlacementId === "0") {
      } else {
        dataPromises.push(
          unitApplicationPlacementForDisplayController.needUnitApplicationPlacementForDisplay(
            props.unitApplicationPlacementId,
            UAPLevel.placement,
          ),
        );
      }

      Promise.all(dataPromises).then(() => {
        loadUnitApplicationPlacementIntoForm(
          unitApplicationPlacement,
          apiUnitApplicationPlacement,
        );
        dataLoaded.value = true;
        if (unitApplicationPlacement.replacesId) {
          // look up the one the replace
          unitApplicationPlacementForDisplayController.needUnitApplicationPlacementForDisplay(
            unitApplicationPlacement.replacesId.toString(),
            UAPLevel.replaces,
          );
        }
      });
    });

    const startDelete = () => {
      deleting.value = true;
      if (props.unitApplicationPlacementId === "0") {
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      unitApplicationPlacementForDisplayController
        .deleteUnitApplicationPlacement(unitApplicationPlacement)
        .then(() => {
          cancelDelete();
          ctx.emit("data-changed");
          ctx.root.$bvToast.toast("Application deleted", TOAST_OPTIONS);
        })
        .then(() => {
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          cancelDelete();
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let apiResponse;
      if (props.unitApplicationPlacementId === "0") {
        apiResponse = unitApplicationPlacementForDisplayController.createUnitApplicationPlacement(
          unitApplicationPlacement,
        );
      } else {
        apiResponse = unitApplicationPlacementForDisplayController.updateUnitApplicationPlacement(
          unitApplicationPlacement,
        );
      }

      apiResponse.then(() => {
        ctx.root.$bvToast.toast(
          "Unit application placement was successfully saved.",
          TOAST_OPTIONS,
        );
        submitting.value = false;
        submitButtonText.value = "Submit";
        ctx.emit("data-changed");
        ctx.root.$router.push({
          name: NamedRts.personDashboardUnitApplicationPlacement,
          params: {
            personId: apiUnitApplicationPlacement.value.personId.toString(),
            unitApplicationId: apiUnitApplicationPlacement.value.unitApplicationId.toString(),
            unitApplicationPlacementId: apiUnitApplicationPlacement.value.id.toString(),
          },
        });
      });
    };

    const removeReplaces = () => {
      if (unitApplicationPlacement.replacesId) {
        unitApplicationPlacement.replacesId = null;
      } else {
        if (apiUnitApplicationReplaces.value) {
          unitApplicationPlacement.replacesId =
            apiUnitApplicationReplaces.value.id;
        }
      }
    };

    return {
      dataLoaded,
      errorMessages,
      hasErrors,
      submitting,
      submitButtonText,

      deleting,
      deleteButtonText,
      startDelete,
      performDelete,
      cancelDelete,

      cancelRoute,

      unitApplicationPlacement,
      unitApplicationPlacementStatuses,
      prettifyUAPStatus,

      apiUnitApplicationReplaces,

      onSubmit,
      removeReplaces,
    };
  },
});
