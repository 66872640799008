import { defineComponent } from "@vue/composition-api";

import FormPartial from "@/components/Person/FormPartial.vue";

export default defineComponent({
  name: "PersonFormNew",
  components: {
    FormPartial,
  },
  props: {
    personId: {
      type: String,
      required: true,
    },
  },

  setup(_props, _ctx) {
    return {};
  },
});
