import {
  onMounted,
  onUnmounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { SMMode } from "@/store/sidebarMenu";
import { StoreActions } from "@/store";
import {
  emptyUnitApplication,
  UnitApplication,
} from "@/controllers/unitApplications";

import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";
import { NamedRts } from "@/router";
import parseISO from "date-fns/parseISO";
import {
  appendYearsOld,
  personAgeToday,
  friendlyDate,
} from "@/utilities/displayHelpers";

import {
  prettifyUAPStatus,
  hBadgeForUAPStatus,
} from "@/utilities/displayHelpers";

import Tel from "@/components/Tel/Tel.vue";
import MailTo from "@/components/MailTo/MailTo.vue";
import {
  PersonDashboardController,
  person,
  personLoaded,
  unitApplications,
  unitApplicationsLoaded,
  unitApplicationPlacements,
} from "@/controllers/personDashboard";

export default defineComponent({
  name: "personDashboard",
  components: {
    Tel,
    MailTo,
  },
  props: {
    personId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;

    const routeParams = {
      workInProgress: {
        name: NamedRts.recruiterWorkInProgress,
      },
    };

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const uapForUAId = computed(() => (id: number) => {
      return unitApplicationPlacements.value.filter(item => {
        return item.unitApplicationId === id;
      });
    });

    // sidebar menu code
    const sidebarMenuClassObject = computed(() => {
      return $store.getters.sidebarMenuClassObject;
    });

    const dataColClassObject = computed(() => {
      return $store.getters.dataColClassObject;
    });

    const showMenu = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.menu);
    };

    const showData = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.data);
    };

    const dashboardLinkClasses = (ua: UnitApplication, rtName: string) => {
      const routeUAId = ctx.root.$route.params.unitApplicationId;

      if (!routeUAId) {
        // early return
        return {
          active: false,
        };
      }

      if (ctx.root.$route.name === rtName && ua.id.toString() === routeUAId) {
        return {
          active: true,
        };
      } else {
        return {
          active: false,
        };
      }
    };

    const dashboardUAPLinkClasses = (
      uap: UnitApplicationPlacementForDisplay,
      rtName: string,
    ) => {
      const routeUAPId = ctx.root.$route.params.unitApplicationPlacementId;

      if (!routeUAPId) {
        // early return
        return {
          active: false,
        };
      }

      if (ctx.root.$route.name === rtName && uap.id.toString() === routeUAPId) {
        return {
          active: true,
        };
      } else {
        return {
          active: false,
        };
      }
    };

    const personLinkClasses = (rtName: string) => {
      if (ctx.root.$route.name === rtName) {
        return {
          active: true,
        };
      } else {
        return {
          active: false,
        };
      }
    };

    const uaRouteBuilder = (ua: UnitApplication) => {
      return {
        name: NamedRts.personDashboardUnitApplication,
        params: {
          personId: props.personId,
          unitApplicationId: ua.id.toString(),
        },
      };
    };

    const uapRouteBuilder = (uap: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personDashboardUnitApplicationPlacement,
        params: {
          personId: props.personId,
          unitApplicationId: uap.unitApplicationId.toString(),
          unitApplicationPlacementId: uap.id.toString(),
        },
      };
    };

    const uapEditRouteBuilder = (uap: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personDashboardUnitApplicationPlacementEdit,
        params: {
          personId: props.personId,
          unitApplicationId: uap.unitApplicationId.toString(),
          unitApplicationPlacementId: uap.id.toString(),
        },
      };
    };

    const uaEditRouteBuilder = (ua: UnitApplication) => {
      return {
        name: NamedRts.personDashboardUnitApplicationEdit,
        params: {
          personId: props.personId,
          unitApplicationId: ua.id.toString(),
        },
      };
    };

    const editRouteBuilder = () => {
      return {
        name: NamedRts.personDashboardPersonEdit,
        params: {
          personId: props.personId,
        },
      };
    };

    const showRouteBuilder = () => {
      return {
        name: NamedRts.personDashboardPersonShow,
        params: {
          personId: props.personId,
        },
      };
    };

    const recruiterDashboardRouteBuilder = () => {
      return {
        name: NamedRts.recruiterWorkInProgress,
      };
    };

    const loadData = () => {
      PersonDashboardController.loadData(props.personId);
    };

    const unloadData = () => {
      PersonDashboardController.unloadData();
    };

    onMounted(() => {
      unloadData();
      loadData();
    });

    onUnmounted(() => {
      unloadData();
    });

    const localPersonAge = () => {
      const birthDate = parseISO(person.value.birthDate);
      return appendYearsOld(personAgeToday(birthDate));
    };

    return {
      editRouteBuilder,
      showRouteBuilder,
      isAdmin,
      personLoaded,
      unitApplicationsLoaded,

      loadData,

      showMenu,
      showData,
      sidebarMenuClassObject,
      dataColClassObject,
      routeParams,
      person,
      unitApplications,
      dashboardLinkClasses,
      uaRouteBuilder,
      uaEditRouteBuilder,
      personLinkClasses,
      emptyUnitApplication,
      recruiterDashboardRouteBuilder,

      uapForUAId,
      uapRouteBuilder,
      uapEditRouteBuilder,

      prettifyUAPStatus,
      hBadgeForUAPStatus,
      dashboardUAPLinkClasses,

      localPersonAge,
      friendlyDate,
    };
  },
});
