import api from "@/api";
import store, { StoreActions } from "@/store";
import {
  ApiUnit,
  apiUnitToUnit,
  UnitClassification,
} from "@/controllers/units";

async function needUnits(classification: UnitClassification) {
  await api
    .getPublicUnits(classification)
    .then((response: ApiUnit[]) => {
      const c = response.map(item => {
        return apiUnitToUnit(item);
      });
      return store.dispatch(StoreActions.loadUnits, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const publicUnitsController = {
  needUnits,
};
