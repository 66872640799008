import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import UnitApplicationPlacementWarnings from "@/components/RecruiterDashboard/UnitApplicationPlacementWarnings.vue";
import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";
import { PersonUnitDataItem } from "@/components/PersonUnitData/person-unit-data";

import {
  pinnedUnitApplicationController,
  PinnedUnitApplicationFull,
} from "@/controllers/pinnedUnitApplications";

import {
  pinnedUnitApplicationPlacementController,
  PinnedUnitApplicationPlacementFull,
} from "@/controllers/pinnedUnitApplicationPlacements";
import RecentActivity from "@/components/RecruiterDashboard/RecentActivity.vue";

export default defineComponent({
  name: "workInProgress",
  components: {
    LoadingIndicator,
    RecentActivity,
    UnitApplicationPlacementWarnings,
  },

  setup(_props, ctx) {
    const dataLoaded = ref(false);
    const $store = ctx.root.$store;

    const pinnedUnitApplications = computed(() => {
      return $store.getters
        .pinnedUnitApplications as PinnedUnitApplicationFull[];
    });

    const pinnedUnitApplicationPlacements = computed(() => {
      return $store.getters
        .pinnedUnitApplicationPlacements as PinnedUnitApplicationPlacementFull[];
    });

    const unpinUnitApplication = (pa: PinnedUnitApplicationFull) => {
      pinnedUnitApplicationController
        .deletePinnedUnitApplication(pa)
        .then(() => {
          ctx.root.$bvToast.toast("Application Unpinned.", TOAST_OPTIONS);
          pinnedUnitApplicationController.needPinnedUnitApplications();
        });
    };

    const unpinUnitApplicationPlacement = (
      pap: PinnedUnitApplicationPlacementFull,
    ) => {
      pinnedUnitApplicationPlacementController
        .deletePinnedUnitApplicationPlacement(pap)
        .then(() => {
          ctx.root.$bvToast.toast(
            "Application Placement Unpinned.",
            TOAST_OPTIONS,
          );
          pinnedUnitApplicationPlacementController.needPinnedUnitApplicationPlacements();
        });
    };

    const unitApplicationRoute = (pa: PinnedUnitApplicationFull) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.unitApplication,
          itemId: pa.unitApplicationId,
        },
      };
    };

    const unitApplicationPlacementRoute = (
      pa: PinnedUnitApplicationPlacementFull,
    ) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.unitApplicationPlacement,
          itemId: pa.unitApplicationPlacementId,
        },
      };
    };

    const findReplacementRoute = (pa: PinnedUnitApplicationPlacementFull) => {
      return {
        name: NamedRts.unitFindReplacementApplications,
        params: {
          unitApplicationPlacementId: pa.unitApplicationPlacementId.toString(),
        },
      };
    };

    onMounted(() => {
      Promise.all([
        pinnedUnitApplicationController.needPinnedUnitApplications(),
        pinnedUnitApplicationPlacementController.needPinnedUnitApplicationPlacements(),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    return {
      dataLoaded,
      unpinUnitApplication,
      unpinUnitApplicationPlacement,
      pinnedUnitApplications,
      pinnedUnitApplicationPlacements,
      unitApplicationRoute,
      unitApplicationPlacementRoute,
      findReplacementRoute,
    };
  },
});
