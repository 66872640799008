import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { NamedRts } from "@/router";

import { prettifyPCAStatus } from "@/utilities/displayHelpers";

export default defineComponent({
  name: "preparatoryClassApplicationChildList",
  props: {
    dataLoaded: Boolean,
    items: Array,
  },
  components: {},

  setup(_props, ctx) {
    const $store = ctx.root.$store;

    const filter = ref("");
    const filterOn = ["fullName", "congregation", "ministerName", "status"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "fullName",
        sortable: true,
      },
      {
        key: "congregation",
        sortable: true,
      },
      {
        key: "status",
        sortable: true,
      },
      {
        key: "ministerName",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin.value) {
      fieldsForTable = [
        {
          key: "fullName",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "ministerName",
          sortable: true,
        },
        {
          key: "congregation",
          sortable: true,
        },
      ];
    }

    const routeParams = {
      preparatoryClassApplicationForm: {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: "0",
        },
      },
    };

    const computedRouteParams = (id: number) => {
      return {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: id.toString(),
        },
      };
    };

    const showRouteParams = (id: number) => {
      return {
        name: NamedRts.preparatoryClassApplicationShow,
        params: {
          preparatoryClassApplicationId: id.toString(),
        },
      };
    };

    return {
      showRouteParams,
      computedRouteParams,
      fieldsForTable,
      filter,
      filterOn,
      isAdmin,
      routeParams,
      prettifyPCAStatus,
    };
  },
});
