import api from "@/api";
import store, { StoreActions } from "@/store";

export const enum PhoneNumberDescription {
  cell = "cell",
  home = "home",
  fax = "fax",
}

export const phoneNumberDescriptionReverseLookup = <
  { [key: string]: PhoneNumberDescription }
>{
  cell: PhoneNumberDescription.cell,
  home: PhoneNumberDescription.home,
  fax: PhoneNumberDescription.fax,
};

export const enum Gender {
  male = "male",
  female = "female",
}

export const genderReverseLookup = <{ [key: string]: Gender }>{
  male: Gender.male,
  female: Gender.female,
};

export const enum MaritalStatus {
  single = "single",
  married = "married",
}

export const enum Qualification {
  normal = "normal",
  cna = "cna",
  lpn = "lpn",
  rn = "rn",
  constructionExperience = "construction_experience",
}

export interface ApiPersonIndexParams {
  updated_after?: string;
  gender?: Gender;
}

export const qualificationEnumReverseLookup = <
  { [key: string]: Qualification }
>{
  normal: Qualification.normal,
  cna: Qualification.cna,
  lpn: Qualification.lpn,
  rn: Qualification.rn,
  construction_experience: Qualification.constructionExperience,
};

export interface ApiSimilarPeopleParams {
  first_name: string;
  gender: Gender;
  birth_date: string;
}

export const prettifiedQualification = {
  normal: "Normal",
  cna: "CNA",
  lpn: "LPN",
  rn: "RN",
  construction_experience: "Construction Experience",
};

export const prettifiedGender = {
  male: "Male",
  female: "Female",
};

export const prettifiedMaritalStatus = {
  single: "Single",
  married: "Married",
};

export interface ApiPersonParams {
  first_name: string;
  last_name: string;
  middle_name: string;
  gender: string;
  birth_date: string;
  parents: string;
  address: string;
  address2: string;
  city: string;
  state_id: number;
  zip: string;
  drivers_license: string;
  congregation_id: number;
  qualification: string;
  marital_status: string;
  birth_city: string;
  birth_state_id: number;
  phone_numbers_attributes: ApiPhoneNumberParams[];
  email_addresses_attributes: ApiEmailAddressParams[];
  citizenships_attributes: ApiCitizenshipParams[];
}

export interface ApiPerson {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  birth_date: string;
  gender: string;
  marital_status: string;
  parents: string;
  congregation_id: number;
  congregation_name: string;
  qualification: string;
  drivers_license: string;
  address: string;
  address2: string;
  city: string;
  state_id: number;
  state_name: string;
  state_abbreviation: string;
  zip: string;
  birth_city: string;
  birth_state_id: number;
  birth_state_name: string;
  phone_numbers: ApiPhoneNumber[];
  email_addresses: ApiEmailAddress[];
  citizenships: ApiCitizenship[];
}

export interface Person {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  gender: Gender;
  maritalStatus: MaritalStatus;
  parents: string;
  congregationId: number;
  congregationName: string;
  qualification: Qualification;
  driversLicense: string;
  address: string;
  address2: string;
  city: string;
  stateProvinceId: number;
  stateProvinceName: string;
  stateProvinceAbbreviation: string;
  zip: string;
  birthCity: string;
  birthStateId: number;
  birthStateName: string;
  phoneNumbers: ApiPhoneNumber[];
  emailAddresses: ApiEmailAddress[];
  citizenships: Citizenship[];
}

interface ApiEmailAddressParams {
  id?: number;
  address: string;
  _destroy: boolean;
}

interface ApiPhoneNumberParams {
  id?: number;
  number: string;
  description: string;
  _destroy: boolean;
}

interface ApiCitizenshipParams {
  id?: number;
  country_id: number;
  _destroy: boolean;
}

interface ApiPhoneNumber {
  id: number;
  number: string;
  description: string;
  _destroy: boolean;
}

interface ApiEmailAddress {
  id: number;
  address: string;
  _destroy: boolean;
}

interface ApiCitizenship {
  id: number;
  country_id: number;
  country_name: string;
  _destroy: boolean;
}

interface Citizenship {
  id: number;
  countryId: number;
  countryName: string;
  _destroy: boolean;
}

function apiCitizenshipToCitizenship(cz: ApiCitizenship): Citizenship {
  return {
    id: cz.id,
    countryId: cz.country_id,
    countryName: cz.country_name,
    _destroy: cz._destroy,
  };
}

function citizenshipToApiCitizenship(cz: Citizenship): ApiCitizenshipParams {
  if (cz.id === 0) {
    return {
      country_id: cz.countryId,
      _destroy: cz._destroy,
    };
  } else {
    return {
      id: cz.id,
      country_id: cz.countryId,
      _destroy: cz._destroy,
    };
  }
}

function emailAddressToApiEmailAddress(
  em: ApiEmailAddress,
): ApiEmailAddressParams {
  if (em.id === 0) {
    return {
      address: em.address,
      _destroy: em._destroy,
    };
  } else {
    return {
      id: em.id,
      address: em.address,
      _destroy: em._destroy,
    };
  }
}

function phoneNumberToApiPhoneNumber(ph: ApiPhoneNumber): ApiPhoneNumberParams {
  if (ph.id === 0) {
    return {
      description: ph.description,
      number: ph.number,
      _destroy: ph._destroy,
    };
  } else {
    return {
      id: ph.id,
      description: ph.description,
      number: ph.number,
      _destroy: ph._destroy,
    };
  }
}

function apiPersonToPerson(p: ApiPerson): Person {
  const g = p.gender === "male" ? Gender.male : Gender.female;
  const ms =
    p.marital_status === "single"
      ? MaritalStatus.single
      : MaritalStatus.married;
  const q =
    p.qualification === "cna"
      ? Qualification.cna
      : p.qualification === "lpn"
      ? Qualification.lpn
      : p.qualification === "rn"
      ? Qualification.rn
      : p.qualification === "construction_experience"
      ? Qualification.constructionExperience
      : Qualification.normal;

  const c = p.citizenships.map(item => {
    return apiCitizenshipToCitizenship(item);
  });

  return {
    id: p.id,
    firstName: p.first_name,
    middleName: p.middle_name,
    lastName: p.last_name,
    birthDate: p.birth_date,
    gender: g,
    maritalStatus: ms,
    parents: p.parents,
    congregationId: p.congregation_id,
    congregationName: p.congregation_name,
    qualification: q,
    driversLicense: p.drivers_license,
    address: p.address,
    address2: p.address2,
    city: p.city,
    stateProvinceId: p.state_id,
    stateProvinceName: p.state_name,
    stateProvinceAbbreviation: p.state_abbreviation,
    zip: p.zip,
    birthCity: p.birth_city,
    birthStateId: p.birth_state_id,
    birthStateName: p.birth_state_name,
    phoneNumbers: p.phone_numbers,
    emailAddresses: p.email_addresses,
    citizenships: c,
  };
}

async function createPerson(p: Person): Promise<Person> {
  const pParams: ApiPersonParams = {
    first_name: p.firstName,
    middle_name: p.middleName,
    last_name: p.lastName,
    birth_date: p.birthDate,
    gender: p.gender,
    marital_status: p.maritalStatus,
    parents: p.parents,
    congregation_id: p.congregationId,
    qualification: p.qualification,
    drivers_license: p.driversLicense,
    address: p.address,
    address2: p.address2,
    city: p.city,
    state_id: p.stateProvinceId,
    zip: p.zip,
    birth_city: p.birthCity,
    birth_state_id: p.birthStateId,
    phone_numbers_attributes: p.phoneNumbers.map(item => {
      return phoneNumberToApiPhoneNumber(item);
    }),
    email_addresses_attributes: p.emailAddresses.map(item => {
      return emailAddressToApiEmailAddress(item);
    }),
    citizenships_attributes: p.citizenships.map(item => {
      return citizenshipToApiCitizenship(item);
    }),
  };

  return api
    .postPerson(pParams)
    .then((response: ApiPerson) => {
      return store.dispatch(
        StoreActions.loadPerson,
        apiPersonToPerson(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updatePerson(p: Person) {
  const pParams: ApiPersonParams = {
    first_name: p.firstName,
    middle_name: p.middleName,
    last_name: p.lastName,
    birth_date: p.birthDate,
    gender: p.gender,
    marital_status: p.maritalStatus,
    parents: p.parents,
    congregation_id: p.congregationId,
    qualification: p.qualification,
    drivers_license: p.driversLicense,
    address: p.address,
    address2: p.address2,
    city: p.city,
    state_id: p.stateProvinceId,
    zip: p.zip,
    birth_city: p.birthCity,
    birth_state_id: p.birthStateId,
    phone_numbers_attributes: p.phoneNumbers.map(item => {
      return phoneNumberToApiPhoneNumber(item);
    }),
    email_addresses_attributes: p.emailAddresses.map(item => {
      return emailAddressToApiEmailAddress(item);
    }),
    citizenships_attributes: p.citizenships.map(item => {
      return citizenshipToApiCitizenship(item);
    }),
  };

  return api
    .patchPerson(p.id, pParams)
    .then((response: ApiPerson) => {
      return store.dispatch(
        StoreActions.loadPerson,
        apiPersonToPerson(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePerson(p: Person) {
  return api.deletePerson(p.id).catch(error => {
    return Promise.reject(error);
  });
}

async function needSimilarPeople(params: ApiSimilarPeopleParams) {
  return api
    .getSimilarPeople(params)
    .then(response => {
      // 2020-02-14
      // Not going to store this data in the store
      // it is needed directly on the form where it is being viewed
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPerson(personId: string) {
  return api
    .getPerson(personId)
    .then((response: ApiPerson) => {
      return store.dispatch(
        StoreActions.loadPerson,
        apiPersonToPerson(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPeople(p: ApiPersonIndexParams) {
  return api
    .getPeople(p)
    .then((response: ApiPerson[]) => {
      const c = response.map(item => {
        return apiPersonToPerson(item);
      });
      return store.dispatch(StoreActions.loadPeople, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const peopleController = {
  createPerson,
  deletePerson,
  needPerson,
  needPeople,
  needSimilarPeople,
  updatePerson,
};
