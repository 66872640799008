import {
  Unit,
  UnitWorkType,
  UnitClassification,
  UnitStatus,
} from "@/controllers/units";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface UnitLookup {
  [key: number]: Unit;
}

interface UnitsByWorkType {
  [key: string]: {
    units: Unit[];
    unitIds: number[];
  };
}

interface UnitsState {
  unit: Unit;
  unitsForAutocomplete: AutocompleteResult[];
  unitsForTable: Unit[];
  boysUnitsForTable: Unit[];
  girlsUnitsForTable: Unit[];
  units: UnitLookup;
  unitsByWorkType: UnitsByWorkType;
}

const emptyUnit: Unit = {
  id: 0,
  name: "",
  classification: UnitClassification.boys,
  workType: UnitWorkType.rebuilding,
  status: UnitStatus.active,
  city: "",
  address: "",
  address2: "",
  stateId: 0,
  stateName: "",
  startDate: "",
  endDate: "",
  zip: "",
};

const state: UnitsState = {
  unit: emptyUnit,
  unitsForAutocomplete: [],
  unitsForTable: [],
  girlsUnitsForTable: [],
  boysUnitsForTable: [],
  units: {},
  unitsByWorkType: {},
};

const filterUnitsByWorkType = (units: Unit[]) => {
  return units.reduce((accum, item) => {
    if (accum.hasOwnProperty(item.workType)) {
      accum[item.workType].units.push(item);
      accum[item.workType].unitIds.push(item.id);
      return accum;
    } else {
      accum[item.workType] = {
        units: [item],
        unitIds: [item.id],
      };
      //   .units = [item];
      // accum[item.workType].unitIds = [item.id];
      return accum;
    }
  }, {} as UnitsByWorkType);
};

const mutations = {
  LOAD_UNIT(state: UnitsState, payload: Unit) {
    state.unit = payload;
  },
  LOAD_UNITS_FOR_AUTOCOMPLETE(
    state: UnitsState,
    payload: AutocompleteResult[],
  ) {
    state.unitsForAutocomplete = payload;
  },

  LOAD_UNITS_FOR_TABLE(state: UnitsState, payload: Unit[]) {
    state.unitsForTable = payload;
  },

  LOAD_BOYS_UNITS_FOR_TABLE(state: UnitsState, payload: Unit[]) {
    state.boysUnitsForTable = payload;
  },

  LOAD_GIRLS_UNITS_FOR_TABLE(state: UnitsState, payload: Unit[]) {
    state.girlsUnitsForTable = payload;
  },

  LOAD_UNITS_FOR_LOOKUP(state: UnitsState, payload: UnitLookup) {
    state.units = payload;
  },

  LOAD_UNITS_BY_WORK_TYPE(state: UnitsState, payload: UnitsByWorkType) {
    state.unitsByWorkType = payload;
  },
};

const actions = {
  loadUnit(context: any, unit: Unit) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_UNIT", unit);
      resolve(unit);
    });
  },
  loadUnits(context: any, units: Unit[]) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = units.map(item => {
        return {
          label: item.name,
          id: item.id,
        };
      });

      const byWorkType = filterUnitsByWorkType(units);

      const unitsByClassification = units.reduce(
        (accum, item) => {
          if (item.classification === UnitClassification.boys) {
            accum.boysUnits.push(item);
            return accum;
          } else {
            accum.girlsUnits.push(item);
            return accum;
          }
        },
        { boysUnits: [] as Unit[], girlsUnits: [] as Unit[] },
      );

      const lookupItems = units.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit("LOAD_UNITS_FOR_AUTOCOMPLETE", acItems);
      context.commit("LOAD_UNITS_FOR_TABLE", units);
      context.commit(
        "LOAD_BOYS_UNITS_FOR_TABLE",
        unitsByClassification.boysUnits,
      );
      context.commit(
        "LOAD_GIRLS_UNITS_FOR_TABLE",
        unitsByClassification.girlsUnits,
      );
      context.commit("LOAD_UNITS_FOR_LOOKUP", lookupItems);
      context.commit("LOAD_UNITS_BY_WORK_TYPE", byWorkType);
      resolve(acItems);
    });
  },
};

const getters = {
  unit: (state: UnitsState) => {
    return state.unit;
  },
  unitsForAutocomplete: (state: UnitsState) => {
    return state.unitsForAutocomplete;
  },

  unitsForTable: (state: UnitsState) => {
    return state.unitsForTable;
  },

  boysUnitsForTable: (state: UnitsState) => {
    return state.boysUnitsForTable;
  },

  girlsUnitsForTable: (state: UnitsState) => {
    return state.girlsUnitsForTable;
  },

  unitById: (state: UnitsState) => (id: number) => {
    return state.units[id];
  },

  unitsByWorkType: (state: UnitsState) => (wt: UnitWorkType) => {
    return state.unitsByWorkType[wt];
  },
};

const unitsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default unitsModule;
