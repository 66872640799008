import { ref, onMounted, defineComponent } from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import {
  unitRecentActivitiesController,
  RecentNote,
  RecentUnitApplication,
  RecentUnitApplicationPlacement,
} from "@/controllers/unitRecentActivity";
import SingleNote from "@/components/UnitApplicationNote/SingleNote.vue";
import { NamedRts } from "@/router";
import { friendlyDate } from "@/utilities/displayHelpers";
import { Gender } from "@/controllers/people";

export default defineComponent({
  name: "wipRecentActivity",
  components: {
    LoadingIndicator,
    SingleNote,
  },

  setup(_props, _ctx) {
    const dataLoaded = ref(false);
    const notes = ref<RecentNote[]>([]);
    const unitApplications = ref<RecentUnitApplication[]>([]);
    const unitApplicationPlacements = ref<RecentUnitApplicationPlacement[]>([]);

    const personDashboardRoute = (p: RecentNote) => {
      return {
        name: NamedRts.personDashboardUnitApplication,
        params: {
          personId: p.personId.toString(),
          unitApplicationId: p.unitApplicationId.toString(),
        },
      };
    };

    const uaDashboardRoute = (ua: RecentUnitApplication) => {
      return {
        name: NamedRts.personDashboardUnitApplication,
        params: {
          personId: ua.personId.toString(),
          unitApplicationId: ua.id.toString(),
        },
      };
    };

    const uapDashboardRoute = (uap: RecentUnitApplicationPlacement) => {
      return {
        name: NamedRts.personDashboardUnitApplication,
        params: {
          personId: uap.personId.toString(),
          unitApplicationId: uap.unitApplicationId.toString(),
          unitApplicationPlacementId: uap.id.toString(),
        },
      };
    };

    const genderColorClass = (p: Gender) => {
      if (p === Gender.female) {
        return {
          girls: true,
        };
      } else {
        return {
          boys: true,
        };
      }
    };

    onMounted(() => {
      unitRecentActivitiesController.needRecentActivities().then(response => {
        notes.value = response.notes;
        unitApplications.value = response.unitApplications;
        unitApplicationPlacements.value = response.unitApplicationPlacements;
        dataLoaded.value = true;
      });
    });

    return {
      notes,
      unitApplications,
      unitApplicationPlacements,
      friendlyDate,

      dataLoaded,
      personDashboardRoute,
      uaDashboardRoute,
      uapDashboardRoute,

      genderColorClass,
    };
  },
});
