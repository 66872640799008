import { PreparatoryClassSeason } from "@/controllers/preparatoryClassSeasons";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface PreparatoryClassSeasonLookup {
  [key: number]: PreparatoryClassSeason;
}

interface PreparatoryClassSeasonsState {
  preparatoryClassSeason: PreparatoryClassSeason;
  preparatoryClassSeasonsForTable: PreparatoryClassSeason[];
  preparatoryClassSeasonsForAutocomplete: AutocompleteResult[];
  preparatoryClassSeasons: PreparatoryClassSeasonLookup;
}

const emptyPreparatoryClassSeason: PreparatoryClassSeason = {
  description: "",
  endDate: "",
  friendlyEndDate: "",
  friendlyStartDate: "",
  id: 0,
  notes: "",
  startDate: "",
};

const state: PreparatoryClassSeasonsState = {
  preparatoryClassSeason: emptyPreparatoryClassSeason,
  preparatoryClassSeasonsForTable: [],
  preparatoryClassSeasonsForAutocomplete: [],
  preparatoryClassSeasons: {},
};

const mutations = {
  LOAD_PREPARATORY_CLASS_SEASON(
    state: PreparatoryClassSeasonsState,
    payload: PreparatoryClassSeason,
  ) {
    state.preparatoryClassSeason = payload;
  },
  LOAD_PREPARATORY_CLASS_SEASONS_FOR_TABLE(
    state: PreparatoryClassSeasonsState,
    payload: PreparatoryClassSeason[],
  ) {
    state.preparatoryClassSeasonsForTable = payload;
  },
  LOAD_PREPARATORY_CLASS_SEASONS_FOR_AUTOCOMPLETE(
    state: PreparatoryClassSeasonsState,
    payload: AutocompleteResult[],
  ) {
    state.preparatoryClassSeasonsForAutocomplete = payload;
  },

  LOAD_PREPARATORY_CLASS_SEASONS_FOR_LOOKUP(
    state: PreparatoryClassSeasonsState,
    payload: PreparatoryClassSeasonLookup,
  ) {
    state.preparatoryClassSeasons = payload;
  },
};

const actions = {
  loadPreparatoryClassSeasons(
    context: any,
    preparatoryClassSeasons: PreparatoryClassSeason[],
  ) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = preparatoryClassSeasons.map(
        item => {
          return {
            label: item.description,
            id: item.id,
          };
        },
      );

      const lookupItems = preparatoryClassSeasons.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit(
        "LOAD_PREPARATORY_CLASS_SEASONS_FOR_AUTOCOMPLETE",
        acItems,
      );
      context.commit(
        "LOAD_PREPARATORY_CLASS_SEASONS_FOR_TABLE",
        preparatoryClassSeasons,
      );
      context.commit("LOAD_PREPARATORY_CLASS_SEASONS_FOR_LOOKUP", lookupItems);
      resolve(acItems);
    });
  },

  loadPreparatoryClassSeason(
    context: any,
    preparatoryClassSeason: PreparatoryClassSeason,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PREPARATORY_CLASS_SEASON", preparatoryClassSeason);
      resolve(preparatoryClassSeason);
    });
  },
};

const getters = {
  preparatoryClassSeason: (state: PreparatoryClassSeasonsState) => {
    return state.preparatoryClassSeason;
  },
  preparatoryClassSeasonsForTable: (state: PreparatoryClassSeasonsState) => {
    return state.preparatoryClassSeasonsForTable;
  },
  preparatoryClassSeasonsForAutocomplete: (
    state: PreparatoryClassSeasonsState,
  ) => {
    return state.preparatoryClassSeasonsForAutocomplete;
  },
  preparatoryClassSeasonById: (state: PreparatoryClassSeasonsState) => (
    id: number,
  ) => {
    return state.preparatoryClassSeasons[id];
  },
};

const preparatoryClassSeasonsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default preparatoryClassSeasonsModule;
