import api from "@/api";
import store, { StoreActions } from "@/store";

import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import {
  UnitPosition,
  unitPositionEnumReverseLookup,
} from "@/controllers/unitPositionTermLengths";
import { UnitClassification, unitClassificationReverseLookup } from "./units";

export const enum UnitApplicationPlacementStatus {
  pending = "pending",
  confirmed = "confirmed",
}

export const enum UAPLevel {
  placement = "placement",
  replacement = "replacement",
  replaces = "replaces",
}

export const unitApplicationPlacementStatusReverseLookup = <
  { [key: string]: UnitApplicationPlacementStatus }
>{
  pending: UnitApplicationPlacementStatus.pending,
  confirmed: UnitApplicationPlacementStatus.confirmed,
};

export const prettifiedUnitApplicationPlacementStatus = {
  pending: "Pending",
  confirmed: "Confirmed",
};

export interface ApiUnitApplicationPlacementParams {
  unit_application_placement: {
    unit_application_id: number;
    start_date: string;
    end_date: string;
    unit_id: number;
    replaces_id: number | null;
    status: string;
  };
}

export interface ApiUnitApplicationPlacementForDisplay {
  id: number;
  placement_end_date: string;
  placement_start_date: string;
  name: string;
  person_id: number;
  birth_date: string;
  parents: string;
  congregation_name: string;
  congregation_city: string;
  congregation_id: number;
  state: string;
  unit_application_id: number;
  unit_application_position: string;
  replaces_id: number | null;
  unit_name: string;
  unit_classification: string;
  unit_id: number;
  status: string;
  other_people_concatenated: string;
  latest_note_content: string | null;
  latest_note_user: string | null;
  latest_note_created_at: string | null;
}

export interface UnitApplicationPlacementForDisplay {
  id: number;
  placementEndDate: string;
  placementStartDate: string;
  name: string;
  personId: number;
  birthDate: string;
  parents: string;
  congregationName: string;
  congregationId: number;
  congregationCity: string;
  state: string;
  unitApplicationId: number;
  unitApplicationPosition: UnitPosition;
  replacesId: number | null;
  unitName: string;
  unitId: number;
  unitClassification: UnitClassification;
  status: UnitApplicationPlacementStatus;
  otherPeopleConcatenated: string;
  latestNoteContent: string | null;
  latestNoteUser: string | null;
  latestNoteCreatedAt: string | null;
  latestNoteTimeAgoInWords: string | null;
  replacement: UnitApplicationPlacementForDisplay | null;
}

export interface ApiUnitApplicationPlacementDisplayIndexParams {
  unit_application_position?: UnitPosition;
  unit_id?: number;
  unit_ids?: number[];
  unit_application_id?: number;
  unit_application_placement_ids?: number[];
  unit_application_replaces_ids?: number[];
  unit_application_ids?: number[];
}

function apiUnitApplicationPlacementToUnitApplicationPlacement(
  p: ApiUnitApplicationPlacementForDisplay,
): UnitApplicationPlacementForDisplay {
  const pPosition = unitPositionEnumReverseLookup[p.unit_application_position];

  const st = unitApplicationPlacementStatusReverseLookup[p.status];
  const cl = unitClassificationReverseLookup[p.unit_classification];

  let distanceToNow = "";

  if (p.latest_note_created_at) {
    distanceToNow = formatDistanceToNow(parseISO(p.latest_note_created_at));
  }

  return {
    id: p.id,
    placementEndDate: p.placement_end_date,
    placementStartDate: p.placement_start_date,
    name: p.name,
    personId: p.person_id,
    birthDate: p.birth_date,
    parents: p.parents,
    congregationName: p.congregation_name,
    congregationCity: p.congregation_city,
    congregationId: p.congregation_id,
    state: p.state,
    unitApplicationId: p.unit_application_id,
    unitApplicationPosition: pPosition,
    replacesId: p.replaces_id,
    unitName: p.unit_name,
    unitClassification: cl,
    unitId: p.unit_id,
    status: st,
    otherPeopleConcatenated: p.other_people_concatenated,
    latestNoteContent: p.latest_note_content,
    latestNoteUser: p.latest_note_user,
    latestNoteCreatedAt: p.latest_note_created_at,
    latestNoteTimeAgoInWords: distanceToNow,
    replacement: null,
  };
}

// this function is non-standard
// is is being used for both getting the placement
// and the replacement, the only difference is where it
// is stored in the store
async function needUnitApplicationPlacementForDisplay(
  unitApplicationPlacementId: string,
  l: UAPLevel,
) {
  return api
    .getUnitApplicationPlacementForDisplay(unitApplicationPlacementId)
    .then((response: ApiUnitApplicationPlacementForDisplay) => {
      if (l === UAPLevel.placement) {
        return store.dispatch(
          StoreActions.loadUnitApplicationPlacementForDisplay,
          apiUnitApplicationPlacementToUnitApplicationPlacement(response),
        );
      } else if (l === UAPLevel.replaces) {
        return store.dispatch(
          StoreActions.loadUnitApplicationReplacesForDisplay,
          apiUnitApplicationPlacementToUnitApplicationPlacement(response),
        );
      } else {
        return store.dispatch(
          StoreActions.loadUnitApplicationReplacementForDisplay,
          apiUnitApplicationPlacementToUnitApplicationPlacement(response),
        );
      }
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitApplicationPlacementsForDisplay(
  p?: ApiUnitApplicationPlacementDisplayIndexParams,
) {
  return api
    .getUnitApplicationPlacementsForDisplay(p)
    .then((response: ApiUnitApplicationPlacementForDisplay[]) => {
      const c = response.map(item => {
        return apiUnitApplicationPlacementToUnitApplicationPlacement(item);
      });
      return store.dispatch(
        StoreActions.loadUnitApplicationPlacementsForDisplay,
        c,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function createUnitApplicationPlacement(
  c: UnitApplicationPlacementForDisplay,
) {
  const cParams: ApiUnitApplicationPlacementParams = {
    unit_application_placement: {
      unit_application_id: c.unitApplicationId,
      start_date: c.placementStartDate,
      end_date: c.placementEndDate,
      unit_id: c.unitId,
      replaces_id: c.replacesId,
      status: c.status,
    },
  };

  return api
    .postUnitApplicationPlacement(cParams)
    .then((response: ApiUnitApplicationPlacementForDisplay) => {
      return store.dispatch(
        StoreActions.loadUnitApplicationReplacementForDisplay,
        apiUnitApplicationPlacementToUnitApplicationPlacement(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateUnitApplicationPlacement(
  c: UnitApplicationPlacementForDisplay,
) {
  const cParams: ApiUnitApplicationPlacementParams = {
    unit_application_placement: {
      unit_application_id: c.unitApplicationId,
      start_date: c.placementStartDate,
      end_date: c.placementEndDate,
      unit_id: c.unitId,
      replaces_id: c.replacesId,
      status: c.status,
    },
  };

  return api
    .patchUnitApplicationPlacement(c.id, cParams)
    .then((response: ApiUnitApplicationPlacementForDisplay) => {
      return store.dispatch(
        StoreActions.loadUnitApplicationReplacementForDisplay,
        apiUnitApplicationPlacementToUnitApplicationPlacement(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteUnitApplicationPlacement(
  c: UnitApplicationPlacementForDisplay,
) {
  return api
    .deleteUnitApplicationPlacement(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const unitApplicationPlacementForDisplayController = {
  createUnitApplicationPlacement,
  needUnitApplicationPlacementForDisplay,
  needUnitApplicationPlacementsForDisplay,
  updateUnitApplicationPlacement,
  deleteUnitApplicationPlacement,
};
