import { defineComponent, PropType } from "@vue/composition-api";
import { UnitApplicationNote } from "@/controllers/unitApplications";

export default defineComponent({
  name: "unitApplicationNotesModal",
  components: {},
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    content: {
      type: Array as PropType<UnitApplicationNote[]>,
      required: true,
    },
  },
  setup(_props, ctx) {
    const cm = () => {
      ctx.emit("closeModal");
    };

    return {
      cm,
    };
  },
});
