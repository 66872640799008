import { defineComponent } from "@vue/composition-api";

import FormPartial from "@/components/UnitApplication/FormPartial.vue";

export default defineComponent({
  name: "personDashboardUnitApplicationEdit",
  components: {
    FormPartial,
  },
  props: {
    unitApplicationId: {
      type: String,
      required: true,
    },
    personId: {
      type: String,
      required: true,
    },
  },

  setup(_props, ctx) {
    const dataChanged = () => {
      ctx.emit("data-changed");
    };

    return {
      dataChanged,
    };
  },
});
