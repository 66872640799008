const BUILD_URL = "staging.cps-inc.org";
// const BUILD_URL = "recruiter.cps-inc.org";
// const BUILD_URL = "ca-recruiter.cps-inc.org";

const urlFunction = () => {
  const env = process.env.NODE_ENV;
  if (env === "development") {
    return "http://10.0.0.13:3001/api/v2";
  } else {
    return `https://${BUILD_URL}/api/v2`;
  }
};

const baseUrlFunction = () => {
  const env = process.env.NODE_ENV;
  if (env === "development") {
    return "http://10.0.0.13:3001";
  } else {
    return `https://${BUILD_URL}`;
  }
};

const domainUrlFunction = () => {
  const env = process.env.NODE_ENV;
  if (env === "development") {
    return "10.0.0.13:3001";
  } else {
    return BUILD_URL;
  }
};

const toastOptions = () => {
  return {
    appendToast: true,
    autoHideDelay: 4000,
    solid: false,
    title: "Success!",
    toaster: "b-toaster-top-right",
    variant: "success",
  };
};

export const API_URL = urlFunction();
export const BASE_URL = baseUrlFunction();
export const DOMAIN_URL = domainUrlFunction();
export const TOAST_OPTIONS = toastOptions();
