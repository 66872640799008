import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import PlacementListHeader from "@/components/UnitApplicationPlacements/PlacementListHeader.vue";
import PlacementListBody from "@/components/UnitApplicationPlacements/PlacementListBody.vue";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";

import { unitApplicationPlacementForDisplayController } from "@/controllers/unitApplicationPlacements";

export default defineComponent({
  name: "unitApplicationPlacementList",
  components: {
    LoadingIndicator,
    PlacementListHeader,
    PlacementListBody,
  },
  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const placements = computed(() => {
      return $store.getters.unitApplicationPlacementsForDisplay;
    });

    const userUnitApplicationPosition = computed(() => {
      return $store.getters.userUnitApplicationPosition as UnitPosition;
    });

    onMounted(() => {
      Promise.all([
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
          {
            unit_application_position: userUnitApplicationPosition.value,
          },
        ),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    return {
      dataLoaded,
      placements,
    };
  },
});
