import {
  PreparatoryClassInstructor,
  InstructorStatus,
} from "@/controllers/preparatoryClassInstructors";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { PreparatoryClassClassification } from "@/controllers/preparatoryClasses";

interface PreparatoryClassInstructorLookup {
  [key: number]: PreparatoryClassInstructor;
}

interface PreparatoryClassInstructorsState {
  preparatoryClassInstructor: PreparatoryClassInstructor;
  preparatoryClassInstructorsForTable: PreparatoryClassInstructor[];
  preparatoryClassInstructorsForAutocomplete: AutocompleteResult[];
  preparatoryClassInstructors: PreparatoryClassInstructorLookup;
}

const emptyPreparatoryClassInstructor: PreparatoryClassInstructor = {
  id: 0,
  personId: 0,
  name: "",
  status: InstructorStatus.active,
  classification: PreparatoryClassClassification.boys,
  startDate: "",
  endDate: "",
  notes: "",
  congregationNameWithState: "",
  emailAddress: "",
  phoneNumber: "",
};

const state: PreparatoryClassInstructorsState = {
  preparatoryClassInstructor: emptyPreparatoryClassInstructor,
  preparatoryClassInstructorsForTable: [],
  preparatoryClassInstructorsForAutocomplete: [],
  preparatoryClassInstructors: {},
};

const mutations = {
  LOAD_PREPARATORY_CLASS_INSTRUCTOR(
    state: PreparatoryClassInstructorsState,
    payload: PreparatoryClassInstructor,
  ) {
    state.preparatoryClassInstructor = payload;
  },
  LOAD_PREPARATORY_CLASS_INSTRUCTORS_FOR_TABLE(
    state: PreparatoryClassInstructorsState,
    payload: PreparatoryClassInstructor[],
  ) {
    state.preparatoryClassInstructorsForTable = payload;
  },
  LOAD_PREPARATORY_CLASS_INSTRUCTORS_FOR_AUTOCOMPLETE(
    state: PreparatoryClassInstructorsState,
    payload: AutocompleteResult[],
  ) {
    state.preparatoryClassInstructorsForAutocomplete = payload;
  },
  LOAD_PREPARATORY_CLASS_INSTRUCTORS_FOR_LOOKUP(
    state: PreparatoryClassInstructorsState,
    payload: PreparatoryClassInstructorLookup,
  ) {
    state.preparatoryClassInstructors = payload;
  },
};

const actions = {
  loadPreparatoryClassInstructor(
    context: any,
    preparatoryClassInstructor: PreparatoryClassInstructor,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_PREPARATORY_CLASS_INSTRUCTOR",
        preparatoryClassInstructor,
      );
      resolve(preparatoryClassInstructor);
    });
  },
  loadPreparatoryClassInstructors(
    context: any,
    preparatoryClassInstructors: PreparatoryClassInstructor[],
  ) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = preparatoryClassInstructors.map(
        item => {
          return {
            label: item.name,
            id: item.id,
          };
        },
      );

      const lookupItems = preparatoryClassInstructors.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit(
        "LOAD_PREPARATORY_CLASS_INSTRUCTORS_FOR_AUTOCOMPLETE",
        acItems,
      );
      context.commit(
        "LOAD_PREPARATORY_CLASS_INSTRUCTORS_FOR_TABLE",
        preparatoryClassInstructors,
      );
      context.commit(
        "LOAD_PREPARATORY_CLASS_INSTRUCTORS_FOR_LOOKUP",
        lookupItems,
      );
      resolve(acItems);
    });
  },
};

const getters = {
  preparatoryClassInstructor: (state: PreparatoryClassInstructorsState) => {
    return state.preparatoryClassInstructor;
  },
  preparatoryClassInstructorsForTable: (
    state: PreparatoryClassInstructorsState,
  ) => {
    return state.preparatoryClassInstructorsForTable;
  },
  preparatoryClassInstructorsForAutocomplete: (
    state: PreparatoryClassInstructorsState,
  ) => {
    return state.preparatoryClassInstructorsForAutocomplete;
  },

  preparatoryClassInstructorById: (state: PreparatoryClassInstructorsState) => (
    id: number,
  ) => {
    return state.preparatoryClassInstructors[id];
  },
};

const preparatoryClassInstructorsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default preparatoryClassInstructorsModule;
