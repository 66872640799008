import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import ShowPartial from "@/components/UnitApplicationPlacements/ShowPartial.vue";
import PinUnitApplicationPlacement from "@/components/PinUnitApplicationPlacement/PinUnitApplicationPlacement.vue";
import {
  unitApplicationPlacementForDisplayController,
  UAPLevel,
  UnitApplicationPlacementForDisplay,
} from "@/controllers/unitApplicationPlacements";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  friendlyLongDate,
  friendlyDate,
  hBadgeForUnitPosition,
  hBadgeForUAPStatus,
  prettifyUnitPosition,
  prettifyUAPStatus,
} from "@/utilities/displayHelpers";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import addMonths from "date-fns/addMonths";
import {
  unitApplicationController,
  UnitApplicationStatus,
} from "@/controllers/unitApplications";
import {
  unitPositionTermLengthController,
  UnitPositionTermLength,
} from "@/controllers/unitPositionTermLengths";
import { unitApplicationsForReplacementController } from "@/controllers/unitApplicationsForReplacement";

import { matchReplacementUnitApplicationController } from "@/controllers/matchReplacementUnitApplications";

import { StoreActions } from "@/store";
import UapStatus from "@/components/UAPStatus/UAPStatus.vue";
import { unitApplicationPlacementWarningsController } from "@/controllers/unitApplicationPlacementWarnings";

export default defineComponent({
  name: "unitApplicationPlacementShow",
  components: {
    LoadingIndicator,
    PinUnitApplicationPlacement,
    ShowPartial,
    UapStatus,
  },
  props: {
    unitApplicationPlacementId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const dataLoaded = ref(false);
    const $store = ctx.root.$store;
    const showOtherPeople = ref(false);

    const placement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const termLength = (): number => {
      const t = $store.getters.unitWithPositionTermLengthLookup(
        placement.value.unitApplicationPosition,
        placement.value.unitId,
      ) as UnitPositionTermLength;

      if (t) {
        return t.termLength;
      } else {
        return 0;
      }
    };

    const replacementStartDate = () => {
      return placement.value.placementEndDate;
    };

    const replacementEndDate = () => {
      return formatISO(
        addMonths(parseISO(placement.value.placementEndDate), termLength()),
      );
    };

    const otherPlacementsAtUnit = (
      unitId: number,
      startDate: string,
      endDate: string,
    ) => {
      return $store.getters.placementsForUnitForDateRange(
        unitId,
        startDate,
        endDate,
      ) as UnitApplicationPlacementForDisplay[];
    };

    const toggleShowOtherPeople = () => {
      showOtherPeople.value = !showOtherPeople.value;
    };

    onMounted(() => {
      Promise.all([
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementForDisplay(
          props.unitApplicationPlacementId,
          UAPLevel.placement,
        ),
        matchReplacementUnitApplicationController.needMatchReplacementUnitApplications(
          {
            unit_application_placement_id: Number(
              props.unitApplicationPlacementId,
            ),
          },
        ),
      ])
        .then(() => {
          const position = placement.value.unitApplicationPosition;
          return Promise.all([
            unitPositionTermLengthController.needUnitPositionTermLengths({
              position: position,
              unit_id: placement.value.unitId,
            }),
            unitApplicationController.needUnitApplications({
              position: position,
              statuses: [
                UnitApplicationStatus.available,
                UnitApplicationStatus.teaching,
              ],
            }),
            unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
              {
                unit_ids: [placement.value.unitId],
              },
            ),
            // load the warnings for the unit
            // we want to be able to show the ones that are not following anyone
            unitApplicationPlacementWarningsController.needUnitApplicationPlacementWarnings(
              { unit_id: placement.value.unitId },
            ),
          ]);
        })
        .then(() => {
          // the other people at the unit should be loaded
          // get the congregation ids
          const op = otherPlacementsAtUnit(
            placement.value.unitId,
            replacementStartDate(),
            replacementEndDate(),
          );
          const otherCIds = op.map(item => item.congregationId);

          return Promise.all([
            unitApplicationsForReplacementController.filterUnitApplications(
              placement.value,
            ),
            $store.dispatch(
              StoreActions.loadOtherPeopleCongregationIds,
              new Set(otherCIds),
            ),
          ]);
        })
        .finally(() => {
          dataLoaded.value = true;
        });
    });

    const unitApplicationUpdated = () => {
      // This data is loaded in the onMounted event
      // if an application is updated, it needs to be loaded
      // and filtered again. The code could be refactored to only
      // have one version of it.
      const position = placement.value.unitApplicationPosition;
      return Promise.all([
        unitApplicationController.needUnitApplications({
          position: position,
          statuses: [
            UnitApplicationStatus.available,
            UnitApplicationStatus.teaching,
          ],
        }),
      ]).then(() => {
        return unitApplicationsForReplacementController.filterUnitApplications(
          placement.value,
        );
      });
    };

    return {
      replacementStartDate,
      replacementEndDate,
      termLength,
      formatDistanceToNow,
      parseISO,
      placement,
      dataLoaded,
      friendlyLongDate,

      otherPlacementsAtUnit,
      hBadgeForUnitPosition,
      prettifyUnitPosition,

      prettifyUAPStatus,
      hBadgeForUAPStatus,
      friendlyDate,

      showOtherPeople,
      toggleShowOtherPeople,

      unitApplicationUpdated,
    };
  },
});
