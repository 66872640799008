import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiPinnedUnitApplicationPlacementParams {
  unit_application_placement_id: number;
}

export interface ApiPinnedUnitApplicationPlacementFull {
  id: number;
  pinned_for_user_id: number;
  unit_application_placement_id: number;
  unit_application_id: number;
  name: string;
  congregation_name: string;
  congregation_city: string;
  state: string;
  parents: string;
  latest_note_content: string;
  latest_note_user: string;
  latest_note_created_at: string;
}

export interface ApiPinnedUnitApplicationPlacementPartial {
  id: number;
  unit_application_placement_id: number;
}

export interface PinnedUnitApplicationPlacementFull {
  id: number;
  pinnedForUserId: number;
  unitApplicationPlacementId: number;
  unitApplicationId: number;
  name: string;
  congregationName: string;
  congregationCity: string;
  state: string;
  parents: string;
  latestNoteContent: string;
  latestNoteUser: string;
  latestNoteCreatedAt: string;
}

export interface PinnedUnitApplicationPlacementPartial {
  id: number;
  unitApplicationPlacementId: number;
}

export const emptyPinnedUnitApplicationPlacementPartial: PinnedUnitApplicationPlacementPartial = {
  id: 0,
  unitApplicationPlacementId: 0,
};

function apiPinnedUnitApplicationPlacementFullToPinnedUnitApplicationPlacement(
  p: ApiPinnedUnitApplicationPlacementFull,
): PinnedUnitApplicationPlacementFull {
  return {
    id: p.id,
    pinnedForUserId: p.pinned_for_user_id,
    unitApplicationPlacementId: p.unit_application_placement_id,
    unitApplicationId: p.unit_application_id,
    name: p.name,
    congregationName: p.congregation_name,
    congregationCity: p.congregation_city,
    state: p.state,
    parents: p.parents,
    latestNoteContent: p.latest_note_content,
    latestNoteUser: p.latest_note_user,
    latestNoteCreatedAt: p.latest_note_created_at,
  };
}

function apiPinnedUnitApplicationPlacementPartialToPinnedUnitApplicationPlacement(
  p: ApiPinnedUnitApplicationPlacementPartial,
): PinnedUnitApplicationPlacementPartial {
  return {
    id: p.id,
    unitApplicationPlacementId: p.unit_application_placement_id,
  };
}

async function createPinnedUnitApplicationPlacement(
  c: PinnedUnitApplicationPlacementPartial,
) {
  const cParams: ApiPinnedUnitApplicationPlacementParams = {
    unit_application_placement_id: c.unitApplicationPlacementId,
  };

  return api
    .postPinnedUnitApplicationPlacement(cParams)
    .then((response: ApiPinnedUnitApplicationPlacementPartial) => {
      return store.dispatch(
        StoreActions.loadPinnedUnitApplicationPlacement,
        apiPinnedUnitApplicationPlacementPartialToPinnedUnitApplicationPlacement(
          response,
        ),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPinnedUnitApplicationPlacements() {
  return api
    .getPinnedUnitApplicationPlacements()
    .then((response: ApiPinnedUnitApplicationPlacementFull[]) => {
      const c = response.map(item => {
        return apiPinnedUnitApplicationPlacementFullToPinnedUnitApplicationPlacement(
          item,
        );
      });
      return store.dispatch(
        StoreActions.loadPinnedUnitApplicationPlacements,
        c,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPinnedUnitApplicationPlacement(
  unitApplicationPlacementId: string,
) {
  // This is non-standard because the unit application id is being used to check
  // for the item and not the actual id of the item.
  return api
    .getPinnedUnitApplicationPlacement(unitApplicationPlacementId)
    .then((response: ApiPinnedUnitApplicationPlacementPartial) => {
      return store.dispatch(
        StoreActions.loadPinnedUnitApplicationPlacement,
        apiPinnedUnitApplicationPlacementPartialToPinnedUnitApplicationPlacement(
          response,
        ),
      );
    })
    .catch(_error => {
      return Promise.resolve("");
    });
}

async function deletePinnedUnitApplicationPlacement(
  c: PinnedUnitApplicationPlacementPartial | PinnedUnitApplicationPlacementFull,
) {
  return api
    .deletePinnedUnitApplicationPlacement(c.unitApplicationPlacementId)
    .then(() => {
      return store.dispatch(
        StoreActions.loadPinnedUnitApplicationPlacement,
        emptyPinnedUnitApplicationPlacementPartial,
      );
    });
}

export const pinnedUnitApplicationPlacementController = {
  createPinnedUnitApplicationPlacement,
  needPinnedUnitApplicationPlacement,
  needPinnedUnitApplicationPlacements,
  deletePinnedUnitApplicationPlacement,
};
