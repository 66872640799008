import Vue, { VueConstructor } from "vue";
import VueRouter from "vue-router";

import LandingPage from "@/components/LandingPage/LandingPage.vue";
import Login from "@/components/Account/Login.vue";
import PublicPreparatoryClassApplication from "@/components/public/PreparatoryClassApplication/PreparatoryClassApplication.vue";
import PublicUnitApplication from "@/components/public/UnitApplication/Form.vue";

import UnitApplicationForm from "../components/UnitApplication/Form.vue";
import PersonUnitApplicationForm from "@/components/UnitApplication/PersonUnitApplicationForm.vue";
import UnitApplicationMainList from "@/components/UnitApplication/MainList.vue";

import UnitApplicationUnreplacedPlacements from "@/components/UnitApplicationPlacements/Unreplaced.vue";
import UnitApplicationPlacementList from "@/components/UnitApplicationPlacements/List.vue";

import UnitFindReplacementDashboard from "@/components/UnitFindReplacementDashboard/Dashboard.vue";
import UnitApplicationPlacementShow from "@/components/UnitApplicationPlacements/Show.vue";
import UnitFindReplacementApplications from "@/components/UnitFindReplacementDashboard/ApplicationsList.vue";
import UnitFindReplacementPlacementsNotFollowing from "@/components/UnitFindReplacementDashboard/PlacementsNotFollowing.vue";
import UnitFindReplacementApplicationShow from "@/components/UnitFindReplacementDashboard/ShowApplication.vue";
import UnitFindReplacementReplacementShow from "@/components/UnitFindReplacementDashboard/ShowReplacement.vue";

import SubmittedUnitApplicationList from "@/components/SubmittedUnitApplication/List.vue";

import SubmittedPreparatoryClassApplicationList from "@/components/SubmittedPreparatoryClassApplication/List.vue";

import CoordinatorView from "@/views/Coordinator/Coordinator.vue";
import CoordinatorDashboard from "@/components/CoordinatorDashboard/CoordinatorDashboard.vue";

import RecruiterView from "@/views/Recruiter/Recruiter.vue";
import RecruiterDashboard from "@/components/RecruiterDashboard/RecruiterDashboard.vue";
import RecruiterWorkInProgress from "@/components/RecruiterDashboard/WorkInProgress.vue";
import RecruiterInstructions from "@/components/RecruiterDashboard/Instructions.vue";

import UnitDashboard from "@/components/UnitDashboard/Dashboard.vue";
import UnitDashboardPlacementsList from "@/components/UnitDashboard/PlacementsList.vue";
import UnitDashboardApplicationsList from "@/components/UnitDashboard/ApplicationsList.vue";

import PersonUnitData from "@/components/PersonUnitData/PersonUnitData.vue";
import PersonDashboard from "@/components/PersonDashboard/PersonDashboard.vue";
import PersonDashboardUnitApplication from "@/components/PersonDashboard/UnitApplication.vue";
import PersonDashboardUnitApplicationEdit from "@/components/PersonDashboard/UnitApplicationEdit.vue";
import PersonDashboardPersonEdit from "@/components/PersonDashboard/PersonEdit.vue";
import PersonDashboardPersonShow from "@/components/PersonDashboard/PersonShow.vue";
import PersonDashboardUnitApplicationPlacement from "@/components/PersonDashboard/UnitApplicationPlacement.vue";
import PersonDashboardUnitApplicationPlacementEdit from "@/components/PersonDashboard/UnitApplicationPlacementEdit.vue";

import PersonForm from "@/components/Person/Form.vue";
import PersonList from "@/components/Person/List.vue";

import CommonView from "@/views/Common/Common.vue";
import CountryList from "@/components/Country/List.vue";
import CountryForm from "@/components/Country/Form.vue";

import StateProvinceList from "@/components/StateProvince/List.vue";
import StateProvinceForm from "@/components/StateProvince/Form.vue";

import CongregationList from "@/components/Congregation/List.vue";
import CongregationForm from "@/components/Congregation/Form.vue";

import UnitList from "@/components/Unit/List.vue";
import UnitForm from "@/components/Unit/Form.vue";

import InstructorList from "@/components/Instructor/List.vue";
import InstructorForm from "@/components/Instructor/Form.vue";

import TextDocumentList from "@/components/TextDocument/List.vue";
import TextDocumentForm from "@/components/TextDocument/Form.vue";

import EmailBuilderForm from "@/components/EmailBuilder/Form.vue";

import PreparatoryClassApplicationForm from "@/components/PreparatoryClassApplication/Form.vue";
import PreparatoryClassApplicationShow from "@/components/PreparatoryClassApplication/Show.vue";
import PreparatoryClassApplicationList from "@/components/PreparatoryClassApplication/List.vue";

import PreparatoryClassSeasonForm from "@/components/PreparatoryClassSeason/Form.vue";
import PreparatoryClassSeasonList from "@/components/PreparatoryClassSeason/List.vue";

import PreparatoryClassForm from "@/components/PreparatoryClass/Form.vue";
import PreparatoryClassShow from "@/components/PreparatoryClass/Show.vue";
import PreparatoryClassList from "@/components/PreparatoryClass/List.vue";

import UserProfile from "@/components/Account/Profile.vue";

// needed to check if user is logged in
import store from "@/store";
import { UserResponsibility } from "@/store/user";

Vue.use(VueRouter);

export const enum NamedRts {
  coordinatorDashboard = "coordinatorDashboard",

  submittedUnitApplicationList = "submittedUnitApplicationList",

  publicPreparatoryClassApplicationForm = "publicPreparatoryClassApplicationForm",
  publicUnitApplicationForm = "publicUnitApplicationForm",

  submittedPreparatoryClassApplicationList = "submittedPreparatoryClassApplicationList",

  preparatoryClassApplicationForm = "preparatoryClassApplicationForm",
  preparatoryClassApplicationShow = "preparatoryClassApplicationShow",
  preparatoryClassApplicationList = "preparatoryClassApplicationList",

  personUnitApplicationForm = "personUnitApplicationForm",
  unitApplicationForm = "unitApplicationForm",
  unitApplicationShow = "unitApplicationShow",
  unitApplicationList = "unitApplicationList",
  unitApplicationMainList = "unitApplicationMainList",

  unitApplicationUnreplacedPlacements = "unitApplicationUnreplacedPlacements",
  unitApplicationPlacements = "unitApplicationPlacements",
  unitFindReplacementDashboard = "unitFindReplacementDashboard",
  unitFindReplacementApplications = "unitFindReplacementApplications",
  unitFindReplacementPlacementsNotFollowing = "unitFindReplacementPlacementsNotFollowing",
  unitFindReplacementApplicationsSimilarUnit = "unitFindReplacementApplicationsSimilarUnit",
  unitFindReplacementApplicationsOther = "unitFindReplacementApplicationsOther",
  unitFindReplacementApplicationsUnmatched = "unitFindReplacementApplicationsUnmatched",
  unitFindReplacementApplicationShow = "unitFindReplacementApplicationShow",
  unitFindReplacementReplacementShow = "unitFindReplacementReplacementShow",
  unitApplicationPlacementShow = "unitApplicationPlacementShow",

  preparatoryClassSeasonForm = "preparatoryClassSeasonForm",
  preparatoryClassSeasonList = "preparatoryClassSeasonList",

  preparatoryClassForm = "preparatoryClassForm",
  preparatoryClassShow = "preparatoryClassShow",
  preparatoryClassList = "preparatoryClassList",

  countryForm = "countryForm",
  countryList = "countryList",

  stateProvinceList = "stateProvinceList",
  stateProvinceForm = "stateProvinceForm",

  congregationList = "congregationList",
  congregationForm = "congregationForm",

  unitList = "unitList",
  unitForm = "unitForm",

  instructorList = "instructorList",
  instructorForm = "instructorForm",

  textDocumentList = "textDocumentList",
  textDocumentForm = "textDocumentForm",

  emailBuilderForm = "emailBuilderForm",

  landingPage = "landingPage",
  recruiterDashboard = "recruiterDashboard",
  recruiterWorkInProgress = "recruiterWorkInProgress",
  recruiterInstructions = "recruiterInstructions",

  unitDashboard = "unitDashboard",
  unitDashboardPlacementList = "unitDashboardPlacementList",
  unitDashboardApplicationList = "unitDashboardApplicationList",

  personUnitData = "personUnitData",
  personDashboard = "personDashboard",
  personDashboardUnitApplication = "personDashboardUnitApplication",
  personDashboardUnitApplicationEdit = "personDashboardUnitApplicationEdit",
  personDashboardPersonEdit = "personDashboardPersonEdit",
  personDashboardPersonShow = "personDashboardPersonShow",
  personDashboardUnitApplicationPlacement = "personDashboardUnitApplicationPlacement",
  personDashboardUnitApplicationPlacementEdit = "personDashboardUnitApplicationPlacementEdit",

  personForm = "personForm",
  personList = "personList",

  login = "login",
  userProfile = "userProfile",
}

const allResponsibilities = [
  UserResponsibility.recruiter,
  UserResponsibility.coordinator,
  UserResponsibility.general,
];

const routes = [
  {
    path: "/",
    name: NamedRts.landingPage,
    component: LandingPage as VueConstructor<Vue>,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      allowedResponsibilities: allResponsibilities,
    },
  },
  {
    path: "/login",
    name: NamedRts.login,
    component: Login as VueConstructor<Vue>,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      allowedResponsibilities: allResponsibilities,
    },
  },
  {
    path: "/profile",
    name: NamedRts.userProfile,
    component: UserProfile as VueConstructor<Vue>,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      allowedResponsibilities: allResponsibilities,
    },
  },
  {
    path: "/public/preparatory-class-application",
    name: NamedRts.publicPreparatoryClassApplicationForm,
    component: PublicPreparatoryClassApplication as VueConstructor<Vue>,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      allowedResponsibilities: allResponsibilities,
    },
  },
  {
    path: "/public/unit-application/:id",
    name: NamedRts.publicUnitApplicationForm,
    component: PublicUnitApplication as VueConstructor<Vue>,
    props: true,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      allowedResponsibilities: allResponsibilities,
    },
  },
  {
    path: "/coordinator",
    name: "coordinatorView",
    component: CoordinatorView as VueConstructor<Vue>,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      allowedResponsibilities: [UserResponsibility.coordinator],
    },
    children: [
      {
        path: "coordinator-dashboard",
        name: NamedRts.coordinatorDashboard,
        component: CoordinatorDashboard as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
        children: [
          {
            path: "submitted-preparatory-class-applications",
            name: NamedRts.submittedPreparatoryClassApplicationList,
            component: SubmittedPreparatoryClassApplicationList as VueConstructor<
              Vue
            >,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.coordinator],
            },
          },
          {
            path: "preparatory-class-application-list",
            name: NamedRts.preparatoryClassApplicationList,
            component: PreparatoryClassApplicationList as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: false,
              allowedResponsibilities: [UserResponsibility.coordinator],
            },
          },
          {
            path: "preparatory-class-list",
            name: NamedRts.preparatoryClassList,
            component: PreparatoryClassList as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: false,
              allowedResponsibilities: [UserResponsibility.coordinator],
            },
          },
          {
            path:
              "preparatory-class-application-form/:preparatoryClassApplicationId",
            name: NamedRts.preparatoryClassApplicationForm,
            component: PreparatoryClassApplicationForm as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.coordinator],
            },
          },
        ],
      },
      {
        path: "text-document-list",
        name: NamedRts.textDocumentList,
        component: TextDocumentList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "text-document-form/:id",
        name: NamedRts.textDocumentForm,
        component: TextDocumentForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "email-builder-form",
        name: NamedRts.emailBuilderForm,
        component: EmailBuilderForm as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "instructor-list",
        name: NamedRts.instructorList,
        component: InstructorList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "instructor-form/:id",
        name: NamedRts.instructorForm,
        component: InstructorForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "preparatory-class-form/:preparatoryClassId",
        name: NamedRts.preparatoryClassForm,
        component: PreparatoryClassForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "preparatory-class-show/:preparatoryClassId",
        name: NamedRts.preparatoryClassShow,
        component: PreparatoryClassShow as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "preparatory-class-season-form/:preparatoryClassSeasonId",
        name: NamedRts.preparatoryClassSeasonForm,
        component: PreparatoryClassSeasonForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path: "preparatory-class-season-list",
        name: NamedRts.preparatoryClassSeasonList,
        component: PreparatoryClassSeasonList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
      {
        path:
          "preparatory-class-application-show/:preparatoryClassApplicationId",
        name: NamedRts.preparatoryClassApplicationShow,
        component: PreparatoryClassApplicationShow as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: [UserResponsibility.coordinator],
        },
      },
    ],
  },
  {
    path: "/common",
    name: "commonView",
    component: CommonView as VueConstructor<Vue>,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      allowedResponsibilities: allResponsibilities,
    },
    children: [
      {
        path: "country-list",
        name: NamedRts.countryList,
        component: CountryList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "country-form/:countryId",
        name: NamedRts.countryForm,
        component: CountryForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "state-province-list",
        name: NamedRts.stateProvinceList,
        component: StateProvinceList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "state-province-form/:stateProvinceId",
        name: NamedRts.stateProvinceForm,
        component: StateProvinceForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "unit-list",
        name: NamedRts.unitList,
        component: UnitList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "unit-form/:unitId",
        name: NamedRts.unitForm,
        component: UnitForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "congregation-list",
        name: NamedRts.congregationList,
        component: CongregationList as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: allResponsibilities,
        },
      },
      {
        path: "congregation-form/:congregationId",
        name: NamedRts.congregationForm,
        component: CongregationForm as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          allowedResponsibilities: allResponsibilities,
        },
      },
    ],
  },
  {
    path: "/recruiter",
    name: "recruiterView",
    component: RecruiterView as VueConstructor<Vue>,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      allowedResponsibilities: [UserResponsibility.recruiter],
    },
    children: [
      {
        path: "unit-find-replacement/:unitApplicationPlacementId",
        name: NamedRts.unitFindReplacementDashboard,
        component: UnitFindReplacementDashboard as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.recruiter],
        },
        children: [
          {
            path: "unit-application-placement-show/",
            name: NamedRts.unitApplicationPlacementShow,
            component: UnitApplicationPlacementShow as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
            children: [
              {
                path: "application-show/:unitApplicationId",
                name: NamedRts.unitFindReplacementApplicationShow,
                component: UnitFindReplacementApplicationShow as VueConstructor<
                  Vue
                >,
                props: true,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
              {
                path: "replacement-show/:unitApplicationReplacementId",
                name: NamedRts.unitFindReplacementReplacementShow,
                component: UnitFindReplacementReplacementShow as VueConstructor<
                  Vue
                >,
                props: true,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
              {
                path: "applications",
                name: NamedRts.unitFindReplacementApplications,
                component: UnitFindReplacementApplications as VueConstructor<
                  Vue
                >,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
              {
                path: "placements-not-following",
                name: NamedRts.unitFindReplacementPlacementsNotFollowing,
                component: UnitFindReplacementPlacementsNotFollowing as VueConstructor<
                  Vue
                >,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
              {
                path: "applications-similar-unit",
                name: NamedRts.unitFindReplacementApplicationsSimilarUnit,
                component: UnitFindReplacementApplications as VueConstructor<
                  Vue
                >,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
              {
                path: "applications-other",
                name: NamedRts.unitFindReplacementApplicationsOther,
                component: UnitFindReplacementApplications as VueConstructor<
                  Vue
                >,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
              {
                path: "applications-unmatched",
                name: NamedRts.unitFindReplacementApplicationsUnmatched,
                component: UnitFindReplacementApplications as VueConstructor<
                  Vue
                >,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
            ],
          },
        ],
      },
      {
        path: "unit-dashboard/:unitId",
        name: NamedRts.unitDashboard,
        component: UnitDashboard as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.recruiter],
        },
        children: [
          {
            path: "unit-application-placements/",
            name: NamedRts.unitDashboardPlacementList,
            component: UnitDashboardPlacementsList as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "unit-applications/",
            name: NamedRts.unitDashboardApplicationList,
            component: UnitDashboardApplicationsList as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
        ],
      },
      {
        path: "person-unit-data/:itemType/:itemId",
        name: NamedRts.personUnitData,
        component: PersonUnitData as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.recruiter],
        },
      },
      {
        path: "person-dashboard/:personId",
        name: NamedRts.personDashboard,
        component: PersonDashboard as VueConstructor<Vue>,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.recruiter],
        },
        children: [
          {
            path: "edit",
            name: NamedRts.personDashboardPersonEdit,
            component: PersonDashboardPersonEdit as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "show",
            name: NamedRts.personDashboardPersonShow,
            component: PersonDashboardPersonShow as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "unit-application/:unitApplicationId",
            name: NamedRts.personDashboardUnitApplication,
            component: PersonDashboardUnitApplication as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
            children: [
              {
                path: "unit-application-placement/:unitApplicationPlacementId",
                name: NamedRts.personDashboardUnitApplicationPlacement,
                component: PersonDashboardUnitApplicationPlacement as VueConstructor<
                  Vue
                >,
                props: true,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },

              {
                path:
                  "unit-application-placement-edit/:unitApplicationPlacementId",
                name: NamedRts.personDashboardUnitApplicationPlacementEdit,
                component: PersonDashboardUnitApplicationPlacementEdit as VueConstructor<
                  Vue
                >,
                props: true,
                meta: {
                  requiresAuth: true,
                  requiresAdmin: true,
                  allowedResponsibilities: [UserResponsibility.recruiter],
                },
              },
            ],
          },
          {
            path: "unit-application-edit/:unitApplicationId",
            name: NamedRts.personDashboardUnitApplicationEdit,
            component: PersonDashboardUnitApplicationEdit as VueConstructor<
              Vue
            >,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
        ],
      },
      {
        path: "recruiter-dashboard",
        name: NamedRts.recruiterDashboard,
        component: RecruiterDashboard as VueConstructor<Vue>,
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
          allowedResponsibilities: [UserResponsibility.recruiter],
        },
        children: [
          {
            path: "work-in-progress",
            name: NamedRts.recruiterWorkInProgress,
            component: RecruiterWorkInProgress as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "instructions",
            name: NamedRts.recruiterInstructions,
            component: RecruiterInstructions as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "submitted-unit-applications",
            name: NamedRts.submittedUnitApplicationList,
            component: SubmittedUnitApplicationList as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "person-form/:personId",
            name: NamedRts.personForm,
            component: PersonForm as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "person-list",
            name: NamedRts.personList,
            component: PersonList as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "unit-application-form/:unitApplicationId",
            name: NamedRts.unitApplicationForm,
            component: UnitApplicationForm as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "person-unit-application-form/:unitApplicationId",
            name: NamedRts.personUnitApplicationForm,
            component: PersonUnitApplicationForm as VueConstructor<Vue>,
            props: true,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "unit-application-main-list/",
            name: NamedRts.unitApplicationMainList,
            component: UnitApplicationMainList as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "unit-application-unreplaced-placements/",
            name: NamedRts.unitApplicationUnreplacedPlacements,
            component: UnitApplicationUnreplacedPlacements as VueConstructor<
              Vue
            >,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
          {
            path: "unit-application-placements/",
            name: NamedRts.unitApplicationPlacements,
            component: UnitApplicationPlacementList as VueConstructor<Vue>,
            meta: {
              requiresAuth: true,
              requiresAdmin: true,
              allowedResponsibilities: [UserResponsibility.recruiter],
            },
          },
        ],
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, _from, next) => {
  let passedLogInCheck = false;
  let passedResponsibilityCheck = false;
  let passedAdminCheck = false;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      // needs it and has it
      passedLogInCheck = true;
    } else {
      // needs auth, does not have it
      // the to route could be store in the store here

      next({
        name: NamedRts.login,
      });
      return;
    }
  } else {
    // does not need auth
    passedLogInCheck = true;
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      // needs it and has it
      passedAdminCheck = true;
    } else {
      // needs admin, does not have it
      // go back
      next(false);
      return;
    }
  } else {
    // does not need admin
    passedAdminCheck = true;
  }

  const uR = store.getters.userResponsibility;

  if (
    to.matched.every(record => {
      return record.meta.allowedResponsibilities.includes(uR);
    })
  ) {
    passedResponsibilityCheck = true;
  } else {
    passedResponsibilityCheck = false;
  }

  if (passedLogInCheck && passedResponsibilityCheck && passedAdminCheck) {
    next();
  } else {
    next({
      name: NamedRts.landingPage,
    });
  }
});

export default router;
