import { defineComponent, computed, ref } from "@vue/composition-api";
import { UnitApplicationPlacementWarning } from "@/controllers/unitApplicationPlacementWarnings";
import {
  UnitApplicationPlacementForDisplay,
  unitApplicationPlacementForDisplayController,
  UAPLevel,
} from "@/controllers/unitApplicationPlacements";
import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";

import { buildUnitApplicationPlacement } from "@/utilities/formHelpers";

export default defineComponent({
  name: "placementsNotFollowing",
  setup(_props, ctx) {
    const $store = ctx.root.$store;

    const placement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const {
      unitApplicationPlacement,
      loadUnitApplicationPlacementIntoForm,
    } = buildUnitApplicationPlacement();

    const warnings = computed(() => {
      return $store.getters
        .unitApplicationPlacementWarnings as UnitApplicationPlacementWarning[];
    });

    const replacePlacement = (w: UnitApplicationPlacementWarning) => {
      // load the one we want
      unitApplicationPlacementForDisplayController
        .needUnitApplicationPlacementForDisplay(
          w.id.toString(),
          UAPLevel.replaces,
        )
        .then(replaces => {
          // this has to be wrapped in a ref
          // not because it needs to be reactive
          // but because the load into form expects that.
          const tmp = ref(replaces);
          loadUnitApplicationPlacementIntoForm(unitApplicationPlacement, tmp);
          unitApplicationPlacement.replacesId = placement.value.id;
          return unitApplicationPlacementForDisplayController.updateUnitApplicationPlacement(
            unitApplicationPlacement,
          );
        })
        .then(() => {
          ctx.root.$bvToast.toast("Successfully placed", TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.personDashboardUnitApplicationPlacement,
            params: {
              personId: placement.value.personId.toString(),
              unitApplicationId: placement.value.unitApplicationId.toString(),
              unitApplicationPlacementId: placement.value.id.toString(),
            },
          });
        });
    };

    return {
      replacePlacement,
      warnings,
    };
  },
});
