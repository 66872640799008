import { render, staticRenderFns } from "./placement-list-header.html?vue&type=template&id=a40e3324&scoped=true&"
import script from "./placement-list-header.ts?vue&type=script&lang=js&"
export * from "./placement-list-header.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a40e3324",
  null
  
)

export default component.exports