import api from "@/api";
import { ApiPreparatoryClassCommunicationParams } from "@/controllers/placementPacket";

async function sendInstructorUpdate(p: ApiPreparatoryClassCommunicationParams) {
  return api.sendInstructorUpdate(p);
}

export const pcInstructorUpdateController = {
  sendInstructorUpdate,
};
