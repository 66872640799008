import {
  PreparatoryClass,
  PreparatoryClassClassification,
} from "@/controllers/preparatoryClasses";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface PreparatoryClassLookup {
  [key: number]: PreparatoryClass;
}

interface PreparatoryClassesState {
  preparatoryClass: PreparatoryClass;
  preparatoryClassesForTable: PreparatoryClass[];
  preparatoryClassesForAutocomplete: AutocompleteResult[];
  preparatoryClasses: PreparatoryClassLookup;
}

const emptyPreparatoryClass: PreparatoryClass = {
  fullName: "",
  classification: PreparatoryClassClassification.boys,
  congregationId: 0,
  congregationCity: "",
  congregationName: "",
  congregationStateId: 0,
  congregationStateName: "",
  description: "",
  endDate: "",
  id: 0,
  name: "",
  number: 0,
  preparatoryClassSeasonId: 0,
  startDate: "",
};

const state: PreparatoryClassesState = {
  preparatoryClass: emptyPreparatoryClass,
  preparatoryClassesForTable: [],
  preparatoryClassesForAutocomplete: [],
  preparatoryClasses: {},
};

const mutations = {
  LOAD_PREPARATORY_CLASS(
    state: PreparatoryClassesState,
    payload: PreparatoryClass,
  ) {
    state.preparatoryClass = payload;
  },
  LOAD_PREPARATORY_CLASSES_FOR_TABLE(
    state: PreparatoryClassesState,
    payload: PreparatoryClass[],
  ) {
    state.preparatoryClassesForTable = payload;
  },
  LOAD_PREPARATORY_CLASSES_FOR_AUTOCOMPLETE(
    state: PreparatoryClassesState,
    payload: AutocompleteResult[],
  ) {
    state.preparatoryClassesForAutocomplete = payload;
  },

  LOAD_PREPARATORY_CLASSES_FOR_LOOKUP(
    state: PreparatoryClassesState,
    payload: PreparatoryClassLookup,
  ) {
    state.preparatoryClasses = payload;
  },
};

const actions = {
  loadPreparatoryClass(context: any, preparatoryClass: PreparatoryClass) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PREPARATORY_CLASS", preparatoryClass);
      resolve(preparatoryClass);
    });
  },
  loadPreparatoryClasses(context: any, preparatoryClasses: PreparatoryClass[]) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = preparatoryClasses.map(item => {
        return {
          label: item.fullName,
          id: item.id,
        };
      });

      const lookupItems = preparatoryClasses.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit("LOAD_PREPARATORY_CLASSES_FOR_AUTOCOMPLETE", acItems);
      context.commit("LOAD_PREPARATORY_CLASSES_FOR_TABLE", preparatoryClasses);
      context.commit("LOAD_PREPARATORY_CLASSES_FOR_LOOKUP", lookupItems);
      resolve(acItems);
    });
  },
};

const getters = {
  preparatoryClass: (state: PreparatoryClassesState) => {
    return state.preparatoryClass;
  },
  preparatoryClassesForTable: (state: PreparatoryClassesState) => {
    return state.preparatoryClassesForTable;
  },
  preparatoryClassesForAutocomplete: (state: PreparatoryClassesState) => {
    return state.preparatoryClassesForAutocomplete;
  },
  preparatoryClassById: (state: PreparatoryClassesState) => (id: number) => {
    return state.preparatoryClasses[id];
  },
};

const preparatoryClassesModule = {
  state,
  mutations,
  actions,
  getters,
};

export default preparatoryClassesModule;
