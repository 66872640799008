import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import PinUnitApplication from "@/components/PinUnitApplication/PinUnitApplication.vue";
import ReplacementForm from "@/components/UnitFindReplacementDashboard/ReplacementForm.vue";
import {
  unitApplicationController,
  UnitApplication,
} from "@/controllers/unitApplications";

import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";
import { unitApplicationsForReplacementController } from "@/controllers/unitApplicationsForReplacement";

import {
  MatchReplacementUnitApplicationStatus,
  matchReplacementUnitApplicationController,
} from "@/controllers/matchReplacementUnitApplications";

import { peopleController, Person } from "@/controllers/people";
import Tel from "@/components/Tel/Tel.vue";
import MailTo from "@/components/MailTo/MailTo.vue";
import {
  prettifyUAStatus,
  friendlyDate,
  hBadgeForUnitPosition,
} from "@/utilities/displayHelpers";
import UaNotes from "@/components/UnitApplicationNote/ListAndForm.vue";
import UaStatus from "@/components/UAStatus/UAStatus.vue";

import { TOAST_OPTIONS } from "@/constants";

import {
  buildUnitApplicationStatuses,
  buildUnitApplication,
} from "@/utilities/formHelpers";

import { NamedRts } from "@/router";

import UnitApplicationPreferenceDetails from "@/components/PersonDashboard/UnitApplicationPreferenceDetails.vue";

export default defineComponent({
  name: "unitFindReplacementShowApplication",

  props: {
    unitApplicationId: {
      type: String,
      required: true,
    },
  },
  components: {
    PinUnitApplication,
    LoadingIndicator,
    ReplacementForm,
    UaNotes,
    Tel,
    MailTo,
    UaStatus,
    UnitApplicationPreferenceDetails,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const editMode = ref(false);
    const replacementFormMode = ref(false);

    const submitting = ref(false);
    const submitButtonText = ref("Submit");

    const {
      loadUnitApplicationStatusesIntoForm,
      unitApplicationStatuses,
    } = buildUnitApplicationStatuses();

    const {
      unitApplication,
      loadUnitApplicationIntoForm,
    } = buildUnitApplication();

    const ua = computed(() => {
      return $store.getters.unitApplication as UnitApplication;
    });

    const p = computed(() => {
      return $store.getters.person as Person;
    });

    const placement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const sameUnitApplications = computed(() => {
      return $store.getters
        .unitApplicationsForPlacementSameUnit as UnitApplicationPlacementForDisplay[];
    });

    const similarUnitApplications = computed(() => {
      return $store.getters
        .unitApplicationsForPlacementSimilarUnit as UnitApplicationPlacementForDisplay[];
    });

    const otherUnitApplications = computed(() => {
      return $store.getters
        .unitApplicationsForPlacementOtherUnit as UnitApplicationPlacementForDisplay[];
    });

    const toggleEditMode = () => {
      editMode.value = !editMode.value;
    };

    const toggleReplacementFormMode = () => {
      replacementFormMode.value = !replacementFormMode.value;
    };

    const replacement = computed(() => {
      return $store.getters
        .unitApplicationReplacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const applicationPlaced = () => {
      ctx.root.$router.push({
        name: NamedRts.unitFindReplacementReplacementShow,
        params: {
          unitApplicationPlacementId: placement.value.id.toString(),
          unitApplicationReplacementId: replacement.value.id.toString(),
        },
      });
    };

    const unmatchApplication = () => {
      matchReplacementUnitApplicationController
        .createMatchReplacementUnitApplication({
          id: 0,
          status: MatchReplacementUnitApplicationStatus.unmatched,
          unitApplicationId: Number(props.unitApplicationId),
          unitApplicationPlacementId: placement.value.id,
        })
        .then(() => {
          ctx.root.$bvToast.toast("Successfully unmatched", TOAST_OPTIONS);
          // Some of this seems like giving too much responsibility to this
          // component. The refreshing of the applications could perhaps be moved
          // to the controller. The only problem with that is that the controller
          // may not know if it is supposed to get them for the placement or
          // the unit application
          // 2020-04-14
          return matchReplacementUnitApplicationController.needMatchReplacementUnitApplications(
            {
              unit_application_placement_id: placement.value.id,
            },
          );
        })
        .then(() => {
          return unitApplicationsForReplacementController.filterUnitApplications(
            placement.value,
          );
        })
        .then(() => {
          if (sameUnitApplications.value.length > 0) {
            ctx.root.$router.push({
              name: NamedRts.unitFindReplacementApplications,
              params: {
                unitApplicationPlacementId: placement.value.id.toString(),
              },
            });
          } else if (similarUnitApplications.value.length > 0) {
            ctx.root.$router.push({
              name: NamedRts.unitFindReplacementApplicationsSimilarUnit,
              params: {
                unitApplicationPlacementId: placement.value.id.toString(),
              },
            });
          } else {
            ctx.root.$router.push({
              name: NamedRts.unitFindReplacementApplicationsOther,
              params: {
                unitApplicationPlacementId: placement.value.id.toString(),
              },
            });
          }
        });
    };

    onMounted(() => {
      Promise.all([
        unitApplicationController.needUnitApplication(props.unitApplicationId),
      ])
        .then(() => {
          return peopleController.needPerson(ua.value.personId.toString());
        })
        .then(() => {
          loadUnitApplicationStatusesIntoForm(unitApplicationStatuses);
          loadUnitApplicationIntoForm(unitApplication, ua);
        })
        .finally(() => {
          dataLoaded.value = true;
        });
    });

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      unitApplicationController
        .updateUnitApplication(unitApplication)
        .then(() => {
          editMode.value = false;
          ctx.root.$bvToast.toast("Successfully edited", TOAST_OPTIONS);
          ctx.emit("unitApplicationUpdated");
        })
        .finally(() => {
          submitting.value = false;
          submitButtonText.value = "Submit";
        });
    };

    const availableByPlacementEndDate = (ua: UnitApplication) => {
      if (ua.availableDate <= placement.value.placementEndDate) {
        return true;
      } else {
        return false;
      }
    };

    return {
      dataLoaded,
      editMode,
      onSubmit,
      p,
      prettifyUAStatus,
      replacementFormMode,
      submitButtonText,
      submitting,
      toggleEditMode,
      toggleReplacementFormMode,
      applicationPlaced,
      ua,
      unitApplication,
      unitApplicationStatuses,

      placement,

      unmatchApplication,

      hBadgeForUnitPosition,
      friendlyDate,
      availableByPlacementEndDate,
    };
  },
});
