import axios from "axios";
import { API_URL } from "@/constants";

export const HTTP = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer {token}",
  },
});
