import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";

import {
  UnitApplication,
  unitApplicationController,
  UnitApplicationStatus,
} from "@/controllers/unitApplications";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";

import ApplicationListHeader from "@/components/UnitApplication/ApplicationListHeader.vue";
import ApplicationListBody from "@/components/UnitApplication/ApplicationListBody.vue";
import { useFilterable } from "@/utilities/sortHelpers";
import ListFilterInput from "@/components/ListFilterInput/ListFilterInput.vue";

export default defineComponent({
  name: "unitApplicationsList",
  props: {
    unitId: {
      required: true,
      type: String,
    },
  },
  components: {
    LoadingIndicator,
    ApplicationListHeader,
    ApplicationListBody,
    ListFilterInput,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const normalSort = ref(true);
    const filteredUnitApplications = ref<UnitApplication[]>([]);

    const userUnitApplicationPosition = computed(() => {
      return $store.getters.userUnitApplicationPosition as UnitPosition;
    });

    const apiUnitApplications = computed(() => {
      return $store.getters.unitApplicationsForTable as UnitApplication[];
    });

    const unitApplications = computed(() => {
      if (normalSort.value) {
        return $store.getters.unitApplicationsForTable as UnitApplication[];
      } else {
        const tempValues = $store.getters
          .unitApplicationsForTable as UnitApplication[];
        return ([] as UnitApplication[]).concat(tempValues).reverse();
      }
    });

    const qryStatusesAsArray = (q: any) => {
      if (q.statuses) {
        if (Array.isArray(q.statuses)) {
          return q.statuses as UnitApplicationStatus[];
        } else {
          return [q.statuses as UnitApplicationStatus];
        }
      } else {
        return [UnitApplicationStatus.available];
      }
    };

    onMounted(() => {
      loadData(qryStatusesAsArray(ctx.root.$route.query));
    });

    watch(
      () => ctx.root.$route.query,
      (q, _prevQ) => {
        loadData(qryStatusesAsArray(q));
      },
    );

    function loadData(s: UnitApplicationStatus[]) {
      dataLoaded.value = false;
      if (ctx.root.$route.query.statuses[0] === "placed") {
        normalSort.value = false;
      } else {
        normalSort.value = true;
      }

      const dataPromises = [
        unitApplicationController.needUnitApplications({
          unit_id: Number(props.unitId),
          position: userUnitApplicationPosition.value,
          statuses: s,
        }),
      ];

      Promise.all(dataPromises).then(() => {
        filteredUnitApplications.value = [...apiUnitApplications.value];
        dataLoaded.value = true;
      });
    }

    const { filterChanged } = useFilterable(
      apiUnitApplications,
      filteredUnitApplications,
      ["fullName", "parents", "congregation"],
    );

    return {
      filteredUnitApplications,
      filterChanged,
      dataLoaded,
      unitApplications,
    };
  },
});
