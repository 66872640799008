import api from "@/api";

// 2020-02-26 The notes are not being stored in the store
// The api is sending this with js keys
export interface ApiPreparatoryClassNote {
  content: string;
  timeAgoInWords: string;
  createdDate: string;
  userName: string;
  id: number;
}

export interface ApiPreparatoryClassNoteParams {
  preparatory_class_note: {
    preparatory_class_id: number;
    content: string;
  };
}

async function needPreparatoryClassNotes(pcId: number) {
  return api
    .getPreparatoryClassNotes(pcId)
    .then((response: ApiPreparatoryClassNote[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClassNote(pcId: number) {
  return api
    .deletePreparatoryClassNote(pcId)
    .then((response: ApiPreparatoryClassNote[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function createPreparatoryClassNote(pcId: number, noteText: string) {
  const apiParams: ApiPreparatoryClassNoteParams = {
    preparatory_class_note: {
      preparatory_class_id: pcId,
      content: noteText,
    },
  };

  return api
    .postPreparatoryClassNote(apiParams)
    .then((response: ApiPreparatoryClassNote[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassNotesController = {
  needPreparatoryClassNotes,
  deletePreparatoryClassNote,
  createPreparatoryClassNote,
};
