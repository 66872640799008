import { PreparatoryClassStatistic } from "@/controllers/preparatoryClassStatistics";

interface StatisticLookup {
  [key: number]: PreparatoryClassStatistic;
}

interface PreparatoryClassStatisticsState {
  preparatoryClassStatisticsForTable: PreparatoryClassStatistic[];
  preparatoryClassStatistics: StatisticLookup;
  pcStatisticsTotalPlacements: number;
  pcStatisticsConfirmedPlacements: number;
}

const state: PreparatoryClassStatisticsState = {
  preparatoryClassStatisticsForTable: [],
  preparatoryClassStatistics: {},
  pcStatisticsTotalPlacements: 0,
  pcStatisticsConfirmedPlacements: 0,
};

const mutations = {
  LOAD_PREPARATORY_CLASS_STATISTICS(
    state: PreparatoryClassStatisticsState,
    payload: StatisticLookup,
  ) {
    state.preparatoryClassStatistics = payload;
  },
  LOAD_PREPARATORY_CLASS_STATISTICS_FOR_TABLE(
    state: PreparatoryClassStatisticsState,
    payload: PreparatoryClassStatistic[],
  ) {
    state.preparatoryClassStatisticsForTable = payload;
  },
};

const actions = {
  loadPreparatoryClassStatistics(
    context: any,
    statistics: PreparatoryClassStatistic[],
  ) {
    return new Promise((resolve, _reject) => {
      const lookupItems = statistics.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit("LOAD_PREPARATORY_CLASS_STATISTICS_FOR_TABLE", statistics);
      context.commit("LOAD_PREPARATORY_CLASS_STATISTICS", lookupItems);
      resolve(statistics);
    });
  },
};

const getters = {
  preparatoryClassStatisticById: (state: PreparatoryClassStatisticsState) => (
    id: number,
  ) => {
    return state.preparatoryClassStatistics[id];
  },
  getPreparatoryClassPlacementsCount: (
    state: PreparatoryClassStatisticsState,
  ) => (id: number) => {
    return state.preparatoryClassStatistics[id].placements;
  },
  getPreparatoryClassConfirmedPlacementsCount: (
    state: PreparatoryClassStatisticsState,
  ) => (id: number) => {
    return state.preparatoryClassStatistics[id].confirmedPlacements;
  },
  getPreparatoryClassPendingPreferencesCount: (
    state: PreparatoryClassStatisticsState,
  ) => (id: number) => {
    return state.preparatoryClassStatistics[id].pendingFirstPreferences;
  },
};

const preparatoryClassStatisticsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default preparatoryClassStatisticsModule;
