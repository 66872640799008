import { InstructorPreparatoryClassDetail } from "@/controllers/instructorPreparatoryClassDetails";

interface InstructorPreparatoryClassDetailsState {
  instructorPreparatoryClassDetails: InstructorPreparatoryClassDetail[];
}
const state: InstructorPreparatoryClassDetailsState = {
  instructorPreparatoryClassDetails: [],
};

const mutations = {
  LOAD_INSTRUCTOR_PREPARATORY_CLASS_DETAILS(
    state: InstructorPreparatoryClassDetailsState,
    payload: InstructorPreparatoryClassDetail[],
  ) {
    state.instructorPreparatoryClassDetails = payload;
  },
};

const actions = {
  loadInstructorPreparatoryClassDetails(
    context: any,
    items: InstructorPreparatoryClassDetail[],
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_INSTRUCTOR_PREPARATORY_CLASS_DETAILS", items);
      resolve(items);
    });
  },
};

const getters = {
  instructorPreparatoryClassDetails: (
    state: InstructorPreparatoryClassDetailsState,
  ) => {
    return state.instructorPreparatoryClassDetails;
  },
};

const instructorPreparatoryClassDetailsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default instructorPreparatoryClassDetailsModule;
