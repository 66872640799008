import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import {
  unitApplicationPlacementForDisplayController,
  UAPLevel,
  UnitApplicationPlacementForDisplay,
} from "@/controllers/unitApplicationPlacements";

export default defineComponent({
  name: "unitFindReplacementShowReplacement",
  props: {
    unitApplicationReplacementId: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingIndicator,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const placement = computed(() => {
      return $store.getters
        .unitApplicationReplacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    onMounted(() => {
      Promise.all([
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementForDisplay(
          props.unitApplicationReplacementId,
          UAPLevel.replacement,
        ),
      ]).then(() => {
        dataLoaded.value = true;
      });
    });

    return {
      placement,
      dataLoaded,
    };
  },
});
