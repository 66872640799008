import api from "@/api";
import { SendEmailMode } from "@/controllers/placementPacket";
import {
  ApiPreparatoryClassCommunicationParams,
  ApiPreparatoryClassApplicationCommunicationParams,
} from "@/controllers/placementPacket";

async function sendMinisterUpdate(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return api.sendMinisterUpdate(p);
}

export const pcaMinisterUpdateController = {
  sendMinisterUpdate,
};
