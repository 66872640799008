import { defineComponent } from "@vue/composition-api";

import FormPartial from "@/components/UnitApplication/FormPartial.vue";

export default defineComponent({
  name: "UnitApplicationFormNew",
  components: {
    FormPartial,
  },
  props: {
    unitApplicationId: {
      type: String,
      required: true,
    },
    personId: {
      type: String,
      required: true,
    },
  },

  setup(_props, _ctx) {
    return {};
  },
});
