import { v4 as uuidv4 } from "uuid";
import CpsAutocompleteObj from "@/components/Autocomplete/AutocompleteObj.vue";
import CpsAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { FormModes, setFormMode } from "@/main";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
} from "@vue/composition-api";
import { peopleListController } from "@/controllers/peopleList";
import {
  Congregation,
  congregationsController,
} from "@/controllers/congregations";
import { statesController, StateProvince } from "@/controllers/states";
import { zipForCityController } from "@/controllers/zipForCity";
import {
  peopleController,
  Gender,
  Person,
  PhoneNumberDescription,
  MaritalStatus,
  Qualification,
} from "@/controllers/people";
import {
  InstructorStatus,
  preparatoryClassInstructorsController,
  PreparatoryClassInstructor,
} from "@/controllers/preparatoryClassInstructors";
import { PreparatoryClassClassification } from "@/controllers/preparatoryClasses";

import {
  addPhoneNumber,
  PhoneNumberFormItem,
  EmailAddressFormItem,
  addEmailAddress,
} from "@/utilities/formHelpers";

export default defineComponent({
  name: "defaultForm",
  components: {
    FormErrors,
    LoadingIndicator,
    CpsAutocompleteObj,
    CpsAutocomplete,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const formMode = ref(FormModes.new);
    const submitting = ref(false);
    const submitButtonText = ref("Save");

    const fd = reactive({
      personId: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      congregationId: 0,
      stateProvinceId: 0,
      address: "",
      address2: "",
      city: "",
      zip: "",
      emailAddresses: [] as EmailAddressFormItem[],
      phoneNumbers: [] as PhoneNumberFormItem[],
    });

    const instructorData = reactive({
      id: 0,
      status: "active",
      startDate: "",
      endDate: "",
      notes: "",
    });

    const cancelRoute = {
      name: NamedRts.instructorList,
    };

    const nextRoute = {
      name: NamedRts.instructorList,
    };

    onMounted(() => {
      const dataPromises = [
        peopleListController.needPeopleMales(),
        congregationsController.needCongregations(),
        statesController.needStatesProvinces(),
      ];
      formMode.value = setFormMode(props.id);

      if (formMode.value === FormModes.new) {
        Promise.all(dataPromises)
          .then(_response => {
            dataLoaded.value = true;
            addEmailAddress(fd.emailAddresses);
            addPhoneNumber(fd.phoneNumbers, PhoneNumberDescription.cell);
          })
          .catch(errors => {
            loadErrors(errors);
            dataLoaded.value = true;
          });
      } else {
        dataPromises.push(
          preparatoryClassInstructorsController.needPreparatoryClassInstructor(
            props.id,
          ),
        );
        Promise.all(dataPromises)
          .then(_response => {
            dataLoaded.value = true;
            const tempData = apiData.value;
            instructorData.id = tempData.id;
            instructorData.endDate = tempData.endDate;
            instructorData.status = tempData.status;
            instructorData.startDate = tempData.startDate;
            instructorData.notes = tempData.notes;
            return peopleController.needPerson(tempData.personId.toString());
          })
          .then((response: Person) => {
            fd.personId = response.id;
            fd.firstName = response.firstName;
            fd.middleName = response.middleName;
            fd.lastName = response.lastName;
            fd.congregationId = response.congregationId;
            fd.stateProvinceId = response.stateProvinceId;
            fd.address = response.address;
            fd.address2 = response.address2;
            fd.city = response.city;
            fd.zip = response.zip;
            fd.phoneNumbers = response.phoneNumbers.map(item => {
              const desc =
                item.description === "home"
                  ? PhoneNumberDescription.home
                  : item.description === "fax"
                  ? PhoneNumberDescription.fax
                  : PhoneNumberDescription.cell;
              const bId = `cps-${uuidv4()}`;
              return {
                id: item.id,
                inputLabel: `Phone - ${desc}`,
                browserId: bId,
                number: item.number,
                description: desc,
                _destroy: false,
              };
            });

            fd.emailAddresses = response.emailAddresses.map(item => {
              const bId = `cps-${uuidv4()}`;
              return {
                id: item.id,
                inputLabel: "Email",
                browserId: bId,
                address: item.address,
                _destroy: false,
              };
            });

            const c: Congregation = congregationById(response.congregationId);
            const s: StateProvince = stateProvinceById(
              response.stateProvinceId,
            );
            ctx.root.$nextTick(() => {
              refCongregationInput.value.setResultParent({
                id: c.id,
                label: c.fullName,
              });

              refStateInput.value.setResultParent({
                id: s.id,
                label: s.fullName,
              });
            });
          })
          .catch(errors => {
            loadErrors(errors);
            dataLoaded.value = true;
          });
      }
    });

    const selectExistingPerson = ref(false);
    const toggleSelectExistingPerson = () => {
      selectExistingPerson.value = !selectExistingPerson.value;
    };

    const apiData = computed(() => {
      return $store.getters
        .preparatoryClassInstructor as PreparatoryClassInstructor;
    });

    const apiPerson = computed(() => {
      return $store.getters.person as Person;
    });

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      let apiResponse;

      // reset some things
      resetErrors();

      // set some things
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      const person = {
        id: fd.personId,
        firstName: fd.firstName,
        middleName: fd.middleName,
        lastName: fd.lastName,
        birthDate: "",
        gender: Gender.male,
        maritalStatus: MaritalStatus.married,
        parents: "",
        congregationId: fd.congregationId,
        congregationName: "",
        qualification: Qualification.normal,
        driversLicense: "",
        address: fd.address,
        address2: fd.address2,
        city: fd.city,
        stateProvinceId: fd.stateProvinceId,
        stateProvinceName: "",
        stateProvinceAbbreviation: "",
        zip: fd.zip,
        birthCity: "",
        birthStateId: 0,
        birthStateName: "",
        phoneNumbers: fd.phoneNumbers,
        emailAddresses: fd.emailAddresses,
        citizenships: [],
      };

      if (formMode.value === FormModes.edit) {
        // some of these items are used for the edit to make sure that
        // they are not overwritten
        person.gender = apiPerson.value.gender;
        person.maritalStatus = apiPerson.value.maritalStatus;
        person.parents = apiPerson.value.parents;
        person.qualification = apiPerson.value.qualification;
        person.driversLicense = apiPerson.value.driversLicense;
        person.birthCity = apiPerson.value.birthCity;
        if (apiPerson.value.birthStateId) {
          person.birthStateId = apiPerson.value.birthStateId;
        }

        peopleController
          .updatePerson(person)
          .then(responsePerson => {
            // the person edits were saved
            // save the edits for the instructor
            // build the instructor data
            const st =
              instructorData.status === "inactive"
                ? InstructorStatus.inactive
                : InstructorStatus.active;
            // some of the data is not needed by the api
            const instructor: PreparatoryClassInstructor = {
              id: instructorData.id,
              personId: responsePerson.id,
              name: "",
              status: st,
              classification: apiData.value.classification,
              startDate: instructorData.startDate,
              endDate: instructorData.endDate,
              notes: instructorData.notes,
              congregationNameWithState: "",
              emailAddress: "",
              phoneNumber: "",
            };
            return preparatoryClassInstructorsController.updatePreparatoryClassInstructor(
              instructor,
            );
          })
          .then(_response => {
            ctx.root.$bvToast.toast(
              "Data was successfully saved.",
              TOAST_OPTIONS,
            );
            ctx.root.$router.push(nextRoute);
          })
          .catch(error => {
            loadErrors(error);
          })
          .finally(() => {
            // reset some values
            submitting.value = false;
            submitButtonText.value = "Submit";
          });
      } else {
        // an edit form
        if (fd.personId === 0) {
          // it is a new person post the data
          apiResponse = peopleController.createPerson(person);
          apiResponse
            .then((responsePerson: Person) => {
              fd.personId = responsePerson.id;
              // build the instructor data
              const st =
                instructorData.status === "inactive"
                  ? InstructorStatus.inactive
                  : InstructorStatus.active;
              // some of the data is not needed by the api
              const instructor: PreparatoryClassInstructor = {
                id: instructorData.id,
                personId: responsePerson.id,
                name: "",
                status: st,
                classification: PreparatoryClassClassification.boys,
                startDate: instructorData.startDate,
                endDate: instructorData.endDate,
                notes: instructorData.notes,
                congregationNameWithState: "",
                emailAddress: "",
                phoneNumber: "",
              };
              return preparatoryClassInstructorsController.createPreparatoryClassInstructor(
                instructor,
              );
            })
            .then(_response => {
              ctx.root.$bvToast.toast(
                "Data was successfully saved.",
                TOAST_OPTIONS,
              );
              ctx.root.$router.push(nextRoute);
            })
            .catch(error => {
              loadErrors(error);
            })
            .finally(() => {
              // reset some values
              submitting.value = false;
              submitButtonText.value = "Submit";
            });
        } else {
          // assume the if the form is in new mode
          // the personId is an existing person
          // the only data that needs to be saved is the instructor data
          const st =
            instructorData.status === "inactive"
              ? InstructorStatus.inactive
              : InstructorStatus.active;
          // some of the data is not needed by the api
          const instructor: PreparatoryClassInstructor = {
            id: instructorData.id,
            personId: fd.personId,
            name: "",
            status: st,
            classification: PreparatoryClassClassification.boys,
            startDate: instructorData.startDate,
            endDate: instructorData.endDate,
            notes: instructorData.notes,
            congregationNameWithState: "",
            emailAddress: "",
            phoneNumber: "",
          };
          preparatoryClassInstructorsController
            .createPreparatoryClassInstructor(instructor)
            .then(_response => {
              ctx.root.$bvToast.toast(
                "Data was successfully saved.",
                TOAST_OPTIONS,
              );
              ctx.root.$router.push(nextRoute);
            })
            .catch(error => {
              loadErrors(error);
            })
            .finally(() => {
              // reset some values
              submitting.value = false;
              submitButtonText.value = "Submit";
            });
        }
      }
    };

    // Errors
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const resetErrors = () => {
      hasErrors.value = false;
      errorMessages.value = [];
    };

    const loadErrors = (errors: string[]) => {
      hasErrors.value = true;
      errorMessages.value = errors;
    };

    // End Errors

    // Delete Items
    const deleting = ref(false);
    const deleteButtonText = ref("Delete");

    const startDelete = () => {
      deleting.value = true;
      deleteButtonText.value = "Deleting...";
      if (formMode.value === FormModes.new) {
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
      }
    };

    const resetDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const performDelete = () => {
      resetErrors();
      // build the instructor data
      const st =
        instructorData.status === "inactive"
          ? InstructorStatus.inactive
          : InstructorStatus.active;
      // some of the data is not needed by the api
      const instructor: PreparatoryClassInstructor = {
        id: instructorData.id,
        personId: apiData.value.personId,
        name: "",
        status: st,
        classification: apiData.value.classification,
        startDate: instructorData.startDate,
        endDate: instructorData.endDate,
        notes: instructorData.notes,
        congregationNameWithState: "",
        emailAddress: "",
        phoneNumber: "",
      };
      preparatoryClassInstructorsController
        .deletePreparatoryClassInstructor(instructor)
        .then(_response => {
          resetDelete();
          ctx.root.$bvToast.toast("Item deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          resetDelete();
          loadErrors(errors);
        });
    };

    // End Delete
    // These are the items necessary for
    // an person autcomplete
    const refPersonInput: any = ref(null);

    const peopleForAutocomplete = computed(() => {
      return $store.getters.peopleMales;
    });

    const personAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updatePersonAc = (evt: AutocompleteResult) => {
      fd.personId = evt.id;
    };
    // End person autocomplete object

    const lookupCityZip = () => {
      if (fd.city !== "" && fd.zip === "") {
        zipForCityController
          .zipForCity(fd.city, fd.stateProvinceId.toString())
          .then(response => {
            if (response) {
              fd.zip = response;
              return response;
            }
          });
      }
    };

    // These are the items necessary for
    // the congregation Autocomplete
    const refCongregationInput: any = ref(null);

    const congregationsForAutocomplete = computed(() => {
      return $store.getters.congregationsForAutocomplete;
    });

    const congregationAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateCongregationAc = (evt: AutocompleteResult) => {
      fd.congregationId = evt.id;
      congregationAcResult.id = evt.id;
      congregationAcResult.label = evt.label;

      const c: Congregation = congregationById(evt.id);

      if (fd.city === "") {
        fd.city = c.city;
      }

      lookupCityZip();
    };

    const congregationById = (id: number) => {
      return $store.getters.congregationById(id);
    };

    // End congregation autocomplete

    // These are the items necessary for
    // the state Autcomplete object
    const refStateInput: any = ref(null);

    const statesProvincesForAutocomplete = computed(() => {
      return $store.getters.statesProvincesForAutocomplete;
    });

    const stateAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateStateAc = (evt: AutocompleteResult) => {
      fd.stateProvinceId = evt.id;
      stateAcResult.id = evt.id;
      stateAcResult.label = evt.label;
    };

    const stateProvinceById = (id: number) => {
      return $store.getters.stateProvinceById(id);
    };

    // End state autocomplete

    return {
      fd,
      instructorData,
      formMode,

      cancelRoute,
      dataLoaded,
      errorMessages,
      hasErrors,

      onSubmit,
      submitButtonText,
      submitting,

      deleteButtonText,
      deleting,
      performDelete,
      resetDelete,
      startDelete,

      refPersonInput,
      peopleForAutocomplete,
      personAcResult,
      updatePersonAc,

      refCongregationInput,
      congregationsForAutocomplete,
      congregationAcResult,
      updateCongregationAc,

      lookupCityZip,

      refStateInput,
      statesProvincesForAutocomplete,
      stateAcResult,
      updateStateAc,

      toggleSelectExistingPerson,
      selectExistingPerson,
    };
  },
});
