import { defineComponent, PropType } from "@vue/composition-api";
import UapStatus from "@/components/UAPStatus/UAPStatus.vue";

export default defineComponent({
  name: "unitOtherPeopleModal",
  components: {
    UapStatus,
  },
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    content: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  setup(_props, ctx) {
    const cm = () => {
      ctx.emit("closeModal");
    };
    return {
      cm,
    };
  },
});
