import {
  MatchReplacementUnitApplication,
  MatchReplacementUnitApplicationStatus,
} from "@/controllers/matchReplacementUnitApplications";

interface MatchReplacementUnitApplicationState {
  matchedReplacementUnitApplications: MatchReplacementUnitApplication[];
  unmatchedReplacementUnitApplications: MatchReplacementUnitApplication[];
}

const state: MatchReplacementUnitApplicationState = {
  matchedReplacementUnitApplications: [],
  unmatchedReplacementUnitApplications: [],
};

const mutations = {
  LOAD_MATCHED_REPLACEMENT_UNIT_APPLICATIONS(
    state: MatchReplacementUnitApplicationState,
    payload: MatchReplacementUnitApplication[],
  ) {
    state.matchedReplacementUnitApplications = payload;
  },
  LOAD_UNMATCHED_REPLACEMENT_UNIT_APPLICATIONS(
    state: MatchReplacementUnitApplicationState,
    payload: MatchReplacementUnitApplication[],
  ) {
    state.unmatchedReplacementUnitApplications = payload;
  },
};

const actions = {
  loadMatchReplacementUnitApplications(
    context: any,
    matchReplacementApplications: MatchReplacementUnitApplication[],
  ) {
    return new Promise((resolve, _reject) => {
      const byStatus = matchReplacementApplications.reduce(
        (accum, item) => {
          if (item.status === MatchReplacementUnitApplicationStatus.matched) {
            accum.matchedApplications.push(item);
          } else {
            accum.unmatchedApplications.push(item);
          }

          return accum;
        },
        {
          matchedApplications: [] as MatchReplacementUnitApplication[],
          unmatchedApplications: [] as MatchReplacementUnitApplication[],
        },
      );
      context.commit(
        "LOAD_MATCHED_REPLACEMENT_UNIT_APPLICATIONS",
        byStatus.matchedApplications,
      );
      context.commit(
        "LOAD_UNMATCHED_REPLACEMENT_UNIT_APPLICATIONS",
        byStatus.unmatchedApplications,
      );
      resolve(matchReplacementApplications);
    });
  },
};

const getters = {
  matchedReplacementUnitApplications: (
    state: MatchReplacementUnitApplicationState,
  ) => {
    return state.matchedReplacementUnitApplications;
  },
  unmatchedReplacementUnitApplications: (
    state: MatchReplacementUnitApplicationState,
  ) => {
    return state.unmatchedReplacementUnitApplications;
  },
  unmatchedPlacementIds: (state: MatchReplacementUnitApplicationState) => {
    const unmatchedIds = new Set();

    for (const u of state.unmatchedReplacementUnitApplications) {
      unmatchedIds.add(u.unitApplicationPlacementId);
    }
    return unmatchedIds;
  },
};

const matchReplacementUnitApplicationsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default matchReplacementUnitApplicationsModule;
