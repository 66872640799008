import {
  UnitPositionTermLength,
  UnitPosition,
} from "@/controllers/unitPositionTermLengths";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface UnitPositionTermLengthLookup {
  [key: number]: UnitPositionTermLength;
}

interface UnitWithPositionTermLengthLookup {
  [key: string]: UnitPositionTermLength;
}

interface UnitPositionTermLengthsState {
  unitPositionTermLength: UnitPositionTermLength;
  unitPositionTermLengthsForTable: UnitPositionTermLength[];
  unitPositionTermLengthsForAutocomplete: AutocompleteResult[];
  unitPositionTermLengths: UnitPositionTermLengthLookup;
  unitWithPositionTermLengths: UnitWithPositionTermLengthLookup;
}

const emptyUnitPositionTermLength: UnitPositionTermLength = {
  id: 0,
  unitId: 0,
  unitName: "",
  position: UnitPosition.boysUnitVolunteer,
  termLength: 6,
  quantity: 4,
};

const state: UnitPositionTermLengthsState = {
  unitPositionTermLength: emptyUnitPositionTermLength,
  unitPositionTermLengthsForTable: [],
  unitPositionTermLengthsForAutocomplete: [],
  unitPositionTermLengths: {},
  unitWithPositionTermLengths: {},
};

const mutations = {
  LOAD_UNIT_POSITION_TERM_LENGTH(
    state: UnitPositionTermLengthsState,
    payload: UnitPositionTermLength,
  ) {
    state.unitPositionTermLength = payload;
  },
  LOAD_UNIT_POSITION_TERM_LENGTHS_FOR_TABLE(
    state: UnitPositionTermLengthsState,
    payload: UnitPositionTermLength[],
  ) {
    state.unitPositionTermLengthsForTable = payload;
  },
  LOAD_UNIT_POSITION_TERM_LENGTHS_FOR_AUTOCOMPLETE(
    state: UnitPositionTermLengthsState,
    payload: AutocompleteResult[],
  ) {
    state.unitPositionTermLengthsForAutocomplete = payload;
  },
  LOAD_UNIT_POSITION_TERM_LENGTHS_FOR_LOOKUP(
    state: UnitPositionTermLengthsState,
    payload: UnitPositionTermLengthLookup,
  ) {
    state.unitPositionTermLengths = payload;
  },
  LOAD_UNIT_WITH_POSITION_TERM_LENGTHS_FOR_LOOKUP(
    state: UnitPositionTermLengthsState,
    payload: UnitWithPositionTermLengthLookup,
  ) {
    state.unitWithPositionTermLengths = payload;
  },
};

const actions = {
  loadUnitPositionTermLength(
    context: any,
    unitPositionTermLength: UnitPositionTermLength,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_UNIT_POSITION_TERM_LENGTH", unitPositionTermLength);
      resolve(unitPositionTermLength);
    });
  },
  loadUnitPositionTermLengths(
    context: any,
    unitPositionTermLengths: UnitPositionTermLength[],
  ) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = unitPositionTermLengths.map(
        item => {
          return {
            label: item.position,
            id: item.id,
          };
        },
      );

      const lookupItems = unitPositionTermLengths.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      const lookupItemsUnitWithPosition = unitPositionTermLengths.reduce(
        (accum, item) => {
          return {
            ...accum,
            [`${item.position}${item.unitId}`]: {
              ...item,
            },
          };
        },
        {},
      );

      context.commit(
        "LOAD_UNIT_POSITION_TERM_LENGTHS_FOR_AUTOCOMPLETE",
        acItems,
      );
      context.commit(
        "LOAD_UNIT_POSITION_TERM_LENGTHS_FOR_TABLE",
        unitPositionTermLengths,
      );
      context.commit("LOAD_UNIT_POSITION_TERM_LENGTHS_FOR_LOOKUP", lookupItems);
      context.commit(
        "LOAD_UNIT_WITH_POSITION_TERM_LENGTHS_FOR_LOOKUP",
        lookupItemsUnitWithPosition,
      );
      resolve(acItems);
    });
  },
};

const getters = {
  unitPositionTermLength: (state: UnitPositionTermLengthsState) => {
    return state.unitPositionTermLength;
  },
  unitPositionTermLengthsForTable: (state: UnitPositionTermLengthsState) => {
    return state.unitPositionTermLengthsForTable;
  },
  unitPositionTermLengthsForAutocomplete: (
    state: UnitPositionTermLengthsState,
  ) => {
    return state.unitPositionTermLengthsForAutocomplete;
  },

  unitPositionTermLengthById: (state: UnitPositionTermLengthsState) => (
    id: number,
  ) => {
    return state.unitPositionTermLengths[id];
  },
  unitWithPositionTermLengthLookup: (state: UnitPositionTermLengthsState) => (
    position: UnitPosition,
    unitId: number,
  ) => {
    return state.unitWithPositionTermLengths[`${position}${unitId}`];
  },
};

const unitPositionTermLengthsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default unitPositionTermLengthsModule;
