import api from "@/api";
import store, { StoreActions } from "@/store";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import {
  UnitPosition,
  unitPositionEnumReverseLookup,
} from "@/controllers/unitPositionTermLengths";

export const enum UnitApplicationStatus {
  available = "available",
  pending = "pending",
  teaching = "teaching",
  untilVolunteerNotifies = "until_volunteer_notifies",
  onHold = "on_hold",
  placed = "placed",
  unavailable = "unavailable",
}

export const unitApplicationStatusReverseLookup = <
  { [key: string]: UnitApplicationStatus }
>{
  available: UnitApplicationStatus.available,
  pending: UnitApplicationStatus.pending,
  teaching: UnitApplicationStatus.teaching,
  until_volunteer_notifies: UnitApplicationStatus.untilVolunteerNotifies,
  on_hold: UnitApplicationStatus.onHold,
  placed: UnitApplicationStatus.placed,
  unavailable: UnitApplicationStatus.unavailable,
};

export const prettifiedUnitApplicationStatus = {
  available: "Available",
  pending: "Pending",
  teaching: "Teaching",
  until_volunteer_notifies: "Until Volunteer Notifies",
  on_hold: "On Hold",
  placed: "Placed",
  unavailable: "Unavailable",
};

export interface ApiUnitApplicationPreference {
  id: number;
  unit_id: number;
  unit_name: string;
  unit_application_id: number;
}

export interface ApiUnitApplicationNote {
  id: number;
  content: string;
  user: string;
  user_id: number;
  created_at: string;
}

export interface ApiUnitApplicationIndexParams {
  unit_id?: number;
  person_id?: number;
  position?: UnitPosition;
  statuses?: UnitApplicationStatus[];
}

export interface ApiUnitApplication {
  id: number;
  person_id: number;
  spouse_id: number;
  full_name: string;
  birth_date: string;
  parents: string;
  congregation: string;
  congregation_id: number;
  submitted_date: string;
  available_date: string;
  status: string;
  position: string;
  minister_name: string;
  minister_email_address: string;
  minister_phone_number: string;
  unit_application_preferences: ApiUnitApplicationPreference[];
  unit_application_notes: ApiUnitApplicationNote[];
}

export interface ApiUnitApplicationParams {
  submitted_unit_application?: {
    id: number;
  };
  unit_application: {
    person_id: number;
    spouse_id: number;
    submitted_date: string;
    available_date: string;
    status: UnitApplicationStatus;
    position: UnitPosition;
    minister_name: string;
    minister_email_address: string;
    minister_phone_number: string;
    unit_application_preferences_attributes: ApiUnitApplicationPreferenceParams[];
  };
}

export interface ApiUnitApplicationPreferenceParams {
  id?: number;
  unit_id: number;
  _destroy: boolean;
}

export interface UnitApplication {
  personId: number;
  spouseId: number;
  id: number;
  fullName: string;
  parents: string;
  birthDate: string;
  congregation: string;
  congregationId: number;
  position: UnitPosition;
  ministerEmailAddress: string;
  ministerName: string;
  ministerPhoneNumber: string;
  status: UnitApplicationStatus;
  submittedDate: string;
  availableDate: string;
  unitApplicationPreferences: UnitApplicationPreference[];
  unitApplicationNotes: UnitApplicationNote[];
}

export interface UnitApplicationPreference {
  _destroy: boolean;
  id: number;
  unitId: number;
  unitApplicationId: number;
  unitName: string;
}

export interface UnitApplicationNote {
  id: number;
  content: string;
  user: string;
  createdAt: string;
  timeAgoInWords: string;
  userId: number;
}

export const emptyUnitApplication = (): UnitApplication => {
  return {
    fullName: "",
    congregation: "",
    parents: "",
    birthDate: "",
    congregationId: 0,
    personId: 0,
    spouseId: 0,
    id: 0,
    position: UnitPosition.boysUnitVolunteer,
    ministerEmailAddress: "",
    ministerName: "",
    ministerPhoneNumber: "",
    status: UnitApplicationStatus.available,
    submittedDate: "",
    availableDate: "",
    unitApplicationPreferences: [],
    unitApplicationNotes: [],
  };
};

function apiUnitApplicationToUnitApplication(
  p: ApiUnitApplication,
): UnitApplication {
  const applicationPosition = unitPositionEnumReverseLookup[p.position];

  const st = unitApplicationStatusReverseLookup[p.status];

  const preferences: UnitApplicationPreference[] = p.unit_application_preferences.map(
    item => {
      return {
        _destroy: false,
        id: item.id,
        unitId: item.unit_id,
        unitName: item.unit_name,
        unitApplicationId: item.unit_application_id,
      };
    },
  );

  const notes: UnitApplicationNote[] = p.unit_application_notes.map(item => {
    const distanceToNow = formatDistanceToNow(parseISO(item.created_at));

    return {
      id: item.id,
      content: item.content,
      user: item.user,
      createdAt: item.created_at,
      timeAgoInWords: distanceToNow,
      userId: item.user_id,
    };
  });

  return {
    fullName: p.full_name,
    parents: p.parents,
    congregation: p.congregation,
    congregationId: p.congregation_id,
    personId: p.person_id,
    birthDate: p.birth_date,
    spouseId: p.spouse_id,
    id: p.id,
    position: applicationPosition,
    ministerEmailAddress: p.minister_email_address,
    ministerName: p.minister_name,
    ministerPhoneNumber: p.minister_phone_number,
    status: st,
    submittedDate: p.submitted_date,
    availableDate: p.available_date,
    unitApplicationPreferences: preferences,
    unitApplicationNotes: notes,
  };
}

async function createUnitApplication(c: UnitApplication, sId?: number) {
  let cParams: ApiUnitApplicationParams;
  const preferences = c.unitApplicationPreferences.map(item => {
    return {
      unit_id: item.unitId,
      _destroy: item._destroy,
    };
  });
  if (sId) {
    cParams = {
      submitted_unit_application: {
        id: sId,
      },
      unit_application: {
        person_id: c.personId,
        spouse_id: c.spouseId,
        submitted_date: c.submittedDate,
        available_date: c.availableDate,
        status: c.status,
        position: c.position,
        minister_name: c.ministerName,
        minister_email_address: c.ministerEmailAddress,
        minister_phone_number: c.ministerPhoneNumber,
        unit_application_preferences_attributes: preferences,
      },
    };
  } else {
    cParams = {
      unit_application: {
        person_id: c.personId,
        spouse_id: c.spouseId,
        submitted_date: c.submittedDate,
        available_date: c.availableDate,
        status: c.status,
        position: c.position,
        minister_name: c.ministerName,
        minister_email_address: c.ministerEmailAddress,
        minister_phone_number: c.ministerPhoneNumber,
        unit_application_preferences_attributes: preferences,
      },
    };
  }

  return api
    .postUnitApplication(cParams)
    .then((response: ApiUnitApplication) => {
      return store.dispatch(
        StoreActions.loadUnitApplication,
        apiUnitApplicationToUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitApplications(p?: ApiUnitApplicationIndexParams) {
  return api
    .getUnitApplications(p)
    .then((response: ApiUnitApplication[]) => {
      const c = response.map(item => {
        return apiUnitApplicationToUnitApplication(item);
      });
      return store.dispatch(StoreActions.loadUnitApplications, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitApplication(UnitApplicationId: string) {
  return api
    .getUnitApplication(UnitApplicationId)
    .then((response: ApiUnitApplication) => {
      return store.dispatch(
        StoreActions.loadUnitApplication,
        apiUnitApplicationToUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateUnitApplication(c: UnitApplication) {
  const cParams: ApiUnitApplicationParams = {
    unit_application: {
      person_id: c.personId,
      spouse_id: c.spouseId,
      submitted_date: c.submittedDate,
      available_date: c.availableDate,
      status: c.status,
      position: c.position,
      minister_name: c.ministerName,
      minister_email_address: c.ministerEmailAddress,
      minister_phone_number: c.ministerPhoneNumber,
      unit_application_preferences_attributes: c.unitApplicationPreferences.map(
        item => {
          let temp: ApiUnitApplicationPreferenceParams = {
            unit_id: item.unitId,
            _destroy: item._destroy,
          };

          if (item.id !== 0) {
            temp = {
              id: item.id,
              ...temp,
            };
          }

          return temp;
        },
      ),
    },
  };

  return api
    .patchUnitApplication(c.id, cParams)
    .then((response: ApiUnitApplication) => {
      return store.dispatch(
        StoreActions.loadUnitApplication,
        apiUnitApplicationToUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteUnitApplication(c: UnitApplication) {
  return api
    .deleteUnitApplication(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const unitApplicationController = {
  createUnitApplication,
  needUnitApplications,
  needUnitApplication,
  updateUnitApplication,
  deleteUnitApplication,
};
