import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "unitApplicationListHeader",
  components: {},
  props: {},
  setup(_props, _ctx) {
    return {};
  },
});
