import { SubmittedPreparatoryClassApplication } from "@/controllers/submittedPreparatoryClassApplications";
import { Gender } from "@/controllers/people";
import { SubmittedPreparatoryClassApplicationStatus } from "@/controllers/public/submittedPreparatoryClassApplication";

interface SubmittedPreparatoryClassApplicationLookup {
  [key: number]: SubmittedPreparatoryClassApplication;
}

interface SubmittedPreparatoryClassApplicationsState {
  submittedPreparatoryClassApplication: SubmittedPreparatoryClassApplication;
  submittedPreparatoryClassApplicationsForTable: SubmittedPreparatoryClassApplication[];
  submittedPreparatoryClassApplications: SubmittedPreparatoryClassApplicationLookup;
}

const emptySubmittedPreparatoryClassApplication: SubmittedPreparatoryClassApplication = {
  id: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  birthDate: "",
  gender: Gender.male,
  phoneNumber: "",
  emailAddress: "",
  fatherFirstName: "",
  motherFirstName: "",
  ministerName: "",
  ministerEmail: "",
  ministerConfirmation: false,
  acceptsCpsPolicy: false,
  ministerPhoneNumber: "",
  ministerNote: "",
  submittedAt: "",
  status: SubmittedPreparatoryClassApplicationStatus.pending,
  travelPlans: "",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
  congregationId: 0,
  stateProvinceId: 0,
  firstChoiceId: 0,
  secondChoiceId: 0,
  thirdChoiceId: 0,
  fourthChoiceId: 0,
};

const state: SubmittedPreparatoryClassApplicationsState = {
  submittedPreparatoryClassApplication: emptySubmittedPreparatoryClassApplication,
  submittedPreparatoryClassApplicationsForTable: [],
  submittedPreparatoryClassApplications: {},
};

const mutations = {
  LOAD_SUBMITTED_PREPARATORY_CLASS_APPLICATION(
    state: SubmittedPreparatoryClassApplicationsState,
    payload: SubmittedPreparatoryClassApplication,
  ) {
    state.submittedPreparatoryClassApplication = payload;
  },
  LOAD_SUBMITTED_PREPARATORY_CLASS_APPLICATIONS_FOR_TABLE(
    state: SubmittedPreparatoryClassApplicationsState,
    payload: SubmittedPreparatoryClassApplication[],
  ) {
    state.submittedPreparatoryClassApplicationsForTable = payload;
  },

  LOAD_SUBMITTED_PREPARATORY_CLASS_APPLICATIONS_FOR_LOOKUP(
    state: SubmittedPreparatoryClassApplicationsState,
    payload: SubmittedPreparatoryClassApplicationLookup,
  ) {
    state.submittedPreparatoryClassApplications = payload;
  },
};

const actions = {
  loadSubmittedPreparatoryClassApplications(
    context: any,
    submittedPreparatoryClassApplications: SubmittedPreparatoryClassApplication[],
  ) {
    return new Promise((resolve, _reject) => {
      const lookupItems = submittedPreparatoryClassApplications.reduce(
        (accum, item) => {
          return {
            ...accum,
            [item.id]: {
              ...item,
            },
          };
        },
        {},
      );

      context.commit(
        "LOAD_SUBMITTED_PREPARATORY_CLASS_APPLICATIONS_FOR_TABLE",
        submittedPreparatoryClassApplications,
      );
      context.commit(
        "LOAD_SUBMITTED_PREPARATORY_CLASS_APPLICATIONS_FOR_LOOKUP",
        lookupItems,
      );
      resolve(submittedPreparatoryClassApplications);
    });
  },

  loadSubmittedPreparatoryClassApplication(
    context: any,
    submittedPreparatoryClassApplication: SubmittedPreparatoryClassApplication,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_SUBMITTED_PREPARATORY_CLASS_APPLICATION",
        submittedPreparatoryClassApplication,
      );
      resolve(submittedPreparatoryClassApplication);
    });
  },
};

const getters = {
  submittedPreparatoryClassApplication: (
    state: SubmittedPreparatoryClassApplicationsState,
  ) => {
    return state.submittedPreparatoryClassApplication;
  },
  submittedPreparatoryClassApplicationsForTable: (
    state: SubmittedPreparatoryClassApplicationsState,
  ) => {
    return state.submittedPreparatoryClassApplicationsForTable;
  },
  countOfSubmittedPreparatoryClassApplicationsForTable: (
    state: SubmittedPreparatoryClassApplicationsState,
  ) => {
    return state.submittedPreparatoryClassApplicationsForTable.length;
  },
  submittedPreparatoryClassApplicationById: (
    state: SubmittedPreparatoryClassApplicationsState,
  ) => (id: number) => {
    return state.submittedPreparatoryClassApplications[id];
  },
};

const submittedPreparatoryClassApplicationsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default submittedPreparatoryClassApplicationsModule;
