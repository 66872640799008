import api from "@/api";
import store, { StoreActions } from "@/store";
import { Gender } from "./people";

interface ApiSubmittedPreparatoryClassApplicationPreference {
  id: number;
  preparatory_class_id: number;
  preference_order: number;
}

export interface ApiSubmittedPreparatoryClassApplication {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  gender: string;
  birth_date: string;
  father_first_name: string;
  mother_first_name: string;
  phone_number: string;
  email_address: string;
  accepts_cps_policy: boolean;
  minister_confirmation: boolean;
  congregation_id: number;
  address: string;
  address2: string;
  city: string;
  state_id: number;
  zip: string;
  submitted_at: string;
  minister_name: string;
  minister_email_address: string;
  minister_phone_number: string;
  minister_note: string;
  status: string;
  travel_plans: string;
  preferences: ApiSubmittedPreparatoryClassApplicationPreference[];
}

export interface ApiSubmittedPreparatoryClassApplicationParams {
  address2: string;
  address: string;
  birth_date: string;
  city: string;
  congregation_id: number;
  email_address: string;
  father_first_name: string;
  first_name: string;
  gender: string;
  last_name: string;
  middle_name: string;
  minister_email_address: string;
  minister_name: string;
  minister_note: string;
  minister_phone_number: string;
  mother_first_name: string;
  phone_number: string;
  state_id: number;
  status: string;
  submitted_at: string;
  travel_plans: string;
  zip: string;
}

export interface SubmittedPreparatoryClassApplication {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  gender: Gender;
  phoneNumber: string;
  emailAddress: string;
  fatherFirstName: string;
  motherFirstName: string;
  ministerName: string;
  ministerEmail: string;
  ministerPhoneNumber: string;
  ministerNote: string;
  submittedAt: string;
  status: string;
  acceptsCpsPolicy: boolean;
  ministerConfirmation: boolean;
  travelPlans: string;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  congregationId: number;
  stateProvinceId: number;
  firstChoiceId: number;
  secondChoiceId: number;
  thirdChoiceId: number;
  fourthChoiceId: number;
}

function apiSubmittedPreparatoryClassApplicationToSubmittedPreparatoryClassApplication(
  p: ApiSubmittedPreparatoryClassApplication,
): SubmittedPreparatoryClassApplication {
  const g = p.gender === "male" ? Gender.male : Gender.female;
  // get the preferences
  const firstChoice = p.preferences[0]
    ? p.preferences[0].preparatory_class_id
    : 0;
  const secondChoice = p.preferences[1]
    ? p.preferences[1].preparatory_class_id
    : 0;
  const thirdChoice = p.preferences[2]
    ? p.preferences[2].preparatory_class_id
    : 0;
  const fourthChoice = p.preferences[3]
    ? p.preferences[3].preparatory_class_id
    : 0;

  return {
    id: p.id,
    firstName: p.first_name,
    stateProvinceId: p.state_id,
    middleName: p.middle_name,
    lastName: p.last_name,
    birthDate: p.birth_date,
    gender: g,
    phoneNumber: p.phone_number,
    emailAddress: p.email_address,
    fatherFirstName: p.father_first_name,
    motherFirstName: p.mother_first_name,
    ministerName: p.minister_name,
    ministerEmail: p.minister_email_address,
    ministerPhoneNumber: p.minister_phone_number,
    ministerNote: p.minister_note,
    travelPlans: p.travel_plans,
    address1: p.address,
    address2: p.address2,
    city: p.city,
    zipCode: p.zip,
    status: p.status,
    submittedAt: p.submitted_at,
    congregationId: p.congregation_id,
    firstChoiceId: firstChoice,
    secondChoiceId: secondChoice,
    thirdChoiceId: thirdChoice,
    fourthChoiceId: fourthChoice,
    acceptsCpsPolicy: p.accepts_cps_policy,
    ministerConfirmation: p.minister_confirmation,
  };
}

async function createSubmittedPreparatoryClassApplication(
  c: SubmittedPreparatoryClassApplication,
) {
  const cParams: ApiSubmittedPreparatoryClassApplicationParams = {
    first_name: c.firstName,
    last_name: c.lastName,
    middle_name: c.middleName,
    gender: c.gender,
    birth_date: c.birthDate,
    father_first_name: c.fatherFirstName,
    mother_first_name: c.motherFirstName,
    phone_number: c.phoneNumber,
    email_address: c.emailAddress,
    congregation_id: c.congregationId,
    address: c.address1,
    address2: c.address2,
    city: c.city,
    state_id: c.stateProvinceId,
    zip: c.zipCode,
    submitted_at: c.submittedAt,
    minister_name: c.ministerName,
    minister_email_address: c.ministerEmail,
    minister_phone_number: c.ministerPhoneNumber,
    minister_note: c.ministerNote,
    status: c.status,
    travel_plans: c.travelPlans,
  };
  return api
    .postSubmittedPreparatoryClassApplication(cParams)
    .then((response: ApiSubmittedPreparatoryClassApplication) => {
      return store.dispatch(
        StoreActions.loadSubmittedPreparatoryClassApplication,
        apiSubmittedPreparatoryClassApplicationToSubmittedPreparatoryClassApplication(
          response,
        ),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needSubmittedPreparatoryClassApplications() {
  await api
    .getSubmittedPreparatoryClassApplications()
    .then((response: ApiSubmittedPreparatoryClassApplication[]) => {
      const c = response.map(item => {
        return apiSubmittedPreparatoryClassApplicationToSubmittedPreparatoryClassApplication(
          item,
        );
      });
      return store.dispatch(
        StoreActions.loadSubmittedPreparatoryClassApplications,
        c,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needSubmittedPreparatoryClassApplication(
  submittedPreparatoryClassApplicationId: string,
) {
  return api
    .getSubmittedPreparatoryClassApplication(
      submittedPreparatoryClassApplicationId,
    )
    .then((response: ApiSubmittedPreparatoryClassApplication) => {
      return store.dispatch(
        StoreActions.loadSubmittedPreparatoryClassApplication,
        apiSubmittedPreparatoryClassApplicationToSubmittedPreparatoryClassApplication(
          response,
        ),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateSubmittedPreparatoryClassApplication(
  c: SubmittedPreparatoryClassApplication,
) {
  const cParams: ApiSubmittedPreparatoryClassApplicationParams = {
    first_name: c.firstName,
    last_name: c.lastName,
    middle_name: c.middleName,
    gender: c.gender,
    birth_date: c.birthDate,
    father_first_name: c.fatherFirstName,
    mother_first_name: c.motherFirstName,
    phone_number: c.phoneNumber,
    email_address: c.emailAddress,
    congregation_id: c.congregationId,
    address: c.address1,
    address2: c.address2,
    city: c.city,
    state_id: c.stateProvinceId,
    zip: c.zipCode,
    submitted_at: c.submittedAt,
    minister_name: c.ministerName,
    minister_email_address: c.ministerEmail,
    minister_phone_number: c.ministerPhoneNumber,
    minister_note: c.ministerNote,
    status: c.status,
    travel_plans: c.travelPlans,
  };
  return api
    .patchSubmittedPreparatoryClassApplication(c.id, cParams)
    .then((response: ApiSubmittedPreparatoryClassApplication) => {
      return store.dispatch(
        StoreActions.loadSubmittedPreparatoryClassApplication,
        apiSubmittedPreparatoryClassApplicationToSubmittedPreparatoryClassApplication(
          response,
        ),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteSubmittedPreparatoryClassApplication(
  c: SubmittedPreparatoryClassApplication,
) {
  return api
    .deleteSubmittedPreparatoryClassApplication(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const submittedPreparatoryClassApplicationsController = {
  createSubmittedPreparatoryClassApplication,
  needSubmittedPreparatoryClassApplications,
  needSubmittedPreparatoryClassApplication,
  updateSubmittedPreparatoryClassApplication,
  deleteSubmittedPreparatoryClassApplication,
};
