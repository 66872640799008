export interface UserSetting {
  internalName: UserSettingName;
  integerValue: number;
  stringValue: string;
}

export const enum UserSettingName {
  currentPreparatoryClassSeason = "current_preparatory_class_season",
  currentUnitApplicationPosition = "current_unit_application_position",
}

interface UserSettingLookup {
  [key: string]: UserSetting;
}

interface UserSettingsState {
  userSettings: UserSettingLookup;
}

const state: UserSettingsState = {
  userSettings: {},
};

const mutations = {
  SET_CURRENT_PREPARATORY_CLASS_SEASON(
    state: UserSettingsState,
    payload: UserSetting,
  ) {
    state.userSettings[payload.internalName] = payload;
    state.userSettings = {
      ...state.userSettings,
    };
  },
};

const actions = {
  setCurrentPreparatoryClassSeason(context: any, season: number) {
    return new Promise((resolve, _reject) => {
      const us: UserSetting = {
        internalName: UserSettingName.currentPreparatoryClassSeason,
        stringValue: "",
        integerValue: season,
      };
      context.commit("SET_CURRENT_PREPARATORY_CLASS_SEASON", us);
      resolve(us);
    });
  },
};

const getters = {
  currentPreparatoryClassSeason: (state: UserSettingsState) => {
    const t = state.userSettings[UserSettingName.currentPreparatoryClassSeason];
    return t;
  },
};

const userSettingsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userSettingsModule;
