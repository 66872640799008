import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { TOAST_OPTIONS } from "@/constants";
import format from "date-fns/format";
import startOfToday from "date-fns/startOfToday";
import {
  PreparatoryClassTaskStatus,
  ApiPreparatoryClassTask,
  preparatoryClassTasksController,
} from "@/controllers/pcTasks";

export default defineComponent({
  name: "preparatoryClassTasks",
  components: {
    LoadingIndicator,
  },
  props: {
    preparatoryClassId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const taskText = ref("");
    const tasks = ref<ApiPreparatoryClassTask[]>([]);

    onMounted(() => {
      preparatoryClassTasksController
        .needPreparatoryClassTasks(Number(props.preparatoryClassId))
        .then(response => {
          tasks.value = response;
          dataLoaded.value = true;
        });
    });

    const deleteTask = (taskId: number) => {
      preparatoryClassTasksController
        .deletePreparatoryClassTask(taskId)
        .then(response => {
          tasks.value = response;
          ctx.root.$bvToast.toast("Task deleted.", TOAST_OPTIONS);
        });
    };

    const completeTask = (task: ApiPreparatoryClassTask) => {
      preparatoryClassTasksController
        .updatePreparatoryClassTask({
          id: task.id,
          preparatoryClassId: task.preparatoryClassId,
          description: task.description,
          status: PreparatoryClassTaskStatus.completed,
          sortOrder: task.sortOrder,
          completedDate: format(startOfToday(), "yyyy-MM-dd"),
        })
        .then(response => {
          tasks.value = response;
          ctx.root.$bvToast.toast("Task completed.", TOAST_OPTIONS);
          taskText.value = "";
        });
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      if (taskText.value !== "") {
        preparatoryClassTasksController
          .createPreparatoryClassTask({
            id: 0,
            preparatoryClassId: Number(props.preparatoryClassId),
            description: taskText.value,
            status: PreparatoryClassTaskStatus.pending,
            sortOrder: (tasks.value.length + 1) * 100,
            completedDate: "",
          })
          .then(response => {
            tasks.value = response;
            ctx.root.$bvToast.toast("Task created.", TOAST_OPTIONS);
            taskText.value = "";
          });
      }
    };

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    return {
      onSubmit,
      deleteTask,
      dataLoaded,
      tasks,
      taskText,
      isAdmin,
      completeTask,
    };
  },
});
