import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "formErrors",
  props: {
    errorMessages: Array,
    hasErrors: Boolean,
  },

  setup(_props) {
    return {};
  },
});
