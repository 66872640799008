import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiCountry {
  id: number;
  name: string;
  abbreviation: string;
}

export interface ApiCountryParams {
  name: string;
  abbreviation: string;
}

export interface Country {
  id: number;
  name: string;
  abbreviation: string;
}

export function apiCountryToCountry(p: ApiCountry): Country {
  return {
    id: p.id,
    name: p.name,
    abbreviation: p.abbreviation,
  };
}

async function createCountry(c: Country) {
  const cParams: ApiCountryParams = {
    name: c.name,
    abbreviation: c.abbreviation,
  };
  return api
    .postCountry(cParams)
    .then((response: ApiCountry) => {
      return store.dispatch(
        StoreActions.loadCountry,
        apiCountryToCountry(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needCountries() {
  return api
    .getCountries()
    .then((response: ApiCountry[]) => {
      const c = response.map(item => {
        return apiCountryToCountry(item);
      });
      return store.dispatch(StoreActions.loadCountries, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needCountry(countryId: string) {
  return api
    .getCountry(countryId)
    .then((response: ApiCountry) => {
      return store.dispatch(
        StoreActions.loadCountry,
        apiCountryToCountry(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateCountry(c: Country) {
  const cParams: ApiCountryParams = {
    name: c.name,
    abbreviation: c.abbreviation,
  };
  return api
    .patchCountry(c.id, cParams)
    .then((response: ApiCountry) => {
      return store.dispatch(
        StoreActions.loadCountry,
        apiCountryToCountry(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteCountry(c: Country) {
  return api
    .deleteCountry(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const countriesController = {
  createCountry,
  needCountry,
  needCountries,
  updateCountry,
  deleteCountry,
};
