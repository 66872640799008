import api from "@/api";
import store, { StoreActions } from "@/store";

import {
  UserClassification,
  UserResponsibility,
  userClassificationReverseLookup,
  userResponsibilityReverseLookup,
} from "@/store/user";

export interface ApiAppRoleParams {
  app_role: {
    current: boolean;
  };
}

export interface ApiAppRole {
  id: number;
  user_id: number;
  classification: string;
  responsibility: string;
  current: boolean;
}

export interface AppRole {
  id: number;
  userId: number;
  classification: UserClassification;
  responsibility: UserResponsibility;
  current: boolean;
}

function apiAppRoleToAppRole(p: ApiAppRole): AppRole {
  const uc = userClassificationReverseLookup[p.classification];
  const ur = userResponsibilityReverseLookup[p.responsibility];
  return {
    id: p.id,
    userId: p.user_id,
    classification: uc,
    responsibility: ur,
    current: p.current,
  };
}

async function needAppRoles() {
  return api.getAppRoles().then((response: ApiAppRole[]) => {
    const c = response.map(item => {
      return apiAppRoleToAppRole(item);
    });

    return store.dispatch(StoreActions.loadAppRoles, c);
  });
}

async function updateAppRole(c: AppRole) {
  const cParams: ApiAppRoleParams = {
    app_role: {
      current: c.current,
    },
  };

  return api
    .patchAppRole(c.id, cParams)
    .then((response: ApiAppRole[]) => {
      return store.dispatch(StoreActions.loadAppRoles, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const appRolesController = {
  needAppRoles,
  updateAppRole,
};
