import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";

import { PersonUnitDataItem } from "@/components/PersonUnitData/person-unit-data";
import { NamedRts } from "@/router";

import { UserClassification } from "@/store/user";

import {
  prettifiedGender,
  Gender,
  Person,
  peopleController,
} from "@/controllers/people";
import startOfToday from "date-fns/startOfToday";
import formatISO from "date-fns/formatISO";
import subYears from "date-fns/subYears";
import format from "date-fns/format";

export default defineComponent({
  name: "personList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const filter = ref("");
    const filterOn = ref(["fullName", "congregationName"]);
    const updatedAfterDate = ref<Date>(startOfToday());
    const listGender = ref<Gender>(Gender.male);

    function loadData() {
      dataLoaded.value = false;

      const dataPromises = [
        peopleController.needPeople({
          updated_after: formatISO(updatedAfterDate.value, {
            representation: "date",
          }),
          gender: listGender.value,
        }),
      ];

      Promise.all(dataPromises).then(() => {
        dataLoaded.value = true;
      });
    }
    const fields = [
      {
        key: "fullName",
        label: "Name",
        sortable: true,
      },
      {
        key: "parents",
        label: "Parents",
        sortable: true,
      },
      {
        key: "congregationName",
        label: "Congregation",
        sortable: true,
      },
    ];

    const people = computed(() => {
      const temp = $store.getters.peopleForTable as Person[];

      return temp.map(item => {
        let fn = "";
        if (item.firstName && item.middleName && item.lastName) {
          fn = `${item.firstName} ${item.middleName} ${item.lastName}`;
        } else if (item.firstName && item.lastName) {
          fn = `${item.firstName} ${item.lastName}`;
        } else {
          fn = `${item.firstName}`;
        }

        return {
          id: item.id,
          fullName: fn,
          parents: item.parents,
          congregationName: item.congregationName,
          gender: item.gender,
        };
      });
    });

    const personDashboardLink = (id: number) => {
      return {
        name: NamedRts.personDashboardPersonShow,
        params: {
          personId: id.toString(),
        },
      };
    };

    const personRoute = (p: Person) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.person,
          itemId: p.id,
        },
      };
    };

    const subtractYearsFromUpdatedAfterDate = (numOfYears: number) => {
      updatedAfterDate.value = subYears(updatedAfterDate.value, numOfYears);
    };

    const getOlderData = () => {
      subtractYearsFromUpdatedAfterDate(1);
      loadData();
    };

    const localFriendlyDate = () => {
      return format(updatedAfterDate.value, "MMMM d, yyyy");
    };

    const getOtherGender = () => {
      if (listGender.value === Gender.male) {
        listGender.value = Gender.female;
      } else {
        listGender.value = Gender.male;
      }

      loadData();
    };

    const otherGender = () => {
      if (listGender.value === Gender.male) {
        return prettifiedGender[Gender.female];
      } else {
        return prettifiedGender[Gender.male];
      }
    };

    const userClassification = computed(() => {
      return $store.getters.userClassification as UserClassification;
    });

    const genderColorClass = (p: Gender) => {
      if (p === Gender.female) {
        return {
          girls: true,
        };
      } else {
        return {
          boys: true,
        };
      }
    };

    onMounted(() => {
      if (userClassification.value === UserClassification.girls) {
        listGender.value = Gender.female;
      } else {
        listGender.value = Gender.male;
      }
      subtractYearsFromUpdatedAfterDate(2);
      loadData();
    });

    return {
      filter,
      filterOn,

      personDashboardLink,

      fields,
      dataLoaded,
      people,
      personRoute,

      getOlderData,
      getOtherGender,
      otherGender,

      localFriendlyDate,
      genderColorClass,
    };
  },
});
