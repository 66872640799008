import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiPreparatoryClassStatistic {
  id: number;
  name: string;
  placements: number;
  confirmed_placements: number;
  pending_first_preferences: number;
}

export interface PreparatoryClassStatistic {
  id: number;
  name: string;
  placements: number;
  confirmedPlacements: number;
  pendingFirstPreferences: number;
}

function apiPreparatoryClassStatisticToStatistic(
  p: ApiPreparatoryClassStatistic,
): PreparatoryClassStatistic {
  return {
    id: p.id,
    name: p.name,
    placements: p.placements,
    confirmedPlacements: p.confirmed_placements,
    pendingFirstPreferences: p.pending_first_preferences,
  };
}

async function needPreparatoryClassStatistics() {
  return api
    .getPreparatoryClassStatistics()
    .then((response: ApiPreparatoryClassStatistic[]) => {
      const c = response.map(item => {
        return apiPreparatoryClassStatisticToStatistic(item);
      });
      return store.dispatch(StoreActions.loadPreparatoryClassStatistics, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassStatisticsController = {
  needPreparatoryClassStatistics,
};
