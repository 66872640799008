export interface PersistentUserData {
  ministerName: string;
  ministerEmail: string;
  congregationId: number;
  city: string;
  zipCode: string;
  stateId: number;
  ministerPhoneNumber: string;
}

const enum LocalStorageKeys {
  city = "city",
  congregationId = "congregationId",
  ministerEmail = "ministerEmail",
  ministerName = "ministerName",
  ministerPhoneNumber = "ministerPhoneNumber",
  stateId = "stateId",
  zipCode = "zipCode",
  sessionToken = "sessionToken",
}

export function setPersistentUserDate(d: PersistentUserData) {
  localStorage.setItem(LocalStorageKeys.city, d.city);
  localStorage.setItem(
    LocalStorageKeys.congregationId,
    d.congregationId.toString(),
  );
  localStorage.setItem(LocalStorageKeys.ministerEmail, d.ministerEmail);
  localStorage.setItem(LocalStorageKeys.ministerName, d.ministerName);
  localStorage.setItem(
    LocalStorageKeys.ministerPhoneNumber,
    d.ministerPhoneNumber,
  );
  localStorage.setItem(LocalStorageKeys.stateId, d.stateId.toString());
  localStorage.setItem(LocalStorageKeys.zipCode, d.zipCode);
}

export function getPersistentUserData(): PersistentUserData {
  const pud: PersistentUserData = {
    city: "",
    congregationId: 0,
    ministerEmail: "",
    ministerName: "",
    ministerPhoneNumber: "",
    stateId: 0,
    zipCode: "",
  };

  const city = localStorage.getItem(LocalStorageKeys.city);
  const congregationId = localStorage.getItem(LocalStorageKeys.congregationId);
  const ministerEmail = localStorage.getItem(LocalStorageKeys.ministerEmail);
  const ministerName = localStorage.getItem(LocalStorageKeys.ministerName);
  const ministerPhoneNumber = localStorage.getItem(
    LocalStorageKeys.ministerPhoneNumber,
  );
  const stateId = localStorage.getItem(LocalStorageKeys.stateId);
  const zipCode = localStorage.getItem(LocalStorageKeys.zipCode);

  if (city) {
    pud.city = city;
  }

  if (congregationId) {
    pud.congregationId = parseFloat(congregationId);
  }

  if (ministerEmail) {
    pud.ministerEmail = ministerEmail;
  }

  if (ministerName) {
    pud.ministerName = ministerName;
  }

  if (ministerPhoneNumber) {
    pud.ministerPhoneNumber = ministerPhoneNumber;
  }

  if (stateId) {
    pud.stateId = parseFloat(stateId);
  }

  if (zipCode) {
    pud.zipCode = zipCode;
  }

  return pud;
}

export function getSessionToken(): string {
  const t = localStorage.getItem(LocalStorageKeys.sessionToken);

  if (t) {
    return t;
  } else {
    return "";
  }
}

export function setSessionToken(token: string): void {
  localStorage.setItem(LocalStorageKeys.sessionToken, token);
}
