import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiPinnedUnitApplicationParams {
  unit_application_id: number;
}

export interface ApiPinnedUnitApplicationFull {
  id: number;
  pinned_for_user_id: number;
  unit_application_id: number;
  name: string;
  congregation_name: string;
  congregation_city: string;
  state: string;
  parents: string;
  latest_note_content: string;
  latest_note_user: string;
  latest_note_created_at: string;
}

export interface ApiPinnedUnitApplicationPartial {
  id: number;
  unit_application_id: number;
}

export interface PinnedUnitApplicationFull {
  id: number;
  pinnedForUserId: number;
  unitApplicationId: number;
  name: string;
  congregationName: string;
  congregationCity: string;
  state: string;
  parents: string;
  latestNoteContent: string;
  latestNoteUser: string;
  latestNoteCreatedAt: string;
}

export interface PinnedUnitApplicationPartial {
  id: number;
  unitApplicationId: number;
}

export const emptyPinnedUnitApplicationPartial: PinnedUnitApplicationPartial = {
  id: 0,
  unitApplicationId: 0,
};

function apiPinnedUnitApplicationFullToPinnedUnitApplication(
  p: ApiPinnedUnitApplicationFull,
): PinnedUnitApplicationFull {
  return {
    id: p.id,
    pinnedForUserId: p.pinned_for_user_id,
    unitApplicationId: p.unit_application_id,
    name: p.name,
    congregationName: p.congregation_name,
    congregationCity: p.congregation_city,
    state: p.state,
    parents: p.parents,
    latestNoteContent: p.latest_note_content,
    latestNoteUser: p.latest_note_user,
    latestNoteCreatedAt: p.latest_note_created_at,
  };
}

function apiPinnedUnitApplicationPartialToPinnedUnitApplication(
  p: ApiPinnedUnitApplicationPartial,
): PinnedUnitApplicationPartial {
  return {
    id: p.id,
    unitApplicationId: p.unit_application_id,
  };
}

async function createPinnedUnitApplication(c: PinnedUnitApplicationPartial) {
  const cParams: ApiPinnedUnitApplicationParams = {
    unit_application_id: c.unitApplicationId,
  };

  return api
    .postPinnedUnitApplication(cParams)
    .then((response: ApiPinnedUnitApplicationPartial) => {
      return store.dispatch(
        StoreActions.loadPinnedUnitApplication,
        apiPinnedUnitApplicationPartialToPinnedUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPinnedUnitApplications() {
  return api
    .getPinnedUnitApplications()
    .then((response: ApiPinnedUnitApplicationFull[]) => {
      const c = response.map(item => {
        return apiPinnedUnitApplicationFullToPinnedUnitApplication(item);
      });
      return store.dispatch(StoreActions.loadPinnedUnitApplications, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPinnedUnitApplication(unitApplicationId: string) {
  // This is non-standard because the unit application id is being used to check
  // for the item and not the actual id of the item.
  return api
    .getPinnedUnitApplication(unitApplicationId)
    .then((response: ApiPinnedUnitApplicationPartial) => {
      return store.dispatch(
        StoreActions.loadPinnedUnitApplication,
        apiPinnedUnitApplicationPartialToPinnedUnitApplication(response),
      );
    })
    .catch(_error => {
      return Promise.resolve("");
    });
}

async function deletePinnedUnitApplication(
  c: PinnedUnitApplicationPartial | PinnedUnitApplicationFull,
) {
  return api.deletePinnedUnitApplication(c.unitApplicationId).then(() => {
    return store.dispatch(
      StoreActions.loadPinnedUnitApplication,
      emptyPinnedUnitApplicationPartial,
    );
  });
}

export const pinnedUnitApplicationController = {
  createPinnedUnitApplication,
  needPinnedUnitApplication,
  needPinnedUnitApplications,
  deletePinnedUnitApplication,
};
