interface PeopleNamesState {
  firstNames: string[];
  middleNames: string[];
  lastNames: string[];
}

const state: PeopleNamesState = {
  firstNames: [],
  middleNames: [],
  lastNames: [],
};

const mutations = {
  LOAD_FIRST_NAMES(state: PeopleNamesState, payload: string[]) {
    state.firstNames = payload;
  },
  LOAD_MIDDLE_NAMES(state: PeopleNamesState, payload: string[]) {
    state.middleNames = payload;
  },
  LOAD_LAST_NAMES(state: PeopleNamesState, payload: string[]) {
    state.lastNames = payload;
  },
};

const actions = {
  loadFirstNames(context: any, names: string[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_FIRST_NAMES", names);
      resolve(names);
    });
  },
  loadMiddleNames(context: any, names: string[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_MIDDLE_NAMES", names);
      resolve(names);
    });
  },
  loadLastNames(context: any, names: string[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_LAST_NAMES", names);
      resolve(names);
    });
  },
};

const getters = {
  firstNames: (state: PeopleNamesState) => {
    return state.firstNames;
  },
  middleNames: (state: PeopleNamesState) => {
    return state.middleNames;
  },
  lastNames: (state: PeopleNamesState) => {
    return state.lastNames;
  },
};

const peopleNamesModule = {
  state,
  mutations,
  actions,
  getters,
};

export default peopleNamesModule;
