import { defineComponent } from "@vue/composition-api";
import { ApplicationFormEvent } from "@/components/PreparatoryClassApplication/form";

export default defineComponent({
  name: "similarPeople",
  props: {
    people: Array,
  },

  setup(_props, ctx) {
    const selectPerson = (p: any) => {
      ctx.emit(ApplicationFormEvent.selectSimilarPerson, p.id);
    };

    return {
      selectPerson,
    };
  },
});
