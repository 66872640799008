import {
  SubmittedUnitApplication,
  SubmittedUnitApplicationStatus,
} from "@/controllers/submittedUnitApplications";
import { Gender, Qualification } from "@/controllers/people";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";

interface SubmittedUnitApplicationLookup {
  [key: number]: SubmittedUnitApplication;
}

interface SubmittedUnitApplicationsState {
  submittedUnitApplication: SubmittedUnitApplication;
  submittedUnitApplicationsForTable: SubmittedUnitApplication[];
  submittedUnitApplications: SubmittedUnitApplicationLookup;
}

const emptySubmittedUnitApplication: SubmittedUnitApplication = {
  id: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  gender: Gender.male,
  phoneNumber: "",
  emailAddress: "",
  birthDate: "",
  fatherFirstName: "",
  motherFirstName: "",
  availableDate: "",
  acceptsCpsPolicy: false,
  ministerConfirmation: false,
  driversLicense: "",
  dlStateId: 0,
  birthCity: "",
  birthStateId: 0,
  congregationId: 0,
  address1: "",
  address2: "",
  city: "",
  stateProvinceId: 0,
  zipCode: "",
  submittedAt: "",
  ministerName: "",
  ministerEmail: "",
  ministerPhoneNumber: "",
  ministerNote: "",
  status: SubmittedUnitApplicationStatus.pending,
  applicantNote: "",
  qualification: Qualification.normal,
  position: UnitPosition.boysUnitVolunteer,
  unitApplicationId: 0,
  preferences: [],
  citizenships: [],
};

const state: SubmittedUnitApplicationsState = {
  submittedUnitApplication: emptySubmittedUnitApplication,
  submittedUnitApplicationsForTable: [],
  submittedUnitApplications: {},
};

const mutations = {
  LOAD_SUBMITTED_UNIT_APPLICATION(
    state: SubmittedUnitApplicationsState,
    payload: SubmittedUnitApplication,
  ) {
    state.submittedUnitApplication = payload;
  },
  LOAD_SUBMITTED_UNIT_APPLICATIONS_FOR_TABLE(
    state: SubmittedUnitApplicationsState,
    payload: SubmittedUnitApplication[],
  ) {
    state.submittedUnitApplicationsForTable = payload;
  },

  LOAD_SUBMITTED_UNIT_APPLICATIONS_FOR_LOOKUP(
    state: SubmittedUnitApplicationsState,
    payload: SubmittedUnitApplicationLookup,
  ) {
    state.submittedUnitApplications = payload;
  },
};

const actions = {
  loadSubmittedUnitApplications(
    context: any,
    submittedUnitApplications: SubmittedUnitApplication[],
  ) {
    return new Promise((resolve, _reject) => {
      const lookupItems = submittedUnitApplications.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit(
        "LOAD_SUBMITTED_UNIT_APPLICATIONS_FOR_TABLE",
        submittedUnitApplications,
      );
      context.commit(
        "LOAD_SUBMITTED_UNIT_APPLICATIONS_FOR_LOOKUP",
        lookupItems,
      );
      resolve(submittedUnitApplications);
    });
  },

  loadSubmittedUnitApplication(
    context: any,
    submittedUnitApplication: SubmittedUnitApplication,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_SUBMITTED_UNIT_APPLICATION",
        submittedUnitApplication,
      );
      resolve(submittedUnitApplication);
    });
  },
};

const getters = {
  submittedUnitApplication: (state: SubmittedUnitApplicationsState) => {
    return state.submittedUnitApplication;
  },
  submittedUnitApplicationsForTable: (
    state: SubmittedUnitApplicationsState,
  ) => {
    return state.submittedUnitApplicationsForTable;
  },
  countOfSubmittedUnitApplicationsForTable: (
    state: SubmittedUnitApplicationsState,
  ) => {
    return state.submittedUnitApplicationsForTable.length;
  },
  submittedUnitApplicationById: (state: SubmittedUnitApplicationsState) => (
    id: number,
  ) => {
    return state.submittedUnitApplications[id];
  },
};

const submittedUnitApplicationsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default submittedUnitApplicationsModule;
