import api from "@/api";
import { SendEmailMode } from "./placementPacket";

export interface EmailBuilderRecipient {
  id: number;
  name: string;
  congregationNameWithState: string;
  emailAddress: string;
  selected: boolean;
}

export interface ApiEmailBuilderRecipient {
  id: number;
  name: string;
  congregation_name_with_state: string;
  email_address: string;
}

export interface ApiEmailBuilderPreparatoryClass {
  id: number;
  preparatory_class_name: string;
  congregation_name_with_state: string;
  applicants: ApiEmailBuilderRecipient[];
  ministers: ApiEmailBuilderRecipient[];
}

export interface EmailBuilderPreparatoryClass {
  id: number;
  preparatoryClassName: string;
  congregationNameWithState: string;
  applicants: EmailBuilderRecipient[];
  ministers: EmailBuilderRecipient[];
}

export interface ApiEmailBuilderResponse {
  instructors: ApiEmailBuilderRecipient[];
  courtesy_committee_members: ApiEmailBuilderRecipient[];
  preparatory_classes: ApiEmailBuilderPreparatoryClass[];
}

export interface ApiEmailBuilderParams {
  subject: string;
  recipients: string[];
  editor_content: string;
  content_text: string;
  mode: SendEmailMode;
}

function apiEmailBuilderRecipientToEmailBuilderRecipient(
  p: ApiEmailBuilderRecipient,
): EmailBuilderRecipient {
  return {
    id: p.id,
    name: p.name,
    congregationNameWithState: p.congregation_name_with_state,
    emailAddress: p.email_address,
    selected: false,
  };
}

function apiEmailBuilderPreparatoryClassToEmailBuilderPreparatoryClass(
  p: ApiEmailBuilderPreparatoryClass,
): EmailBuilderPreparatoryClass {
  return {
    id: p.id,
    preparatoryClassName: p.preparatory_class_name,
    congregationNameWithState: p.congregation_name_with_state,
    applicants: p.applicants.map(item =>
      apiEmailBuilderRecipientToEmailBuilderRecipient(item),
    ),
    ministers: p.ministers.map(item =>
      apiEmailBuilderRecipientToEmailBuilderRecipient(item),
    ),
  };
}

async function needEmailBuilders() {
  return api.getEmailBuilders().then((response: ApiEmailBuilderResponse) => {
    const instructors = response.instructors.map(item =>
      apiEmailBuilderRecipientToEmailBuilderRecipient(item),
    );
    const courtesyCommitteeMembers = response.courtesy_committee_members.map(
      item => apiEmailBuilderRecipientToEmailBuilderRecipient(item),
    );
    const preparatoryClasses = response.preparatory_classes.map(item =>
      apiEmailBuilderPreparatoryClassToEmailBuilderPreparatoryClass(item),
    );

    // if there is a need to store this information in the store, code will need to be added here
    return {
      instructors,
      courtesyCommitteeMembers,
      preparatoryClasses,
    };
  });
}

async function sendEmailBuilder(p: FormData) {
  return api.sendEmailBuilder(p);
}

export const emailBuildersController = {
  needEmailBuilders,
  sendEmailBuilder,
};
