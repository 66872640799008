import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import SimilarPeople from "@/components/SimilarPeople/SimilarPeople.vue";
import CongregationsMinisters from "@/components/CongregationsMinisters/CongregationsMinisters.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import CpsAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import CpsAutocompleteObj from "@/components/Autocomplete/AutocompleteObj.vue";
import { peopleNamesController } from "@/controllers/peopleNames";
import { congregationsController } from "@/controllers/congregations";
import { statesController } from "@/controllers/states";
import { countriesController } from "@/controllers/countries";
import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import {
  appendYearsOld,
  personAgeToday,
  animateOnStateChange,
} from "@/utilities/displayHelpers";

import {
  peopleController,
  Gender,
  Person,
  PhoneNumberDescription,
} from "@/controllers/people";
import { CongregationMinister } from "@/controllers/congregationsMinisters";
import {
  unitApplicationController,
  UnitApplication,
} from "@/controllers/unitApplications";
import { unitsController, UnitClassification, Unit } from "@/controllers/units";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";
import { FormModes } from "@/main";
import {
  submittedUnitApplicationsController,
  SubmittedUnitApplication,
} from "@/controllers/submittedUnitApplications";
import {
  prettifyQualification,
  prettifyUnitPosition,
  prettifyUAStatus,
} from "@/utilities/displayHelpers";

import { UserClassification } from "@/store/user";
import { PersonUnitDataItem } from "@/components/PersonUnitData/person-unit-data";

import {
  addEmailAddress,
  addPhoneNumber,
  removeBlankPhoneNumbers,
  showSimilarPeople,
  buildCitizenships,
  buildUAPreferences,
  buildPerson,
  nameAutocompleteData,
  congregationAutocomplete,
  stateProvinceAutocomplete,
  buildPersonQualifications,
  buildPersonCitizenshipIds,
  buildCongregationsMinisters,
  buildSubmitButton,
  lookupCityZipForPerson,
  buildUnitApplication,
  buildUnitApplicationStatuses,
  buildUnitApplicationPositions,
} from "@/utilities/formHelpers";

import { UnitPosition } from "@/controllers/unitPositionTermLengths";

function setFormMode(id: string | undefined | null): FormModes {
  if (id) {
    if (id === "0") {
      return FormModes.new;
    } else {
      return FormModes.edit;
    }
  } else {
    return FormModes.new;
  }
}

export default defineComponent({
  name: "unitApplicationForm",
  components: {
    CpsAutocomplete,
    CpsAutocompleteObj,
    FormErrors,
    LoadingIndicator,
    SimilarPeople,
    CongregationsMinisters,
  },
  props: {
    unitApplicationId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;

    const dataLoaded = ref(false);

    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const { submitting, submitButtonText } = buildSubmitButton();
    const formMode = ref(FormModes.new);
    const loadspca = ref(false);

    const cancelRoute = {
      name: NamedRts.coordinatorDashboard,
    };

    const deleting = ref(false);
    const deleteButtonText = ref("Delete Application");

    const similarPeople = ref([]);
    const similarPeopleBell = ref(false);

    const refState: any = ref(null);
    const refBirthState: any = ref(null);
    const refCongregation: any = ref(null);

    const {
      congregationsMinisters,
      congregationsMinistersBell,
      lookupCongregationsMinisters,
      loadMinister,
    } = buildCongregationsMinisters();

    const { person, loadPersonIntoForm } = buildPerson();
    const {
      personQualifications,
      loadPersonQualificationsIntoForm,
    } = buildPersonQualifications();

    const { pCitizenshipIds } = buildPersonCitizenshipIds();

    const {
      unitApplication,
      loadUnitApplicationIntoForm,
    } = buildUnitApplication();

    const { loadUAPreferencesIntoForm, uaPreferenceIds } = buildUAPreferences();
    const {
      unitApplicationStatuses,
      loadUnitApplicationStatusesIntoForm,
    } = buildUnitApplicationStatuses();

    const {
      unitPositions,
      loadUnitPositionsIntoForm,
    } = buildUnitApplicationPositions();

    const personCongregation = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const personState = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const personBirthState = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    onMounted(() => {
      const dataPromises = [
        peopleNamesController.needLastNames(),
        peopleNamesController.needFirstNames(),
        peopleNamesController.needMiddleNames(),
        statesController.needStatesProvinces(),
        countriesController.needCountries(),
        congregationsController.needCongregations(),
        unitsController.needUnitsList(UnitClassification.all),
      ];

      formMode.value = setFormMode(props.unitApplicationId);
      if (props.unitApplicationId) {
        if (formMode.value === FormModes.new) {
          // it is a new form
          // write the code to check for a submitted application here to load the data
          const submittedUnitApplicationId =
            ctx.root.$route.query.submittedUnitApplicationId;
          if (submittedUnitApplicationId) {
            loadspca.value = true;
            dataPromises.push(
              submittedUnitApplicationsController.needSubmittedUnitApplication(
                submittedUnitApplicationId as string,
              ),
            );
          }

          // from the api and add it to the form
          // it should just be one of the promises added to the dataPromises
          // and then afterwards import the date
          // This form should maybe have an extra mode along with the new mode
          // to indicate that it is importing data
          Promise.all(dataPromises).then(_response => {
            if (loadspca.value) {
              dataLoaded.value = true;

              // TODO change this to show only the necessary qualifications?
              loadPersonQualificationsIntoForm(personQualifications);
              loadUnitPositionsIntoForm(unitPositions);
              loadUnitApplicationStatusesIntoForm(unitApplicationStatuses);

              const apiSPCA = apiSubmittedUnitApplication.value;

              person.firstName = apiSPCA.firstName;
              person.lastName = apiSPCA.lastName;
              person.middleName = apiSPCA.middleName;
              person.birthDate = apiSPCA.birthDate;
              person.address = apiSPCA.address1;
              person.address2 = apiSPCA.address2;
              person.city = apiSPCA.city;
              person.birthCity = apiSPCA.birthCity;
              person.parents = `${apiSPCA.fatherFirstName} and ${apiSPCA.motherFirstName}`;
              person.gender = apiSPCA.gender;
              person.zip = apiSPCA.zipCode;
              person.driversLicense = apiSPCA.driversLicense;

              person.citizenships = apiSPCA.citizenships.map(item => {
                return {
                  id: 0,
                  countryId: item.countryId,
                  countryName: "",
                  _destroy: false,
                };
              });

              pCitizenshipIds.value = person.citizenships.map(item => {
                return item.countryId;
              });

              // load the phone numbers that were submitted
              person.phoneNumbers = [
                {
                  id: 0,
                  inputLabel: `Phone - cell`,
                  browserId: `cps-${uuidv4()}`,
                  number: apiSPCA.phoneNumber,
                  description: PhoneNumberDescription.cell,
                  _destroy: false,
                },
              ];

              // and the email address
              person.emailAddresses = [
                {
                  inputLabel: "Email",
                  browserId: `cps-${uuidv4()}`,
                  id: 0,
                  address: apiSPCA.emailAddress,
                  _destroy: false,
                },
              ];

              localShowSimilarPeople();

              unitApplication.ministerName = apiSPCA.ministerName;
              unitApplication.ministerEmailAddress = apiSPCA.ministerEmail;
              unitApplication.ministerPhoneNumber = apiSPCA.ministerPhoneNumber;
              unitApplication.submittedDate = apiSPCA.submittedAt;
              unitApplication.availableDate = apiSPCA.availableDate;
              unitApplication.position = apiSPCA.position;

              unitApplication.unitApplicationPreferences = apiSPCA.preferences.map(
                item => {
                  return {
                    id: 0,
                    unitId: item.unitId,
                    _destroy: false,
                    unitApplicationId: 0,
                    unitName: "",
                  };
                },
              );

              uaPreferenceIds.value = unitApplication.unitApplicationPreferences.map(
                item => {
                  return item.unitId;
                },
              );

              // load the congregation and state
              person.congregationId = apiSPCA.congregationId;
              person.stateProvinceId = apiSPCA.stateProvinceId;
              person.birthStateId = apiSPCA.birthStateId;
              person.birthCity = apiSPCA.birthCity;
              const s = stateProvinceById(apiSPCA.stateProvinceId);
              const sb = stateProvinceById(apiSPCA.birthStateId);
              const c = congregationById(apiSPCA.congregationId);

              ctx.root.$nextTick(() => {
                if (s) {
                  refState.value.setResultParent({
                    id: s.id,
                    label: s.name,
                  });
                }

                if (sb) {
                  refBirthState.value.setResultParent({
                    id: sb.id,
                    label: sb.name,
                  });
                }

                if (c) {
                  refCongregation.value.setResultParent({
                    id: c.id,
                    label: c.name,
                  });
                }
              });
            } else {
              // this is for a new form with no data prefilled,
              // it is for manual data entry

              addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.cell);
              addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.home);
              addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.fax);

              addEmailAddress(person.emailAddresses);

              loadPersonQualificationsIntoForm(personQualifications);
              loadUnitPositionsIntoForm(unitPositions);
              loadUnitApplicationStatusesIntoForm(unitApplicationStatuses);

              unitApplication.submittedDate = format(
                startOfToday(),
                "yyyy-MM-dd",
              );

              unitApplication.availableDate = format(
                startOfToday(),
                "yyyy-MM-dd",
              );

              if (userClassification.value === UserClassification.boys) {
                unitApplication.position = UnitPosition.boysUnitVolunteer;
                person.gender == Gender.male;
              } else {
                unitApplication.position = UnitPosition.girlsUnitVolunteer;
                person.gender == Gender.female;
              }
              dataLoaded.value = true;
            }
          });
        } else {
          dataPromises.push(
            unitApplicationController.needUnitApplication(
              props.unitApplicationId,
            ),
          );
          Promise.all(dataPromises)
            .then(response => {
              // make the form visible right away
              // necessary to be able to access the refs in the html
              dataLoaded.value = true;

              loadUnitApplicationIntoForm(unitApplication, apiUnitApplication);

              return response;
            })
            .then(_response => {
              // lookup the person here
              return peopleController.needPerson(
                apiUnitApplication.value.personId.toString(),
              );
            })
            .then(response => {
              loadPersonIntoForm(apiPerson.value, person);
              loadStateProvinceAutocomplete(refState, person.stateProvinceId);
              loadCongregationAutocomplete(
                refCongregation,
                person.congregationId,
              );
              return response;
            })
            .finally(() => {
              dataLoaded.value = true;
            });
        }
      }
    });

    // getters from the store for the autocompletes
    const { firstNames, middleNames, lastNames } = nameAutocompleteData($store);

    const unitsForList = computed(() => {
      if (unitApplication.position === UnitPosition.boysUnitVolunteer) {
        return $store.getters.boysUnitsForTable;
      } else if (unitApplication.position === UnitPosition.girlsUnitVolunteer) {
        return $store.getters.girlsUnitsForTable;
      } else {
        return $store.getters.unitsForTable;
      }
    });

    const congregationById = (id: number) => {
      return $store.getters.congregationById(id);
    };

    const stateProvinceById = (id: number) => {
      return $store.getters.stateProvinceById(id);
    };

    const {
      congregationsForAutocomplete,
      loadCongregationAutocomplete,
      updateCongregationForPerson,
    } = congregationAutocomplete($store);
    const {
      statesProvincesForAutocomplete,
      loadStateProvinceAutocomplete,
    } = stateProvinceAutocomplete($store);

    const countriesForTable = computed(() => {
      return $store.getters.countriesForTable;
    });

    const updateFirstName = (evt: string) => {
      person.firstName = evt;
    };

    const updateMiddleName = (evt: string) => {
      person.middleName = evt;
    };

    const updateCongregation = (evt: AutocompleteResult): void => {
      updateCongregationForPerson(evt, person, personCongregation, refState);

      lookupCongregationsMinisters(
        evt.id.toString(),
        congregationsMinisters,
        congregationsMinistersBell,
      );
    };

    const updateState = (evt: AutocompleteResult): void => {
      person.stateProvinceId = evt.id;
    };

    const updateBirthState = (evt: AutocompleteResult): void => {
      person.birthStateId = evt.id;
    };

    // end of the code for the autocompletes

    const localShowSimilarPeople = () => {
      // only run this if a new person is being entered
      showSimilarPeople(
        person.id,
        person.firstName,
        person.birthDate,
        person.gender,
      ).then(response => {
        similarPeople.value = response;
        if (similarPeople.value.length > 0) {
          similarPeopleBell.value = true;
        }
      });
    };

    const lookupCityZip = () => {
      lookupCityZipForPerson(person);
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      // reset some things
      hasErrors.value = false;
      errorMessages.value = [];
      // set some things
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      // clean out the phone numbers that are blank
      person.phoneNumbers = removeBlankPhoneNumbers(person.phoneNumbers);

      person.citizenships = buildCitizenships(
        person.citizenships,
        pCitizenshipIds.value,
      );

      let apiResponse;
      if (person.id === 0) {
        // it is a new person, post the data
        apiResponse = peopleController.createPerson(person);
      } else {
        apiResponse = peopleController.updatePerson(person);
      }
      apiResponse
        .then(_response => {
          // the person was successfully saved
          // change the data on the person so
          // that they are not saved again
          person.id = apiPerson.value.id;
          // update the unit application
          unitApplication.personId = apiPerson.value.id;
          const pca: UnitApplication = {
            fullName: "",
            congregation: "",
            parents: "",
            birthDate: "",
            congregationId: 0,
            personId: unitApplication.personId,
            spouseId: 0,
            id: unitApplication.id,
            position: unitApplication.position,
            ministerEmailAddress: unitApplication.ministerEmailAddress,
            ministerName: unitApplication.ministerName,
            ministerPhoneNumber: unitApplication.ministerPhoneNumber,
            status: unitApplication.status,
            submittedDate: unitApplication.submittedDate,
            availableDate: unitApplication.availableDate,
            unitApplicationNotes: [],
            unitApplicationPreferences: loadUAPreferencesIntoForm(
              unitApplication.unitApplicationPreferences,
              uaPreferenceIds.value,
            ),
          };
          if (unitApplication.id === 0) {
            if (loadspca) {
              return unitApplicationController.createUnitApplication(
                pca,
                apiSubmittedUnitApplication.value.id,
              );
            } else {
              return unitApplicationController.createUnitApplication(pca);
            }
          } else {
            return unitApplicationController.updateUnitApplication(pca);
          }
        })
        .then(response => {
          ctx.root.$bvToast.toast(
            "Application was successfully saved.",
            TOAST_OPTIONS,
          );
          ctx.root.$router.push({
            name: NamedRts.personUnitData,
            params: {
              itemType: PersonUnitDataItem.unitApplication,
              itemId: response.id.toString(),
            },
          });
        })
        .catch(error => {
          errorMessages.value = error;
          hasErrors.value = true;
        })
        .finally(() => {
          // reset some values
          submitting.value = false;
          submitButtonText.value = "Submit";
        });
    };

    const personAge = computed(() => {
      if (person.birthDate !== "") {
        const birthDate = parseISO(person.birthDate);
        return appendYearsOld(personAgeToday(birthDate));
      } else {
        return "";
      }
    });

    const apiPerson = computed(() => {
      return $store.getters.person as Person;
    });

    const apiUnitApplication = computed(() => {
      return $store.getters.unitApplication as UnitApplication;
    });

    const apiSubmittedUnitApplication = computed(() => {
      return $store.getters
        .submittedUnitApplication as SubmittedUnitApplication;
    });

    const userClassification = computed(() => {
      return $store.getters.userClassification as UserClassification;
    });

    const loadPerson = (id: number) => {
      // this function loads an existing person into the form
      person.id = id;
      peopleController.needPerson(id.toString()).then(_response => {
        lookupCongregationsMinisters(
          apiPerson.value.congregationId.toString(),
          congregationsMinisters,
          congregationsMinistersBell,
        );
      });
      similarPeople.value = [];
      similarPeopleBell.value = false;
      const el = document.getElementById("person-data-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    const localLoadMinister = (minister: CongregationMinister) => {
      loadMinister(minister, congregationsMinistersBell, unitApplication);

      const el = document.getElementById("minister-data-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      // hasErrors.value = false;
      // errorMessages.value = [];
      // const pca: UnitApplication = {
      //   fullName: "",
      //   congregation: "",
      //   personId: unitApplication.personId,
      //   classification: unitApplication.classification,
      //   flag: unitApplication.flag,
      //   id: unitApplication.id,
      //   ministerEmailAddress: unitApplication.ministerEmailAddress,
      //   ministerName: unitApplication.ministerName,
      //   ministerPhoneNumber: unitApplication.ministerPhoneNumber,
      //   status: unitApplication.status,
      //   submittedDate: unitApplication.submittedDate,
      //   unitApplicationPreferences: pcaPreferences.map(item => {
      //     return {
      //       id: item.id,
      //       unitId: item.unitId,
      //       preferenceOrder: item.preferenceOrder,
      //       _destroy: item._destroy,
      //       unitApplicationId: unitApplication.id,
      //       unitCongregationName: "",
      //       unitEndDate: "",
      //       unitName: "",
      //       unitStartDate: "",
      //     };
      //   }),
      // };
      // unitApplicationController
      //   .deleteUnitApplication(pca)
      //   .then(_response => {
      //     cancelDelete();
      //     ctx.root.$bvToast.toast("Application deleted", TOAST_OPTIONS);
      //     ctx.root.$router.push(cancelRoute);
      //   })
      //   .catch(errors => {
      //     deleting.value = false;
      //     deleteButtonText.value = "Delete Application";
      //     hasErrors.value = true;
      //     errorMessages.value = errors;
      //   });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete Application";
    };

    return {
      apiSubmittedUnitApplication,
      cancelDelete,
      cancelRoute,
      congregationsForAutocomplete,
      congregationsMinisters,
      congregationsMinistersBell,
      countriesForTable,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      firstNames,
      hasErrors,
      lastNames,
      localLoadMinister,
      loadPerson,
      loadspca,
      lookupCityZip,
      middleNames,
      onSubmit,
      pCitizenshipIds,
      performDelete,
      person,
      personAge,
      personBirthState,
      personCongregation,
      personQualifications,
      personState,
      prettifyQualification,
      prettifyUAStatus,
      prettifyUnitPosition,
      refBirthState,
      refCongregation,
      refState,
      showSimilarPeople,
      similarPeople,
      similarPeopleBell,
      startDelete,
      statesProvincesForAutocomplete,
      submitButtonText,
      submitting,
      uaPreferenceIds,
      unitApplication,
      unitApplicationStatuses,
      unitPositions,

      unitsForList,

      updateBirthState,
      updateCongregation,
      updateFirstName,
      updateMiddleName,
      updateState,
    };
  },
});
