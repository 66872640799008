// This is for loading the person and all their data
// for the dashboard
import { ref } from "@vue/composition-api";
import { emptyPerson } from "@/store/people";
import { Person, peopleController } from "@/controllers/people";
import { unitApplicationController, UnitApplication } from "./unitApplications";
import {
  unitApplicationPlacementForDisplayController,
  UnitApplicationPlacementForDisplay,
} from "./unitApplicationPlacements";

export const person = ref<Person>(emptyPerson);
export const unitApplications = ref<UnitApplication[]>([]);
export const unitApplicationPlacements = ref<
  UnitApplicationPlacementForDisplay[]
>([]);
export const unitApplicationReplacements = ref<
  UnitApplicationPlacementForDisplay[]
>([]);
export const unitApplicationReplaces = ref<
  UnitApplicationPlacementForDisplay[]
>([]);

export const personLoaded = ref(false);
export const unitApplicationsLoaded = ref(false);
export const unitApplicationPlacementsLoaded = ref(false);
export const replacementsAndReplacesLoaded = ref(false);

async function unloadData() {
  person.value = emptyPerson;
  unitApplications.value = [];
  unitApplicationPlacements.value = [];
  unitApplicationReplacements.value = [];
  unitApplicationReplaces.value = [];

  personLoaded.value = false;
  unitApplicationsLoaded.value = false;
  unitApplicationPlacementsLoaded.value = false;
  replacementsAndReplacesLoaded.value = false;
}

async function loadData(personId: string) {
  personLoaded.value = false;
  unitApplicationsLoaded.value = false;
  unitApplicationPlacementsLoaded.value = false;
  replacementsAndReplacesLoaded.value = false;

  Promise.all([
    peopleController.needPerson(personId),
    unitApplicationController.needUnitApplications({
      person_id: Number(personId),
    }),
  ])
    .then(p => {
      person.value = p[0];
      unitApplications.value = p[1];
      personLoaded.value = true;
      unitApplicationsLoaded.value = true;
      // okay here we need to get all the placements for all the unit applications
      return Promise.all([
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
          {
            unit_application_ids: unitApplications.value.map(item => {
              return item.id;
            }),
          },
        ),
      ]);
    })
    .then(p => {
      unitApplicationPlacements.value = p[0];

      unitApplicationPlacementsLoaded.value = true;
      // now the placements and replaces are loaded
      // the first one of these promises are the replacements, the ones that are replacing the the placements for the person that we are looking up
      // the second one is the people they replaced
      const dataPromises = [];
      dataPromises.push(
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
          {
            unit_application_replaces_ids: unitApplicationPlacements.value.map(
              item => {
                return item.id;
              },
            ),
          },
        ),
      );

      // if they do not replace anyone, don't hit the server
      const replacesIds = unitApplicationPlacements.value.reduce(
        (accum, item) => {
          if (item.replacesId) {
            accum.push(item.replacesId);
          }
          return accum;
        },
        <number[]>[],
      );

      if (replacesIds.length > 0) {
        dataPromises.push(
          unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
            {
              unit_application_placement_ids: replacesIds,
            },
          ),
        );
      } else {
        dataPromises.push(Promise.resolve([]));
      }

      return Promise.all(dataPromises);
    })
    .then(p => {
      unitApplicationReplacements.value = p[0];
      unitApplicationReplaces.value = p[1];
      replacementsAndReplacesLoaded.value = true;

      // okay, link the placements with the replacements
      unitApplicationPlacements.value.map(item => {
        // look through the replacements to find one that fits
        const tmpReplacement = unitApplicationReplacements.value.find(
          replacementItem => {
            return replacementItem.replacesId === item.id;
          },
        );

        if (tmpReplacement) {
          item.replacement = tmpReplacement;
        }
      });

      // and then link the replaces with the placement
      unitApplicationReplaces.value.map(item => {
        // look through the placements to find one that may fit
        const tmpReplacement = unitApplicationPlacements.value.find(
          replacementItem => {
            return replacementItem.replacesId === item.id;
          },
        );

        if (tmpReplacement) {
          item.replacement = tmpReplacement;
        }
      });
    });
}

export const PersonDashboardController = {
  loadData,
  unloadData,
};
