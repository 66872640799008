import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiCongregation {
  id: number;
  full_name: string;
  name: string;
  city: string;
  state_id: number;
  state_name: string;
}

export interface ApiCongregationParams {
  name: string;
  city: string;
  state_id: number;
}

export interface Congregation {
  id: number;
  fullName: string;
  name: string;
  city: string;
  stateId: number;
  stateName: string;
}

function apiCongregationToCongregation(p: ApiCongregation): Congregation {
  return {
    id: p.id,
    name: p.name,
    city: p.city,
    stateId: p.state_id,
    stateName: p.state_name,
    fullName: p.full_name,
  };
}

async function createCongregation(c: Congregation) {
  const cParams: ApiCongregationParams = {
    name: c.name,
    city: c.city,
    state_id: c.stateId,
  };
  return api
    .postCongregation(cParams)
    .then((response: ApiCongregation) => {
      return store.dispatch(
        StoreActions.loadCongregation,
        apiCongregationToCongregation(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needCongregations() {
  return api
    .getCongregations()
    .then((response: ApiCongregation[]) => {
      const c = response.map(item => {
        return apiCongregationToCongregation(item);
      });
      return store.dispatch(StoreActions.loadCongregations, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needCongregation(congregationId: string) {
  return api
    .getCongregation(congregationId)
    .then((response: ApiCongregation) => {
      return store.dispatch(
        StoreActions.loadCongregation,
        apiCongregationToCongregation(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateCongregation(c: Congregation) {
  const cParams: ApiCongregationParams = {
    name: c.name,
    city: c.city,
    state_id: c.stateId,
  };
  return api
    .patchCongregation(c.id, cParams)
    .then((response: ApiCongregation) => {
      return store.dispatch(
        StoreActions.loadCongregation,
        apiCongregationToCongregation(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteCongregation(c: Congregation) {
  return api.deleteCongregation(c.id).catch(error => {
    return Promise.reject(error);
  });
}

export const congregationsController = {
  createCongregation,
  needCongregations,
  needCongregation,
  updateCongregation,
  deleteCongregation,
};
