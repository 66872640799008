import api from "@/api";
import store, { StoreActions } from "@/store";

export interface CongregationMinister {
  name: string;
  phoneNumber: string;
  emailAddress: string;
}

async function needCongregationsMinisters(congregationId: string) {
  return api
    .getCongregationsMinisters(congregationId)
    .then((response: CongregationMinister[]) => {
      return store.dispatch(StoreActions.loadCongregationsMinisters, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const congregationsMinistersController = {
  needCongregationsMinisters,
};
