import { v4 as uuidv4 } from "uuid";
import { emptyUnitApplicationPlacementForDisplay } from "@/store/unitApplicationPlacementsForDisplay";
import {
  PhoneNumberDescription,
  phoneNumberDescriptionReverseLookup,
  Person,
  Gender,
  MaritalStatus,
  Qualification,
  peopleController,
} from "@/controllers/people";

import {
  congregationsMinistersController,
  CongregationMinister,
} from "@/controllers/congregationsMinisters";

import {
  UnitApplicationStatus,
  UnitApplication,
  UnitApplicationPreference,
} from "@/controllers/unitApplications";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";

import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

import { zipForCityController } from "@/controllers/zipForCity";

import { reactive, computed, ref, Ref } from "@vue/composition-api";
import { Store } from "vuex";

import parseISO from "date-fns/parseISO";
import { appendYearsOld, personAgeToday } from "@/utilities/displayHelpers";

import {
  UnitApplicationPlacementForDisplay,
  UnitApplicationPlacementStatus,
} from "@/controllers/unitApplicationPlacements";

export interface PhoneNumberFormItem {
  inputLabel: string;
  browserId: string;
  id: number;
  number: string;
  description: PhoneNumberDescription;
  _destroy: boolean;
}

export interface CitizenshipFormItem {
  id: number;
  countryId: number;
  countryName: string;
  _destroy: boolean;
}

export interface EmailAddressFormItem {
  inputLabel: string;
  browserId: string;
  id: number;
  address: string;
  _destroy: boolean;
}

export interface PersonFormItem {
  address2: string;
  address: string;
  birthCity: string;
  birthDate: string;
  birthStateId: number;
  birthStateName: string;
  citizenships: CitizenshipFormItem[];
  city: string;
  congregationId: number;
  congregationName: string;
  driversLicense: string;
  emailAddresses: EmailAddressFormItem[];
  firstName: string;
  gender: Gender;
  id: number;
  lastName: string;
  maritalStatus: MaritalStatus;
  middleName: string;
  parents: string;
  phoneNumbers: PhoneNumberFormItem[];
  qualification: Qualification;
  stateProvinceId: number;
  stateProvinceName: string;
  stateProvinceAbbreviation: string;
  zip: string;
}

export function addPhoneNumber(
  ph: PhoneNumberFormItem[],
  description: PhoneNumberDescription,
) {
  const bId = `cps-${uuidv4()}`;
  ph.push({
    id: 0,
    inputLabel: `Phone - ${description}`,
    browserId: bId,
    number: "",
    description: description,
    _destroy: false,
  });
}

export function removeBlankPhoneNumbers(ph: PhoneNumberFormItem[]) {
  const validNumbers = ph.reduce((accum, item) => {
    if (item.number !== "") {
      accum.push(item);
      return accum;
    } else {
      return accum;
    }
  }, [] as PhoneNumberFormItem[]);

  return validNumbers;
}

export function addEmailAddress(em: EmailAddressFormItem[]) {
  const bId = `cps-${uuidv4()}`;
  em.push({
    inputLabel: "Email",
    browserId: bId,
    id: 0,
    address: "",
    _destroy: false,
  });
}

export function lookupCityZipForPerson(person: PersonFormItem) {
  if (person.city !== "") {
    zipForCityController
      .zipForCity(person.city, person.stateProvinceId.toString())
      .then(response => {
        if (response) {
          person.zip = response;
          return response;
        }
      });
  }
}

export async function showSimilarPeople(
  id: number,
  firstName: string,
  birthDate: string,
  gender: Gender,
) {
  if (id === 0) {
    if (!(firstName === "" || birthDate === "")) {
      return peopleController
        .needSimilarPeople({
          first_name: firstName,
          birth_date: birthDate,
          gender: gender,
        })
        .then(response => {
          return response;
        })
        .catch(_e => {
          return [];
        });
    } else {
      return Promise.resolve([]);
    }
  } else {
    return Promise.resolve([]);
  }
}

export function buildCitizenships(
  currentCitizenships: CitizenshipFormItem[],
  countryIds: number[],
) {
  // set destroy all to true
  const tempCurrentCitizenships = currentCitizenships.map(item => {
    return {
      ...item,
      _destroy: true,
    };
  });

  // these are the country ids that were selected, but not found
  const idsNotFound = [] as number[];

  for (const c of countryIds) {
    let notFound = true;

    for (const item of tempCurrentCitizenships) {
      if (item.countryId === c) {
        notFound = false;
        item._destroy = false;
      }
    }

    // was it an existing id
    if (notFound) {
      idsNotFound.push(c);
    }
  }

  for (const c of idsNotFound) {
    tempCurrentCitizenships.push({
      id: 0,
      countryId: c,
      countryName: "",
      _destroy: false,
    });
  }

  return tempCurrentCitizenships;
}

export function buildUAPreferences() {
  const uaPreferenceIds = ref([] as number[]);

  const loadUAPreferencesIntoForm = (
    currentPreferences: UnitApplicationPreference[],
    unitIds: number[],
  ) => {
    // set destroy all to true
    const tempCurrentPreferences = currentPreferences.map(item => {
      return {
        ...item,
        _destroy: true,
      };
    });

    const idsNotFound = [] as number[];

    for (const u of unitIds) {
      let notFound = true;
      for (const item of tempCurrentPreferences) {
        if (item.unitId === u) {
          notFound = false;
          item._destroy = false;
        }
      }

      // is it a new id?
      if (notFound) {
        idsNotFound.push(u);
      }
    }

    for (const u of idsNotFound) {
      tempCurrentPreferences.push({
        id: 0,
        unitId: u,
        _destroy: false,
        unitApplicationId: 0,
        unitName: "",
      });
    }

    return tempCurrentPreferences;
  };

  return {
    loadUAPreferencesIntoForm,
    uaPreferenceIds,
  };
}

export function buildUnitApplication() {
  const unitApplication = reactive<UnitApplication>({
    personId: 0,
    spouseId: 0,
    id: 0,
    fullName: "",
    birthDate: "",
    parents: "",
    congregation: "",
    congregationId: 0,
    position: UnitPosition.boysUnitVolunteer,
    ministerEmailAddress: "",
    ministerName: "",
    ministerPhoneNumber: "",
    status: UnitApplicationStatus.available,
    submittedDate: "",
    availableDate: "",
    unitApplicationPreferences: [],
    unitApplicationNotes: [],
  });

  const loadUnitApplicationIntoForm = (
    ua: UnitApplication,
    apiData: Ref<UnitApplication>,
  ) => {
    ua.id = apiData.value.id;
    ua.personId = apiData.value.personId;
    ua.ministerEmailAddress = apiData.value.ministerEmailAddress;
    ua.ministerName = apiData.value.ministerName;
    ua.ministerPhoneNumber = apiData.value.ministerPhoneNumber;
    ua.status = apiData.value.status;
    ua.submittedDate = apiData.value.submittedDate;
    ua.availableDate = apiData.value.availableDate;
    ua.spouseId = apiData.value.spouseId;
    ua.fullName = apiData.value.fullName;
    ua.congregation = apiData.value.congregation;
    ua.position = apiData.value.position;
    ua.unitApplicationPreferences = apiData.value.unitApplicationPreferences;
    ua.unitApplicationNotes = apiData.value.unitApplicationNotes;
  };

  return {
    loadUnitApplicationIntoForm,
    unitApplication,
  };
}

export function buildUnitApplicationPlacement() {
  const unitApplicationPlacement = reactive<UnitApplicationPlacementForDisplay>(
    {
      ...emptyUnitApplicationPlacementForDisplay,
    },
  );

  const loadUnitApplicationPlacementIntoForm = (
    uap: UnitApplicationPlacementForDisplay,
    apiData: Ref<UnitApplicationPlacementForDisplay>,
  ) => {
    uap.id = apiData.value.id;
    uap.placementEndDate = apiData.value.placementEndDate;
    uap.placementStartDate = apiData.value.placementStartDate;
    uap.name = apiData.value.name;
    uap.parents = apiData.value.parents;
    uap.congregationName = apiData.value.congregationName;
    uap.congregationCity = apiData.value.congregationCity;
    uap.state = apiData.value.state;
    uap.unitApplicationId = apiData.value.unitApplicationId;
    uap.unitApplicationPosition = apiData.value.unitApplicationPosition;
    uap.replacesId = apiData.value.replacesId;
    uap.unitName = apiData.value.unitName;
    uap.unitId = apiData.value.unitId;
    uap.unitClassification = apiData.value.unitClassification;
    uap.status = apiData.value.status;
    uap.otherPeopleConcatenated = apiData.value.otherPeopleConcatenated;
    uap.latestNoteContent = apiData.value.latestNoteContent;
    uap.latestNoteUser = apiData.value.latestNoteUser;
    uap.latestNoteCreatedAt = apiData.value.latestNoteCreatedAt;
    uap.replacement = apiData.value.replacement;
  };

  return {
    unitApplicationPlacement,
    loadUnitApplicationPlacementIntoForm,
  };
}

export function buildCongregationsMinisters() {
  const congregationsMinisters = ref<CongregationMinister[]>([]);

  const congregationsMinistersBell = ref(false);

  const lookupCongregationsMinisters = (
    congregationId: string,
    congregationsMinisters: Ref<CongregationMinister[]>,
    congregationsMinistersBell: Ref<boolean>,
  ) => {
    congregationsMinistersController
      .needCongregationsMinisters(congregationId)
      .then(response => {
        if (response.length > 0) {
          congregationsMinisters.value = response;
          congregationsMinistersBell.value = true;
        }
      });
  };

  const loadMinister = (
    minister: CongregationMinister,
    congregationsMinistersBell: Ref<boolean>,
    unitApplication: UnitApplication,
  ) => {
    congregationsMinistersBell.value = false;
    unitApplication.ministerName = minister.name;
    unitApplication.ministerEmailAddress = minister.emailAddress;
    unitApplication.ministerPhoneNumber = minister.phoneNumber;
  };

  return {
    loadMinister,
    lookupCongregationsMinisters,
    congregationsMinisters,
    congregationsMinistersBell,
  };
}

export function buildPerson() {
  const person = reactive<PersonFormItem>({
    address2: "",
    address: "",
    birthCity: "",
    birthDate: "",
    birthStateId: 0,
    birthStateName: "",
    citizenships: [] as CitizenshipFormItem[],
    city: "",
    congregationId: 0,
    congregationName: "",
    driversLicense: "",
    emailAddresses: [] as EmailAddressFormItem[],
    firstName: "",
    gender: Gender.male,
    id: 0,
    lastName: "",
    maritalStatus: MaritalStatus.single,
    middleName: "",
    parents: "",
    phoneNumbers: [] as PhoneNumberFormItem[],
    qualification: Qualification.normal,
    stateProvinceId: 0,
    stateProvinceName: "",
    stateProvinceAbbreviation: "",
    zip: "",
  });

  const loadPersonIntoForm = (ap: Person, person: PersonFormItem) => {
    person.phoneNumbers = ap.phoneNumbers.map(item => {
      const desc = phoneNumberDescriptionReverseLookup[item.description];
      const bId = `cps-${uuidv4()}`;

      return {
        id: item.id,
        inputLabel: `Phone - ${desc}`,
        browserId: bId,
        number: item.number,
        description: desc,
        _destroy: false,
      };
    });

    person.emailAddresses = ap.emailAddresses.map(item => {
      const bId = `cps-${uuidv4()}`;
      return {
        id: item.id,
        inputLabel: "Email",
        browserId: bId,
        address: item.address,
        _destroy: false,
      };
    });

    person.citizenships = ap.citizenships.map(item => {
      return {
        id: item.id,
        countryId: item.countryId,
        countryName: item.countryName,
        _destroy: false,
      };
    });

    person.firstName = ap.firstName;
    person.lastName = ap.lastName;
    person.middleName = ap.middleName;
    person.id = ap.id;
    person.birthDate = ap.birthDate;
    person.address = ap.address;
    person.address2 = ap.address2;
    person.city = ap.city;
    person.parents = ap.parents;
    person.congregationId = ap.congregationId;
    person.gender = ap.gender;
    person.zip = ap.zip;
    person.stateProvinceId = ap.stateProvinceId;
    person.driversLicense = ap.driversLicense;
    person.birthStateId = ap.birthStateId;
    person.maritalStatus = ap.maritalStatus;
    person.qualification = ap.qualification;
    person.birthCity = ap.birthCity;
  };

  const personAge = (person: PersonFormItem) =>
    computed(() => {
      if (person.birthDate !== "") {
        const birthDate = parseISO(person.birthDate);
        return appendYearsOld(personAgeToday(birthDate));
      } else {
        return "";
      }
    });

  return {
    person,
    personAge,
    loadPersonIntoForm,
  };
}

export function nameAutocompleteData(store: any) {
  const firstNames = computed(() => {
    return store.getters.firstNames;
  });

  const middleNames = computed(() => {
    return store.getters.middleNames;
  });

  const lastNames = computed(() => {
    return store.getters.lastNames;
  });

  return {
    firstNames,
    middleNames,
    lastNames,
  };
}

export function congregationAutocomplete(store: Store<any>) {
  const congregationsForAutocomplete = computed(() => {
    return store.getters.congregationsForAutocomplete;
  });

  const loadCongregationAutocomplete = (refCongregation: any, id: number) => {
    const s = store.getters.congregationById(id);

    if (s) {
      refCongregation.value.setResultParent({
        id: s.id,
        label: s.name,
      });
    }
  };

  const updateCongregationForPerson = (
    evt: AutocompleteResult,
    person: PersonFormItem,
    personCongregation: AutocompleteResult,
    refState: any,
  ) => {
    // this function seems to take on a lot of responsibility
    // look up the city here
    const t = store.getters.congregationById(evt.id);
    personCongregation.id = evt.id;
    personCongregation.label = evt.label;
    person.congregationId = evt.id;

    // set up the city
    if (person.city === "") {
      person.city = t.city;
    }

    // load the state
    const s = store.getters.stateProvinceById(t.stateId);
    if (person.stateProvinceId === 0) {
      person.stateProvinceId = s.id;
      refState.value.setResultParent({
        id: s.id,
        label: s.name,
      });
    }
  };

  return {
    congregationsForAutocomplete,
    loadCongregationAutocomplete,
    updateCongregationForPerson,
  };
}

export function stateProvinceAutocomplete(store: Store<any>) {
  const statesProvincesForAutocomplete = computed(() => {
    return store.getters.statesProvincesForAutocomplete;
  });

  const loadStateProvinceAutocomplete = (refState: any, id: number) => {
    const s = store.getters.stateProvinceById(id);

    if (s) {
      refState.value.setResultParent({
        id: s.id,
        label: s.name,
      });
    }
  };

  return {
    statesProvincesForAutocomplete,
    loadStateProvinceAutocomplete,
  };
}

export function buildPersonQualifications() {
  const personQualifications = reactive<Qualification[]>([]);

  const loadPersonQualificationsIntoForm = (
    pQualifications: Qualification[],
  ) => {
    pQualifications.push(
      Qualification.normal,
      Qualification.constructionExperience,
      Qualification.cna,
      Qualification.lpn,
      Qualification.rn,
    );
  };

  return {
    personQualifications,
    loadPersonQualificationsIntoForm,
  };
}

export function buildUnitApplicationPositions() {
  const unitPositions = reactive<UnitPosition[]>([]);

  const loadUnitPositionsIntoForm = (uaPositions: UnitPosition[]) => {
    uaPositions.push(
      UnitPosition.boysUnitVolunteer,
      UnitPosition.girlsUnitVolunteer,
    );
  };

  return {
    unitPositions,
    loadUnitPositionsIntoForm,
  };
}

export function buildUnitApplicationStatuses() {
  const unitApplicationStatuses = reactive<UnitApplicationStatus[]>([]);

  const loadUnitApplicationStatusesIntoForm = (
    uaStatuses: UnitApplicationStatus[],
  ) => {
    uaStatuses.push(
      UnitApplicationStatus.available,
      UnitApplicationStatus.pending,
      UnitApplicationStatus.teaching,
      UnitApplicationStatus.untilVolunteerNotifies,
      UnitApplicationStatus.onHold,
      UnitApplicationStatus.placed,
      UnitApplicationStatus.unavailable,
    );
  };

  return {
    unitApplicationStatuses,
    loadUnitApplicationStatusesIntoForm,
  };
}

export function buildUnitApplicationPlacementStatuses() {
  const unitApplicationPlacementStatuses = reactive<
    UnitApplicationPlacementStatus[]
  >([]);

  const loadUnitApplicationPlacementStatusesIntoForm = (
    uapStatuses: UnitApplicationPlacementStatus[],
  ) => {
    uapStatuses.push(
      UnitApplicationPlacementStatus.pending,
      UnitApplicationPlacementStatus.confirmed,
    );
  };

  return {
    unitApplicationPlacementStatuses,
    loadUnitApplicationPlacementStatusesIntoForm,
  };
}

export function buildPersonCitizenshipIds() {
  const pCitizenshipIds = ref([] as number[]);

  return {
    pCitizenshipIds,
  };
}

export function buildSubmitButton() {
  const submitting = ref(false);
  const submitButtonText = ref("Submit");

  return {
    submitting,
    submitButtonText,
  };
}

export function buildDeleteButton() {
  const deleting = ref(false);
  const deleteButtonText = ref("Delete");

  return {
    deleting,
    deleteButtonText,
  };
}
