import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { NamedRts } from "@/router";
import { SMMode } from "@/store/sidebarMenu";
import { StoreActions } from "@/store";
import { preparatoryClassApplicationController } from "@/controllers/preparatoryClassApplications";
import { submittedPreparatoryClassApplicationsController } from "@/controllers/submittedPreparatoryClassApplications";

export default defineComponent({
  name: "coordinatorDashboard",

  setup(_props, ctx) {
    const dataLoaded = ref(false);
    const $store = ctx.root.$store;

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const sidebarMenuClassObject = computed(() => {
      return $store.getters.sidebarMenuClassObject;
    });

    const dataColClassObject = computed(() => {
      return $store.getters.dataColClassObject;
    });

    const showMenu = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.menu);
    };

    const showData = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.data);
    };

    const routeParams = {
      preparatoryClassList: {
        name: NamedRts.preparatoryClassList,
      },
      submittedPreparatoryClassApplicationList: {
        name: NamedRts.submittedPreparatoryClassApplicationList,
      },
      preparatoryClassApplicationList: {
        name: NamedRts.preparatoryClassApplicationList,
      },
      preparatoryClassApplicationForm: {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: "0",
        },
      },
      preparatoryClassForm: {
        name: NamedRts.preparatoryClassForm,
        params: {
          preparatoryClassId: "0",
        },
      },
    };

    const dashboardLinkClasses = (rtName: string) => {
      if (rtName === ctx.root.$route.name) {
        return {
          active: true,
        };
      } else {
        return {
          active: false,
        };
      }
    };

    onMounted(() => {
      // load the submitted applications, but it does not have to happen immediately

      Promise.all([
        submittedPreparatoryClassApplicationsController.needSubmittedPreparatoryClassApplications(),
        preparatoryClassApplicationController.needPreparatoryClassApplications(),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    const countOfSubmittedPCApplications = computed(() => {
      return $store.getters
        .countOfSubmittedPreparatoryClassApplicationsForTable as number;
    });

    const countOfPCApplications = computed(() => {
      return $store.getters
        .countOfPreparatoryClassApplicationsForTable as number;
    });

    return {
      showMenu,
      showData,
      sidebarMenuClassObject,
      dataColClassObject,
      dashboardLinkClasses,

      isAdmin,
      countOfSubmittedPCApplications,
      countOfPCApplications,
      routeParams,
      dataLoaded,
    };
  },
});
