import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiTextDocument {
  id: number;
  description: string;
  editor_content: string;
  content_text: string;
  document_name: string;
  internal_name: string;
}

export interface TextDocument {
  id: number;
  description: string;
  editorContent: string;
  contentText: string;
  documentName: string;
  internalName: string;
}

export interface ApiTextDocumentParams {
  id: number;
  description: string;
  name: string;
  content_html: string;
  content_txt: string;
}

function apiTextDocumentToTextDocument(p: ApiTextDocument): TextDocument {
  return {
    id: p.id,
    description: p.description,
    editorContent: p.editor_content,
    contentText: p.content_text,
    documentName: p.document_name,
    internalName: p.internal_name,
  };
}

async function needTextDocuments() {
  return api
    .getTextDocuments()
    .then((response: ApiTextDocument[]) => {
      const items = response.map(item => {
        return apiTextDocumentToTextDocument(item);
      });
      return store.dispatch(StoreActions.loadTextDocuments, items);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needTextDocument(id: string) {
  return api
    .getTextDocument(id)
    .then((response: ApiTextDocument) => {
      return store.dispatch(
        StoreActions.loadTextDocument,
        apiTextDocumentToTextDocument(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateTextDocument(p: TextDocument) {
  const apiParams: ApiTextDocumentParams = {
    id: p.id,
    description: p.description,
    name: p.documentName,
    content_html: p.editorContent,
    content_txt: p.contentText,
  };

  return api
    .patchTextDocument(p.id, apiParams)
    .then((response: ApiTextDocument) => {
      return store.dispatch(
        StoreActions.loadTextDocument,
        apiTextDocumentToTextDocument(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const textDocumentsController = {
  needTextDocuments,
  needTextDocument,
  updateTextDocument,
};
