import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import formatISO from "date-fns/formatISO";
import differenceInYears from "date-fns/differenceInYears";
import {
  PreparatoryClassApplicationStatus,
  prettifiedPreparatoryClassApplicationStatus,
} from "@/controllers/preparatoryClassApplications";
import {
  UnitApplicationStatus,
  prettifiedUnitApplicationStatus,
} from "@/controllers/unitApplications";

import {
  UserResponsibility,
  prettifiedUserResponsibility,
  UserClassification,
  prettifiedUserClassification,
} from "@/store/user";

import {
  UnitApplicationPlacementStatus,
  prettifiedUnitApplicationPlacementStatus,
} from "@/controllers/unitApplicationPlacements";
import { UnitWorkType, prettifiedUnitWorkType } from "@/controllers/units";
import {
  Qualification,
  prettifiedQualification,
  Gender,
  prettifiedGender,
  MaritalStatus,
  prettifiedMaritalStatus,
} from "@/controllers/people";
import {
  UnitPosition,
  prettifiedUnitPosition,
} from "@/controllers/unitPositionTermLengths";

export const friendlyDate = (dateString: string) => {
  const formatPattern = "MMMM d, yyyy";
  return format(parseISO(dateString), formatPattern);
};

export const friendlyLongDate = (dateString: string) => {
  const formatPattern = "EEEE, MMMM d, yyyy";
  return format(parseISO(dateString), formatPattern);
};

export const todayISO = () => {
  return formatISO(startOfToday(), { representation: "date" });
};

export const personAge = (asOfDate: Date, birthDate: Date) => {
  return differenceInYears(asOfDate, birthDate);
};

export const personAgeToday = (birthDate: Date) => {
  const td = startOfToday();
  return personAge(td, birthDate);
};

export const appendYearsOld = (age: number) => {
  return `${age} years old`;
};

export const prettifyUnitWorkType = (wt: UnitWorkType) => {
  return prettifiedUnitWorkType[wt];
};

export const prettifyQualification = (q: Qualification) => {
  return prettifiedQualification[q];
};

export const prettifyGender = (g: Gender) => {
  return prettifiedGender[g];
};

export const prettifyMaritalStatus = (s: MaritalStatus) => {
  return prettifiedMaritalStatus[s];
};

export const prettifyUnitPosition = (p: UnitPosition) => {
  return prettifiedUnitPosition[p];
};

export const prettifyUAStatus = (status: UnitApplicationStatus) => {
  return prettifiedUnitApplicationStatus[status];
};

export const prettifyUAPStatus = (status: UnitApplicationPlacementStatus) => {
  return prettifiedUnitApplicationPlacementStatus[status];
};

export const prettifyPCAStatus = (
  status: PreparatoryClassApplicationStatus,
) => {
  return prettifiedPreparatoryClassApplicationStatus[status];
};

export const prettifyUserResponsibility = (item: UserResponsibility) => {
  return prettifiedUserResponsibility[item];
};

export const prettifyUserClassification = (item: UserClassification) => {
  return prettifiedUserClassification[item];
};

export const animateOnStateChange = (el: HTMLElement) => {
  // this expects to receive a container element
  // the element should have a class of programmatically-can-change
  // the css class has a transition
  // this code changes the background by adding another class, then removes the class
  // this if for giving the user an indication that the state was programmatically changed
  el.classList.remove("programmatically-changed");

  el.classList.add("programmatically-changed");
  setTimeout(() => {
    el.classList.remove("programmatically-changed");
  }, 1500);
};

export const hBadgeForUnitPosition = (u: UnitPosition) => {
  if (u === UnitPosition.girlsUnitVolunteer) {
    return "badge-female";
  } else {
    return "badge-male";
  }
};

export const hBadgeForUAPStatus = (status: UnitApplicationPlacementStatus) => {
  if (status === UnitApplicationPlacementStatus.confirmed) {
    return "badge-success";
  } else {
    return "badge-warning";
  }
};
