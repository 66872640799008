import api from "@/api";
import store, { StoreActions } from "@/store";
import {
  Gender,
  Qualification,
  genderReverseLookup,
  qualificationEnumReverseLookup,
} from "./people";
import {
  UnitPosition,
  unitPositionEnumReverseLookup,
} from "./unitPositionTermLengths";

export const enum SubmittedUnitApplicationStatus {
  pending = "pending",
  approved = "approved",
}

export const submittedUnitApplicationStatusLookup = <
  { [key: string]: SubmittedUnitApplicationStatus }
>{
  pending: SubmittedUnitApplicationStatus.pending,
  approved: SubmittedUnitApplicationStatus.approved,
};

interface ApiSubmittedUnitApplicationPreference {
  id: number;
  unit_id: number;
}

interface ApiSubmittedUnitApplicationPreferenceParams {
  id: number;
  unit_id: number;
  _destroy: boolean;
}

interface ApiSubmittedUnitApplicationCitizenshipParams {
  id: number;
  country_id: number;
  _destroy: boolean;
}

interface ApiSubmittedUnitApplicationCitizenship {
  id: number;
  country_id: number;
}

export interface ApiSubmittedUnitApplication {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  gender: string;
  phone_number: string;
  email_address: string;
  birth_date: string;
  father_first_name: string;
  mother_first_name: string;
  available_date: string;
  accepts_cps_policy: boolean;
  minister_confirmation: boolean;
  drivers_license: string;
  dl_state_id: number;
  birth_city: string;
  birth_state_id: number;
  congregation_id: number;
  address: string;
  address2: string;
  city: string;
  state_id: number;
  zip: string;
  submitted_at: string;
  minister_name: string;
  minister_email_address: string;
  minister_phone_number: string;
  minister_note: string;
  status: string;
  applicant_note: string;
  qualification: string;
  unit_application_id: number | null;
  position: string;
  preferences: ApiSubmittedUnitApplicationPreference[];
  citizenships: ApiSubmittedUnitApplicationCitizenship[];
}

export interface ApiSubmittedUnitApplicationParams {
  first_name: string;
  last_name: string;
  middle_name: string;
  gender: Gender;
  phone_number: string;
  email_address: string;
  birth_date: string;
  father_first_name: string;
  mother_first_name: string;
  available_date: string;
  accepts_cps_policy: boolean;
  minister_confirmation: boolean;
  drivers_license: string;
  dl_state_id: number;
  birth_city: string;
  birth_state_id: number;
  congregation_id: number;
  address2: string;
  address: string;
  city: string;
  state_id: number;
  zip: string;
  minister_email_address: string;
  minister_name: string;
  minister_note: string;
  minister_phone_number: string;
  submitted_at: string;
  status: string;
  applicant_note: string;
  qualification: Qualification;
  position: UnitPosition;
  unit_application_id: number | null;
  preferences: ApiSubmittedUnitApplicationPreferenceParams[];
  citizenships: ApiSubmittedUnitApplicationCitizenshipParams[];
}

interface SubmittedUnitApplicationCitizenship {
  id: number;
  countryId: number;
  _destroy: boolean;
}

interface SubmittedUnitApplicationPreference {
  id: number;
  unitId: number;
  _destroy: boolean;
}

export interface SubmittedUnitApplication {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: Gender;
  phoneNumber: string;
  emailAddress: string;
  birthDate: string;
  fatherFirstName: string;
  motherFirstName: string;
  availableDate: string;
  acceptsCpsPolicy: boolean;
  ministerConfirmation: boolean;
  driversLicense: string;
  dlStateId: number;
  birthCity: string;
  birthStateId: number;
  congregationId: number;
  address1: string;
  address2: string;
  city: string;
  stateProvinceId: number;
  zipCode: string;
  submittedAt: string;
  ministerName: string;
  ministerEmail: string;
  ministerPhoneNumber: string;
  ministerNote: string;
  status: SubmittedUnitApplicationStatus;
  applicantNote: string;
  qualification: Qualification;
  position: UnitPosition;
  unitApplicationId: number | null;
  preferences: SubmittedUnitApplicationPreference[];
  citizenships: SubmittedUnitApplicationCitizenship[];
}

function apiSubmittedUnitApplicationPreferenceToPreference(
  p: ApiSubmittedUnitApplicationPreference,
): SubmittedUnitApplicationPreference {
  return {
    id: p.id,
    unitId: p.unit_id,
    _destroy: false,
  };
}

function apiSubmittedUnitApplicationCitizenshipToCitizenship(
  p: ApiSubmittedUnitApplicationCitizenship,
): SubmittedUnitApplicationCitizenship {
  return {
    id: p.id,
    countryId: p.country_id,
    _destroy: false,
  };
}

function submittedUnitApplicationToApiParams(
  c: SubmittedUnitApplication,
): ApiSubmittedUnitApplicationParams {
  return {
    first_name: c.firstName,
    last_name: c.lastName,
    middle_name: c.middleName,
    gender: c.gender,
    phone_number: c.phoneNumber,
    email_address: c.emailAddress,
    birth_date: c.birthDate,
    father_first_name: c.fatherFirstName,
    mother_first_name: c.motherFirstName,
    available_date: c.availableDate,
    accepts_cps_policy: c.acceptsCpsPolicy,
    minister_confirmation: c.ministerConfirmation,
    drivers_license: c.driversLicense,
    dl_state_id: c.dlStateId,
    birth_city: c.birthCity,
    birth_state_id: c.birthStateId,
    congregation_id: c.congregationId,
    address: c.address1,
    address2: c.address2,
    city: c.city,
    state_id: c.stateProvinceId,
    zip: c.zipCode,
    submitted_at: c.submittedAt,
    minister_name: c.ministerName,
    minister_email_address: c.ministerEmail,
    minister_phone_number: c.ministerPhoneNumber,
    minister_note: c.ministerNote,
    status: c.status,
    applicant_note: c.applicantNote,
    qualification: c.qualification,
    unit_application_id: c.unitApplicationId,
    position: c.position,
    preferences: c.preferences.map(item => {
      return {
        id: item.id,
        unit_id: item.unitId,
        _destroy: item._destroy,
      };
    }),
    citizenships: c.citizenships.map(item => {
      return {
        id: item.id,
        country_id: item.countryId,
        _destroy: item._destroy,
      };
    }),
  };
}

function apiSubmittedUnitApplicationToSubmittedUnitApplication(
  p: ApiSubmittedUnitApplication,
): SubmittedUnitApplication {
  const st = submittedUnitApplicationStatusLookup[p.status];
  const g = genderReverseLookup[p.gender];
  const q = qualificationEnumReverseLookup[p.qualification];
  const up = unitPositionEnumReverseLookup[p.position];

  return {
    id: p.id,
    firstName: p.first_name,
    middleName: p.middle_name,
    lastName: p.last_name,
    gender: g,
    phoneNumber: p.phone_number,
    emailAddress: p.email_address,
    birthDate: p.birth_date,
    fatherFirstName: p.father_first_name,
    motherFirstName: p.mother_first_name,
    availableDate: p.available_date,
    acceptsCpsPolicy: p.accepts_cps_policy,
    ministerConfirmation: p.minister_confirmation,
    driversLicense: p.drivers_license,
    dlStateId: p.dl_state_id,
    birthCity: p.birth_city,
    birthStateId: p.birth_state_id,
    congregationId: p.congregation_id,
    address1: p.address,
    address2: p.address2,
    city: p.city,
    stateProvinceId: p.state_id,
    zipCode: p.zip,
    submittedAt: p.submitted_at,
    ministerName: p.minister_name,
    ministerEmail: p.minister_email_address,
    ministerPhoneNumber: p.minister_phone_number,
    ministerNote: p.minister_note,
    status: st,
    applicantNote: p.applicant_note,
    qualification: q,
    position: up,
    unitApplicationId: p.unit_application_id,
    preferences: p.preferences.map(item =>
      apiSubmittedUnitApplicationPreferenceToPreference(item),
    ),
    citizenships: p.citizenships.map(item =>
      apiSubmittedUnitApplicationCitizenshipToCitizenship(item),
    ),
  };
}

async function createSubmittedUnitApplication(c: SubmittedUnitApplication) {
  const cParams = submittedUnitApplicationToApiParams(c);
  return api
    .postSubmittedUnitApplication(cParams)
    .then((response: ApiSubmittedUnitApplication) => {
      return store.dispatch(
        StoreActions.loadSubmittedUnitApplication,
        apiSubmittedUnitApplicationToSubmittedUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needSubmittedUnitApplications() {
  await api
    .getSubmittedUnitApplications()
    .then((response: ApiSubmittedUnitApplication[]) => {
      const c = response.map(item => {
        return apiSubmittedUnitApplicationToSubmittedUnitApplication(item);
      });
      return store.dispatch(StoreActions.loadSubmittedUnitApplications, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needSubmittedUnitApplication(
  submittedUnitApplicationId: string,
) {
  return api
    .getSubmittedUnitApplication(submittedUnitApplicationId)
    .then((response: ApiSubmittedUnitApplication) => {
      return store.dispatch(
        StoreActions.loadSubmittedUnitApplication,
        apiSubmittedUnitApplicationToSubmittedUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateSubmittedUnitApplication(c: SubmittedUnitApplication) {
  const cParams = submittedUnitApplicationToApiParams(c);
  return api
    .patchSubmittedUnitApplication(c.id, cParams)
    .then((response: ApiSubmittedUnitApplication) => {
      return store.dispatch(
        StoreActions.loadSubmittedUnitApplication,
        apiSubmittedUnitApplicationToSubmittedUnitApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteSubmittedUnitApplication(c: SubmittedUnitApplication) {
  return api
    .deleteSubmittedUnitApplication(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const submittedUnitApplicationsController = {
  createSubmittedUnitApplication,
  needSubmittedUnitApplications,
  needSubmittedUnitApplication,
  updateSubmittedUnitApplication,
  deleteSubmittedUnitApplication,
};
