import { render, staticRenderFns } from "./congregations-ministers.html?vue&type=template&id=053f43e4&scoped=true&"
import script from "./congregations-ministers.ts?vue&type=script&lang=js&"
export * from "./congregations-ministers.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "053f43e4",
  null
  
)

export default component.exports