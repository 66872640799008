import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import SimilarPeople from "@/components/SimilarPeople/SimilarPeople.vue";
import CongregationsMinisters from "@/components/CongregationsMinisters/CongregationsMinisters.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import CpsAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import CpsAutocompleteObj from "@/components/Autocomplete/AutocompleteObj.vue";
import { peopleNamesController } from "@/controllers/peopleNames";
import {
  Congregation,
  congregationsController,
} from "@/controllers/congregations";
import { zipForCityController } from "@/controllers/zipForCity";
import { statesController } from "@/controllers/states";
import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import {
  appendYearsOld,
  personAgeToday,
  animateOnStateChange,
} from "@/utilities/displayHelpers";
import {
  peopleController,
  Gender,
  Person,
  PhoneNumberDescription,
  MaritalStatus,
  Qualification,
} from "@/controllers/people";
import {
  congregationsMinistersController,
  CongregationMinister,
} from "@/controllers/congregationsMinisters";
import {
  PCAPlacementPacketSentByMethod,
  PreparatoryClassApplicationStatus,
  PreparatoryClassApplicationFlag,
  preparatoryClassApplicationController,
  PreparatoryClassApplication,
  PCAPlacementConfirmedMethod,
  PreparatoryClassApplicationPreference,
} from "@/controllers/preparatoryClassApplications";
import {
  preparatoryClassesController,
  PreparatoryClassClassification,
  PreparatoryClass,
} from "@/controllers/preparatoryClasses";
import { preparatoryClassStatisticsController } from "@/controllers/preparatoryClassStatistics";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";
import { FormModes } from "@/main";
import {
  submittedPreparatoryClassApplicationsController,
  SubmittedPreparatoryClassApplication,
} from "@/controllers/submittedPreparatoryClassApplications";

import {
  addPhoneNumber,
  PhoneNumberFormItem,
  EmailAddressFormItem,
  addEmailAddress,
  buildPerson,
} from "@/utilities/formHelpers";

interface PCAPreferenceFormItem {
  browserId: string;
  id: number;
  inputLabel: string;
  preparatoryClass: { label: string; id: number };
  preparatoryClassId: number;
  preferenceOrder: number;
  placements: number;
  _destroy: boolean;
}

export const enum ApplicationFormEvent {
  selectSimilarPerson = "selectSimilarPerson",
  selectCongregationMinister = "selectCongregationMinister",
}

function setFormMode(id: string | undefined | null): FormModes {
  if (id) {
    if (id === "0") {
      return FormModes.new;
    } else {
      return FormModes.edit;
    }
  } else {
    return FormModes.new;
  }
}

export default defineComponent({
  name: "preparatoryClassApplicationForm",
  components: {
    CpsAutocomplete,
    CpsAutocompleteObj,
    FormErrors,
    LoadingIndicator,
    SimilarPeople,
    CongregationsMinisters,
  },
  props: {
    preparatoryClassApplicationId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);
    const submitButtonText = ref("Submit");

    const formMode = ref(FormModes.new);
    const loadspca = ref(false);

    const cancelRoute = {
      name: NamedRts.preparatoryClassApplicationList,
    };

    const deleting = ref(false);
    const deleteButtonText = ref("Delete Application");

    const similarPeople = ref([]);
    const similarPeopleBell = ref(false);

    const congregationsMinisters = ref([]);
    const congregationsMinistersBell = ref(false);
    const refState: any = ref(null);
    const refCongregation: any = ref(null);
    const refPlacement: any = ref(null);

    const { person } = buildPerson();

    const preparatoryClassApplication = reactive({
      classification: PreparatoryClassClassification.boys,
      flag: PreparatoryClassApplicationFlag.normal,
      id: 0,
      personId: 0,
      placementPacketSentDate: "",
      ministerEmailAddress: "",
      ministerName: "",
      ministerPhoneNumber: "",
      placementDate: "",
      placementConfirmedMethod: PCAPlacementConfirmedMethod.emailLink,
      placementPreparatoryClassId: 0,
      status: PreparatoryClassApplicationStatus.pending,
      submittedDate: "",
      placementPreparatoryClass: {
        label: "",
        id: 0,
      },
      placementConfirmedDate: "",
      placementPacketSentByMethod: PCAPlacementPacketSentByMethod.email,
    });

    const pcaPreferences = reactive<PCAPreferenceFormItem[]>([]);

    const personCongregation = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const personState = reactive({
      label: "",
      id: 0,
    });

    onMounted(() => {
      const dataPromises = [
        peopleNamesController.needLastNames(),
        peopleNamesController.needFirstNames(),
        statesController.needStatesProvinces(),
        congregationsController.needCongregations(),
        preparatoryClassesController.needPreparatoryClasses(),
        preparatoryClassStatisticsController.needPreparatoryClassStatistics(),
      ];

      formMode.value = setFormMode(props.preparatoryClassApplicationId);
      if (props.preparatoryClassApplicationId) {
        if (formMode.value === FormModes.new) {
          // it is a new form
          // write the code to check for a submitted application here to load the data
          const submittedPreparatoryClassApplicationId =
            ctx.root.$route.query.submittedPreparatoryClassApplicationId;
          if (submittedPreparatoryClassApplicationId) {
            loadspca.value = true;
            dataPromises.push(
              submittedPreparatoryClassApplicationsController.needSubmittedPreparatoryClassApplication(
                submittedPreparatoryClassApplicationId as string,
              ),
            );
          }

          // from the api and add it to the form
          // it should just be one of the promises added to the dataPromises
          // and then afterwards import the date
          // This form should maybe have an extra mode along with the new mode
          // to indicate that it is importing data
          Promise.all(dataPromises).then(_response => {
            if (loadspca.value) {
              dataLoaded.value = true;
              const apiSPCA = apiSubmittedPreparatoryClassApplication.value;

              person.firstName = apiSPCA.firstName;
              person.lastName = apiSPCA.lastName;
              person.middleName = apiSPCA.middleName;
              person.birthDate = apiSPCA.birthDate;
              person.address = apiSPCA.address1;
              person.city = apiSPCA.city;
              person.parents = `${apiSPCA.fatherFirstName} and ${apiSPCA.motherFirstName}`;
              person.gender = apiSPCA.gender;
              person.zip = apiSPCA.zipCode;

              // load the phone numbers that were submitted
              person.phoneNumbers = [
                {
                  id: 0,
                  inputLabel: `Phone - cell`,
                  browserId: `cps-${uuidv4()}`,
                  number: apiSPCA.phoneNumber,
                  description: PhoneNumberDescription.cell,
                  _destroy: false,
                },
              ];

              // and the email address
              person.emailAddresses = [
                {
                  inputLabel: "Email",
                  browserId: `cps-${uuidv4()}`,
                  id: 0,
                  address: apiSPCA.emailAddress,
                  _destroy: false,
                },
              ];

              showSimilarPeople();

              preparatoryClassApplication.ministerName = apiSPCA.ministerName;
              preparatoryClassApplication.ministerEmailAddress =
                apiSPCA.ministerEmail;
              preparatoryClassApplication.ministerPhoneNumber =
                apiSPCA.ministerPhoneNumber;
              preparatoryClassApplication.submittedDate = apiSPCA.submittedAt;

              const choiceIdsCleaner = (ids: number[]) => {
                return ids.reduce((accum, item) => {
                  if (item !== 0) {
                    accum.push(item);
                  }
                  return accum;
                }, [] as number[]);
              };

              const choiceIds = choiceIdsCleaner([
                apiSPCA.firstChoiceId,
                apiSPCA.secondChoiceId,
                apiSPCA.thirdChoiceId,
                apiSPCA.fourthChoiceId,
              ]);

              const tempItems = choiceIds.map(
                (item, idx): PCAPreferenceFormItem => {
                  const bId = `cps-${uuidv4()}`;
                  const pl = getPreparatoryClassPlacementsCount(item);
                  const pClass = preparatoryClassById(item);
                  const prefLabels = [
                    "1st Preference",
                    "2nd Preference",
                    "3rd Preference",
                    "4th Preference",
                  ];

                  return {
                    id: 0,
                    preparatoryClassId: item,
                    _destroy: false,
                    browserId: bId,
                    preferenceOrder: idx + 1,
                    inputLabel: prefLabels[idx],
                    placements: pl,
                    preparatoryClass: {
                      id: item,
                      label: pClass.fullName,
                    },
                  };
                },
              );

              tempItems.map(item => {
                pcaPreferences.push(item);
              });

              // load the congregation and state
              person.congregationId = apiSPCA.congregationId;
              person.stateProvinceId = apiSPCA.stateProvinceId;
              const s = stateProvinceById(apiSPCA.stateProvinceId);
              const c = congregationById(apiSPCA.congregationId);

              ctx.root.$nextTick(() => {
                if (s) {
                  refState.value.setResultParent({
                    id: s.id,
                    label: s.name,
                  });
                }

                if (c) {
                  refCongregation.value.setResultParent({
                    id: c.id,
                    label: c.name,
                  });
                }

                autoSetPlacement();
              });
            } else {
              const formatPattern = "yyyy-MM-dd";
              const thisDay = startOfToday();
              // this is for a new form with no data
              // prefilled, it is for manual data entry
              preparatoryClassApplication.submittedDate = format(
                thisDay,
                formatPattern,
              );
              addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.cell);

              addEmailAddress(person.emailAddresses);
              // add four choices
              addPreparatoryClassApplicationPreference();
              addPreparatoryClassApplicationPreference();
              addPreparatoryClassApplicationPreference();
              addPreparatoryClassApplicationPreference();
              dataLoaded.value = true;
            }
          });
        } else {
          dataPromises.push(
            preparatoryClassApplicationController.needPreparatoryClassApplication(
              props.preparatoryClassApplicationId,
            ),
          );
          Promise.all(dataPromises)
            .then(response => {
              // make the form visible right away
              // necessary to be able to access the refs in the html
              dataLoaded.value = true;
              preparatoryClassApplication.id =
                apiPreparatoryClassApplication.value.id;
              preparatoryClassApplication.personId =
                apiPreparatoryClassApplication.value.personId;
              preparatoryClassApplication.placementPacketSentDate =
                apiPreparatoryClassApplication.value.placementPacketSentDate;
              preparatoryClassApplication.classification =
                apiPreparatoryClassApplication.value.classification;
              preparatoryClassApplication.flag =
                apiPreparatoryClassApplication.value.flag;
              preparatoryClassApplication.ministerEmailAddress =
                apiPreparatoryClassApplication.value.ministerEmailAddress;
              preparatoryClassApplication.ministerName =
                apiPreparatoryClassApplication.value.ministerName;
              preparatoryClassApplication.ministerPhoneNumber =
                apiPreparatoryClassApplication.value.ministerPhoneNumber;
              preparatoryClassApplication.placementDate =
                apiPreparatoryClassApplication.value.placementDate;
              preparatoryClassApplication.placementConfirmedMethod =
                apiPreparatoryClassApplication.value.placementConfirmedMethod;
              preparatoryClassApplication.placementPreparatoryClassId =
                apiPreparatoryClassApplication.value.placementPreparatoryClassId;
              preparatoryClassApplication.status =
                apiPreparatoryClassApplication.value.status;
              preparatoryClassApplication.submittedDate =
                apiPreparatoryClassApplication.value.submittedDate;
              preparatoryClassApplication.placementConfirmedDate =
                apiPreparatoryClassApplication.value.placementConfirmedDate;
              preparatoryClassApplication.placementPacketSentByMethod =
                apiPreparatoryClassApplication.value.placementPacketSentByMethod;

              preparatoryClassApplication.placementPreparatoryClass.id =
                apiPreparatoryClassApplication.value.placementPreparatoryClassId;
              const tempItems = addFieldsToPreferences(
                apiPreparatoryClassApplication.value
                  .preparatoryClassApplicationPreferences,
              );

              tempItems.map(item => {
                pcaPreferences.push(item);
              });

              // load the placement preparatory class
              const ppc = preparatoryClassById(
                preparatoryClassApplication.placementPreparatoryClassId,
              );
              ctx.root.$nextTick(() => {
                // loop over the preferences and set the combo box
                if (ppc) {
                  refPlacement.value.setResultParent({
                    id: ppc.id,
                    label: ppc.fullName,
                  });
                }
              });
              return response;
            })
            .then(_response => {
              // lookup the person here
              return peopleController.needPerson(
                apiPreparatoryClassApplication.value.personId.toString(),
              );
            })
            .then(response => {
              loadPersonIntoForm();
              return response;
            })
            .finally(() => {
              dataLoaded.value = true;
            });
        }
      }
    });

    // getters from the store for the autocompletes
    const firstNames = computed(() => {
      return $store.getters.firstNames;
    });

    const lastNames = computed(() => {
      return $store.getters.lastNames;
    });

    const preparatoryClassesForAutocomplete = computed(() => {
      return $store.getters.preparatoryClassesForAutocomplete;
    });

    const congregationById = (id: number) => {
      return $store.getters.congregationById(id);
    };

    const stateProvinceById = (id: number) => {
      return $store.getters.stateProvinceById(id);
    };

    const preparatoryClassById = (id: number): PreparatoryClass => {
      return $store.getters.preparatoryClassById(id);
    };

    const congregationsForAutocomplete = computed(() => {
      return $store.getters.congregationsForAutocomplete;
    });

    const statesProvincesForAutocomplete = computed(() => {
      return $store.getters.statesProvincesForAutocomplete;
    });

    const getPreparatoryClassPlacementsCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassPlacementsCount(id);
      } else {
        0;
      }
    };

    const updateFirstName = (evt: string) => {
      person.firstName = evt;
    };

    const updateCongregation = (evt: AutocompleteResult): void => {
      // look up the city here
      const t: Congregation = congregationById(evt.id);
      personCongregation.id = evt.id;
      personCongregation.label = evt.label;
      person.congregationId = evt.id;

      // set up the city
      if (person.city === "") {
        person.city = t.city;
      }

      // load the state
      const s = stateProvinceById(t.stateId);
      if (person.stateProvinceId === 0) {
        person.stateProvinceId = s.id;
        refState.value.setResultParent({
          id: s.id,
          label: s.name,
        });
      }

      lookupCongregationsMinisters(evt.id.toString());
    };

    const updateState = (evt: AutocompleteResult): void => {
      person.stateProvinceId = evt.id;
    };

    // end of the code for the autocompletes

    const showSimilarPeople = () => {
      // only run this if a new person is being entered
      if (person.id === 0) {
        const firstName = person.firstName;
        const birthDate = person.birthDate;
        if (!(firstName === "" || birthDate === "")) {
          peopleController
            .needSimilarPeople({
              first_name: person.firstName,
              birth_date: person.birthDate,
              gender: person.gender,
            })
            .then(response => {
              similarPeople.value = response;
              if (similarPeople.value.length > 0) {
                similarPeopleBell.value = true;
              }
            });
        }
      }
    };

    const lookupCityZip = () => {
      if (person.city !== "") {
        zipForCityController
          .zipForCity(person.city, person.stateProvinceId.toString())
          .then(response => {
            if (response) {
              person.zip = response;
              return response;
            }
          });
      }
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();

      // reset some things
      hasErrors.value = false;
      errorMessages.value = [];

      // set some things
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let apiResponse;

      if (person.id === 0) {
        // it is a new person, post the data
        apiResponse = peopleController.createPerson(person);
      } else {
        apiResponse = peopleController.updatePerson(person);
      }

      apiResponse
        .then(_response => {
          // the person was successfully saved
          // change the data on the person so
          // that they are not saved again
          person.id = apiPerson.value.id;
          // update the class application
          preparatoryClassApplication.personId = apiPerson.value.id;

          const pca: PreparatoryClassApplication = {
            fullName: "",
            congregation: "",
            personId: preparatoryClassApplication.personId,
            classification: preparatoryClassApplication.classification,
            flag: preparatoryClassApplication.flag,
            id: preparatoryClassApplication.id,
            ministerEmailAddress:
              preparatoryClassApplication.ministerEmailAddress,
            ministerName: preparatoryClassApplication.ministerName,
            ministerPhoneNumber:
              preparatoryClassApplication.ministerPhoneNumber,
            placementConfirmedDate:
              preparatoryClassApplication.placementConfirmedDate,
            placementConfirmedMethod:
              preparatoryClassApplication.placementConfirmedMethod,
            placementDate: preparatoryClassApplication.placementDate,
            placementPacketSentByMethod:
              preparatoryClassApplication.placementPacketSentByMethod,
            placementPacketSentDate:
              preparatoryClassApplication.placementPacketSentDate,
            placementPreparatoryClassId:
              preparatoryClassApplication.placementPreparatoryClassId,
            status: preparatoryClassApplication.status,
            submittedDate: preparatoryClassApplication.submittedDate,
            preparatoryClassApplicationPreferences: pcaPreferences.reduce(
              (accum, item) => {
                if (item.preparatoryClassId) {
                  accum.push({
                    _destroy: item._destroy,
                    id: item.id,
                    preferenceOrder: item.preferenceOrder,
                    preparatoryClassId: item.preparatoryClassId,
                    preparatoryClassApplicationId:
                      preparatoryClassApplication.id,
                    preparatoryClassCongregationName: "",
                    preparatoryClassEndDate: "",
                    preparatoryClassName: "",
                    preparatoryClassStartDate: "",
                  });

                  return accum;
                } else {
                  return accum;
                }
              },
              [] as PreparatoryClassApplicationPreference[],
            ),
          };
          if (preparatoryClassApplication.id === 0) {
            if (loadspca) {
              return preparatoryClassApplicationController.createPreparatoryClassApplication(
                pca,
                apiSubmittedPreparatoryClassApplication.value.id,
              );
            } else {
              return preparatoryClassApplicationController.createPreparatoryClassApplication(
                pca,
              );
            }
          } else {
            return preparatoryClassApplicationController.updatePreparatoryClassApplication(
              pca,
            );
          }
        })
        .then(_response => {
          ctx.root.$bvToast.toast(
            "Application was successfully saved.",
            TOAST_OPTIONS,
          );
          ctx.root.$router.push({
            name: NamedRts.preparatoryClassApplicationList,
          });
        })
        .catch(error => {
          errorMessages.value = error;
          hasErrors.value = true;
        })
        .finally(() => {
          // reset some values
          submitting.value = false;
          submitButtonText.value = "Submit";
        });
    };

    const personAge = computed(() => {
      if (person.birthDate !== "") {
        const birthDate = parseISO(person.birthDate);
        return appendYearsOld(personAgeToday(birthDate));
      } else {
        return "";
      }
    });

    const apiPerson = computed(() => {
      return $store.getters.person as Person;
    });

    const apiPreparatoryClassApplication = computed(() => {
      return $store.getters
        .preparatoryClassApplication as PreparatoryClassApplication;
    });

    const apiSubmittedPreparatoryClassApplication = computed(() => {
      return $store.getters
        .submittedPreparatoryClassApplication as SubmittedPreparatoryClassApplication;
    });

    const loadPersonIntoForm = () => {
      const ap = apiPerson.value as Person;
      person.phoneNumbers = ap.phoneNumbers.map(item => {
        const desc =
          item.description === "home"
            ? PhoneNumberDescription.home
            : item.description === "fax"
            ? PhoneNumberDescription.fax
            : PhoneNumberDescription.cell;
        const bId = `cps-${uuidv4()}`;

        return {
          id: item.id,
          inputLabel: `Phone - ${desc}`,
          browserId: bId,
          number: item.number,
          description: desc,
          _destroy: false,
        };
      });

      person.emailAddresses = ap.emailAddresses.map(item => {
        const bId = `cps-${uuidv4()}`;
        return {
          id: item.id,
          inputLabel: "Email",
          browserId: bId,
          address: item.address,
          _destroy: false,
        };
      });

      person.firstName = ap.firstName;
      person.lastName = ap.lastName;
      person.id = ap.id;
      person.birthDate = ap.birthDate;
      person.address = ap.address;
      person.city = ap.city;
      person.parents = ap.parents;
      person.congregationId = ap.congregationId;
      person.gender = ap.gender;
      person.zip = ap.zip;
      person.stateProvinceId = ap.stateProvinceId;

      const s = stateProvinceById(ap.stateProvinceId);
      if (s) {
        refState.value.setResultParent({
          id: s.id,
          label: s.name,
        });
      }

      const c = congregationById(ap.congregationId);
      if (c) {
        refCongregation.value.setResultParent({
          id: c.id,
          label: c.name,
        });
      }
    };

    const loadPerson = (id: number) => {
      // this function loads an existing person into the form
      person.id = id;
      peopleController.needPerson(id.toString()).then(_response => {
        loadPersonIntoForm();
        lookupCongregationsMinisters(apiPerson.value.congregationId.toString());
      });
      similarPeople.value = [];
      similarPeopleBell.value = false;
      const el = document.getElementById("person-data-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    const loadMinister = (minister: CongregationMinister) => {
      congregationsMinistersBell.value = false;
      preparatoryClassApplication.ministerName = minister.name;
      preparatoryClassApplication.ministerEmailAddress = minister.emailAddress;
      preparatoryClassApplication.ministerPhoneNumber = minister.phoneNumber;

      const el = document.getElementById("minister-data-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    const lookupCongregationsMinisters = (congregationId: string) => {
      congregationsMinistersController
        .needCongregationsMinisters(congregationId)
        .then(response => {
          if (response.length > 0) {
            congregationsMinisters.value = response;
            congregationsMinistersBell.value = true;
          }
        });
    };

    const addPreparatoryClassApplicationPreference = () => {
      const prefLabels = [
        "0th Preference",
        "1st Preference",
        "2nd Preference",
        "3rd Preference",
        "4th Preference",
      ];

      const bId = `cps-${uuidv4()}`;
      const prefOrd = pcaPreferences.length + 1;
      const ordinalizedLabel = `${prefLabels[prefOrd]}`;

      pcaPreferences.push({
        browserId: bId,
        id: 0,
        inputLabel: ordinalizedLabel,
        preparatoryClassId: 0,
        preparatoryClass: {
          label: "",
          id: 0,
        },
        preferenceOrder: prefOrd,
        placements: 0,
        _destroy: false,
      });
    };

    const updatePreparatoryClassApplicationPreference = (
      pref: PCAPreferenceFormItem,
      evt: AutocompleteResult,
    ) => {
      pref.preparatoryClassId = evt.id;
      pref.preparatoryClass.label = evt.label;
      pref.preparatoryClass.id = evt.id;
      pref.placements = getPreparatoryClassPlacementsCount(evt.id);
      if (preparatoryClassApplication.placementPreparatoryClassId === 0) {
        if (pref.placements < 16) {
          preparatoryClassApplication.placementPreparatoryClassId = evt.id;
          preparatoryClassApplication.status =
            PreparatoryClassApplicationStatus.placed;
          refPlacement.value.setResultParent(evt);
          preparatoryClassApplication.placementPreparatoryClass = evt;
        }
      }
    };

    const autoSetPlacement = () => {
      if (preparatoryClassApplication.placementPreparatoryClassId === 0) {
        for (const item of pcaPreferences) {
          if (item.placements < 16) {
            preparatoryClassApplication.placementPreparatoryClassId =
              item.preparatoryClassId;
            preparatoryClassApplication.status =
              PreparatoryClassApplicationStatus.placed;
            refPlacement.value.setResultParent({
              id: item.preparatoryClass.id,
              label: item.preparatoryClass.label,
            });
            preparatoryClassApplication.placementPreparatoryClass =
              item.preparatoryClass;

            // found a match
            break;
          }
        }
      }
    };

    const addFieldsToPreferences = (
      preferences: PreparatoryClassApplicationPreference[],
    ): PCAPreferenceFormItem[] => {
      const prefLabels = [
        "1st Preference",
        "2nd Preference",
        "3rd Preference",
        "4th Preference",
      ];

      // lookup the number of placements

      const prefWithFields = preferences.map((item, idx) => {
        const bId = `cps-${uuidv4()}`;
        const pl = getPreparatoryClassPlacementsCount(item.preparatoryClassId);

        const pClass = preparatoryClassById(item.preparatoryClassId);

        return {
          ...item,
          browserId: bId,
          preferenceOrder: idx + 1,
          inputLabel: prefLabels[idx],
          placements: pl,
          preparatoryClass: {
            id: item.preparatoryClassId,
            label: pClass.fullName,
          },
        };
      });

      return prefWithFields;
    };

    const updatePreparatoryClassApplicationPlacement = (
      evt: AutocompleteResult,
    ) => {
      const formatPattern = "yyyy-MM-dd";
      const thisDay = startOfToday();
      preparatoryClassApplication.placementPreparatoryClass = evt;
      preparatoryClassApplication.placementPreparatoryClassId = evt.id;
      preparatoryClassApplication.status =
        PreparatoryClassApplicationStatus.placed;
      preparatoryClassApplication.submittedDate = format(
        thisDay,
        formatPattern,
      );
    };

    const placementApplicationsCount = () => {
      if (preparatoryClassApplication.placementPreparatoryClassId !== 0) {
        return getPreparatoryClassPlacementsCount(
          preparatoryClassApplication.placementPreparatoryClassId,
        );
      } else {
        0;
      }
    };

    const preferenceIsPlacedPreference = (pref: PCAPreferenceFormItem) => {
      return pref.preparatoryClassId === 0
        ? false
        : pref.preparatoryClassId ===
          preparatoryClassApplication.placementPreparatoryClassId
        ? true
        : false;
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      const pca: PreparatoryClassApplication = {
        fullName: "",
        congregation: "",
        personId: preparatoryClassApplication.personId,
        classification: preparatoryClassApplication.classification,
        flag: preparatoryClassApplication.flag,
        id: preparatoryClassApplication.id,
        ministerEmailAddress: preparatoryClassApplication.ministerEmailAddress,
        ministerName: preparatoryClassApplication.ministerName,
        ministerPhoneNumber: preparatoryClassApplication.ministerPhoneNumber,
        placementConfirmedDate:
          preparatoryClassApplication.placementConfirmedDate,
        placementConfirmedMethod:
          preparatoryClassApplication.placementConfirmedMethod,
        placementDate: preparatoryClassApplication.placementDate,
        placementPacketSentByMethod:
          preparatoryClassApplication.placementPacketSentByMethod,
        placementPacketSentDate:
          preparatoryClassApplication.placementPacketSentDate,
        placementPreparatoryClassId:
          preparatoryClassApplication.placementPreparatoryClassId,
        status: preparatoryClassApplication.status,
        submittedDate: preparatoryClassApplication.submittedDate,
        preparatoryClassApplicationPreferences: pcaPreferences.map(item => {
          return {
            id: item.id,
            preparatoryClassId: item.preparatoryClassId,
            preferenceOrder: item.preferenceOrder,
            _destroy: item._destroy,
            preparatoryClassApplicationId: preparatoryClassApplication.id,
            preparatoryClassCongregationName: "",
            preparatoryClassEndDate: "",
            preparatoryClassName: "",
            preparatoryClassStartDate: "",
          };
        }),
      };
      preparatoryClassApplicationController
        .deletePreparatoryClassApplication(pca)
        .then(_response => {
          cancelDelete();
          ctx.root.$bvToast.toast("Application deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          deleting.value = false;
          deleteButtonText.value = "Delete Application";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete Application";
    };

    return {
      apiSubmittedPreparatoryClassApplication,
      cancelDelete,
      cancelRoute,
      congregationsForAutocomplete,
      congregationsMinisters,
      congregationsMinistersBell,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      firstNames,
      hasErrors,
      lastNames,
      loadMinister,
      loadPerson,
      loadspca,
      lookupCityZip,
      onSubmit,
      pcaPreferences,
      performDelete,
      person,
      personAge,
      personCongregation,
      personState,
      placementApplicationsCount,
      preferenceIsPlacedPreference,
      preparatoryClassApplication,
      preparatoryClassesForAutocomplete,
      refCongregation,
      refPlacement,
      refState,
      showSimilarPeople,
      similarPeople,
      similarPeopleBell,
      startDelete,
      statesProvincesForAutocomplete,
      submitButtonText,
      submitting,
      updateCongregation,
      updateFirstName,
      updatePreparatoryClassApplicationPlacement,
      updatePreparatoryClassApplicationPreference,
      updateState,
    };
  },
});
