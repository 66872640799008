import {
  Person,
  Gender,
  MaritalStatus,
  Qualification,
} from "@/controllers/people";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface PeopleLookup {
  [key: number]: Person;
}

interface PeopleState {
  person: Person;
  people: PeopleLookup;
  peopleForTable: Person[];
  peopleForAutocomplete: AutocompleteResult[];
}

export const emptyPerson: Person = {
  address2: "",
  address: "",
  birthCity: "",
  birthDate: "",
  birthStateId: 0,
  birthStateName: "",
  citizenships: [],
  city: "",
  congregationId: 0,
  congregationName: "",
  driversLicense: "",
  emailAddresses: [],
  firstName: "",
  gender: Gender.male,
  id: 0,
  lastName: "",
  maritalStatus: MaritalStatus.single,
  middleName: "",
  parents: "",
  phoneNumbers: [],
  qualification: Qualification.normal,
  stateProvinceId: 0,
  stateProvinceName: "",
  stateProvinceAbbreviation: "",
  zip: "",
};

const state: PeopleState = {
  person: emptyPerson,
  people: [],
  peopleForAutocomplete: [],
  peopleForTable: [],
};

const mutations = {
  LOAD_PERSON(state: PeopleState, payload: Person) {
    state.person = payload;
  },
  LOAD_PEOPLE_FOR_TABLE(state: PeopleState, payload: Person[]) {
    state.peopleForTable = payload;
  },
  LOAD_PEOPLE_FOR_AUTOCOMPLETE(
    state: PeopleState,
    payload: AutocompleteResult[],
  ) {
    state.peopleForAutocomplete = payload;
  },
  LOAD_PEOPLE_FOR_LOOKUP(state: PeopleState, payload: PeopleLookup) {
    state.people = payload;
  },
};

const actions = {
  loadPerson(context: any, person: Person): Promise<Person> {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PERSON", person);
      resolve(person);
    });
  },
  loadPeople(context: any, people: Person[]) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = people.map(item => {
        return {
          label: item.firstName,
          id: item.id,
        };
      });

      const lookupItems = people.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});
      context.commit("LOAD_PEOPLE_FOR_AUTOCOMPLETE", acItems);
      context.commit("LOAD_PEOPLE_FOR_TABLE", people);
      context.commit("LOAD_PEOPLE_FOR_LOOKUP", lookupItems);
      resolve(people);
    });
  },
};

const getters = {
  person: (state: PeopleState) => {
    return state.person;
  },

  peopleForTable: (state: PeopleState) => {
    return state.peopleForTable;
  },
};

const peopleModule = {
  state,
  mutations,
  actions,
  getters,
};

export default peopleModule;
