import CpsAutocompleteObj from "@/components/Autocomplete/AutocompleteObj.vue";
import CpsAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { FormModes } from "@/main";
import {
  Gender,
  Qualification,
  MaritalStatus,
  qualificationEnumReverseLookup,
} from "@/controllers/people";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import {
  prettifyUnitWorkType,
  prettifyQualification,
} from "@/utilities/displayHelpers";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
} from "@vue/composition-api";
import {
  Congregation,
  congregationsController,
} from "@/controllers/congregations";
import { statesController, StateProvince } from "@/controllers/states";
import { publicUnitsController } from "@/controllers/public/units";
import { publicCountriesController } from "@/controllers/public/countries";
import { UnitClassification } from "@/controllers/units";
import {
  PersistentUserData,
  getPersistentUserData,
  setPersistentUserDate,
} from "@/clientStore";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";
import { publicUnitPositionTermLengthsController } from "@/controllers/public/unitPositionTermLengths";
import { publicSubmittedUnitApplicationController } from "@/controllers/public/submittedUnitApplication";
import { SubmittedUnitApplicationStatus } from "@/controllers/submittedUnitApplications";

export default defineComponent({
  name: "defaultForm",
  components: {
    FormErrors,
    LoadingIndicator,
    CpsAutocompleteObj,
    CpsAutocomplete,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const formMode = ref(FormModes.new);
    const submitting = ref(false);
    const submitButtonText = ref("Submit");

    const formTitle = ref("Unit Application");
    const refFirstName: any = ref(null);
    const refDriversLicense: any = ref(null);
    const refMinisterName: any = ref(null);

    const refCongregation: any = ref(null);
    const refStateInput: any = ref(null);
    // Driver's License State input
    const refDlStateInput: any = ref(null);
    // Birth state input
    const refBirthStateInput: any = ref(null);

    const fd = reactive({
      firstName: "",
      middleName: "",
      lastName: "",
      maritalStatus: MaritalStatus.single,
      gender: Gender.male,
      phoneNumber: "",
      emailAddress: "",
      birthDate: "",
      fatherFirstName: "",
      motherFirstName: "",

      applicantNote: "",

      availableDate: "",

      acceptsCpsPolicy: false,
      ministerConfirmation: false,

      driversLicense: "",
      dlStateProvinceId: 0,

      birthCity: "",
      birthStateProvinceId: 0,

      congregationId: 0,
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      stateProvinceId: 0,

      ministerName: "",
      ministerPhoneNumber: "",
      ministerEmail: "",
      ministerNote: "",
    });

    const fdUnitIds = ref<number[]>([]);
    const fdCountryIds = ref<number[]>([]);
    const fdQualification = ref("");
    const personQualifications = reactive<Qualification[]>([]);

    const unitPositionTermLengthLookup = (unitId: number) => {
      const t = $store.getters.unitWithPositionTermLengthLookup(
        unitPosition,
        unitId,
      );

      if (t) {
        return t.termLength;
      } else {
        return 0;
      }
    };

    const unitsForTable = computed(() => {
      return $store.getters.unitsForTable;
    });

    const countriesForTable = computed(() => {
      return $store.getters.countriesForTable;
    });

    // These are the items necessary for
    // the congregation autocomplete object
    const congregationById = (id: number) => {
      return $store.getters.congregationById(id);
    };

    const congregationsForAutocomplete = computed(() => {
      return $store.getters.congregationsForAutocomplete;
    });

    const congregationAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateCongregationAc = (evt: AutocompleteResult) => {
      fd.congregationId = evt.id;
      congregationAcResult.id = evt.id;
      congregationAcResult.label = evt.label;
    };
    // End autocomplete congregation

    // These are the items necessary for
    // the state autocomplete object
    const stateProvinceById = (id: number) => {
      return $store.getters.stateProvinceById(id);
    };

    const statesProvincesForAutocomplete = computed(() => {
      return $store.getters.statesProvincesForAutocomplete;
    });

    const stateAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateStateAc = (evt: AutocompleteResult) => {
      fd.stateProvinceId = evt.id;
      stateAcResult.id = evt.id;
      stateAcResult.label = evt.label;
    };
    // End state autocomplete object

    const dlStateAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateDlStateAc = (evt: AutocompleteResult) => {
      fd.dlStateProvinceId = evt.id;
      dlStateAcResult.id = evt.id;
      dlStateAcResult.label = evt.label;
    };
    // End Driver's License State input

    const birthStateAcResult = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateBirthStateAc = (evt: AutocompleteResult) => {
      fd.birthStateProvinceId = evt.id;
      birthStateAcResult.id = evt.id;
      birthStateAcResult.label = evt.label;
    };
    // End birth state input

    // Section Navigation
    // code for navigation between the steps of
    // the form

    const showPersonalDetails = ref(true);
    const showLegalDetails = ref(false);
    const showCongregationAddress = ref(false);
    const showUnitOptions = ref(false);
    const showMinister = ref(false);
    const showSectionNavigation = ref(true);

    const sectionNames = {
      CONGREGATION_ADDRESS: "congregationAddress",
      MINISTER: "minister",
      PERSONAL_DETAILS: "personalDetails",
      LEGAL_DETAILS: "legalDetails",
      UNIT_OPTIONS: "UnitOptions",
    };

    const showAllSections = (): void => {
      showPersonalDetails.value = true;
      showLegalDetails.value = true;
      showCongregationAddress.value = true;
      showUnitOptions.value = true;
      showMinister.value = true;
      showSectionNavigation.value = false;
    };

    const sectionNavigate = (sectionName: string): void => {
      if (sectionName === sectionNames.PERSONAL_DETAILS) {
        showPersonalDetails.value = true;
        showLegalDetails.value = false;
        showCongregationAddress.value = false;
        showUnitOptions.value = false;
        showMinister.value = false;
        ctx.root.$nextTick(() => {
          refFirstName.value.focus();
        });
      } else if (sectionName === sectionNames.LEGAL_DETAILS) {
        showPersonalDetails.value = false;
        showLegalDetails.value = true;
        showCongregationAddress.value = false;
        showUnitOptions.value = false;
        showMinister.value = false;
        ctx.root.$nextTick(() => {
          refDriversLicense.value.focus();
        });
      } else if (sectionName === sectionNames.CONGREGATION_ADDRESS) {
        showPersonalDetails.value = false;
        showLegalDetails.value = false;
        showCongregationAddress.value = true;
        showUnitOptions.value = false;
        showMinister.value = false;
        ctx.root.$nextTick(() => {
          refCongregation.value.setFocusParent();
        });
      } else if (sectionName === sectionNames.UNIT_OPTIONS) {
        showPersonalDetails.value = false;
        showLegalDetails.value = false;
        showCongregationAddress.value = false;
        showUnitOptions.value = true;
        showMinister.value = false;
      } else {
        showPersonalDetails.value = false;
        showLegalDetails.value = false;
        showCongregationAddress.value = false;
        showUnitOptions.value = false;
        showMinister.value = true;
        ctx.root.$nextTick(() => {
          refMinisterName.value.focus();
        });
      }
    };

    // End Section Navigation

    // Errors
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const resetErrors = () => {
      hasErrors.value = false;
      errorMessages.value = [];
    };

    const loadErrors = (errors: string[]) => {
      hasErrors.value = true;
      errorMessages.value = errors;
    };

    // End Errors

    const cancelRoute = {
      name: NamedRts.landingPage,
    };

    const nextRoute = {
      name: NamedRts.landingPage,
    };

    let unitPosition: UnitPosition = UnitPosition.boysUnitVolunteer;

    onMounted(() => {
      if (props.id === "girls") {
        formTitle.value = "Girls' Unit Application";
        fd.gender = Gender.female;
        unitPosition = UnitPosition.girlsUnitVolunteer;
        personQualifications.push(
          Qualification.normal,
          Qualification.cna,
          Qualification.lpn,
          Qualification.rn,
        );
      } else {
        formTitle.value = "Boys' Unit Application";
        fd.gender = Gender.male;
        unitPosition = UnitPosition.boysUnitVolunteer;
        personQualifications.push(
          Qualification.normal,
          Qualification.constructionExperience,
        );
      }

      const dataPromises = [
        congregationsController.needCongregations(),
        statesController.needStatesProvinces(),
        publicCountriesController.needCountries(),
      ];

      if (props.id === "girls") {
        dataPromises.push(
          publicUnitsController.needUnits(UnitClassification.girls),
          publicUnitPositionTermLengthsController.needUnitPositionTermLengths(
            UnitPosition.girlsUnitVolunteer,
          ),
        );
      } else {
        dataPromises.push(
          publicUnitsController.needUnits(UnitClassification.boys),
          publicUnitPositionTermLengthsController.needUnitPositionTermLengths(
            UnitPosition.boysUnitVolunteer,
          ),
        );
      }
      formMode.value = FormModes.new;

      Promise.all(dataPromises)
        .then(_response => {
          dataLoaded.value = true;
          const pud: PersistentUserData = getPersistentUserData();
          fd.ministerName = pud.ministerName;
          fd.ministerEmail = pud.ministerEmail;
          fd.ministerPhoneNumber = pud.ministerPhoneNumber;
          fd.zipCode = pud.zipCode;
          fd.city = pud.city;

          fdQualification.value = Qualification.normal;

          // lookup the congregation
          const t: Congregation = congregationById(pud.congregationId);
          if (t) {
            fd.congregationId = t.id;
            ctx.root.$nextTick(() => {
              refCongregation.value.setResultParent({
                id: t.id,
                label: t.name,
              });
            });
          }

          const s: StateProvince = stateProvinceById(pud.stateId);
          if (s) {
            fd.stateProvinceId = s.id;
            fd.dlStateProvinceId = s.id;
            fd.birthStateProvinceId = s.id;
            ctx.root.$nextTick(() => {
              refStateInput.value.setResultParent({
                id: s.id,
                label: s.name,
              });

              refDlStateInput.value.setResultParent({
                id: s.id,
                label: s.name,
              });

              refBirthStateInput.value.setResultParent({
                id: s.id,
                label: s.name,
              });
            });
          }

          ctx.root.$nextTick(() => {
            refFirstName.value.focus();
          });
        })
        .catch(errors => {
          loadErrors(errors);
          dataLoaded.value = true;
        });
    });

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      // reset some things
      resetErrors();

      // set some things
      submitting.value = true;
      submitButtonText.value = "Submitting...";
      // lookup the qualification
      const sq = qualificationEnumReverseLookup[fdQualification.value];

      publicSubmittedUnitApplicationController
        .create({
          acceptsCpsPolicy: fd.acceptsCpsPolicy,
          address1: fd.address1,
          address2: fd.address2,
          applicantNote: fd.applicantNote,
          availableDate: fd.availableDate,
          birthCity: fd.birthCity,
          birthDate: fd.birthDate,
          birthStateId: fd.birthStateProvinceId,
          city: fd.city,
          congregationId: fd.congregationId,
          dlStateId: fd.dlStateProvinceId,
          driversLicense: fd.driversLicense,
          emailAddress: fd.emailAddress,
          fatherFirstName: fd.fatherFirstName,
          firstName: fd.firstName,
          gender: fd.gender,
          lastName: fd.lastName,
          maritalStatus: fd.maritalStatus,
          middleName: fd.middleName,
          ministerConfirmation: fd.ministerConfirmation,
          ministerEmail: fd.ministerEmail,
          ministerName: fd.ministerName,
          ministerNote: fd.ministerNote,
          ministerPhoneNumber: fd.ministerPhoneNumber,
          motherFirstName: fd.motherFirstName,
          phoneNumber: fd.phoneNumber,
          position: unitPosition,
          qualification: sq,
          stateProvinceId: fd.stateProvinceId,
          status: SubmittedUnitApplicationStatus.pending,
          submittedAt: "",
          zipCode: fd.zipCode,
          unitIds: fdUnitIds.value,
          countryIds: fdCountryIds.value,
        })
        .then(_response => {
          setPersistentUserDate({
            city: fd.city,
            congregationId: fd.congregationId,
            ministerEmail: fd.ministerEmail,
            ministerName: fd.ministerName,
            ministerPhoneNumber: fd.ministerPhoneNumber,
            stateId: fd.stateProvinceId,
            zipCode: fd.zipCode,
          });

          ctx.root.$bvToast.toast(
            "Form was successfully saved.",
            TOAST_OPTIONS,
          );
          ctx.root.$router.push(nextRoute);
        })
        .catch(error => {
          loadErrors(error);
          showAllSections();
        })
        .finally(() => {
          // reset some values
          submitting.value = false;
          submitButtonText.value = "Submit";
        });
    };

    return {
      fd,
      fdQualification,
      fdUnitIds,
      fdCountryIds,
      formTitle,
      personQualifications,
      prettifyQualification,

      sectionNames,
      sectionNavigate,
      showCongregationAddress,
      showMinister,
      showPersonalDetails,
      showLegalDetails,
      showUnitOptions,
      showSectionNavigation,

      refFirstName,

      unitsForTable,
      countriesForTable,
      unitPositionTermLengthLookup,
      prettifyUnitWorkType,

      refCongregation,
      congregationsForAutocomplete,
      congregationAcResult,
      updateCongregationAc,

      refDriversLicense,
      refMinisterName,

      refDlStateInput,
      dlStateAcResult,
      updateDlStateAc,

      refBirthStateInput,
      birthStateAcResult,
      updateBirthStateAc,

      refStateInput,
      statesProvincesForAutocomplete,
      stateAcResult,
      updateStateAc,
      // default items below

      cancelRoute,
      dataLoaded,
      errorMessages,
      hasErrors,

      onSubmit,
      submitButtonText,
      submitting,
    };
  },
});
