import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { TOAST_OPTIONS } from "@/constants";
import {
  ApiPreparatoryClassNote,
  preparatoryClassNotesController,
} from "@/controllers/pcNotes";

export default defineComponent({
  name: "preparatoryClassNotes",
  components: {
    LoadingIndicator,
  },
  props: {
    preparatoryClassId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const noteText = ref("");
    const notes = ref<ApiPreparatoryClassNote[]>([]);

    onMounted(() => {
      preparatoryClassNotesController
        .needPreparatoryClassNotes(Number(props.preparatoryClassId))
        .then(response => {
          notes.value = response;
          dataLoaded.value = true;
        });
    });

    const deleteNote = (noteId: number) => {
      preparatoryClassNotesController
        .deletePreparatoryClassNote(noteId)
        .then(response => {
          notes.value = response;
          ctx.root.$bvToast.toast("Note deleted.", TOAST_OPTIONS);
        });
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      if (noteText.value !== "") {
        preparatoryClassNotesController
          .createPreparatoryClassNote(
            Number(props.preparatoryClassId),
            noteText.value,
          )
          .then(response => {
            notes.value = response;
            ctx.root.$bvToast.toast("Note created.", TOAST_OPTIONS);
            noteText.value = "";
          });
      }
    };

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    return {
      onSubmit,
      deleteNote,
      dataLoaded,
      notes,
      noteText,
      isAdmin,
    };
  },
});
