import api from "@/api";
import store, { StoreActions } from "@/store";

export const enum UnitPosition {
  boysUnitVolunteer = "boys_unit_volunteer",
  girlsUnitVolunteer = "girls_unit_volunteer",
  houseparents = "houseparents",
  nurse = "nurse",
  director = "director",
  supervisor = "supervisor",
  cna = "cna",
}

export const prettifiedUnitPosition = {
  boys_unit_volunteer: "Boys Unit Volunteer",
  girls_unit_volunteer: "Girls Unit Volunteer",
  houseparents: "Houseparents",
  nurse: "Nurse",
  director: "Director",
  supervisor: "Supervisor",
  cna: "CNA",
};

export const unitPositionEnumReverseLookup = <{ [key: string]: UnitPosition }>{
  boys_unit_volunteer: UnitPosition.boysUnitVolunteer,
  girls_unit_volunteer: UnitPosition.girlsUnitVolunteer,
  houseparents: UnitPosition.houseparents,
  nurse: UnitPosition.nurse,
  director: UnitPosition.director,
  supervisor: UnitPosition.supervisor,
  cna: UnitPosition.cna,
};

export interface ApiUnitPositionTermLengthIndexParams {
  unit_id?: number;
  position?: UnitPosition;
}

export interface ApiUnitPositionTermLength {
  id: number;
  unit_id: number;
  unit_name: string;
  position: string;
  term_length: number;
  quantity: number;
}

export interface ApiUnitPositionTermLengthParams {
  unit_id: number;
  position: string;
  term_length: number;
  quantity: number;
}

export interface UnitPositionTermLength {
  id: number;
  unitId: number;
  unitName: string;
  position: UnitPosition;
  termLength: number;
  quantity: number;
}

export function apiUnitPositionTermLengthToUnitPositionTermLength(
  p: ApiUnitPositionTermLength,
): UnitPositionTermLength {
  const t = unitPositionEnumReverseLookup[p.position];

  return {
    id: p.id,
    unitId: p.unit_id,
    unitName: p.unit_name,
    position: t,
    termLength: p.term_length,
    quantity: p.quantity,
  };
}

async function createUnitPositionTermLength(c: UnitPositionTermLength) {
  const cParams: ApiUnitPositionTermLengthParams = {
    unit_id: c.id,
    position: c.position,
    term_length: c.termLength,
    quantity: c.quantity,
  };
  return api
    .postUnitPositionTermLength(cParams)
    .then((response: ApiUnitPositionTermLength) => {
      return store.dispatch(
        StoreActions.loadUnitPositionTermLength,
        apiUnitPositionTermLengthToUnitPositionTermLength(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitPositionTermLengths(
  p?: ApiUnitPositionTermLengthIndexParams,
) {
  return api
    .getUnitPositionTermLengths(p)
    .then((response: ApiUnitPositionTermLength[]) => {
      const c = response.map(item => {
        return apiUnitPositionTermLengthToUnitPositionTermLength(item);
      });
      return store.dispatch(StoreActions.loadUnitPositionTermLengths, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitPositionTermLength(congregationId: string) {
  return api
    .getUnitPositionTermLength(congregationId)
    .then((response: ApiUnitPositionTermLength) => {
      return store.dispatch(
        StoreActions.loadUnitPositionTermLength,
        apiUnitPositionTermLengthToUnitPositionTermLength(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateUnitPositionTermLength(c: UnitPositionTermLength) {
  const cParams: ApiUnitPositionTermLengthParams = {
    unit_id: c.id,
    position: c.position,
    term_length: c.termLength,
    quantity: c.quantity,
  };
  return api
    .patchUnitPositionTermLength(c.id, cParams)
    .then((response: ApiUnitPositionTermLength) => {
      return store.dispatch(
        StoreActions.loadUnitPositionTermLength,
        apiUnitPositionTermLengthToUnitPositionTermLength(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteUnitPositionTermLength(c: UnitPositionTermLength) {
  return api.deleteUnitPositionTermLength(c.id).catch(error => {
    return Promise.reject(error);
  });
}

export const unitPositionTermLengthController = {
  createUnitPositionTermLength,
  needUnitPositionTermLengths,
  needUnitPositionTermLength,
  updateUnitPositionTermLength,
  deleteUnitPositionTermLength,
};
