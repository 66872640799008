import { UnitApplicationPlacementWarning } from "@/controllers/unitApplicationPlacementWarnings";

interface uapWarningsState {
  unitApplicationPlacementWarnings: UnitApplicationPlacementWarning[];
}

const state: uapWarningsState = {
  unitApplicationPlacementWarnings: [],
};

const mutations = {
  LOAD_UNIT_APPLICATION_PLACEMENT_WARNINGS(
    state: uapWarningsState,
    payload: UnitApplicationPlacementWarning[],
  ) {
    state.unitApplicationPlacementWarnings = payload;
  },
};

const actions = {
  loadUnitApplicationPlacementWarnings(
    context: any,
    items: UnitApplicationPlacementWarning[],
  ) {
    return new Promise(resolve => {
      context.commit("LOAD_UNIT_APPLICATION_PLACEMENT_WARNINGS", items);
      resolve(items);
    });
  },
};

const getters = {
  unitApplicationPlacementWarnings: (state: uapWarningsState) => {
    return state.unitApplicationPlacementWarnings;
  },
};

const UnitApplicationPlacementWarningsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default UnitApplicationPlacementWarningsModule;
