import { render, staticRenderFns } from "./form.html?vue&type=template&id=a7da28e2&scoped=true&"
import script from "./form.ts?vue&type=script&lang=js&"
export * from "./form.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7da28e2",
  null
  
)

export default component.exports