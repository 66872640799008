import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
} from "@vue/composition-api";
// import { PropOptions } from "vue";

const emptyResult = "";

export default defineComponent({
  name: "CpsAutocomplete",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => <string[]>[],
    },
    acResult: {
      type: String,
      required: false,
      default: () => "",
    },
    inputLabel: {
      type: String,
      required: false,
      default: "",
    },
    helpTextLabel: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, ctx) {
    const browserId = ref("");
    const refBaseEl: any = ref(null);
    const isOpen = ref(false);
    const arrowCounter = ref(-1);
    const results = ref(<string[]>[]);

    const acInput = computed({
      get: () => props.acResult,
      set: val => {
        ctx.emit("update:acResult", val);
      },
    });

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
      browserId.value = `cps-${uuidv4()}`;
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    const toggleResults = (evt: any) => {
      evt.preventDefault();
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        // assume if the user opened the list, the want to see
        // all of the values
        results.value = props.items as string[];
      }
    };

    const onArrowUp = () => {
      if (arrowCounter.value > 0) {
        arrowCounter.value = arrowCounter.value - 1;
      }
    };

    const onArrowDown = () => {
      if (arrowCounter.value < results.value.length) {
        arrowCounter.value = arrowCounter.value + 1;
      }
    };

    const onEnter = (evt: any) => {
      evt.preventDefault();
      let result: string;
      if (arrowCounter.value === -1) {
        if (results.value.length > 0) {
          result = results.value[0];
        } else {
          result = emptyResult;
        }
      } else {
        result = results.value[arrowCounter.value];
      }

      setResult(result);
      isOpen.value = false;
      arrowCounter.value = -1;
    };

    const onChange = () => {
      isOpen.value = true;
      filterResults();
    };

    const onTab = () => {
      isOpen.value = false;
    };

    const filterResults = () => {
      ctx.root.$nextTick(() => {
        const filteredItems: string[] = (props.items as string[]).filter(
          (item: string) => {
            return item.toLowerCase().indexOf(acInput.value.toLowerCase()) > -1;
          },
        );
        arrowCounter.value = 0;
        results.value = filteredItems;
      });
    };

    const setResult = (result: string) => {
      acInput.value = result;
      isOpen.value = false;
    };

    const handleClickOutside = (evt: any) => {
      if (!refBaseEl.value.contains(evt.target)) {
        isOpen.value = false;
        arrowCounter.value = -1;
      }
    };

    return {
      acInput,
      arrowCounter,
      browserId,
      isOpen,
      onArrowDown,
      onArrowUp,
      onChange,
      onEnter,
      onTab,
      refBaseEl,
      results,
      setResult,
      toggleResults,
    };
  },
});
