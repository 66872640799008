import api from "@/api";
import store, { StoreActions } from "@/store";
import { PreparatoryClassClassification } from "@/controllers/preparatoryClasses";

export const enum InstructorStatus {
  active = "active",
  inactive = "inactive",
}

export interface ApiPreparatoryClassInstructorIndexParams {
  preparatory_class_id: number;
}

export interface ApiPreparatoryClassInstructor {
  id: number;
  person_id: number;
  name: string;
  status: string;
  classification: string;
  start_date: string;
  end_date: string;
  notes: string;
  congregation_name_with_state: string;
  email_address: string;
  phone_number: string;
}

export interface PreparatoryClassInstructor {
  id: number;
  personId: number;
  name: string;
  status: InstructorStatus;
  classification: PreparatoryClassClassification;
  startDate: string;
  endDate: string;
  notes: string;
  congregationNameWithState: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface ApiPreparatoryClassInstructorParams {
  person_id: number;
  notes: string;
  status: InstructorStatus;
  start_date: string;
  end_date: string;
  classification: PreparatoryClassClassification;
}

function apiPreparatoryClassInstructorToInstructor(
  p: ApiPreparatoryClassInstructor,
): PreparatoryClassInstructor {
  const c =
    p.classification === "girls"
      ? PreparatoryClassClassification.girls
      : PreparatoryClassClassification.boys;
  const s =
    p.status === "active" ? InstructorStatus.active : InstructorStatus.inactive;

  return {
    id: p.id,
    personId: p.person_id,
    name: p.name,
    status: s,
    classification: c,
    startDate: p.start_date,
    endDate: p.end_date,
    notes: p.notes,
    congregationNameWithState: p.congregation_name_with_state,
    emailAddress: p.email_address,
    phoneNumber: p.phone_number,
  };
}

async function createPreparatoryClassInstructor(
  p: PreparatoryClassInstructor,
): Promise<PreparatoryClassInstructor> {
  const apiParams: ApiPreparatoryClassInstructorParams = {
    person_id: p.personId,
    notes: p.notes,
    status: p.status,
    start_date: p.startDate,
    end_date: p.endDate,
    classification: p.classification,
  };

  return api
    .postPreparatoryClassInstructor(apiParams)
    .then((response: ApiPreparatoryClassInstructor) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassInstructor,
        apiPreparatoryClassInstructorToInstructor(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClassInstructors() {
  return api
    .getPreparatoryClassInstructors()
    .then((response: ApiPreparatoryClassInstructor[]) => {
      const items = response.map(item => {
        return apiPreparatoryClassInstructorToInstructor(item);
      });
      return store.dispatch(
        StoreActions.loadPreparatoryClassInstructors,
        items,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClassInstructor(id: string) {
  return api
    .getPreparatoryClassInstructor(id)
    .then((response: ApiPreparatoryClassInstructor) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassInstructor,
        apiPreparatoryClassInstructorToInstructor(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updatePreparatoryClassInstructor(p: PreparatoryClassInstructor) {
  const apiParams: ApiPreparatoryClassInstructorParams = {
    person_id: p.personId,
    notes: p.notes,
    status: p.status,
    start_date: p.startDate,
    end_date: p.endDate,
    classification: p.classification,
  };

  return api
    .patchPreparatoryClassInstructor(p.id, apiParams)
    .then((response: ApiPreparatoryClassInstructor) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassInstructor,
        apiPreparatoryClassInstructorToInstructor(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClassInstructor(p: PreparatoryClassInstructor) {
  return api.deletePreparatoryClassInstructor(p.id).catch(error => {
    return Promise.reject(error);
  });
}

export const preparatoryClassInstructorsController = {
  needPreparatoryClassInstructors,
  needPreparatoryClassInstructor,
  createPreparatoryClassInstructor,
  updatePreparatoryClassInstructor,
  deletePreparatoryClassInstructor,
};
