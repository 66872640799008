export const enum SMMode {
  menu = "menu",
  data = "data",
}

interface sidebarMenuState {
  smMode: SMMode;
}

const state: sidebarMenuState = {
  smMode: SMMode.data,
};

const mutations = {
  SET_SM_MODE(state: sidebarMenuState, payload: SMMode) {
    state.smMode = payload;
  },
};

const actions = {
  setSmMode(context: any, payload: SMMode) {
    return new Promise((resolve, _reject) => {
      context.commit("SET_SM_MODE", payload);
      resolve(payload);
    });
  },
};

// These are returning some bootstrap specific modifiers
// If the css framework changes, either a different one
// or an upgrade to bootstrap
// This may need to be changed
const getters = {
  sidebarMenuClassObject: (state: sidebarMenuState) => {
    if (state.smMode === SMMode.data) {
      return {
        "d-none": true,
        "d-md-block": true,
      };
    } else {
      return {
        "d-none": false,
        "d-md-block": false,
      };
    }
  },
  dataColClassObject: (state: sidebarMenuState) => {
    if (state.smMode === SMMode.menu) {
      return {
        //  "d-sm-none": true,
        "d-none": true,
        "d-md-block": true,
      };
    } else {
      return {
        // "d-sm-none": false,
        "d-none": false,
        "d-md-block": false,
      };
    }
  },
  // smButtonClassObject: (state: sidebarMenuState) => {
  //   if (state.smMode === SMMode.data) {
  //     return {
  //       "d-none": true,
  //     }
  //   } else {
  //     return {
  //       "d-none": false,
  //     }
  //   }
  // }
};

const sidebarMenuModule = {
  state,
  mutations,
  actions,
  getters,
};

export default sidebarMenuModule;
