import api from "@/api";

// 2020-02-26 The notes are not being stored in the store
// The api is sending this with js keys
export interface ApiPreparatoryClassApplicationNote {
  content: string;
  timeAgoInWords: string;
  createdDate: string;
  userName: string;
  id: number;
}

export interface ApiPreparatoryClassApplicationNoteParams {
  preparatory_class_application_note: {
    preparatory_class_application_id: number;
    content: string;
  };
}

async function needPreparatoryClassApplicationNotes(pcId: number) {
  return api
    .getPreparatoryClassApplicationNotes(pcId)
    .then((response: ApiPreparatoryClassApplicationNote[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClassApplicationNote(pcId: number) {
  return api
    .deletePreparatoryClassApplicationNote(pcId)
    .then((response: ApiPreparatoryClassApplicationNote[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function createPreparatoryClassApplicationNote(
  pcId: number,
  noteText: string,
) {
  const apiParams: ApiPreparatoryClassApplicationNoteParams = {
    preparatory_class_application_note: {
      preparatory_class_application_id: pcId,
      content: noteText,
    },
  };

  return api
    .postPreparatoryClassApplicationNote(apiParams)
    .then((response: ApiPreparatoryClassApplicationNote[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassApplicationNotesController = {
  needPreparatoryClassApplicationNotes,
  deletePreparatoryClassApplicationNote,
  createPreparatoryClassApplicationNote,
};
