import { render, staticRenderFns } from "./u-other-people-modal.html?vue&type=template&id=a43028f0&scoped=true&"
import script from "./u-other-people-modal.ts?vue&type=script&lang=js&"
export * from "./u-other-people-modal.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a43028f0",
  null
  
)

export default component.exports