import {
  PinnedUnitApplicationPlacementFull,
  PinnedUnitApplicationPlacementPartial,
  emptyPinnedUnitApplicationPlacementPartial,
} from "@/controllers/pinnedUnitApplicationPlacements";

interface PinnedUnitApplicationPlacementState {
  pinnedUnitApplicationPlacement: PinnedUnitApplicationPlacementPartial;
  pinnedUnitApplicationPlacements: PinnedUnitApplicationPlacementFull[];
}

const state: PinnedUnitApplicationPlacementState = {
  pinnedUnitApplicationPlacement: emptyPinnedUnitApplicationPlacementPartial,
  pinnedUnitApplicationPlacements: [],
};

const mutations = {
  LOAD_PINNED_UNIT_APPLICATION_PLACEMENT(
    state: PinnedUnitApplicationPlacementState,
    payload: PinnedUnitApplicationPlacementPartial,
  ) {
    state.pinnedUnitApplicationPlacement = payload;
  },
  LOAD_PINNED_UNIT_APPLICATION_PLACEMENTS(
    state: PinnedUnitApplicationPlacementState,
    payload: PinnedUnitApplicationPlacementFull[],
  ) {
    state.pinnedUnitApplicationPlacements = payload;
  },
};

const actions = {
  loadPinnedUnitApplicationPlacements(
    context: any,
    pinnedApplicationPlacements: PinnedUnitApplicationPlacementFull[],
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_PINNED_UNIT_APPLICATION_PLACEMENTS",
        pinnedApplicationPlacements,
      );
      resolve(pinnedApplicationPlacements);
    });
  },
  loadPinnedUnitApplicationPlacement(
    context: any,
    pinnedApplicationPlacement: PinnedUnitApplicationPlacementPartial,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_PINNED_UNIT_APPLICATION_PLACEMENT",
        pinnedApplicationPlacement,
      );
      resolve(pinnedApplicationPlacement);
    });
  },
};

const getters = {
  pinnedUnitApplicationPlacement: (
    state: PinnedUnitApplicationPlacementState,
  ) => {
    return state.pinnedUnitApplicationPlacement;
  },
  pinnedUnitApplicationPlacements: (
    state: PinnedUnitApplicationPlacementState,
  ) => {
    return state.pinnedUnitApplicationPlacements;
  },
};

const PinnedUnitApplicationPlacementsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default PinnedUnitApplicationPlacementsModule;
