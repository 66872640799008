import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import {
  unitApplicationNoteController,
  ApiUANote,
} from "@/controllers/unitApplicationNotes";
import { TOAST_OPTIONS } from "@/constants";

import { friendlyDate } from "@/utilities/displayHelpers";

export default defineComponent({
  name: "unitApplicationNotes",
  props: {
    unitApplicationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    LoadingIndicator,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const noteText = ref("");
    const submitting = ref(false);

    const notes = ref<ApiUANote[]>([]);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    onMounted(() => {
      unitApplicationNoteController
        .needUnitApplicationNotes({
          unit_application_id: props.unitApplicationId,
        })
        .then(response => {
          notes.value = response;
          dataLoaded.value = true;
        });
    });

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      submitting.value = true;
      if (noteText.value !== "") {
        unitApplicationNoteController
          .createUnitApplicationNote(props.unitApplicationId, noteText.value)
          .then(response => {
            submitting.value = false;
            notes.value = response;
            ctx.root.$bvToast.toast("Note created.", TOAST_OPTIONS);
            noteText.value = "";
          });
      }
    };

    const deleteNote = (noteId: number) => {
      unitApplicationNoteController
        .deleteUnitApplicationNote(noteId)
        .then(response => {
          notes.value = response;
          ctx.root.$bvToast.toast("Note deleted.", TOAST_OPTIONS);
        });
    };

    return {
      onSubmit,
      deleteNote,
      noteText,
      isAdmin,
      notes,
      dataLoaded,
      submitting,
      friendlyDate,
    };
  },
});
