import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import VueTrix from "vue-trix";
import {
  textDocumentsController,
  TextDocument,
} from "@/controllers/textDocuments";

import { NamedRts } from "@/router";

import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "textDocumentForm",
  components: {
    VueTrix,
    LoadingIndicator,
    FormErrors,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const editorContent = ref("<div>Editor</div>");
    const description = ref("");
    const documentName = ref("");

    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const apiData = computed(() => {
      return $store.getters.textDocument as TextDocument;
    });

    const submitting = ref(false);
    const submitButtonText = ref("Submit");

    // Errors
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const resetErrors = () => {
      hasErrors.value = false;
      errorMessages.value = [];
    };

    const loadErrors = (errors: string[]) => {
      hasErrors.value = true;
      errorMessages.value = errors;
    };

    // End Errors
    const cancelRoute = {
      name: NamedRts.textDocumentList,
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      resetErrors();

      submitButtonText.value = "Saving...";
      submitting.value = true;

      const trixEditor: any = (document as any)
        .getElementById("trix-editor")
        .getElementsByTagName("trix-editor")[0];

      const tDoc: TextDocument = {
        id: apiData.value.id,
        description: description.value,
        documentName: documentName.value,
        editorContent: editorContent.value,
        contentText: trixEditor.editor.getDocument().toString(),
        internalName: apiData.value.internalName,
      };

      textDocumentsController
        .updateTextDocument(tDoc)
        .then(() => {
          ctx.root.$bvToast.toast(
            "Document was successfully saved.",
            TOAST_OPTIONS,
          );
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          loadErrors(errors);
        })
        .finally(() => {
          submitButtonText.value = "Save";
          submitting.value = false;
        });
    };

    onMounted(() => {
      // load the file here
      Promise.all([textDocumentsController.needTextDocument(props.id)]).then(
        () => {
          editorContent.value = apiData.value.editorContent;
          description.value = apiData.value.description;
          documentName.value = apiData.value.documentName;

          dataLoaded.value = true;
        },
      );
    });

    return {
      errorMessages,
      hasErrors,

      dataLoaded,
      editorContent,
      description,
      documentName,

      onSubmit,
      submitting,
      submitButtonText,

      cancelRoute,
    };
  },
});
