import { render, staticRenderFns } from "./recent-activity.html?vue&type=template&id=5cfc8389&scoped=true&"
import script from "./recent-activity.ts?vue&type=script&lang=js&"
export * from "./recent-activity.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfc8389",
  null
  
)

export default component.exports