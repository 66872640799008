import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { congregationsController } from "@/controllers/congregations";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "congregationList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const filter = ref("");
    const filterOn = ["fullName", "abbreviation"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "fullName",
        sortable: true,
      },
      {
        key: "city",
        sortable: true,
      },
      {
        key: "stateName",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin.value) {
      fieldsForTable = [
        {
          key: "fullName",
          sortable: true,
        },
        {
          key: "city",
          sortable: true,
        },
        {
          key: "stateName",
          sortable: true,
        },
      ];
    }

    onMounted(() => {
      Promise.all([congregationsController.needCongregations()])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    const editRoute = (congregationId: number) => {
      return {
        name: NamedRts.congregationForm,
        params: {
          congregationId: congregationId.toString(),
        },
      };
    };

    const congregationsForTable = computed(() => {
      return $store.getters.congregationsForTable;
    });

    return {
      dataLoaded,
      editRoute,
      fieldsForTable,
      filter,
      filterOn,
      isAdmin,
      congregationsForTable,
    };
  },
});
