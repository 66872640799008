import { render, staticRenderFns } from "./applications-list.html?vue&type=template&id=c9886948&scoped=true&"
import script from "./applications-list.ts?vue&type=script&lang=js&"
export * from "./applications-list.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9886948",
  null
  
)

export default component.exports