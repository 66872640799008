import { Country } from "@/controllers/countries";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface CountryLookup {
  [key: number]: Country;
}

interface CountriesState {
  country: Country;
  countriesForTable: Country[];
  countriesForAutocomplete: AutocompleteResult[];
  countries: CountryLookup;
}

const emptyCountry: Country = {
  id: 0,
  name: "",
  abbreviation: "",
};

const state: CountriesState = {
  country: emptyCountry,
  countriesForTable: [],
  countriesForAutocomplete: [],
  countries: {},
};

const mutations = {
  LOAD_COUNTRY(state: CountriesState, payload: Country) {
    state.country = payload;
  },
  LOAD_COUNTRIES_FOR_AUTOCOMPLETE(
    state: CountriesState,
    payload: AutocompleteResult[],
  ) {
    state.countriesForAutocomplete = payload;
  },

  LOAD_COUNTRIES_FOR_LOOKUP(state: CountriesState, payload: CountryLookup) {
    state.countries = payload;
  },
  LOAD_COUNTRIES_FOR_TABLE(state: CountriesState, payload: Country[]) {
    state.countriesForTable = payload;
  },
};

const actions = {
  loadCountry(context: any, country: Country) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_COUNTRY", country);
      resolve(country);
    });
  },
  loadCountries(context: any, countries: Country[]) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = countries.map(item => {
        return {
          label: item.name,
          id: item.id,
        };
      });

      const lookupItems = countries.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit("LOAD_COUNTRIES_FOR_TABLE", countries);
      context.commit("LOAD_COUNTRIES_FOR_AUTOCOMPLETE", acItems);
      context.commit("LOAD_COUNTRIES_FOR_LOOKUP", lookupItems);
      resolve(acItems);
    });
  },
};

const getters = {
  country: (state: CountriesState) => {
    return state.country;
  },
  countriesForTable: (state: CountriesState) => {
    return state.countriesForTable;
  },

  countriesForAutocomplete: (state: CountriesState) => {
    return state.countriesForAutocomplete;
  },
  countryById: (state: CountriesState) => (id: number) => {
    return state.countries[id];
  },
};

const countriesModule = {
  state,
  mutations,
  actions,
  getters,
};

export default countriesModule;
