import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import ShowPartial from "@/components/UnitApplicationPlacements/ShowPartial.vue";
import SingleNote from "@/components/UnitApplicationNote/SingleNote.vue";
import {
  UnitApplicationPlacementForDisplay,
  unitApplicationPlacementForDisplayController,
} from "@/controllers/unitApplicationPlacements";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";
import { NamedRts } from "@/router";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import PlacementListHeader from "@/components/UnitApplicationPlacements/PlacementListHeader.vue";
import PlacementListBody from "@/components/UnitApplicationPlacements/PlacementListBody.vue";

import {
  prettifyUnitPosition,
  prettifyUAPStatus,
  hBadgeForUAPStatus,
  friendlyDate,
} from "@/utilities/displayHelpers";

export default defineComponent({
  name: "unreplacedPlacements",
  components: {
    LoadingIndicator,
    PlacementListHeader,
    PlacementListBody,
    SingleNote,
    ShowPartial,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const userUnitApplicationPosition = computed(() => {
      return $store.getters.userUnitApplicationPosition as UnitPosition;
    });

    const uapFinderRoute = (id: number) => {
      return {
        name: NamedRts.unitFindReplacementApplications,
        params: {
          unitApplicationPlacementId: id.toString(),
        },
      };
    };

    const personDashboardRoute = (p: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personDashboardUnitApplication,
        params: {
          personId: p.personId.toString(),
          unitApplicationId: p.unitApplicationId.toString(),
        },
      };
    };

    onMounted(() => {
      Promise.all([
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
          {
            unit_application_position: userUnitApplicationPosition.value,
          },
        ),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    const unreplacedPlacements = computed(() => {
      return $store.getters.unreplacedUnitApplicationPlacementsForDisplay;
    });

    return {
      dataLoaded,
      unreplacedPlacements,
      personDashboardRoute,

      prettifyUAPStatus,
      prettifyUnitPosition,
      friendlyDate,

      hBadgeForUAPStatus,

      parseISO,
      formatDistanceToNow,
    };
  },
});
