import { ref, onMounted, defineComponent } from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import {
  unitApplicationPreferenceController,
  UnitApplicationPreference,
} from "@/controllers/unitApplicationPreferences";

export default defineComponent({
  name: "personDashboardUnitApplicationPreferenceDetails",
  props: {
    unitApplicationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    LoadingIndicator,
  },

  setup(props, _ctx) {
    const dataLoaded = ref(false);
    const preferences = ref<UnitApplicationPreference[]>([]);

    onMounted(() => {
      unitApplicationPreferenceController
        .needUnitApplicationPreferences({
          unit_application_id: props.unitApplicationId,
        })
        .then(response => {
          preferences.value = response;
          dataLoaded.value = true;
        });
    });

    return {
      preferences,
      dataLoaded,
    };
  },
});
