import api from "@/api";
import store, { StoreActions } from "@/store";
import { UserResponsibility, UserClassification } from "@/store/user";
import { getSessionToken } from "@/clientStore";

export interface SessionParams {
  email: string;
  password: string;
}

export interface ApiSessionParams {
  email: string;
  password: string;
}

export interface ApiSessionResponse {
  email: string;
  name: string;
  token: string;
  admin: boolean;
  responsibility: UserResponsibility;
  classification: UserClassification;
}

function fromParamsToApiParams(fp: SessionParams): ApiSessionParams {
  return {
    email: fp.email,
    password: fp.password,
  };
}

async function create(fp: SessionParams): Promise<ApiSessionResponse> {
  const apiParams = fromParamsToApiParams(fp);

  return api
    .postSession(apiParams)
    .then((response: ApiSessionResponse) => {
      // store in the store
      store.dispatch(StoreActions.loadSessionData, response);
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function update(): Promise<ApiSessionResponse> {
  const sessionToken = getSessionToken();

  if (sessionToken === "") {
    // if the token is empty, the user is obviously logged
    // out, the routing to the correct place takes place in the component
    return Promise.reject("");
  } else {
    return api
      .patchSession({ token: sessionToken })
      .then(response => {
        store.dispatch(StoreActions.loadSessionData, response);
        return response;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

async function destroy(): Promise<ApiSessionResponse> {
  return store.dispatch(StoreActions.loadSessionData, {
    email: "",
    name: "",
    token: "",
    admin: false,
    responsibility: UserResponsibility.general,
    classification: UserClassification.all,
  });
}

export const sessionController = {
  create: create,
  update: update,
  destroy: destroy,
};
