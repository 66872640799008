import api from "@/api";

export const enum PreparatoryClassTaskStatus {
  pending = "pending",
  completed = "completed",
}

// 2020-02-26 The tasks are not being stored in the store
// The api is sending this with js keys
export interface ApiPreparatoryClassTask {
  preparatoryClassId: number;
  description: string;
  status: string;
  sortOrder: number;
  completed: boolean;
  id: number;
}

export interface PreparatoryClassTaskFormParams {
  id: number;
  description: string;
  preparatoryClassId: number;
  sortOrder: number;
  status: PreparatoryClassTaskStatus;
  completedDate: string;
}

export interface ApiPreparatoryClassTaskParams {
  preparatory_class_task: {
    preparatory_class_id: number;
    description: string;
    status: PreparatoryClassTaskStatus;
    sort_order: number;
    completed_date?: string;
  };
}

async function needPreparatoryClassTasks(pcId: number) {
  return api
    .getPreparatoryClassTasks(pcId)
    .then((response: ApiPreparatoryClassTask[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updatePreparatoryClassTask(p: PreparatoryClassTaskFormParams) {
  const apiParams: ApiPreparatoryClassTaskParams = {
    preparatory_class_task: {
      preparatory_class_id: p.preparatoryClassId,
      description: p.description,
      status: p.status,
      sort_order: p.sortOrder,
      completed_date: p.completedDate,
    },
  };

  return api
    .patchPreparatoryClassTask(p.id, apiParams)
    .then((response: ApiPreparatoryClassTask[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClassTask(pcId: number) {
  return api
    .deletePreparatoryClassTask(pcId)
    .then((response: ApiPreparatoryClassTask[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function createPreparatoryClassTask(p: PreparatoryClassTaskFormParams) {
  const apiParams: ApiPreparatoryClassTaskParams = {
    preparatory_class_task: {
      preparatory_class_id: p.preparatoryClassId,
      description: p.description,
      status: p.status,
      sort_order: p.sortOrder,
      completed_date: p.completedDate,
    },
  };

  return api
    .postPreparatoryClassTask(apiParams)
    .then((response: ApiPreparatoryClassTask[]) => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassTasksController = {
  needPreparatoryClassTasks,
  deletePreparatoryClassTask,
  createPreparatoryClassTask,
  updatePreparatoryClassTask,
};
