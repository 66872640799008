import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { preparatoryClassesController } from "@/controllers/preparatoryClasses";
// import { NamedRts } from "@/router";
import { friendlyDate } from "@/utilities/displayHelpers";
import { NamedRts } from "@/router";
import { preparatoryClassStatisticsController } from "@/controllers/preparatoryClassStatistics";

export default defineComponent({
  name: "preparatoryClassList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const preparatoryClassesForTable = computed(() => {
      return $store.getters.preparatoryClassesForTable;
    });

    const getPreparatoryClassPlacementsCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassPlacementsCount(id);
      } else {
        0;
      }
    };

    const getPreparatoryClassConfirmedPlacementsCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassConfirmedPlacementsCount(id);
      } else {
        0;
      }
    };

    const getPreparatoryClassPendingPreferencesCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassPendingPreferencesCount(id);
      } else {
        0;
      }
    };

    const routeParams = {
      preparatoryClassApplicationForm: {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: "0",
        },
      },
      preparatoryClassForm: {
        name: NamedRts.preparatoryClassForm,
        params: {
          preparatoryClassId: "0",
        },
      },
    };

    const computedRouteParams = (id: number) => {
      return {
        name: NamedRts.preparatoryClassForm,
        params: {
          preparatoryClassId: id.toString(),
        },
      };
    };

    const showRouteParams = (id: number) => {
      return {
        name: NamedRts.preparatoryClassShow,
        params: {
          preparatoryClassId: id.toString(),
        },
      };
    };

    onMounted(() => {
      Promise.all([
        preparatoryClassesController.needPreparatoryClasses(),
        preparatoryClassStatisticsController.needPreparatoryClassStatistics(),
      ])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    return {
      computedRouteParams,
      showRouteParams,
      dataLoaded,
      friendlyDate,
      getPreparatoryClassConfirmedPlacementsCount,
      getPreparatoryClassPendingPreferencesCount,
      getPreparatoryClassPlacementsCount,
      isAdmin,
      preparatoryClassesForTable,
      routeParams,
    };
  },
});
