// Some of the data for UnitApplications are stored here
// 2020-04-01
// The data for the unit applications that may be possible replacements are stored
// in this store module
// They could be stored in the store module for the unit applications
// but it seemed to make more sense to store them here because the placement that is being replaced is stored here.

import {
  UnitApplicationPlacementForDisplay,
  UnitApplicationPlacementStatus,
} from "@/controllers/unitApplicationPlacements";

import { UnitPosition } from "@/controllers/unitPositionTermLengths";
import { UnitApplication } from "@/controllers/unitApplications";
import { FilteredUnitApplicationsForPlacement } from "@/controllers/unitApplicationsForReplacement";
import { UnitClassification } from "@/controllers/units";

interface UnitApplicationPlacementsForDisplayState {
  placements: UnitApplicationPlacementForDisplay[];
  placement: UnitApplicationPlacementForDisplay;
  replacement: UnitApplicationPlacementForDisplay;
  replaces: UnitApplicationPlacementForDisplay;
  unreplacedPlacements: UnitApplicationPlacementForDisplay[];
  idxPlacementsByUnitId: IndexedPlacementsByUnitId;
  idxPlacementsByReplacesId: IndexedPlacements;
  unitApplicationsForReplacementSameUnit: UnitApplication[];
  unitApplicationsForReplacementSimilarUnit: UnitApplication[];
  unitApplicationsForReplacementOtherUnit: UnitApplication[];
  unitApplicationsForReplacementUnmatched: UnitApplication[];
  unitApplicationsForReplacementMatched: UnitApplication[];
  otherPeopleCongregationIds: Set<number>;
}

interface IndexedPlacements {
  [key: number]: UnitApplicationPlacementForDisplay;
}

interface IndexedPlacementsByUnitId {
  [key: number]: {
    placements: UnitApplicationPlacementForDisplay[];
    unreplacedPlacements: UnitApplicationPlacementForDisplay[];
  };
}

const filterPlacementsByReplacesId = (
  placements: UnitApplicationPlacementForDisplay[],
) => {
  return placements.reduce(
    (acc: UnitApplicationPlacementForDisplay[], item) => {
      if (item.replacesId && item.replacesId > 0) {
        return acc.concat([item]);
      } else {
        return acc;
      }
    },
    [],
  );
};

export const emptyUnitApplicationPlacementForDisplay: UnitApplicationPlacementForDisplay = {
  id: 0,
  placementEndDate: "",
  placementStartDate: "",
  name: "",
  parents: "",
  birthDate: "",
  personId: 0,
  congregationName: "",
  congregationCity: "",
  congregationId: 0,
  state: "",
  unitApplicationId: 0,
  unitApplicationPosition: UnitPosition.boysUnitVolunteer,
  replacesId: 0,
  unitName: "",
  unitId: 0,
  unitClassification: UnitClassification.boys,
  status: UnitApplicationPlacementStatus.pending,
  otherPeopleConcatenated: "",
  latestNoteContent: null,
  latestNoteUser: null,
  latestNoteCreatedAt: null,
  latestNoteTimeAgoInWords: null,
  replacement: null,
};

const indexPlacementsByReplacesId = (
  placements: UnitApplicationPlacementForDisplay[],
) => {
  return placements.reduce((acc, item) => {
    if (item.replacesId) {
      acc[item.replacesId] = item;
    }
    return acc;
  }, {} as IndexedPlacements);
};

const filterUnreplacedPlacements = (
  placements: UnitApplicationPlacementForDisplay[],
  idxPlacements: IndexedPlacements,
) => {
  return placements.reduce(
    (acc: UnitApplicationPlacementForDisplay[], item) => {
      // if the key is found, they are replaced, just the ones that are not replaced are the ones that
      // are wanted.

      if (idxPlacements.hasOwnProperty(item.id)) {
        if (
          !(
            idxPlacements[item.id]["status"] ===
            UnitApplicationPlacementStatus.confirmed
          )
        ) {
          // this item is needed, it is replaced, but not confirmed
          return acc.concat([item]);
        } else {
          return acc;
        }
      } else {
        return acc.concat([item]);
      }
    },
    [],
  );
};

// placement is the one that is being looked at
// replaces is the placement that they replace
// replacement is the placement that replaces them
const state: UnitApplicationPlacementsForDisplayState = {
  placements: [],
  placement: emptyUnitApplicationPlacementForDisplay,
  replacement: emptyUnitApplicationPlacementForDisplay,
  replaces: emptyUnitApplicationPlacementForDisplay,
  unreplacedPlacements: [],
  idxPlacementsByUnitId: {},
  idxPlacementsByReplacesId: {},
  unitApplicationsForReplacementSameUnit: [],
  unitApplicationsForReplacementSimilarUnit: [],
  unitApplicationsForReplacementOtherUnit: [],
  unitApplicationsForReplacementUnmatched: [],
  unitApplicationsForReplacementMatched: [],
  otherPeopleCongregationIds: new Set([]),
};

const mutations = {
  LOAD_PLACEMENT(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplicationPlacementForDisplay,
  ) {
    state.placement = payload;
  },
  LOAD_REPLACEMENT(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplicationPlacementForDisplay,
  ) {
    state.replacement = payload;
  },
  LOAD_REPLACES(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplicationPlacementForDisplay,
  ) {
    state.replaces = payload;
  },
  LOAD_UAP(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplicationPlacementForDisplay[],
  ) {
    state.placements = payload;
  },
  LOAD_UNREPLACED_UAP(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplicationPlacementForDisplay[],
  ) {
    state.unreplacedPlacements = payload;
  },

  LOAD_UAP_IDX_BY_REPLACES_ID(
    state: UnitApplicationPlacementsForDisplayState,
    payload: IndexedPlacements,
  ) {
    state.idxPlacementsByReplacesId = payload;
  },

  LOAD_UAP_IDX_BY_UNIT_ID(
    state: UnitApplicationPlacementsForDisplayState,
    payload: IndexedPlacementsByUnitId,
  ) {
    state.idxPlacementsByUnitId = payload;
  },

  LOAD_SAME_UNIT_APPLICATIONS(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplication[],
  ) {
    state.unitApplicationsForReplacementSameUnit = payload;
  },
  LOAD_SIMILAR_UNIT_APPLICATIONS(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplication[],
  ) {
    state.unitApplicationsForReplacementSimilarUnit = payload;
  },
  LOAD_OTHER_UNIT_APPLICATIONS(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplication[],
  ) {
    state.unitApplicationsForReplacementOtherUnit = payload;
  },
  LOAD_MATCHED_APPLICATIONS(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplication[],
  ) {
    state.unitApplicationsForReplacementMatched = payload;
  },
  LOAD_UNMATCHED_APPLICATIONS(
    state: UnitApplicationPlacementsForDisplayState,
    payload: UnitApplication[],
  ) {
    state.unitApplicationsForReplacementUnmatched = payload;
  },
  LOAD_OTHER_PEOPLE_CONGREGATION_IDS(
    state: UnitApplicationPlacementsForDisplayState,
    payload: Set<number>,
  ) {
    state.otherPeopleCongregationIds = payload;
  },
};

const actions = {
  loadOtherPeopleCongregationIds(context: any, cIds: Set<number>) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_OTHER_PEOPLE_CONGREGATION_IDS", cIds);
      resolve(cIds);
    });
  },
  loadUnitApplicationsForPlacement(
    context: any,
    filteredApplications: FilteredUnitApplicationsForPlacement,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_SAME_UNIT_APPLICATIONS",
        filteredApplications.sameUnit,
      );
      context.commit(
        "LOAD_SIMILAR_UNIT_APPLICATIONS",
        filteredApplications.similarUnit,
      );
      context.commit(
        "LOAD_OTHER_UNIT_APPLICATIONS",
        filteredApplications.otherUnit,
      );
      context.commit("LOAD_MATCHED_APPLICATIONS", filteredApplications.matched);
      context.commit(
        "LOAD_UNMATCHED_APPLICATIONS",
        filteredApplications.unmatched,
      );
      resolve(filteredApplications);
    });
  },
  loadUnitApplicationPlacementForDisplay(
    context: any,
    uap: UnitApplicationPlacementForDisplay,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PLACEMENT", uap);
      resolve(uap);
    });
  },
  loadUnitApplicationReplacementForDisplay(
    context: any,
    uap: UnitApplicationPlacementForDisplay,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_REPLACEMENT", uap);
      resolve(uap);
    });
  },
  loadUnitApplicationReplacesForDisplay(
    context: any,
    uap: UnitApplicationPlacementForDisplay,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_REPLACES", uap);
      resolve(uap);
    });
  },
  loadUnitApplicationPlacementsForDisplay(
    context: any,
    uap: UnitApplicationPlacementForDisplay[],
  ) {
    return new Promise((resolve, _reject) => {
      const placementsThatReplaceSomeone = filterPlacementsByReplacesId(uap);
      const indexedByReplacesId = indexPlacementsByReplacesId(
        placementsThatReplaceSomeone,
      );

      // link the placements together
      const uapWithReplacement = uap.map(item => {
        const replacement = indexedByReplacesId[item.id];
        if (replacement) {
          item.replacement = replacement;
          return item;
        } else {
          return item;
        }
      });

      const unreplacedPlacements = filterUnreplacedPlacements(
        uap,
        indexedByReplacesId,
      );

      const placementsByUnitId = uapWithReplacement.reduce((accum, item) => {
        // first figure out the unit
        if (accum.hasOwnProperty(item.unitId)) {
          accum[item.unitId].placements.push(item);
          if (!item.replacement) {
            accum[item.unitId].unreplacedPlacements.push(item);
          }

          return accum;
        } else {
          accum[item.unitId] = {
            placements: [],
            unreplacedPlacements: [],
          };
          accum[item.unitId].placements.push(item);
          if (!item.replacement) {
            accum[item.unitId].unreplacedPlacements.push(item);
          }
          return accum;
        }

        // // the code that filters the unreplaced placements, does not
        // // remove the ones that are replaced, but with a pending
        // // status, we want to get rid of those also
        // if (item.replacement) {
        //   return accum;
        // } else {
        //   if (accum.hasOwnProperty(item.unitId)) {
        //     accum[item.unitId].push(item);
        //   } else {
        //     accum[item.unitId] = [item];
        //   }
        //   return accum;
        // }
      }, {} as IndexedPlacementsByUnitId);

      context.commit("LOAD_UAP", uapWithReplacement);
      context.commit("LOAD_UAP_IDX_BY_REPLACES_ID", indexedByReplacesId);
      context.commit("LOAD_UAP_IDX_BY_UNIT_ID", placementsByUnitId);
      context.commit("LOAD_UNREPLACED_UAP", unreplacedPlacements);
      resolve(uap);
    });
  },
};

const getters = {
  unreplacedUnitApplicationPlacementsForDisplay: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.unreplacedPlacements;
  },

  unitApplicationPlacementsForDisplay: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.placements;
  },

  unitApplicationPlacementForDisplay: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.placement;
  },

  unitApplicationReplacementForDisplay: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.replacement;
  },

  unitApplicationReplacesForDisplay: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.replaces;
  },

  unitApplicationsForPlacementSameUnit: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.unitApplicationsForReplacementSameUnit;
  },

  unitApplicationsForPlacementOtherUnit: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.unitApplicationsForReplacementOtherUnit;
  },

  unitApplicationsForPlacementSimilarUnit: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.unitApplicationsForReplacementSimilarUnit;
  },

  unitApplicationsForPlacementMatched: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.unitApplicationsForReplacementMatched;
  },

  unitApplicationsForPlacementUnmatched: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.unitApplicationsForReplacementUnmatched;
  },

  findReplacement: (state: UnitApplicationPlacementsForDisplayState) => (
    id: number,
  ) => {
    return state.idxPlacementsByReplacesId[id];
  },

  unreplacedPlacementsForUnit: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => (id: number, position: UnitPosition) => {
    const pl = state.idxPlacementsByUnitId[id]?.unreplacedPlacements;
    if (pl) {
      return pl.reduce((accum, item) => {
        if (item.unitApplicationPosition === position) {
          accum.push(item);
          return accum;
        } else {
          return accum;
        }
      }, [] as UnitApplicationPlacementForDisplay[]);
    } else {
      return [];
    }
  },

  placementsForUnit: (state: UnitApplicationPlacementsForDisplayState) => (
    id: number,
  ) => {
    const pl = state.idxPlacementsByUnitId[id]?.placements;
    if (pl) {
      return pl;
    } else {
      return [];
    }
  },

  placementsForUnitForDateRange: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => (id: number, startDate: string, endDate: string) => {
    const pl = state.idxPlacementsByUnitId[id]?.placements;

    if (pl) {
      return pl.reduce((accum, item) => {
        if (
          item.placementEndDate >= startDate &&
          item.placementStartDate <= endDate
        ) {
          accum.push(item);
          return accum;
        } else {
          return accum;
        }
      }, [] as UnitApplicationPlacementForDisplay[]);
    }
  },

  placementsForUnitApplication: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => (id: number) => {
    return state.placements.reduce((accum, item) => {
      if (item.unitApplicationId === id) {
        accum.push(item);
        return accum;
      } else {
        return accum;
      }
    }, [] as UnitApplicationPlacementForDisplay[]);
  },

  otherPeopleCongregationIds: (
    state: UnitApplicationPlacementsForDisplayState,
  ) => {
    return state.otherPeopleCongregationIds;
  },
};

const UnitApplicationPlacementsForDisplayModule = {
  state,
  mutations,
  actions,
  getters,
};

export default UnitApplicationPlacementsForDisplayModule;
