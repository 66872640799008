import { defineComponent, onMounted, ref } from "@vue/composition-api";
import TheNavbar from "@/components/TheNavbar/TheNavbar.vue";
import TheFooter from "@/components/TheFooter/TheFooter.vue";
// import { sessionController } from "./controllers/sessions";
// import { NamedRts } from "./router";

export default defineComponent({
  name: "app",
  components: {
    TheNavbar,
    TheFooter,
  },

  setup(_props, _ctx) {
    const dataLoaded = ref(false);

    onMounted(() => {
      dataLoaded.value = true;
    });

    return {
      dataLoaded,
    };
  },
});
