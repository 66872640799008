import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";

import { appRolesController, AppRole } from "@/controllers/appRoles";
import { sessionController } from "@/controllers/sessions";

import { TOAST_OPTIONS } from "@/constants";
import {
  prettifyUserClassification,
  prettifyUserResponsibility,
} from "@/utilities/displayHelpers";

export default defineComponent({
  name: "UserProfile",

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    onMounted(() => {
      Promise.all([appRolesController.needAppRoles()]).then(() => {
        dataLoaded.value = true;
      });
    });

    const appRoles = computed(() => {
      return $store.getters.appRolesForTable as AppRole[];
    });

    const setAppRoleAsCurrent = (ar: AppRole) => {
      appRolesController
        .updateAppRole({
          ...ar,
          current: true,
        })
        .then(() => {
          return sessionController.update();
        })
        .then(() => {
          ctx.root.$bvToast.toast(
            "App Role was successfully set as current.",
            TOAST_OPTIONS,
          );
        });
    };

    return {
      setAppRoleAsCurrent,
      appRoles,
      dataLoaded,
      errorMessages,
      hasErrors,

      prettifyUserResponsibility,
      prettifyUserClassification,
    };
  },
});
