import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";

import {
  pinnedUnitApplicationController,
  PinnedUnitApplicationPartial,
} from "@/controllers/pinnedUnitApplications";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "pinUnitApplication",
  props: {
    unitApplicationId: {
      type: Number,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    onMounted(() => {
      Promise.all([
        pinnedUnitApplicationController.needPinnedUnitApplication(
          props.unitApplicationId.toString(),
        ),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    const isApplicationPinned = (): boolean => {
      const item = $store.getters
        .pinnedUnitApplication as PinnedUnitApplicationPartial;
      return item.unitApplicationId === props.unitApplicationId;
    };

    const pinnedApplication = computed(() => {
      return $store.getters
        .pinnedUnitApplication as PinnedUnitApplicationPartial;
    });

    const buttonText = computed(() => {
      if (isApplicationPinned()) {
        return "Unpin application";
      } else {
        return "Pin Application";
      }
    });

    const toggleApplication = () => {
      if (isApplicationPinned()) {
        pinnedUnitApplicationController
          .deletePinnedUnitApplication(pinnedApplication.value)
          .then(() => {
            ctx.root.$bvToast.toast("Application Unpinned.", TOAST_OPTIONS);
          });
      } else {
        pinnedUnitApplicationController
          .createPinnedUnitApplication({
            id: 0,
            unitApplicationId: props.unitApplicationId,
          })
          .then(() => {
            ctx.root.$bvToast.toast("Application Pinned.", TOAST_OPTIONS);
          });
      }
    };

    return {
      dataLoaded,
      buttonText,
      toggleApplication,
    };
  },
});
