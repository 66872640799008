import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
} from "@vue/composition-api";
import { sessionController } from "@/controllers/sessions";
import { NamedRts } from "@/router";
import { UserResponsibility } from "@/store/user";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "login",
  setup(_props, ctx) {
    const $store = ctx.root.$store;
    // basic variables all forms need
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);

    const fd = reactive({
      email: "",
      password: "",
    });

    onMounted(() => {
      dataLoaded.value = true;
    });

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];

      submitting.value = true;
      sessionController
        .create(fd)
        .then(_response => {
          submitting.value = false;
          ctx.root.$bvToast.toast("Successful Login!", TOAST_OPTIONS);
          // navigate somewhere, based on who the user is.
          if (userResponsibility.value === UserResponsibility.coordinator) {
            ctx.root.$router.push({
              name: NamedRts.coordinatorDashboard,
            });
          } else {
            ctx.root.$router.push({
              name: NamedRts.recruiterDashboard,
            });
          }
        })
        .catch(errors => {
          submitting.value = false;
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const userResponsibility = computed(
      (): UserResponsibility => {
        return $store.getters.userResponsibility;
      },
    );

    return {
      userResponsibility,
      dataLoaded,
      errorMessages,
      fd,
      hasErrors,
      submitting,
      submitForm,
    };
  },
});
