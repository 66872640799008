// This controller is different from some of the others
// It is an implicit requirement that the unit applications for the specific unit position
// are already in the store.
// This controller takes those applications, and with the placement that is passed in,
// separates the data into multiple lists

import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";
import store, { StoreActions } from "@/store";
import { UnitApplication, UnitApplicationPreference } from "./unitApplications";
import { unitsController, Unit } from "./units";
import { MatchReplacementUnitApplication } from "@/controllers/matchReplacementUnitApplications";

export interface FilteredUnitApplicationsForPlacement {
  sameUnit: UnitApplication[];
  similarUnit: UnitApplication[];
  otherUnit: UnitApplication[];
  unmatched: UnitApplication[];
  matched: UnitApplication[];
}

const preferencesIntersectionWithUnitIds = (
  unitIds: number[],
  preferences: UnitApplicationPreference[],
) => {
  return preferences.filter(pref => {
    return unitIds.includes(pref.unitId);
  });
};

async function filterUnitApplications(p: UnitApplicationPlacementForDisplay) {
  const cl = p.unitClassification;

  return unitsController.needUnitsList(cl).then(() => {
    const currentUnit = store.getters.unitById(p.unitId) as Unit;
    const unitApplications = store.getters
      .unitApplicationsForTable as UnitApplication[];
    const similarUnits = store.getters.unitsByWorkType(currentUnit.workType);

    const unmatchedReplacementUnitApplications = store.getters
      .unmatchedReplacementUnitApplications as MatchReplacementUnitApplication[];

    const unmatchedUAIds = new Set();

    for (const u of unmatchedReplacementUnitApplications) {
      unmatchedUAIds.add(u.unitApplicationId);
    }

    const filteredApplications = unitApplications.reduce(
      (accum, ua) => {
        if (unmatchedUAIds.has(ua.id)) {
          accum.unmatched.push(ua);
        } else if (
          ua.unitApplicationPreferences.some(preference => {
            return preference.unitId === p.unitId;
          })
        ) {
          accum.sameUnit.push(ua);
        } else if (
          preferencesIntersectionWithUnitIds(
            similarUnits.unitIds,
            ua.unitApplicationPreferences,
          ).length > 0
        ) {
          accum.similarUnit.push(ua);
        } else {
          accum.otherUnit.push(ua);
        }

        return accum;
      },
      {
        sameUnit: [],
        similarUnit: [],
        otherUnit: [],
        unmatched: [],
        matched: [],
      } as FilteredUnitApplicationsForPlacement,
    );

    return store.dispatch(
      StoreActions.loadUnitApplicationsForPlacement,
      filteredApplications,
    );
  });
}

export const unitApplicationsForReplacementController = {
  filterUnitApplications,
};
