import api from "@/api";
import store, { StoreActions } from "@/store";

async function needLastNames() {
  return api
    .getLastNames()
    .then((response: string[]) => {
      return store.dispatch(StoreActions.loadLastNames, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needMiddleNames() {
  return api
    .getMiddleNames()
    .then((response: string[]) => {
      return store.dispatch(StoreActions.loadMiddleNames, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needFirstNames() {
  return api
    .getFirstNames()
    .then((response: string[]) => {
      return store.dispatch(StoreActions.loadFirstNames, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const peopleNamesController = {
  needLastNames,
  needMiddleNames,
  needFirstNames,
};
