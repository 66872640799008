import "./installCompositionApi";
import App from "./App.vue";
import Vue from "vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBan,
  faBookmark,
  faCalendarCheck,
  faChalkboard,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faCommentAlt,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFilePdf,
  faHandsHelping,
  faList,
  faPaperPlane,
  faPhone,
  faPlus,
  faSearch,
  faSlash,
  faSpinner,
  faSquare,
  faStop,
  faStopCircle,
  faTasks,
  faThumbsDown,
  faThumbtack,
  faTimes,
  faTrashAlt,
  faUserCheck,
  faUserFriends,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { sessionController } from "./controllers/sessions";

library.add(
  faBan,
  faBookmark,
  faCalendarCheck,
  faChalkboard,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faCommentAlt,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFilePdf,
  faHandsHelping,
  faList,
  faPaperPlane,
  faPhone,
  faPlus,
  faSearch,
  faSlash,
  faSpinner,
  faSquare,
  faStop,
  faStopCircle,
  faTasks,
  faThumbsDown,
  faThumbtack,
  faTimes,
  faTrashAlt,
  faUserCheck,
  faUserFriends,
  faUserMinus,
  faUserPlus,
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

import "@/styles/global.scss";

// TS items that are used site wide
export const enum FormModes {
  new = "new",
  edit = "edit",
}

export function setFormMode(id: string | undefined | null): FormModes {
  if (id) {
    if (id === "0") {
      return FormModes.new;
    } else {
      return FormModes.edit;
    }
  } else {
    return FormModes.new;
  }
}
// End TS items

// on page refresh, we want the session to try to update before the Vue is create
// that way the user data is in the store
// it makes the first page routing work better
// With either a success or a failure, the app is going to be mounted
sessionController
  .update()
  .then(_response => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount("#app");
  })
  .catch(_response => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount("#app");
  });
