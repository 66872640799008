import { TextDocument } from "@/controllers/textDocuments";

interface TextDocumentState {
  textDocumentsForTable: TextDocument[];
  textDocument: TextDocument;
}
const emptyTextDocument: TextDocument = {
  id: 0,
  description: "",
  editorContent: "",
  contentText: "",
  documentName: "",
  internalName: "",
};

const state: TextDocumentState = {
  textDocumentsForTable: [],
  textDocument: emptyTextDocument,
};

const mutations = {
  LOAD_TEXT_DOCUMENTS_FOR_TABLE(
    state: TextDocumentState,
    payload: TextDocument[],
  ) {
    state.textDocumentsForTable = payload;
  },
  LOAD_TEXT_DOCUMENT(state: TextDocumentState, payload: TextDocument) {
    state.textDocument = payload;
  },
};

const actions = {
  loadTextDocuments(context: any, textDocuments: TextDocument[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_TEXT_DOCUMENTS_FOR_TABLE", textDocuments);
      resolve(textDocuments);
    });
  },
  loadTextDocument(context: any, textDocument: TextDocument) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_TEXT_DOCUMENT", textDocument);
      resolve(textDocument);
    });
  },
};

const getters = {
  textDocumentsForTable: (state: TextDocumentState) => {
    return state.textDocumentsForTable;
  },
  textDocument: (state: TextDocumentState) => {
    return state.textDocument;
  },
};

const textDocumentsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default textDocumentsModule;
