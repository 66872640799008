import { defineComponent, PropType } from "@vue/composition-api";

import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";

import UapStatus from "@/components/UAPStatus/UAPStatus.vue";

import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import {
  prettifyUnitPosition,
  friendlyDate,
  personAgeToday,
} from "@/utilities/displayHelpers";

import { NamedRts } from "@/router";

export default defineComponent({
  name: "placementListBody",
  components: {
    UapStatus,
  },
  props: {
    placements: {
      type: Array as PropType<UnitApplicationPlacementForDisplay[]>,
      required: true,
    },
  },
  setup(_props, _ctx) {
    const hBgForLeavesIn = (pl: UnitApplicationPlacementForDisplay) => {
      const td = startOfToday();
      const ed = parseISO(pl.placementEndDate);
      const difference = differenceInCalendarDays(ed, td);

      if (pl.replacement) {
        return "bg-success";
      } else if (difference < 31) {
        return "bg-danger";
      } else if (difference < 61) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    };

    // Person Dashboard Unit Application Placement Route
    const pdUapRoute = (placement: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personDashboardUnitApplicationPlacement,
        params: {
          personId: placement.personId.toString(),
          unitApplicationPlacementId: placement.id.toString(),
          unitApplicationId: placement.unitApplicationId.toString(),
        },
      };
    };

    const unitDashboard = (placement: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.unitDashboardPlacementList,
        params: {
          unitId: placement.unitId.toString(),
        },
      };
    };

    const uapFinderRoute = (id: number) => {
      return {
        name: NamedRts.unitFindReplacementApplications,
        params: {
          unitApplicationPlacementId: id.toString(),
        },
      };
    };

    const localPersonAge = (placement: UnitApplicationPlacementForDisplay) => {
      const birthDate = parseISO(placement.birthDate);
      return personAgeToday(birthDate);
    };

    return {
      hBgForLeavesIn,
      formatDistanceToNow,
      parseISO,

      prettifyUnitPosition,
      friendlyDate,

      uapFinderRoute,
      pdUapRoute,
      unitDashboard,

      localPersonAge,
    };
  },
});
