import api from "@/api";
import {
  ApiPreparatoryClassCommunicationParams,
  ApiPreparatoryClassApplicationCommunicationParams,
} from "@/controllers/placementPacket";

async function sendConfirmationReminder(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return api.sendPlacementConfirmationReminder(p);
}

export const pcaPlacementConfirmationReminderController = {
  sendConfirmationReminder,
};
