import api from "@/api";
import { Gender, MaritalStatus, Qualification } from "@/controllers/people";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";
import { SubmittedUnitApplicationStatus } from "@/controllers/submittedUnitApplications";

export interface PublicSubmittedUnitApplication {
  acceptsCpsPolicy: boolean;
  address1: string;
  address2: string;
  applicantNote: string;
  availableDate: string;
  birthCity: string;
  birthDate: string;
  birthStateId: number;
  city: string;
  congregationId: number;
  countryIds: number[];
  dlStateId: number;
  driversLicense: string;
  emailAddress: string;
  fatherFirstName: string;
  firstName: string;
  gender: Gender;
  lastName: string;
  maritalStatus: MaritalStatus;
  middleName: string;
  ministerConfirmation: boolean;
  ministerEmail: string;
  ministerName: string;
  ministerNote: string;
  ministerPhoneNumber: string;
  motherFirstName: string;
  phoneNumber: string;
  position: UnitPosition;
  qualification: Qualification;
  stateProvinceId: number;
  status: SubmittedUnitApplicationStatus;
  submittedAt: string;
  unitIds: number[];
  zipCode: string;
}

function formParamsToApiParams(formParams: PublicSubmittedUnitApplication) {
  return {
    submitted_unit_application: {
      accepts_cps_policy: formParams.acceptsCpsPolicy,
      address2: formParams.address2,
      address: formParams.address1,
      applicant_note: formParams.applicantNote,
      available_date: formParams.availableDate,
      birth_city: formParams.birthCity,
      birth_date: formParams.birthDate,
      birth_state_id: formParams.birthStateId,
      city: formParams.city,
      congregation_id: formParams.congregationId,
      dl_state_id: formParams.dlStateId,
      drivers_license: formParams.driversLicense,
      email_address: formParams.emailAddress,
      father_first_name: formParams.fatherFirstName,
      first_name: formParams.firstName,
      gender: formParams.gender,
      last_name: formParams.lastName,
      marital_status: formParams.maritalStatus,
      middle_name: formParams.middleName,
      minister_confirmation: formParams.ministerConfirmation,
      minister_email_address: formParams.ministerEmail,
      minister_name: formParams.ministerName,
      minister_note: formParams.ministerNote,
      minister_phone_number: formParams.ministerPhoneNumber,
      mother_first_name: formParams.motherFirstName,
      phone_number: formParams.phoneNumber,
      position: formParams.position,
      qualification: formParams.qualification,
      state_id: formParams.stateProvinceId,
      status: formParams.status,
      submitted_at: formParams.submittedAt,
      zip: formParams.zipCode,

      unit_ids: formParams.unitIds,
      country_ids: formParams.countryIds,
    },
  };
}

async function create(formParams: PublicSubmittedUnitApplication) {
  const apiParams = formParamsToApiParams(formParams);

  return api.postPublicSubmittedUnitApplication(apiParams);
}

export const publicSubmittedUnitApplicationController = {
  create: create,
};
