import { defineComponent, computed } from "@vue/composition-api";
import { sessionController } from "@/controllers/sessions";
import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";
import { UserResponsibility } from "@/store/user";

export default defineComponent({
  name: "theNavbar",

  setup(_props, ctx) {
    const $store = ctx.root.$store;

    const isLoggedIn = computed(() => {
      return $store.getters.isLoggedIn;
    });

    const routeParams = {
      recruiterWorkInProgress: {
        name: NamedRts.recruiterWorkInProgress,
      },
      coordinatorDashboard: {
        name: NamedRts.submittedPreparatoryClassApplicationList,
      },
      landingPage: {
        name: NamedRts.landingPage,
      },
      userProfile: {
        name: NamedRts.userProfile,
      },
      login: {
        name: NamedRts.login,
      },
      countryList: {
        name: NamedRts.countryList,
      },
      stateProvinceList: {
        name: NamedRts.stateProvinceList,
      },
      congregationList: {
        name: NamedRts.congregationList,
      },
      unitList: {
        name: NamedRts.unitList,
      },
      preparatoryClassSeasonList: {
        name: NamedRts.preparatoryClassSeasonList,
      },
      preparatoryClassInstructorList: {
        name: NamedRts.instructorList,
      },
      preparatoryClassTextDocumentList: {
        name: NamedRts.textDocumentList,
      },
      preparatoryClassEmailBuilderForm: {
        name: NamedRts.emailBuilderForm,
      },
      preparatoryClassList: {
        name: NamedRts.preparatoryClassList,
      },
    };

    const isCoordinator = computed(() => {
      if (
        $store.getters.userResponsibility === UserResponsibility.coordinator
      ) {
        return true;
      } else {
        return false;
      }
    });

    const isRecruiter = computed(() => {
      if ($store.getters.userResponsibility === UserResponsibility.recruiter) {
        return true;
      } else {
        return false;
      }
    });

    const logOut = async () => {
      await sessionController.destroy();
      ctx.root.$bvToast.toast("Successful Logout!", TOAST_OPTIONS);
      ctx.root.$router.push({
        name: NamedRts.landingPage,
      });
    };

    return {
      isCoordinator,
      isLoggedIn,
      isRecruiter,
      logOut,
      routeParams,
    };
  },
});
