import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import CpsAutocompleteObj from "../Autocomplete/AutocompleteObj.vue";
import { StateProvince, statesController } from "@/controllers/states";
import { congregationsController } from "@/controllers/congregations";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import { FormModes } from "@/main";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

export default defineComponent({
  name: "congregationForm",
  components: {
    FormErrors,
    LoadingIndicator,
    CpsAutocompleteObj,
  },
  props: {
    congregationId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);
    const deleting = ref(false);

    const deleteButtonText = ref("Delete");
    const submitButtonText = ref("Submit");

    const refState: any = ref(null);

    const cancelRoute = {
      name: NamedRts.congregationList,
    };

    const fd = reactive({
      id: 0,
      name: "",
      fullName: "",
      city: "",
      stateName: "",
      stateId: 0,
    });

    const formMode = ref(FormModes.edit);

    onMounted(() => {
      const neededFormData = [statesController.needStatesProvinces()];

      if (props.congregationId) {
        if (props.congregationId === "0") {
          // new form, no data needs to be retrieved
          // for the existing item
          Promise.all(neededFormData)
            .then(_response => {
              formMode.value = FormModes.new;
              dataLoaded.value = true;
            })
            .catch((_error: any) => {});
        } else {
          formMode.value = FormModes.edit;
          neededFormData.push(
            congregationsController.needCongregation(props.congregationId),
          );
          Promise.all(neededFormData)
            .then((_response: any) => {
              fd.id = storeCongregation.value.id;
              fd.name = storeCongregation.value.name;
              fd.city = storeCongregation.value.city;
              fd.stateId = storeCongregation.value.stateId;
              dataLoaded.value = true;
              // lookup the state
              const t: StateProvince = stateById(fd.stateId);
              if (t) {
                ctx.root.$nextTick(() => {
                  refState.value.setResultParent({
                    value: t.id,
                    label: t.name,
                  });
                });
              }
            })
            .catch((_error: any) => {});
        }
      } else {
        dataLoaded.value = true;
      }
    });

    const storeCongregation = computed(() => {
      return $store.getters.congregation;
    });

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let submitAction;
      let toastMessage = "";

      if (formMode.value === FormModes.new) {
        toastMessage = `${fd.name} successfully created.`;
        submitAction = congregationsController.createCongregation;
      } else {
        toastMessage = `${fd.name} successfully updated.`;
        submitAction = congregationsController.updateCongregation;
      }

      submitAction(fd)
        .then(_response => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          ctx.root.$bvToast.toast(toastMessage, TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.congregationList,
          });
        })
        .catch(errors => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        // it's a new item, just cancel
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      congregationsController
        .deleteCongregation(fd)
        .then(_response => {
          cancelDelete();
          ctx.root.$bvToast.toast(fd.name + " deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          deleting.value = false;
          deleteButtonText.value = "Delete";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const stateById = (id: number) => {
      return $store.getters.stateProvinceById(id);
    };

    const stateItems = computed(() => {
      return $store.getters.statesProvincesForAutocomplete;
    });

    const stateAC = reactive({
      label: "",
      id: 0,
    });

    const updateState = (evt: AutocompleteResult): void => {
      const t: StateProvince = stateById(evt.id);
      fd.stateId = t.id;
    };

    return {
      updateState,
      stateAC,
      stateItems,
      refState,
      cancelDelete,
      cancelRoute,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      fd,
      hasErrors,
      performDelete,
      startDelete,
      storeCongregation,
      submitButtonText,
      submitForm,
      submitting,
    };
  },
});
