import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import {
  unitsController,
  UnitClassification,
  UnitStatus,
} from "@/controllers/units";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "unitList",
  components: {
    LoadingIndicator,
  },
  setup(_props, ctx) {
    const dataLoaded = ref(false);
    const $store = ctx.root.$store;

    const filter = ref("");
    const filterOn = ["name", "city"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "name",
        sortable: true,
      },
      {
        key: "city",
        sortable: true,
      },
      {
        key: "classification",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin.value) {
      fieldsForTable = [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "city",
          sortable: true,
        },
        {
          key: "classification",
          sortable: true,
        },
      ];
    }

    onMounted(() => {
      Promise.all([
        unitsController.needUnits(UnitStatus.active).then(() => {
          dataLoaded.value = true;
        }),
      ]);
    });

    const loadAllUnits = () => {
      dataLoaded.value = false;
      Promise.all([
        unitsController.needUnits(UnitStatus.all).then(() => {
          dataLoaded.value = true;
        }),
      ]);
    };

    const editRoute = (unitId: number) => {
      return {
        name: NamedRts.unitForm,
        params: {
          unitId: unitId.toString(),
        },
      };
    };

    const showRoute = (unitId: number) => {
      return {
        name: NamedRts.unitDashboardPlacementList,
        params: {
          unitId: unitId.toString(),
        },
      };
    };

    const unitsForTable = computed(() => {
      return $store.getters.unitsForTable;
    });

    return {
      dataLoaded,
      editRoute,
      showRoute,
      fieldsForTable,
      filter,
      filterOn,
      isAdmin,
      unitsForTable,
      loadAllUnits,
    };
  },
});
