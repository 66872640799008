import {
  ref,
  onMounted,
  defineComponent,
  computed,
  watch,
} from "@vue/composition-api";
import { NamedRts } from "@/router";

import ApplicationListHeader from "@/components/UnitApplication/ApplicationListHeader.vue";
import ApplicationListBody from "@/components/UnitApplication/ApplicationListBody.vue";
import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";

export default defineComponent({
  name: "unitFindReplacementDashboard",
  components: {
    ApplicationListHeader,
    ApplicationListBody,
  },
  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const applications = ref([]);

    watch(
      () => ctx.root.$route.name,
      () => {
        loadDataForRoute();
      },
    );

    const loadDataForRoute = () => {
      if (ctx.root.$route.name === NamedRts.unitFindReplacementApplications) {
        applications.value =
          $store.getters.unitApplicationsForPlacementSameUnit;
      } else if (
        ctx.root.$route.name ===
        NamedRts.unitFindReplacementApplicationsSimilarUnit
      ) {
        applications.value =
          $store.getters.unitApplicationsForPlacementSimilarUnit;
      } else if (
        ctx.root.$route.name ===
        NamedRts.unitFindReplacementApplicationsUnmatched
      ) {
        applications.value =
          $store.getters.unitApplicationsForPlacementUnmatched;
      } else {
        applications.value =
          $store.getters.unitApplicationsForPlacementOtherUnit;
      }
    };

    const placement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const otherPeopleCongregationIds = computed(() => {
      return $store.getters.otherPeopleCongregationIds as Set<number>;
    });

    onMounted(() => {
      loadDataForRoute();
      dataLoaded.value = true;
    });

    return {
      placement,
      applications,
      dataLoaded,

      otherPeopleCongregationIds,
    };
  },
});
