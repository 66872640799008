import { Ref, ref, computed } from "@vue/composition-api";
import store from "@/store";

export const filterOnInputHandler = <T>(
  apiItems: Readonly<Ref<readonly T[]>>, // this is the items from the store
  items: Ref<T[]>, // these are the items that the component is displaying, they are going to be updated in this function

  newValue: string, // the new filter string from the html input
  filter: Ref<string>, // the variable that holds the filter string
  debouncedFilterFunc: Function, // a debounced function that filters the items and returns a promise when the debouncing is, well, debounced

  sortKey: Ref<keyof T>, // the key that is used to sort
  sortAsc: Ref<boolean>, // asc or desc? a boolean
  sortFunc: (sortKey: keyof T) => void, // the function that sorts the items

  selectedIndex: Ref<number>, // the variable that holds the value for which row is selected on the list of items
) => {
  filter.value = newValue;

  debouncedFilterFunc(filter.value, [...apiItems.value]).then(
    (results: T[]) => {
      items.value = results;

      // reapply the sort
      sortAsc.value = !sortAsc.value;
      sortFunc(sortKey.value);

      selectedIndex.value = 0;
    },
  );
};

export const filterOnInputHandlerNoSort = <T>(
  apiItems: Readonly<Ref<readonly T[]>>, // this is the items from the store
  items: Ref<T[]>, // these are the items that the component is displaying, they are going to be updated in this function

  newValue: string, // the new filter string from the html input
  filter: Ref<string>, // the variable that holds the filter string
  filterFunc: Function, // a debounced function that filters the items and returns a promise when the debouncing is, well, debounced
  // debouncedFilterFunc: Function, // a debounced function that filters the items and returns a promise when the debouncing is, well, debounced
) => {
  filter.value = newValue;

  items.value = filterFunc(filter.value, [...apiItems.value]);
};

const regExpFunction = <T>(regExpression: RegExp, itemKey: keyof T) => {
  return (item: T) => {
    return regExpression.test((item[itemKey] as any) as string);
  };
};

// This took quite a bit of figuring out
// an array of keys is passed in,
// map over those keys and get a curried function for doing a reg expressing test
// then test those items to filter the list of things that are passed in
export const filterItems = <T>(
  filter: string,
  keysToFilter: Array<keyof T>,
  items: T[],
): T[] => {
  if (filter !== "") {
    const tmpReg = new RegExp(filter, "i");

    const regItems = keysToFilter.map((itemKey: keyof T) => {
      return regExpFunction(tmpReg, itemKey);
    });
    // return Rfilter((oneOfItems: T) => {
    //   return regItems.some(fn => {
    //     return fn(oneOfItems);
    //   });
    // }, items);

    return items.filter(item => {
      return regItems.some(fn => {
        return fn(item);
      });
    });
  } else {
    return items;
  }
};

export function useFilterable<T>(
  apiItems: Ref<T[]>,
  items: Ref<T[]>,
  filterableKeys: Array<keyof T>,
) {
  const filter = ref("");

  const lFilterItems = (filter: string, items: T[]) => {
    return filterItems(filter, filterableKeys, items);
  };

  const filterChanged = (newFilter: string) => {
    filterOnInputHandlerNoSort(
      apiItems,
      items,

      newFilter,
      filter,
      lFilterItems,
    );
  };

  return {
    filterChanged,
  };
}

export function useItemable<T>(itemName: string) {
  const items = ref<T[]>([]);

  const apiItems = computed(() => {
    return store.getters[itemName];
  });

  const reloadData = () => {
    items.value = [...apiItems.value];
  };

  return {
    items,
    apiItems,

    reloadData,
  };
}
