import api from "@/api";

async function getPreparatoryClassPeopleReport(id: number) {
  return api
    .getPreparatoryClassPeopleReport(id)
    .then(response => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function getPreparatoryClassCertificates(id: number) {
  return api
    .getPreparatoryClassCertificates(id)
    .then(response => {
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassReportsController = {
  getPreparatoryClassPeopleReport,
  getPreparatoryClassCertificates,
};
