import { CongregationMinister } from "@/controllers/congregationsMinisters";

interface CongregationsMinistersState {
  congregationsMinisters: CongregationMinister[];
}

const state: CongregationsMinistersState = {
  congregationsMinisters: [],
};

const mutations = {
  LOAD_CONGREGATIONS_MINISTERS(
    state: CongregationsMinistersState,
    payload: CongregationMinister[],
  ) {
    state.congregationsMinisters = payload;
  },
};

const actions = {
  loadCongregationsMinisters(
    context: any,
    congregationsMinisters: CongregationMinister[],
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_CONGREGATIONS_MINISTERS", congregationsMinisters);
      resolve(congregationsMinisters);
    });
  },
};

const getters = {
  congregationsMinisters: (state: CongregationsMinistersState) => {
    return state.congregationsMinisters;
  },
};

const congregationsMinistersModule = {
  state,
  mutations,
  actions,
  getters,
};

export default congregationsMinistersModule;
