import api from "@/api";
import store, { StoreActions } from "@/store";

export const enum PreparatoryClassClassification {
  girls = "girls",
  boys = "boys",
}

export interface ApiPreparatoryClass {
  full_name: string;
  classification: string;
  congregation_city: string;
  congregation_id: number;
  congregation_name: string;
  congregation_state_id: number;
  congregation_state_name: string;
  description: string;
  end_date: string;
  id: number;
  name: string;
  number: number;
  preparatory_class_season_id: number;
  start_date: string;
}

export interface ApiPreparatoryClassParams {
  preparatory_class: {
    classification: string;
    congregation_id: number;
    description: string;
    end_date: string;
    name: string;
    number: number;
    preparatory_class_season_id: number;
    start_date: string;
  };
}

export interface PreparatoryClass {
  fullName: string;
  classification: string;
  congregationCity: string;
  congregationId: number;
  congregationName: string;
  congregationStateId: number;
  congregationStateName: string;
  description: string;
  endDate: string;
  id: number;
  name: string;
  number: number;
  preparatoryClassSeasonId: number;
  startDate: string;
}

export function apiPreparatoryClassToPreparatoryClass(
  p: ApiPreparatoryClass,
): PreparatoryClass {
  return {
    fullName: p.full_name,
    classification: p.classification,
    congregationCity: p.congregation_city,
    congregationId: p.congregation_id,
    congregationName: p.congregation_name,
    congregationStateId: p.congregation_state_id,
    congregationStateName: p.congregation_state_name,
    description: p.description,
    endDate: p.end_date,
    id: p.id,
    name: p.name,
    number: p.number,
    preparatoryClassSeasonId: p.preparatory_class_season_id,
    startDate: p.start_date,
  };
}

async function createPreparatoryClass(c: PreparatoryClass) {
  const cParams: ApiPreparatoryClassParams = {
    preparatory_class: {
      classification: c.classification,
      congregation_id: c.congregationId,
      description: c.description,
      end_date: c.endDate,
      name: c.name,
      number: c.number,
      preparatory_class_season_id: c.number,
      start_date: c.startDate,
    },
  };
  return api
    .postPreparatoryClass(cParams)
    .then((response: ApiPreparatoryClass) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClass,
        apiPreparatoryClassToPreparatoryClass(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClasses() {
  await api
    .getPreparatoryClasses()
    .then((response: ApiPreparatoryClass[]) => {
      const c = response.map(item => {
        return apiPreparatoryClassToPreparatoryClass(item);
      });
      return store.dispatch(StoreActions.loadPreparatoryClasses, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClass(preparatoryClassId: string) {
  await api
    .getPreparatoryClass(preparatoryClassId)
    .then((response: ApiPreparatoryClass) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClass,
        apiPreparatoryClassToPreparatoryClass(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updatePreparatoryClass(c: PreparatoryClass) {
  const cParams: ApiPreparatoryClassParams = {
    preparatory_class: {
      classification: c.classification,
      congregation_id: c.congregationId,
      description: c.description,
      end_date: c.endDate,
      name: c.name,
      number: c.number,
      preparatory_class_season_id: c.number,
      start_date: c.startDate,
    },
  };
  return api
    .patchPreparatoryClass(c.id, cParams)
    .then((response: ApiPreparatoryClass) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClass,
        apiPreparatoryClassToPreparatoryClass(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClass(c: PreparatoryClass) {
  return api
    .deletePreparatoryClass(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassesController = {
  createPreparatoryClass,
  needPreparatoryClass,
  needPreparatoryClasses,
  updatePreparatoryClass,
  deletePreparatoryClass,
};
