import api from "@/api";
import store, { StoreActions } from "@/store";
import {
  PreparatoryClassClassification,
  ApiPreparatoryClass,
} from "./preparatoryClasses";

export const enum PreparatoryClassApplicationStatus {
  pending = "pending",
  placed = "placed",
  placementConfirmed = "placement_confirmed",
  placementRefused = "placement_refused",
}

export const prettifiedPreparatoryClassApplicationStatus = {
  pending: "Pending",
  placed: "Placed",
  placement_confirmed: "Confirmed",
  placement_refused: "Refused",
};

export const enum PreparatoryClassApplicationFlag {
  normal = "normal",
  notice = "notice",
  warning = "warning",
}

export const enum PCAPlacementPacketSentByMethod {
  email = "email",
  usps = "usps",
}

export const enum PCAPlacementConfirmedMethod {
  emailLink = "email_link",
  manual = "manual",
}

export interface ApiPreparatoryClassApplicationPreference {
  id: number;
  preference_order: number;
  preparatory_class_application_id: number;
  preparatory_class_congregation_name: string;
  preparatory_class_end_date: string;
  preparatory_class_id: number;
  preparatory_class_name: string;
  preparatory_class_start_date: string;
}

export interface ApiPreparatoryClassApplicationIndexParams {
  preparatory_class_id: number;
}

export interface ApiPreparatoryClassApplication {
  full_name: string;
  congregation: string;
  person_id: number;
  id: number;
  classification: string;
  submitted_date: string;
  status: string;
  minister_name: string;
  minister_email_address: string;
  minister_phone_number: string;
  placement_date: string;
  placement_confirmed_date: string;
  placement_confirmed_method: string;
  placement_packet_sent_date: string;
  placement_packet_sent_by_method: string;
  placement_preparatory_class_id: number;
  flag: string;
  preparatory_class_application_preferences: ApiPreparatoryClassApplicationPreference[];
}

export interface ApiPreparatoryClassApplicationParams {
  submitted_preparatory_class_application?: {
    id: number;
  };
  preparatory_class_application: {
    classification: PreparatoryClassClassification;
    flag: PreparatoryClassApplicationFlag;
    minister_email_address: string;
    minister_name: string;
    minister_phone_number: string;
    person_id: number;
    placement_confirmed_date: string;
    placement_confirmed_method: PCAPlacementConfirmedMethod;
    placement_date: string;
    placement_packet_sent_by_method: PCAPlacementPacketSentByMethod;
    placement_packet_sent_date: string;
    placement_preparatory_class_id: number;
    status: PreparatoryClassApplicationStatus;
    submitted_date: string;
    preparatory_class_application_preferences_attributes: ApiPreparatoryClassApplicationPreferenceParams[];
  };
}

export interface ApiPreparatoryClassApplicationPreferenceParams {
  id?: number;
  preparatory_class_id: number;
  preference_order: number;
  _destroy: boolean;
}

export interface PreparatoryClassApplication {
  personId: number;
  fullName: string;
  congregation: string;
  classification: PreparatoryClassClassification;
  flag: PreparatoryClassApplicationFlag;
  id: number;
  ministerEmailAddress: string;
  ministerName: string;
  ministerPhoneNumber: string;
  placementConfirmedDate: string;
  placementConfirmedMethod: PCAPlacementConfirmedMethod;
  placementDate: string;
  placementPacketSentByMethod: PCAPlacementPacketSentByMethod;
  placementPacketSentDate: string;
  placementPreparatoryClassId: number;
  status: PreparatoryClassApplicationStatus;
  submittedDate: string;
  preparatoryClassApplicationPreferences: PreparatoryClassApplicationPreference[];
}

export interface PreparatoryClassApplicationPreference {
  _destroy: boolean;
  id: number;
  preferenceOrder: number;
  preparatoryClassId: number;
  preparatoryClassApplicationId: number;
  preparatoryClassCongregationName: string;
  preparatoryClassEndDate: string;
  preparatoryClassName: string;
  preparatoryClassStartDate: string;
}

function apiPreparatoryClassApplicationToPreparatoryClassApplication(
  p: ApiPreparatoryClassApplication,
): PreparatoryClassApplication {
  const g =
    p.classification === "boys"
      ? PreparatoryClassClassification.boys
      : PreparatoryClassClassification.girls;
  const st =
    p.status === "placed"
      ? PreparatoryClassApplicationStatus.placed
      : p.status === "placement_confirmed"
      ? PreparatoryClassApplicationStatus.placementConfirmed
      : p.status === "placement_refused"
      ? PreparatoryClassApplicationStatus.placementRefused
      : PreparatoryClassApplicationStatus.pending;

  const cm =
    p.placement_confirmed_method === "email_link"
      ? PCAPlacementConfirmedMethod.emailLink
      : PCAPlacementConfirmedMethod.manual;
  const sbm =
    p.placement_packet_sent_by_method === "usps"
      ? PCAPlacementPacketSentByMethod.usps
      : PCAPlacementPacketSentByMethod.email;
  const fl =
    p.flag === "notice"
      ? PreparatoryClassApplicationFlag.notice
      : p.flag === "warning"
      ? PreparatoryClassApplicationFlag.warning
      : PreparatoryClassApplicationFlag.normal;

  const preferences: PreparatoryClassApplicationPreference[] = p.preparatory_class_application_preferences.map(
    item => {
      return {
        _destroy: false,
        id: item.id,
        preferenceOrder: item.preference_order,
        preparatoryClassId: item.preparatory_class_id,
        preparatoryClassApplicationId: item.preparatory_class_application_id,
        preparatoryClassCongregationName:
          item.preparatory_class_congregation_name,
        preparatoryClassEndDate: item.preparatory_class_end_date,
        preparatoryClassName: item.preparatory_class_name,
        preparatoryClassStartDate: item.preparatory_class_start_date,
      };
    },
  );

  return {
    personId: p.person_id,
    id: p.id,
    fullName: p.full_name,
    congregation: p.congregation,
    classification: g,
    submittedDate: p.submitted_date,
    status: st,
    ministerName: p.minister_name,
    ministerEmailAddress: p.minister_email_address,
    ministerPhoneNumber: p.minister_phone_number,
    placementDate: p.placement_date,
    placementConfirmedDate: p.placement_confirmed_date,
    placementConfirmedMethod: cm,
    placementPacketSentDate: p.placement_packet_sent_date,
    placementPacketSentByMethod: sbm,
    placementPreparatoryClassId: p.placement_preparatory_class_id,
    flag: fl,
    preparatoryClassApplicationPreferences: preferences,
  };
}

async function createPreparatoryClassApplication(
  c: PreparatoryClassApplication,
  sId?: number,
) {
  let cParams: ApiPreparatoryClassApplicationParams;
  const preferences = c.preparatoryClassApplicationPreferences.map(item => {
    return {
      preparatory_class_id: item.preparatoryClassId,
      preference_order: item.preferenceOrder,
      _destroy: item._destroy,
    };
  });
  if (sId) {
    cParams = {
      submitted_preparatory_class_application: {
        id: sId,
      },
      preparatory_class_application: {
        classification: c.classification,
        flag: c.flag,
        minister_email_address: c.ministerEmailAddress,
        minister_name: c.ministerName,
        minister_phone_number: c.ministerPhoneNumber,
        person_id: c.personId,
        placement_confirmed_date: c.placementConfirmedDate,
        placement_confirmed_method: c.placementConfirmedMethod,
        placement_date: c.placementDate,
        placement_packet_sent_by_method: c.placementPacketSentByMethod,
        placement_packet_sent_date: c.placementPacketSentDate,
        placement_preparatory_class_id: c.placementPreparatoryClassId,
        status: c.status,
        submitted_date: c.submittedDate,
        preparatory_class_application_preferences_attributes: preferences,
      },
    };
  } else {
    cParams = {
      preparatory_class_application: {
        classification: c.classification,
        flag: c.flag,
        minister_email_address: c.ministerEmailAddress,
        minister_name: c.ministerName,
        minister_phone_number: c.ministerPhoneNumber,
        person_id: c.personId,
        placement_confirmed_date: c.placementConfirmedDate,
        placement_confirmed_method: c.placementConfirmedMethod,
        placement_date: c.placementDate,
        placement_packet_sent_by_method: c.placementPacketSentByMethod,
        placement_packet_sent_date: c.placementPacketSentDate,
        placement_preparatory_class_id: c.placementPreparatoryClassId,
        status: c.status,
        submitted_date: c.submittedDate,
        preparatory_class_application_preferences_attributes: preferences,
      },
    };
  }

  return api
    .postPreparatoryClassApplication(cParams)
    .then((response: ApiPreparatoryClassApplication) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassApplication,
        apiPreparatoryClassApplicationToPreparatoryClassApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClassApplications(
  p?: ApiPreparatoryClassApplicationIndexParams,
) {
  // really the component should just send and id and the controller should build the params
  await api
    .getPreparatoryClassApplications(p)
    .then((response: ApiPreparatoryClassApplication[]) => {
      const c = response.map(item => {
        return apiPreparatoryClassApplicationToPreparatoryClassApplication(
          item,
        );
      });
      return store.dispatch(StoreActions.loadPreparatoryClassApplications, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClassApplication(
  PreparatoryClassApplicationId: string,
) {
  return api
    .getPreparatoryClassApplication(PreparatoryClassApplicationId)
    .then((response: ApiPreparatoryClassApplication) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassApplication,
        apiPreparatoryClassApplicationToPreparatoryClassApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updatePreparatoryClassApplication(
  c: PreparatoryClassApplication,
) {
  const cParams: ApiPreparatoryClassApplicationParams = {
    preparatory_class_application: {
      placement_packet_sent_date: c.placementPacketSentDate,
      person_id: c.personId,
      classification: c.classification,
      submitted_date: c.submittedDate,
      status: c.status,
      minister_name: c.ministerName,
      minister_email_address: c.ministerEmailAddress,
      minister_phone_number: c.ministerPhoneNumber,
      placement_date: c.placementDate,
      placement_confirmed_date: c.placementConfirmedDate,
      placement_confirmed_method: c.placementConfirmedMethod,
      placement_packet_sent_by_method: c.placementPacketSentByMethod,
      placement_preparatory_class_id: c.placementPreparatoryClassId,
      flag: c.flag,
      preparatory_class_application_preferences_attributes: c.preparatoryClassApplicationPreferences.map(
        item => {
          let temp: ApiPreparatoryClassApplicationPreferenceParams = {
            preparatory_class_id: item.preparatoryClassId,
            preference_order: item.preferenceOrder,
            _destroy: item._destroy,
          };

          if (item.id !== 0) {
            temp = {
              id: item.id,
              ...temp,
            };
          }

          return temp;
        },
      ),
    },
  };

  return api
    .patchPreparatoryClassApplication(c.id, cParams)
    .then((response: ApiPreparatoryClassApplication) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassApplication,
        apiPreparatoryClassApplicationToPreparatoryClassApplication(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClassApplication(
  c: PreparatoryClassApplication,
) {
  return api
    .deletePreparatoryClassApplication(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassApplicationController = {
  createPreparatoryClassApplication,
  needPreparatoryClassApplications,
  needPreparatoryClassApplication,
  updatePreparatoryClassApplication,
  deletePreparatoryClassApplication,
};
