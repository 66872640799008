import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { TOAST_OPTIONS } from "@/constants";
import {
  ApiPreparatoryClassApplicationNote,
  preparatoryClassApplicationNotesController,
} from "@/controllers/pcaNotes";

export default defineComponent({
  name: "preparatoryClassApplicationNotes",
  components: {
    LoadingIndicator,
  },
  props: {
    preparatoryClassApplicationId: Number,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const noteText = ref("");
    const notes = ref<ApiPreparatoryClassApplicationNote[]>([]);

    onMounted(() => {
      if (props.preparatoryClassApplicationId) {
        preparatoryClassApplicationNotesController
          .needPreparatoryClassApplicationNotes(
            props.preparatoryClassApplicationId,
          )
          .then(response => {
            notes.value = response;
            dataLoaded.value = true;
          });
      }
    });

    const deleteNote = (noteId: number) => {
      preparatoryClassApplicationNotesController
        .deletePreparatoryClassApplicationNote(noteId)
        .then(response => {
          notes.value = response;
          ctx.root.$bvToast.toast("Note deleted.", TOAST_OPTIONS);
        });
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      if (props.preparatoryClassApplicationId) {
        if (noteText.value !== "") {
          preparatoryClassApplicationNotesController
            .createPreparatoryClassApplicationNote(
              props.preparatoryClassApplicationId,
              noteText.value,
            )
            .then(response => {
              notes.value = response;
              ctx.root.$bvToast.toast("Note created.", TOAST_OPTIONS);
              noteText.value = "";
            });
        }
      }
    };

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    return {
      onSubmit,
      deleteNote,
      dataLoaded,
      notes,
      noteText,
      isAdmin,
    };
  },
});
