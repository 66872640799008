// 2020-04-30
// This controller is different then most of the others
// The data for the preferences is not being stored in the store
// it is just being passed directly to the component
// the component is smart enough to know what to do with the preferences
import api from "@/api";

export interface ApiUnitApplicationPreference {
  id: number;
  unit_id: number;
  unit_name: string;
  unit_application_id: number;
  applications_ahead: number;
  average_days_waited: number;
  average_wait_time: string;
}

export interface UnitApplicationPreference {
  id: number;
  unitId: number;
  unitName: string;
  unitApplicationId: number;
  applicationsAhead: number;
  averageDaysWaited: number;
  averageWaitTime: string;
}

function apiUAPreferenceToUAPreference(
  p: ApiUnitApplicationPreference,
): UnitApplicationPreference {
  return {
    id: p.id,
    unitId: p.unit_id,
    unitName: p.unit_name,
    unitApplicationId: p.unit_application_id,
    applicationsAhead: p.applications_ahead,
    averageDaysWaited: p.average_days_waited,
    averageWaitTime: p.average_wait_time,
  };
}

export interface ApiUnitApplicationPreferenceIndexParams {
  unit_application_id: number;
}

async function needUnitApplicationPreferences(
  p: ApiUnitApplicationPreferenceIndexParams,
) {
  return api
    .getUnitApplicationPreferences(p)
    .then(response => {
      return response.map(item => apiUAPreferenceToUAPreference(item));
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const unitApplicationPreferenceController = {
  needUnitApplicationPreferences,
};
