import api from "@/api";

export const enum SendEmailMode {
  preview = "preview",
  production = "production",
}

export interface ApiPreparatoryClassCommunicationParams {
  preparatory_class_id: number;
  mode: SendEmailMode;
}

export interface ApiPreparatoryClassApplicationCommunicationParams {
  application_id: number;
  mode: SendEmailMode;
}

async function sendPlacementPacketPreparatoryClass(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return api.sendPlacementPacket(p);
}

export const placementPacketController = {
  sendPlacementPacketPreparatoryClass,
};
