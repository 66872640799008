import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import { countriesController } from "@/controllers/countries";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import { FormModes } from "@/main";

export default defineComponent({
  name: "countryForm",
  components: {
    FormErrors,
    LoadingIndicator,
  },
  props: {
    countryId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);
    const deleting = ref(false);

    const deleteButtonText = ref("Delete");
    const submitButtonText = ref("Submit");

    const cancelRoute = {
      name: NamedRts.countryList,
    };

    const fd = reactive({
      id: 0,
      name: "",
      abbreviation: "",
    });

    const formMode = ref(FormModes.edit);

    onMounted(() => {
      if (props.countryId) {
        if (props.countryId === "0") {
          // new form, no data needs to be retrieved
          formMode.value = FormModes.new;
          dataLoaded.value = true;
        } else {
          formMode.value = FormModes.edit;
          Promise.all([countriesController.needCountry(props.countryId)])
            .then((_response: any) => {
              fd.id = storeCountry.value.id;
              fd.name = storeCountry.value.name;
              fd.abbreviation = storeCountry.value.abbreviation;
              dataLoaded.value = true;
            })
            .catch((_error: any) => {});
        }
      } else {
        dataLoaded.value = true;
      }
    });

    const storeCountry = computed(() => {
      return $store.getters.country;
    });

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let submitAction;
      let toastMessage = "";

      if (formMode.value === FormModes.new) {
        toastMessage = `${fd.name} successfully created.`;
        submitAction = countriesController.createCountry;
      } else {
        toastMessage = `${fd.name} successfully updated.`;
        submitAction = countriesController.updateCountry;
      }

      submitAction(fd)
        .then(_response => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          ctx.root.$bvToast.toast(toastMessage, TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.countryList,
          });
        })
        .catch(errors => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        // it's a new item, just cancel
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      countriesController
        .deleteCountry(fd)
        .then(_response => {
          cancelDelete();
          ctx.root.$bvToast.toast(fd.name + " deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          deleting.value = false;
          deleteButtonText.value = "Delete";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    return {
      cancelDelete,
      cancelRoute,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      fd,
      hasErrors,
      performDelete,
      startDelete,
      storeCountry,
      submitButtonText,
      submitForm,
      submitting,
    };
  },
});
