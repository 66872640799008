import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { statesController } from "@/controllers/states";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "stateProvinceList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const filter = ref("");
    const filterOn = ["fullName", "abbreviation"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "fullName",
        sortable: true,
      },
      {
        key: "abbreviation",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin.value) {
      fieldsForTable = [
        {
          key: "fullName",
          sortable: true,
        },
        {
          key: "abbreviation",
          sortable: true,
        },
      ];
    }

    onMounted(() => {
      Promise.all([statesController.needStatesProvinces()])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    const editRoute = (stateProvinceId: number) => {
      return {
        name: NamedRts.stateProvinceForm,
        params: {
          stateProvinceId: stateProvinceId.toString(),
        },
      };
    };

    const statesProvincesForTable = computed(() => {
      return $store.getters.statesProvincesForTable;
    });

    return {
      dataLoaded,
      editRoute,
      fieldsForTable,
      filter,
      filterOn,
      isAdmin,
      statesProvincesForTable,
    };
  },
});
