// 2020-04-30
// This controller is different then most of the others
// The data for the recent activity is not being stored in the store
// it is just being passed directly to the component
// the component is smart enough to know what to do with the recent activity
import api from "@/api";
import { Gender, genderReverseLookup } from "@/controllers/people";

export interface ApiRecentNote {
  content: string;
  time_ago_in_words: string;
  created_at: string;
  user_name: string;
  id: number;
  unit_application_id: number;
  unit_application_name: string;
  person_id: number;
  gender: string;
}

export interface ApiRecentUnitApplication {
  id: number;
  person_id: number;
  unit_application_name: string;
  person_congregation: string;
  person_city: string;
  person_state_abbreviation: string;
  time_ago_in_words: string;
  created_at: string;
  gender: string;
}

export interface ApiRecentUnitApplicationPlacement {
  id: number;
  person_id: number;
  unit_application_name: string;
  unit_application_id: number;
  unit_name: string;
  start_date: string;
  end_date: string;
  person_congregation: string;
  person_city: string;
  person_state_abbreviation: string;
  time_ago_in_words: string;
  created_at: string;
  gender: string;
}

export interface ApiRecentActivities {
  notes: ApiRecentNote[];
  unit_applications: ApiRecentUnitApplication[];
  unit_application_placements: ApiRecentUnitApplicationPlacement[];
}

export interface RecentNote {
  content: string;
  timeAgoInWords: string;
  createdAt: string;
  userName: string;
  id: number;
  unitApplicationId: number;
  unitApplicationName: string;
  personId: number;
  gender: Gender;
}

export interface RecentUnitApplication {
  id: number;
  personId: number;
  unitApplicationName: string;
  personCongregation: string;
  personCity: string;
  personStateAbbreviation: string;
  timeAgoInWords: string;
  createdAt: string;
  gender: Gender;
}

export interface RecentUnitApplicationPlacement {
  id: number;
  personId: number;
  unitApplicationName: string;
  unitApplicationId: number;
  unitName: string;
  startDate: string;
  endDate: string;
  personCongregation: string;
  personCity: string;
  personStateAbbreviation: string;
  timeAgoInWords: string;
  createdAt: string;
  gender: Gender;
}

function apiRecentNoteToRecentNote(p: ApiRecentNote): RecentNote {
  const g = genderReverseLookup[p.gender];
  return {
    id: p.id,
    unitApplicationId: p.unit_application_id,
    timeAgoInWords: p.time_ago_in_words,
    createdAt: p.created_at,
    userName: p.user_name,
    unitApplicationName: p.unit_application_name,
    personId: p.person_id,
    content: p.content,
    gender: g,
  };
}

function apiRecentUAToRecentUA(
  p: ApiRecentUnitApplication,
): RecentUnitApplication {
  const g = genderReverseLookup[p.gender];

  return {
    id: p.id,
    personId: p.person_id,
    unitApplicationName: p.unit_application_name,
    personCongregation: p.person_congregation,
    personCity: p.person_city,
    personStateAbbreviation: p.person_state_abbreviation,
    timeAgoInWords: p.time_ago_in_words,
    createdAt: p.created_at,
    gender: g,
  };
}

function apiRecentUAPToRecentUAP(
  p: ApiRecentUnitApplicationPlacement,
): RecentUnitApplicationPlacement {
  const g = genderReverseLookup[p.gender];

  return {
    id: p.id,
    personId: p.person_id,
    unitApplicationName: p.unit_application_name,
    unitApplicationId: p.unit_application_id,
    unitName: p.unit_name,
    startDate: p.start_date,
    endDate: p.end_date,
    personCongregation: p.person_congregation,
    personCity: p.person_city,
    personStateAbbreviation: p.person_state_abbreviation,
    timeAgoInWords: p.time_ago_in_words,
    createdAt: p.created_at,
    gender: g,
  };
}

async function needRecentActivities() {
  return api
    .getUnitRecentActivities()
    .then(response => {
      return {
        notes: response.notes.map(item => apiRecentNoteToRecentNote(item)),
        unitApplications: response.unit_applications.map(item =>
          apiRecentUAToRecentUA(item),
        ),
        unitApplicationPlacements: response.unit_application_placements.map(
          item => apiRecentUAPToRecentUAP(item),
        ),
      };
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const unitRecentActivitiesController = {
  needRecentActivities,
};
