import { AppRole } from "@/controllers/appRoles";

interface AppRolesState {
  appRolesForTable: AppRole[];
}

const state: AppRolesState = {
  appRolesForTable: [],
};

const mutations = {
  LOAD_APP_ROLES(state: AppRolesState, payload: AppRole[]) {
    state.appRolesForTable = payload;
  },
};

const actions = {
  loadAppRoles(context: any, appRoles: AppRole[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_APP_ROLES", appRoles);
      resolve(appRoles);
    });
  },
};

const getters = {
  appRolesForTable: (state: AppRolesState) => {
    return state.appRolesForTable;
  },
};

const AppRolesModule = {
  state,
  mutations,
  actions,
  getters,
};

export default AppRolesModule;
