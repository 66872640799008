import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiState {
  abbreviation: string;
  country_id: number;
  country_name: string;
  full_name: string;
  id: number;
  name: string;
}

export interface ApiStateParams {
  name: string;
  abbreviation: string;
  country_id: number;
}

export interface StateProvince {
  abbreviation: string;
  countryId: number;
  countryName: string;
  fullName: string;
  id: number;
  name: string;
}

function apiStateToState(p: ApiState): StateProvince {
  return {
    abbreviation: p.abbreviation,
    countryId: p.country_id,
    countryName: p.country_name,
    fullName: p.full_name,
    id: p.id,
    name: p.name,
  };
}

async function createStateProvince(c: StateProvince) {
  const cParams: ApiStateParams = {
    name: c.name,
    abbreviation: c.abbreviation,
    country_id: c.countryId,
  };
  return api
    .postState(cParams)
    .then((response: ApiState) => {
      return store.dispatch(
        StoreActions.loadStateProvince,
        apiStateToState(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needStatesProvinces() {
  await api
    .getStates()
    .then((response: ApiState[]) => {
      const c = response.map(item => {
        return apiStateToState(item);
      });
      return store.dispatch("loadStatesProvinces", c);
    })
    .then((response: any) => {
      return response;
    });
}

async function needStateProvince(stateProvinceId: string) {
  return api
    .getState(stateProvinceId)
    .then((response: ApiState) => {
      return store.dispatch(
        StoreActions.loadStateProvince,
        apiStateToState(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateStateProvince(c: StateProvince) {
  const cParams: ApiStateParams = {
    name: c.name,
    abbreviation: c.abbreviation,
    country_id: c.countryId,
  };
  return api
    .patchState(c.id, cParams)
    .then((response: ApiState) => {
      return store.dispatch(
        StoreActions.loadStateProvince,
        apiStateToState(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteStateProvince(c: StateProvince) {
  return api
    .deleteState(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const statesController = {
  needStatesProvinces,
  needStateProvince,
  createStateProvince,
  updateStateProvince,
  deleteStateProvince,
};
