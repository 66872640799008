import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";

import { Person, Gender } from "@/controllers/people";

import {
  unitApplicationController,
  UnitApplication,
} from "@/controllers/unitApplications";

import FormErrors from "@/components/FormErrors/FormErrors.vue";
import LoadingIndicator from "@/components/Loading/Loading.vue";

import { todayISO } from "@/utilities/displayHelpers";

import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";

import {
  buildUnitApplication,
  buildUnitApplicationStatuses,
  buildUnitApplicationPositions,
  buildUAPreferences,
  buildSubmitButton,
  buildDeleteButton,
  buildCongregationsMinisters,
} from "@/utilities/formHelpers";
import { CongregationMinister } from "@/controllers/congregationsMinisters";

import { unitsController, UnitClassification } from "@/controllers/units";

import {
  prettifyUnitPosition,
  prettifyUAStatus,
  animateOnStateChange,
} from "@/utilities/displayHelpers";

import CongregationsMinisters from "@/components/CongregationsMinisters/CongregationsMinisters.vue";

import { UnitPosition } from "@/controllers/unitPositionTermLengths";

export default defineComponent({
  name: "UnitApplicationFormPartial",
  components: {
    FormErrors,
    LoadingIndicator,
    CongregationsMinisters,
  },
  props: {
    unitApplicationId: {
      type: String,
      required: true,
    },
    personId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const {
      congregationsMinisters,
      congregationsMinistersBell,
      lookupCongregationsMinisters,
      loadMinister,
    } = buildCongregationsMinisters();

    const { submitting, submitButtonText } = buildSubmitButton();
    const { deleting, deleteButtonText } = buildDeleteButton();

    const { loadUAPreferencesIntoForm, uaPreferenceIds } = buildUAPreferences();

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const {
      unitApplication,
      loadUnitApplicationIntoForm,
    } = buildUnitApplication();

    const {
      unitApplicationStatuses,
      loadUnitApplicationStatusesIntoForm,
    } = buildUnitApplicationStatuses();

    const {
      unitPositions,
      loadUnitPositionsIntoForm,
    } = buildUnitApplicationPositions();

    const apiUnitApplication = computed(() => {
      return $store.getters.unitApplication as UnitApplication;
    });

    const cancelRoute = {
      name: NamedRts.personDashboardPersonShow,
      params: {
        personId: props.personId,
      },
    };

    const unitsForList = computed(() => {
      if (unitApplication.position === UnitPosition.boysUnitVolunteer) {
        return $store.getters.boysUnitsForTable;
      } else if (unitApplication.position === UnitPosition.girlsUnitVolunteer) {
        return $store.getters.girlsUnitsForTable;
      } else {
        return $store.getters.unitsForTable;
      }
    });

    const person = computed(() => {
      return $store.getters.person as Person;
    });

    const localLoadMinister = (minister: CongregationMinister) => {
      loadMinister(minister, congregationsMinistersBell, unitApplication);

      const el = document.getElementById("minister-data-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    onMounted(() => {
      const dataPromises = [
        unitsController.needUnitsList(UnitClassification.all),
      ];

      if (props.unitApplicationId === "0") {
        // 2020-04-27
        // This form partial is only being mounted when there is already
        // a person in the store. If that should change,
        // it may be necessary to load the person at this spot
      } else {
        dataPromises.push(
          unitApplicationController.needUnitApplication(
            props.unitApplicationId,
          ),
        );
      }

      Promise.all(dataPromises).then(() => {
        loadUnitApplicationStatusesIntoForm(unitApplicationStatuses);
        loadUnitPositionsIntoForm(unitPositions);

        if (props.unitApplicationId === "0") {
          unitApplication.personId = Number(props.personId);
          unitApplication.submittedDate = todayISO();
          unitApplication.availableDate = todayISO();
          if (person.value.gender === Gender.female) {
            unitApplication.position = UnitPosition.girlsUnitVolunteer;
          } else {
            unitApplication.position = UnitPosition.boysUnitVolunteer;
          }

          // get any ministers for the congregation
          lookupCongregationsMinisters(
            person.value.congregationId.toString(),
            congregationsMinisters,
            congregationsMinistersBell,
          );
        } else {
          loadUnitApplicationIntoForm(unitApplication, apiUnitApplication);
          const tempIds = unitApplication.unitApplicationPreferences.map(
            item => {
              return item.unitId;
            },
          );

          uaPreferenceIds.value = Array.from(new Set(tempIds));
        }

        dataLoaded.value = true;
      });
    });

    const startDelete = () => {
      deleting.value = true;
      if (props.unitApplicationId === "0") {
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      unitApplicationController
        .deleteUnitApplication(unitApplication)
        .then(() => {
          cancelDelete();
          ctx.emit("data-changed");
          ctx.root.$bvToast.toast("Application deleted", TOAST_OPTIONS);
          return unitApplicationController.needUnitApplications({
            person_id: Number(props.personId),
          });
        })
        .then(() => {
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          cancelDelete();
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();

      submitting.value = true;
      submitButtonText.value = "Submitting...";

      unitApplication.unitApplicationPreferences = loadUAPreferencesIntoForm(
        unitApplication.unitApplicationPreferences,
        uaPreferenceIds.value,
      );

      let apiResponse;
      if (props.unitApplicationId === "0") {
        apiResponse = unitApplicationController.createUnitApplication(
          unitApplication,
        );
      } else {
        apiResponse = unitApplicationController.updateUnitApplication(
          unitApplication,
        );
      }

      apiResponse.then(() => {
        ctx.root.$bvToast.toast(
          "Unit application was successfully saved.",
          TOAST_OPTIONS,
        );
        ctx.emit("data-changed");
        ctx.root.$router.push({
          name: NamedRts.personDashboardPersonShow,
          params: {
            personId: apiUnitApplication.value.personId.toString(),
          },
        });
      });
    };

    return {
      dataLoaded,
      errorMessages,
      hasErrors,

      isAdmin,

      unitPositions,
      unitApplication,
      unitApplicationStatuses,

      prettifyUAStatus,
      prettifyUnitPosition,

      congregationsMinisters,
      congregationsMinistersBell,
      localLoadMinister,

      unitsForList,

      uaPreferenceIds,

      onSubmit,
      submitting,
      submitButtonText,

      deleting,
      deleteButtonText,
      startDelete,
      performDelete,
      cancelDelete,

      cancelRoute,

      person,
    };
  },
});
