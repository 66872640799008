import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import {
  unitApplicationController,
  UnitApplication,
} from "@/controllers/unitApplications";
import {
  unitApplicationPlacementForDisplayController,
  UnitApplicationPlacementForDisplay,
} from "@/controllers/unitApplicationPlacements";

import UnitApplicationPlacementOptions from "@/components/PersonDashboard/UnitApplicationPlacementOptions.vue";
import UnitApplicationPreferenceDetails from "@/components/PersonDashboard/UnitApplicationPreferenceDetails.vue";

import UaNotes from "@/components/UnitApplicationNote/ListAndForm.vue";
import Tel from "@/components/Tel/Tel.vue";
import MailTo from "@/components/MailTo/MailTo.vue";
import { NamedRts } from "@/router";
import UaStatus from "@/components/UAStatus/UAStatus.vue";
import PinUnitApplication from "@/components/PinUnitApplication/PinUnitApplication.vue";
import {
  unitApplications,
  unitApplicationsLoaded,
  unitApplicationPlacements,
  unitApplicationPlacementsLoaded,
} from "@/controllers/personDashboard";

import {
  prettifyUAStatus,
  prettifyUnitPosition,
  friendlyDate,
  hBadgeForUnitPosition,
} from "@/utilities/displayHelpers";

export default defineComponent({
  name: "personDashboardUnitApplication",
  components: {
    UaNotes,
    UnitApplicationPlacementOptions,
    UnitApplicationPreferenceDetails,
    Tel,
    MailTo,
    UaStatus,
    PinUnitApplication,
  },
  props: {
    unitApplicationId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const unitApplication = computed(() => {
      const tmpUa = unitApplications.value.filter(item => {
        return item.id === Number(props.unitApplicationId);
      });

      return tmpUa[0];
    });

    const applicationIsPlaced = computed(() => {
      return currentUnitApplicationPlacements.value.length > 0;
    });

    const currentUnitApplicationPlacements = computed(() => {
      return unitApplicationPlacements.value.filter(item => {
        return item.unitApplicationId === Number(props.unitApplicationId);
      });
    });

    const dataChanged = () => {
      ctx.emit("data-changed");
    };

    const applicationPlaced = () => {
      dataChanged();
    };

    const uapRouteParams = (p: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personDashboardUnitApplicationPlacement,
        params: {
          personId: p.personId.toString(),
          unitApplicationId: p.unitApplicationId.toString(),
          unitApplicationPlacementId: p.id.toString(),
        },
      };
    };

    return {
      isAdmin,
      unitApplicationsLoaded,
      unitApplication,
      applicationPlaced,
      applicationIsPlaced,
      currentUnitApplicationPlacements,
      unitApplicationPlacements,

      unitApplicationPlacementsLoaded,

      prettifyUAStatus,
      prettifyUnitPosition,
      friendlyDate,
      hBadgeForUnitPosition,

      uapRouteParams,
      dataChanged,
    };
  },
});
