import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiUnitApplicationPlacementWarning {
  id: number;
  unit_name: string;
  unit_id: string;
  start_date: string;
  medium_start_date: string;
  medium_end_date: string;
  end_date: string;
  person_name: string;
  reason_message: string;
  unit_application_postion: string;
}

export interface UnitApplicationPlacementWarning {
  id: number;
  unitName: string;
  unitId: string;
  startDate: string;
  mediumStartDate: string;
  mediumEndDate: string;
  endDate: string;
  personName: string;
  reasonMessage: string;
  unitApplicationPostion: string;
}

export interface ApiUnitApplicationPlacementWarningIndexParams {
  unit_id: number;
}

function apiUnitApplicationWarningToUnitApplicationWarning(
  p: ApiUnitApplicationPlacementWarning,
): UnitApplicationPlacementWarning {
  return {
    id: p.id,
    unitName: p.unit_name,
    unitId: p.unit_id,
    startDate: p.start_date,
    mediumStartDate: p.medium_start_date,
    mediumEndDate: p.medium_end_date,
    endDate: p.end_date,
    personName: p.person_name,
    reasonMessage: p.reason_message,
    unitApplicationPostion: p.unit_application_postion,
  };
}

async function needUnitApplicationPlacementWarnings(
  p?: ApiUnitApplicationPlacementWarningIndexParams,
) {
  return api
    .getUnitApplicationPlacementWarnings(p)
    .then((response: ApiUnitApplicationPlacementWarning[]) => {
      const c = response.map(item => {
        return apiUnitApplicationWarningToUnitApplicationWarning(item);
      });
      return store.dispatch(
        StoreActions.loadUnitApplicationPlacementWarnings,
        c,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const unitApplicationPlacementWarningsController = {
  needUnitApplicationPlacementWarnings,
};
