import api from "@/api";
import store, { StoreActions } from "@/store";

export interface CourtesyCommitteeMember {
  id: number;
  preparatoryClassId: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ApiCourtesyCommitteeMember {
  id: number;
  preparatory_class_id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface ApiCourtesyCommitteeMemberParams {
  preparatory_class_id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface ApiCourtesyCommitteeMemberIndexParams {
  preparatory_class_id: number;
}

function apiCourtesyCommitteeMemberToMember(
  p: ApiCourtesyCommitteeMember,
): CourtesyCommitteeMember {
  return {
    id: p.id,
    preparatoryClassId: p.preparatory_class_id,
    firstName: p.first_name,
    lastName: p.last_name,
    email: p.email,
  };
}

async function createCourtesyCommitteeMember(c: CourtesyCommitteeMember) {
  const cParams: ApiCourtesyCommitteeMemberParams = {
    first_name: c.firstName,
    last_name: c.lastName,
    email: c.email,
    preparatory_class_id: c.preparatoryClassId,
  };

  return api
    .postCourtesyCommitteeMember(cParams)
    .then((response: ApiCourtesyCommitteeMember[]) => {
      const items = response.map(item => {
        return apiCourtesyCommitteeMemberToMember(item);
      });
      return store.dispatch(StoreActions.loadCourtesyCommitteeMembers, items);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needCourtesyCommitteeMembers(pcId?: number) {
  let apiParams: ApiCourtesyCommitteeMemberIndexParams;
  if (pcId) {
    apiParams = {
      preparatory_class_id: pcId,
    };

    return api
      .getCourtesyCommitteeMembers(apiParams)
      .then((response: ApiCourtesyCommitteeMember[]) => {
        const items = response.map(item => {
          return apiCourtesyCommitteeMemberToMember(item);
        });
        return store.dispatch(StoreActions.loadCourtesyCommitteeMembers, items);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

async function deleteCourtesyCommitteeMember(c: CourtesyCommitteeMember) {
  return api
    .deleteCourtesyCommitteeMember(c.id)
    .then((response: ApiCourtesyCommitteeMember[]) => {
      const items = response.map(item => {
        return apiCourtesyCommitteeMemberToMember(item);
      });
      return store.dispatch(StoreActions.loadCourtesyCommitteeMembers, items);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const courtesyCommitteeMemberController = {
  createCourtesyCommitteeMember,
  needCourtesyCommitteeMembers,
  deleteCourtesyCommitteeMember,
};
