import {
  reactive,
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { preparatoryClassStatisticsController } from "@/controllers/preparatoryClassStatistics";
import {
  preparatoryClassApplicationController,
  PreparatoryClassApplication,
} from "@/controllers/preparatoryClassApplications";
import { Person, peopleController } from "@/controllers/people";
import {
  Congregation,
  congregationsController,
} from "@/controllers/congregations";
import { StateProvince, statesController } from "@/controllers/states";
import { friendlyDate } from "@/utilities/displayHelpers";
import PcaNotes from "./Note/List.vue";
import {
  placementPacketController,
  SendEmailMode,
} from "@/controllers/placementPacket";
import { pcaPlacementConfirmationReminderController } from "@/controllers/pcaPlacmentConfirmationReminder";
import { pcaMinisterUpdateController } from "@/controllers/pcaMinisterUpdate";
import { pcaMinisterReminderController } from "@/controllers/pcaMinisterReminder";
import { TOAST_OPTIONS } from "@/constants";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "preparatoryClassApplicationShow",
  components: {
    LoadingIndicator,
    PcaNotes,
  },
  props: {
    preparatoryClassApplicationId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    onMounted(() => {
      let dataPromises = [];
      if (props.preparatoryClassApplicationId) {
        dataPromises = [
          preparatoryClassApplicationController.needPreparatoryClassApplication(
            props.preparatoryClassApplicationId,
          ),
          preparatoryClassStatisticsController.needPreparatoryClassStatistics(),
        ];

        Promise.all(dataPromises)
          .then(_response => {
            // now load the person
            return peopleController.needPerson(
              preparatoryClassApplication.value.personId.toString(),
            );
          })
          .then(_response => {
            // person state and congregation
            return Promise.all([
              congregationsController.needCongregation(
                person.value.congregationId.toString(),
              ),
              statesController.needStateProvince(
                person.value.stateProvinceId.toString(),
              ),
            ]);
          })
          .then(_response => {
            // now everything should be loaded
            dataLoaded.value = true;
          });
      }
    });

    const preparatoryClassApplication = computed(() => {
      return $store.getters
        .preparatoryClassApplication as PreparatoryClassApplication;
    });

    const person = computed(() => {
      return $store.getters.person as Person;
    });

    const congregation = computed(() => {
      return $store.getters.congregation as Congregation;
    });

    const stateProvince = computed(() => {
      return $store.getters.stateProvince as StateProvince;
    });

    const sendMinisterReminder = (sendMode: string) => {
      if (props.preparatoryClassApplicationId) {
        const m =
          sendMode === SendEmailMode.production
            ? SendEmailMode.production
            : SendEmailMode.preview;
        pcaMinisterReminderController
          .sendMinisterReminder({
            application_id: Number(props.preparatoryClassApplicationId),
            mode: m,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendMinisterUpdate = (sendMode: string) => {
      if (props.preparatoryClassApplicationId) {
        const m =
          sendMode === SendEmailMode.production
            ? SendEmailMode.production
            : SendEmailMode.preview;
        pcaMinisterUpdateController
          .sendMinisterUpdate({
            application_id: Number(props.preparatoryClassApplicationId),
            mode: m,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendPlacementPacket = (sendMode: string) => {
      if (props.preparatoryClassApplicationId) {
        const m =
          sendMode === SendEmailMode.production
            ? SendEmailMode.production
            : SendEmailMode.preview;
        placementPacketController
          .sendPlacementPacketPreparatoryClass({
            application_id: Number(props.preparatoryClassApplicationId),
            mode: m,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendPlacementConfirmationReminder = (sendMode: string) => {
      if (props.preparatoryClassApplicationId) {
        const m =
          sendMode === SendEmailMode.production
            ? SendEmailMode.production
            : SendEmailMode.preview;
        pcaPlacementConfirmationReminderController
          .sendConfirmationReminder({
            application_id: Number(props.preparatoryClassApplicationId),
            mode: m,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const getPreparatoryClassPlacementsCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassPlacementsCount(id);
      } else {
        0;
      }
    };

    const editRouteParams = () => {
      if (props.preparatoryClassApplicationId) {
        return {
          name: NamedRts.preparatoryClassApplicationForm,
          params: {
            preparatoryClassApplicationId: props.preparatoryClassApplicationId.toString(),
          },
        };
      } else {
        return {
          name: NamedRts.preparatoryClassApplicationForm,
          params: {
            preparatoryClassApplicationId: 0,
          },
        };
      }
    };

    return {
      congregation,
      dataLoaded,
      editRouteParams,
      friendlyDate,
      getPreparatoryClassPlacementsCount,
      person,
      preparatoryClassApplication,
      sendMinisterReminder,
      sendMinisterUpdate,
      sendPlacementConfirmationReminder,
      sendPlacementPacket,
      stateProvince,
    };
  },
});
