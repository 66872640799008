import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiPeopleListItem {
  id: number;
  label: string;
}

async function needPeopleMales() {
  return api
    .getMales()
    .then((response: ApiPeopleListItem[]) => {
      return store.dispatch(StoreActions.loadPeopleMales, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPeopleFemales() {
  return api
    .getFemales()
    .then((response: ApiPeopleListItem[]) => {
      return store.dispatch(StoreActions.loadPeopleFemales, response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const peopleListController = {
  needPeopleMales,
  needPeopleFemales,
};
