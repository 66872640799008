import api from "@/api";
import { SendEmailMode } from "@/controllers/placementPacket";
import {
  ApiPreparatoryClassCommunicationParams,
  ApiPreparatoryClassApplicationCommunicationParams,
} from "@/controllers/placementPacket";

async function sendMinisterReminder(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return api.sendMinisterReminder(p);
}

export const pcaMinisterReminderController = {
  sendMinisterReminder,
};
