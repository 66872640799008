import { onMounted, defineComponent } from "@vue/composition-api";

import {
  UnitApplicationPlacementStatus,
  unitApplicationPlacementForDisplayController,
  UnitApplicationPlacementForDisplay,
} from "@/controllers/unitApplicationPlacements";

import { prettifyUAPStatus } from "@/utilities/displayHelpers";

import { emptyUnitApplicationPlacementForDisplay } from "@/store/unitApplicationPlacementsForDisplay";

import {
  buildUnitApplicationPlacementStatuses,
  buildUnitApplicationPlacement,
  buildSubmitButton,
} from "@/utilities/formHelpers";

import { UnitApplication } from "@/controllers/unitApplications";

import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import addMonths from "date-fns/addMonths";

import { UnitPositionTermLength } from "@/controllers/unitPositionTermLengths";
import { TOAST_OPTIONS } from "@/constants";

export const enum ReplacementFormEvent {
  cancelForm = "cancelForm",
  applicationPlaced = "applicationPlaced",
}

export default defineComponent({
  name: "replacementForm",
  props: {
    placement: {
      type: Object as () => UnitApplicationPlacementForDisplay,
      required: true,
    },
    unitApplication: {
      type: Object as () => UnitApplication,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;

    const { submitting, submitButtonText } = buildSubmitButton();

    const {
      unitApplicationPlacementStatuses,
      loadUnitApplicationPlacementStatusesIntoForm,
    } = buildUnitApplicationPlacementStatuses();

    const { unitApplicationPlacement: uap } = buildUnitApplicationPlacement();

    const termLength = (): number => {
      const t = $store.getters.unitWithPositionTermLengthLookup(
        props.placement.unitApplicationPosition,
        props.placement.unitId,
      ) as UnitPositionTermLength;

      if (t) {
        return t.termLength;
      } else {
        return 0;
      }
    };

    onMounted(() => {
      loadUnitApplicationPlacementStatusesIntoForm(
        unitApplicationPlacementStatuses,
      );

      uap.unitId = props.placement.unitId;
      uap.unitApplicationId = props.unitApplication.id;
      uap.replacesId = props.placement.id;
      uap.placementStartDate = props.placement.placementEndDate;
      uap.placementEndDate = formatISO(
        addMonths(parseISO(props.placement.placementEndDate), termLength()),
        { representation: "date" },
      );
      uap.status = UnitApplicationPlacementStatus.pending;
    });

    const onSubmit = (evt: any) => {
      evt.preventDefault();
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      unitApplicationPlacementForDisplayController
        .createUnitApplicationPlacement(uap)
        .then(() => {
          submitting.value = false;
          submitButtonText.value = "Submit";

          ctx.root.$bvToast.toast("Successfully placed", TOAST_OPTIONS);

          ctx.emit(ReplacementFormEvent.applicationPlaced);
        });
    };

    const onCancel = (evt: any) => {
      evt.preventDefault();
      ctx.emit(ReplacementFormEvent.cancelForm);
    };

    return {
      onCancel,
      onSubmit,
      submitting,
      submitButtonText,
      uap,
      unitApplicationPlacementStatuses,
      prettifyUAPStatus,
    };
  },
});
