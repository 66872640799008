import {
  ref,
  reactive,
  defineComponent,
  onMounted,
  computed,
} from "@vue/composition-api";
import CpsAutocompleteObj from "@/components/Autocomplete/AutocompleteObj.vue";
import {
  PublicSubmittedPreparatoryClassApplication,
  publicSubmittedPreparatoryClassApplicationController,
} from "@/controllers/public/submittedPreparatoryClassApplication";
import { Gender } from "@/controllers/people";
import {
  Congregation,
  congregationsController,
} from "@/controllers/congregations";

import { statesController, StateProvince } from "@/controllers/states";
import { publicPreparatoryClassesController } from "@/controllers/public/preparatoryClasses";

import {
  PersistentUserData,
  getPersistentUserData,
  setPersistentUserDate,
} from "@/clientStore";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import LoadingIndicator from "@/components/Loading/Loading.vue";

export default defineComponent({
  name: "preparatoryClassApplication",
  components: {
    CpsAutocompleteObj,
    FormErrors,
    LoadingIndicator,
  },
  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const submitting = ref(false);

    // These are refs that are html elements on the form
    // refState is needed to update the state after selecting
    // the congregation
    // the others are needed to focus the correct element
    // when navigation moves to a new section
    const refCongregation: any = ref(null);
    const refFirstChoice: any = ref(null);
    const refFirstName: any = ref(null);
    const refMinisterName: any = ref(null);
    const refState: any = ref(null);

    const showPersonalDetails = ref(true);
    const showCongregationAddress = ref(false);
    const showPreparatoryClassOptions = ref(false);
    const showMinister = ref(false);
    const showSectionNavigation = ref(true);
    const hasErrors = ref(false);
    const errorMessages = ref<string[]>([]);

    // Section Navigation
    // code for navigation between the steps of
    // the form
    const sectionNames = {
      CONGREGATION_ADDRESS: "congregationAddress",
      MINISTER: "minister",
      PERSONAL_DETAILS: "personalDetails",
      PREPARATORY_CLASS_OPTIONS: "preparatoryClassOptions",
    };

    const showAllSections = (): void => {
      showPersonalDetails.value = true;
      showCongregationAddress.value = true;
      showPreparatoryClassOptions.value = true;
      showMinister.value = true;
      showSectionNavigation.value = false;
    };

    const sectionNavigate = (sectionName: string): void => {
      if (sectionName === sectionNames.PERSONAL_DETAILS) {
        showPersonalDetails.value = true;
        showCongregationAddress.value = false;
        showPreparatoryClassOptions.value = false;
        showMinister.value = false;
        ctx.root.$nextTick(() => {
          refFirstName.value.focus();
        });
      } else if (sectionName === sectionNames.CONGREGATION_ADDRESS) {
        showPersonalDetails.value = false;
        showCongregationAddress.value = true;
        showPreparatoryClassOptions.value = false;
        showMinister.value = false;
        ctx.root.$nextTick(() => {
          refCongregation.value.setFocusParent();
        });
      } else if (sectionName === sectionNames.PREPARATORY_CLASS_OPTIONS) {
        showPersonalDetails.value = false;
        showCongregationAddress.value = false;
        showPreparatoryClassOptions.value = true;
        showMinister.value = false;
        ctx.root.$nextTick(() => {
          refFirstChoice.value.setFocusParent();
        });
      } else {
        showPersonalDetails.value = false;
        showCongregationAddress.value = false;
        showPreparatoryClassOptions.value = false;
        showMinister.value = true;
        ctx.root.$nextTick(() => {
          refMinisterName.value.focus();
        });
      }
    };

    // End Section Navigation

    const submitApplication = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];

      submitting.value = true;

      publicSubmittedPreparatoryClassApplicationController
        .create(pcApplication)
        .then(_response => {
          setPersistentUserDate({
            city: pcApplication.city,
            congregationId: pcApplication.congregationId,
            ministerEmail: pcApplication.ministerEmail,
            ministerName: pcApplication.ministerName,
            ministerPhoneNumber: pcApplication.ministerPhoneNumber,
            stateId: pcApplication.stateProvinceId,
            zipCode: pcApplication.zipCode,
          });

          submitting.value = false;
          ctx.root.$bvToast.toast(
            `Preparatory class application for ${pcApplication.firstName} successfully submitted`,
            TOAST_OPTIONS,
          );
          // navigate back to main menu
          ctx.root.$router.push({
            name: NamedRts.landingPage,
          });
        })
        .catch(errors => {
          submitting.value = false;
          // the errors is an array of strings
          // each one should be displayed at the top of the form
          hasErrors.value = true;
          errorMessages.value = errors;
          showAllSections();
        });
    };

    const updateCongregation = (evt: AutocompleteResult): void => {
      // look up the city here
      const t: Congregation = congregationById(evt.id);
      pcApplication.congregationId = t.id;

      // set up the city
      if (pcApplication.city === "") {
        pcApplication.city = t.city;
      }
      // load the state
      const s = stateProvinceById(t.stateId);

      if (pcApplication.stateProvinceId === 0) {
        pcApplication.stateProvinceId = s.id;
        refState.value.setResultParent({
          id: s.id,
          label: s.name,
        });
      }
    };

    const updateChoice = (
      evt: AutocompleteResult,
      choiceNumber: number,
    ): void => {
      if (choiceNumber === 1) {
        pcApplication.firstChoiceId = evt.id;
      } else if (choiceNumber === 2) {
        pcApplication.secondChoiceId = evt.id;
      } else if (choiceNumber === 3) {
        pcApplication.thirdChoiceId = evt.id;
      } else if (choiceNumber === 4) {
        pcApplication.fourthChoiceId = evt.id;
      }
    };

    const updateStateProvince = (evt: AutocompleteResult): void => {
      pcApplication.stateProvinceId = evt.id;
    };

    onMounted(() => {
      // call the controllers here...
      Promise.all([
        congregationsController.needCongregations(),
        statesController.needStatesProvinces(),
        publicPreparatoryClassesController.needPreparatoryClasses("boys"),
      ]).then((_response: any) => {
        dataLoaded.value = true;

        // load the data from the client store
        const pud: PersistentUserData = getPersistentUserData();
        pcApplication.ministerName = pud.ministerName;
        pcApplication.ministerEmail = pud.ministerEmail;
        pcApplication.ministerPhoneNumber = pud.ministerPhoneNumber;
        pcApplication.zipCode = pud.zipCode;
        pcApplication.city = pud.city;

        // lookup the congregation
        const t: Congregation = congregationById(pud.congregationId);
        if (t) {
          pcApplication.congregationId = t.id;
          ctx.root.$nextTick(() => {
            refCongregation.value.setResultParent({
              id: t.id,
              label: t.name,
            });
          });
        }

        const s: StateProvince = stateProvinceById(pud.stateId);
        if (s) {
          pcApplication.stateProvinceId = s.id;
          ctx.root.$nextTick(() => {
            refState.value.setResultParent({
              id: s.id,
              label: s.name,
            });
          });
        }

        ctx.root.$nextTick(() => {
          refFirstName.value.focus();
        });
      });
    });

    const congregationItems = computed(() => {
      return $store.getters.congregationsForAutocomplete;
    });

    const congregationById = (id: number) => {
      return $store.getters.congregationById(id);
    };

    const preparatoryClassItems = computed(() => {
      return $store.getters.preparatoryClassesForAutocomplete;
    });

    const preparatoryClassById = (id: number) => {
      return $store.getters.preparatoryClassById(id);
    };

    const stateProvinceItems = computed(() => {
      return $store.getters.statesProvincesForAutocomplete;
    });

    const stateProvinceById = (id: number) => {
      return $store.getters.stateProvinceById(id);
    };

    const congregationAC = reactive({
      label: "",
      id: 0,
    });

    const firstChoiceAC = reactive({
      label: "",
      id: 0,
    });

    const secondChoiceAC = reactive({
      label: "",
      id: 0,
    });

    const thirdChoiceAC = reactive({
      label: "",
      id: 0,
    });

    const fourthChoiceAC = reactive({
      label: "",
      id: 0,
    });

    const stateProvinceAC = reactive({
      label: "",
      id: 0,
    });

    const pcApplication = reactive<PublicSubmittedPreparatoryClassApplication>({
      firstName: "",
      middleName: "",
      lastName: "",
      gender: Gender.male,
      phoneNumber: "",
      emailAddress: "",
      fatherFirstName: "",
      motherFirstName: "",

      acceptsCpsPolicy: false,
      ministerConfirmation: false,

      ministerName: "",
      ministerEmail: "",
      ministerPhoneNumber: "",
      ministerNote: "",
      travelPlans: "",
      birthDate: "",
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      congregationId: 0,
      stateProvinceId: 0,
      firstChoiceId: 0,
      secondChoiceId: 0,
      thirdChoiceId: 0,
      fourthChoiceId: 0,
    });

    return {
      congregationAC,
      congregationItems,
      dataLoaded,
      errorMessages,
      firstChoiceAC,
      fourthChoiceAC,
      hasErrors,
      pcApplication,
      preparatoryClassById,
      preparatoryClassItems,
      refCongregation,
      refFirstChoice,
      refFirstName,
      refMinisterName,
      refState,
      secondChoiceAC,
      sectionNames,
      sectionNavigate,
      showCongregationAddress,
      showMinister,
      showPersonalDetails,
      showPreparatoryClassOptions,
      showSectionNavigation,
      stateProvinceAC,
      stateProvinceItems,
      submitApplication,
      submitting,
      thirdChoiceAC,
      updateChoice,
      updateCongregation,
      updateStateProvince,
    };
  },
});
