import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "loading",
  props: {
    loadingMessage: String,
    dataLoaded: Boolean,
  },

  setup(_props) {
    return {};
  },
});
