import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import CpsAutocompleteObj from "../Autocomplete/AutocompleteObj.vue";
import {
  Congregation,
  congregationsController,
} from "@/controllers/congregations";
import {
  PreparatoryClass,
  preparatoryClassesController,
} from "@/controllers/preparatoryClasses";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import { FormModes } from "@/main";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { UserClassification } from "@/store/user";
import { animateOnStateChange } from "@/utilities/displayHelpers";
import addDays from "date-fns/addDays";
import parseISO from "date-fns/parseISO";
// import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import { isTuesday } from "date-fns";
// import isTuesday from "date-fns/isTuesday";

function getOrdinal(n: number): string {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export default defineComponent({
  name: "preparatoryClassForm",
  components: {
    FormErrors,
    LoadingIndicator,
    CpsAutocompleteObj,
  },
  props: {
    preparatoryClassId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);
    const deleting = ref(false);

    const deleteButtonText = ref("Delete");
    const submitButtonText = ref("Submit");

    const refCongregation: any = ref(null);

    const cancelRoute = {
      name: NamedRts.preparatoryClassList,
    };

    const fd = reactive({
      fullName: "",
      id: 0,
      name: "",
      number: 0,
      description: "",
      startDate: "",
      endDate: "",
      congregationId: 0,
      congregation: {
        id: 0,
        label: "",
      },
    });

    const formMode = ref(FormModes.edit);

    const numberChanged = () => {
      fd.name = `${getOrdinal(fd.number)} Preparatory Class`;
      const el = document.getElementById("p-class-name-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    onMounted(() => {
      const neededFormData = [congregationsController.needCongregations()];

      if (props.preparatoryClassId) {
        if (props.preparatoryClassId === "0") {
          // new form, no data needs to be retrieved
          // for the existing item
          // get all the classes in order to get the last one
          // for the ordinal number
          neededFormData.push(
            preparatoryClassesController.needPreparatoryClasses(),
          );
          Promise.all(neededFormData).then(_response => {
            formMode.value = FormModes.new;
            dataLoaded.value = true;
            const lastClass =
              preparatoryClasses.value[preparatoryClasses.value.length - 1];
            if (lastClass) {
              fd.number = lastClass.number + 1;
              const el = document.getElementById("p-class-number-container");
              if (el) {
                animateOnStateChange(el);
              }
              numberChanged();
            }
          });
        } else {
          formMode.value = FormModes.edit;
          neededFormData.push(
            preparatoryClassesController.needPreparatoryClass(
              props.preparatoryClassId,
            ),
          );
          Promise.all(neededFormData).then((_response: any) => {
            dataLoaded.value = true;
            fd.number = storePreparatoryClass.value.number;
            fd.id = storePreparatoryClass.value.id;
            fd.name = storePreparatoryClass.value.name;
            fd.description = storePreparatoryClass.value.description;
            fd.startDate = storePreparatoryClass.value.startDate;
            fd.endDate = storePreparatoryClass.value.endDate;
            fd.congregationId = storePreparatoryClass.value.congregationId;
            // lookup the congregation
            const t: Congregation = congregationById(fd.congregationId);
            if (t) {
              fd.congregation = {
                id: t.id,
                label: t.name,
              };
              ctx.root.$nextTick(() => {
                refCongregation.value.setResultParent({
                  id: t.id,
                  label: t.name,
                });
              });
            }
          });
        }
      } else {
        dataLoaded.value = true;
      }
    });

    const storePreparatoryClass = computed(() => {
      return $store.getters.preparatoryClass as PreparatoryClass;
    });

    const userClassification = computed(() => {
      return $store.getters.userClassification as UserClassification;
    });

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let submitAction;
      let toastMessage = "";

      if (formMode.value === FormModes.new) {
        toastMessage = `${fd.name} successfully created.`;
        submitAction = preparatoryClassesController.createPreparatoryClass;
      } else {
        toastMessage = `${fd.name} successfully updated.`;
        submitAction = preparatoryClassesController.updatePreparatoryClass;
      }

      submitAction({
        fullName: "",
        classification: userClassification.value,
        congregationCity: "",
        congregationId: fd.congregationId,
        congregationName: "",
        congregationStateId: 0,
        congregationStateName: "",
        description: fd.description,
        endDate: fd.endDate,
        id: fd.id,
        name: fd.name,
        number: fd.number,
        preparatoryClassSeasonId: 0,
        startDate: fd.startDate,
      })
        .then(_response => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          ctx.root.$bvToast.toast(toastMessage, TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.preparatoryClassList,
          });
        })
        .catch(errors => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        // it's a new item, just cancel
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      preparatoryClassesController
        .deletePreparatoryClass({
          fullName: "",
          classification: userClassification.value,
          congregationCity: "",
          congregationId: fd.congregationId,
          congregationName: "",
          congregationStateId: 0,
          congregationStateName: "",
          description: fd.description,
          endDate: fd.endDate,
          id: fd.id,
          name: fd.name,
          number: fd.number,
          preparatoryClassSeasonId: 0,
          startDate: fd.startDate,
        })
        .then(_response => {
          cancelDelete();
          ctx.root.$bvToast.toast(fd.name + " deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          deleting.value = false;
          deleteButtonText.value = "Delete";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const congregationById = (id: number) => {
      return $store.getters.congregationById(id) as Congregation;
    };

    const congregationsForAutocomplete = computed(() => {
      return $store.getters.congregationsForAutocomplete;
    });

    const preparatoryClasses = computed(() => {
      return $store.getters.preparatoryClassesForTable;
    });

    const congregationAC = reactive({
      label: "",
      value: 0,
    });

    const updateCongregation = (evt: AutocompleteResult): void => {
      const t: Congregation = congregationById(evt.id);
      fd.congregationId = t.id;
    };

    const startDateChanged = () => {
      const daysToAdd = 10;
      const formatPattern = "yyyy-MM-dd";
      try {
        const dt = parseISO(fd.startDate);
        // if the date is a valid date, then set the end date
        if (dt) {
          fd.endDate = format(addDays(dt, daysToAdd), formatPattern);
          const el = document.getElementById("end-date-container");
          if (el) {
            animateOnStateChange(el);
          }
        }
      } catch (e) {
        // error in parsing the date
        // it will be retried later
      }
    };

    const startDateMessage = () => {
      if (fd.startDate === "") {
        return "";
      } else {
        if (!isTuesday(parseISO(fd.startDate))) {
          return "Start date is not a Tuesday.";
        } else {
          return "";
        }
      }
    };

    return {
      cancelDelete,
      cancelRoute,
      congregationAC,
      congregationsForAutocomplete,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      fd,
      hasErrors,
      numberChanged,
      performDelete,
      refCongregation,
      startDateChanged,
      startDateMessage,
      startDelete,
      storePreparatoryClass,
      submitButtonText,
      submitForm,
      submitting,
      updateCongregation,
    };
  },
});
