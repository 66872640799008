import { CourtesyCommitteeMember } from "@/controllers/courtesyCommitteeMembers";

interface CourtesyCommitteeMembersState {
  courtesyCommitteeMembers: CourtesyCommitteeMember[];
}
const state: CourtesyCommitteeMembersState = {
  courtesyCommitteeMembers: [],
};

const mutations = {
  LOAD_COURTESY_COMMITTEE_MEMBERS(
    state: CourtesyCommitteeMembersState,
    payload: CourtesyCommitteeMember[],
  ) {
    state.courtesyCommitteeMembers = payload;
  },
};

const actions = {
  loadCourtesyCommitteeMembers(context: any, items: CourtesyCommitteeMember[]) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_COURTESY_COMMITTEE_MEMBERS", items);
      resolve(items);
    });
  },
};

const getters = {
  courtesyCommitteeMembers: (state: CourtesyCommitteeMembersState) => {
    return state.courtesyCommitteeMembers;
  },
};

const courtesyCommitteeMembersModule = {
  state,
  mutations,
  actions,
  getters,
};

export default courtesyCommitteeMembersModule;
