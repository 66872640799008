import {
  ref,
  reactive,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import {
  peopleController,
  PhoneNumberDescription,
  Gender,
  Person,
} from "@/controllers/people";
import { UserClassification } from "@/store/user";
import CpsAutocomplete from "@/components/Autocomplete/Autocomplete.vue";
import CpsAutocompleteObj from "@/components/Autocomplete/AutocompleteObj.vue";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { peopleNamesController } from "@/controllers/peopleNames";
import { statesController } from "@/controllers/states";
import { countriesController } from "@/controllers/countries";
import { congregationsController } from "@/controllers/congregations";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import SimilarPeople from "@/components/SimilarPeople/SimilarPeople.vue";
import { NamedRts } from "@/router";

import {
  prettifyQualification,
  animateOnStateChange,
} from "@/utilities/displayHelpers";
import { TOAST_OPTIONS } from "@/constants";

import {
  addEmailAddress,
  addPhoneNumber,
  removeBlankPhoneNumbers,
  showSimilarPeople,
  buildCitizenships,
  buildPerson,
  nameAutocompleteData,
  congregationAutocomplete,
  stateProvinceAutocomplete,
  buildPersonQualifications,
  buildPersonCitizenshipIds,
  buildSubmitButton,
  buildDeleteButton,
  lookupCityZipForPerson,
} from "@/utilities/formHelpers";

export default defineComponent({
  name: "PersonFormPartial",
  components: {
    CpsAutocomplete,
    CpsAutocompleteObj,
    FormErrors,
    LoadingIndicator,
    SimilarPeople,
  },
  props: {
    personId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const similarPeopleBell = ref(false);
    const similarPeople = ref([]);

    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const refState: any = ref(null);
    const refBirthState: any = ref(null);
    const refCongregation: any = ref(null);

    const { submitting, submitButtonText } = buildSubmitButton();
    const { deleting, deleteButtonText } = buildDeleteButton();

    const cancelRoute = () => {
      if (props.personId === "0") {
        return {
          name: NamedRts.recruiterWorkInProgress,
        };
      } else {
        return {
          name: NamedRts.personDashboardPersonShow,
          params: {
            personId: props.personId,
          },
        };
      }
    };

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const apiPerson = computed(() => {
      return $store.getters.person as Person;
    });

    const userClassification = computed(() => {
      return $store.getters.userClassification as UserClassification;
    });

    const personCongregation = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const { person, loadPersonIntoForm, personAge } = buildPerson();

    const {
      personQualifications,
      loadPersonQualificationsIntoForm,
    } = buildPersonQualifications();
    const { pCitizenshipIds } = buildPersonCitizenshipIds();

    const { firstNames, middleNames, lastNames } = nameAutocompleteData($store);
    const {
      congregationsForAutocomplete,
      loadCongregationAutocomplete,
      updateCongregationForPerson,
    } = congregationAutocomplete($store);
    const {
      statesProvincesForAutocomplete,
      loadStateProvinceAutocomplete,
    } = stateProvinceAutocomplete($store);

    const personState = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const personBirthState = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const countriesForTable = computed(() => {
      return $store.getters.countriesForTable;
    });

    const updateFirstName = (evt: string) => {
      person.firstName = evt;
    };

    const updateMiddleName = (evt: string) => {
      person.middleName = evt;
    };

    const localPersonAge = () => {
      return personAge(person).value;
    };

    const localLoadPersonIntoForm = () => {
      loadPersonIntoForm(apiPerson.value, person);
      const tempIds = person.citizenships.map(item => {
        return item.countryId;
      });

      pCitizenshipIds.value = Array.from(new Set(tempIds));

      dataLoaded.value = true;

      // some of the references need to come into view
      ctx.root.$nextTick(() => {
        loadStateProvinceAutocomplete(refState, person.stateProvinceId);
        loadStateProvinceAutocomplete(refBirthState, person.birthStateId);
        loadCongregationAutocomplete(refCongregation, person.congregationId);
      });
    };

    const loadPerson = (id: number) => {
      // this function loads an existing similar person into the form
      person.id = id;
      peopleController.needPerson(id.toString()).then(() => {
        localLoadPersonIntoForm();
      });
      similarPeople.value = [];
      similarPeopleBell.value = false;
      const el = document.getElementById("person-data-container");
      if (el) {
        animateOnStateChange(el);
      }
    };

    const onSubmit = (evt: any) => {
      evt.preventDefault();

      // reset some things
      hasErrors.value = false;
      errorMessages.value = [];

      // set some things
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      // clean out the phone numbers that are blank
      person.phoneNumbers = removeBlankPhoneNumbers(person.phoneNumbers);

      person.citizenships = buildCitizenships(
        person.citizenships,
        pCitizenshipIds.value,
      );

      let apiResponse;
      if (person.id === 0) {
        apiResponse = peopleController.createPerson(person);
      } else {
        apiResponse = peopleController.updatePerson(person);
      }

      apiResponse
        .then(() => {
          ctx.root.$bvToast.toast(
            "Person was successfully saved.",
            TOAST_OPTIONS,
          );
          ctx.root.$router.push({
            name: NamedRts.personDashboardPersonShow,
            params: {
              personId: apiPerson.value.id.toString(),
            },
          });
        })
        .catch(error => {
          errorMessages.value = error;
          hasErrors.value = true;
        })
        .finally(() => {
          // reset some values
          submitting.value = false;
          submitButtonText.value = "Submit";
        });
    };

    const updateCongregation = (evt: AutocompleteResult): void => {
      updateCongregationForPerson(evt, person, personCongregation, refState);
    };

    const updateState = (evt: AutocompleteResult): void => {
      person.stateProvinceId = evt.id;
      personState.id = evt.id;
      personState.label = evt.label;
    };

    const updateBirthState = (evt: AutocompleteResult): void => {
      person.birthStateId = evt.id;
    };

    const localShowSimilarPeople = () => {
      showSimilarPeople(
        person.id,
        person.firstName,
        person.birthDate,
        person.gender,
      ).then(response => {
        similarPeople.value = response;
        if (similarPeople.value.length > 0) {
          similarPeopleBell.value = true;
        }
      });
    };

    const lookupCityZip = () => {
      lookupCityZipForPerson(person);
    };

    onMounted(() => {
      const dataPromises = [
        peopleNamesController.needLastNames(),
        peopleNamesController.needFirstNames(),
        peopleNamesController.needMiddleNames(),
        statesController.needStatesProvinces(),
        countriesController.needCountries(),
        congregationsController.needCongregations(),
      ];

      if (props.personId !== "0") {
        dataPromises.push(peopleController.needPerson(props.personId));
      }

      Promise.all(dataPromises).then(() => {
        loadPersonQualificationsIntoForm(personQualifications);

        if (props.personId === "0") {
          // this is for a new form with no data prefilled,
          // it is for manual data entry

          addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.cell);
          addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.home);
          addPhoneNumber(person.phoneNumbers, PhoneNumberDescription.fax);

          addEmailAddress(person.emailAddresses);

          // set the gender based on the classification
          if (userClassification.value === UserClassification.girls) {
            person.gender = Gender.female;
          } else {
            person.gender = Gender.male;
          }

          dataLoaded.value = true;
        } else {
          localLoadPersonIntoForm();
        }
      });
    });

    const startDelete = () => {
      deleting.value = true;
      if (props.personId === "0") {
        ctx.root.$router.push(cancelRoute());
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      peopleController
        .deletePerson(person)
        .then(() => {
          cancelDelete();
          ctx.root.$bvToast.toast("Person deleted", TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.recruiterWorkInProgress,
          });
        })
        .catch(errors => {
          cancelDelete();
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    return {
      isAdmin,
      dataLoaded,

      person,
      firstNames,
      middleNames,
      lastNames,
      localPersonAge,

      personCongregation,
      personState,
      personBirthState,

      personQualifications,
      pCitizenshipIds,

      similarPeopleBell,
      similarPeople,

      congregationsForAutocomplete,
      statesProvincesForAutocomplete,

      countriesForTable,

      prettifyQualification,

      submitting,
      submitButtonText,
      onSubmit,

      refBirthState,
      refCongregation,
      refState,

      updateState,
      updateCongregation,
      updateBirthState,
      updateFirstName,
      updateMiddleName,

      hasErrors,
      errorMessages,

      loadPerson,

      localShowSimilarPeople,
      lookupCityZip,

      cancelRoute,
      deleting,
      deleteButtonText,
      startDelete,
      performDelete,
      cancelDelete,
    };
  },
});
