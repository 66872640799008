import api from "@/api";
import { ApiPreparatoryClassCommunicationParams } from "@/controllers/placementPacket";

async function sendUpdate(p: ApiPreparatoryClassCommunicationParams) {
  return api.sendCommitteeUpdate(p);
}

async function sendClassProtocol(p: ApiPreparatoryClassCommunicationParams) {
  return api.sendProtocolLetter(p);
}

async function sendCommitteeProtocol(
  p: ApiPreparatoryClassCommunicationParams,
) {
  return api.sendCommitteeProtocolLetter(p);
}

export const pcCommitteeEmailController = {
  sendUpdate,
  sendClassProtocol,
  sendCommitteeProtocol,
};
