import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { SMMode } from "@/store/sidebarMenu";
import { StoreActions } from "@/store";
import {
  unitApplicationController,
  UnitApplication,
} from "@/controllers/unitApplications";
import {
  unitApplicationPlacementForDisplayController,
  UAPLevel,
  UnitApplicationPlacementForDisplay,
} from "@/controllers/unitApplicationPlacements";
import { Person } from "@/controllers/people";
import { NamedRts } from "@/router";

export const enum PersonUnitDataItem {
  unitApplication = "unitApplication",
  person = "person",
  unitApplicationPlacement = "unitApplicationPlacement",
}

export default defineComponent({
  name: "personUnitData",
  props: {
    itemId: {
      type: Number,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const routeParams = {
      workInProgress: {
        name: NamedRts.recruiterWorkInProgress,
      },
    };

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const unitApplication = computed(() => {
      return $store.getters.unitApplication as UnitApplication;
    });

    const unitApplicationPlacement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const unitApplications = computed(() => {
      return $store.getters.unitApplicationsForTable as UnitApplication[];
    });

    const person = computed(() => {
      return $store.getters.person as Person;
    });

    // sidebar menu code
    const sidebarMenuClassObject = computed(() => {
      return $store.getters.sidebarMenuClassObject;
    });

    const dataColClassObject = computed(() => {
      return $store.getters.dataColClassObject;
    });

    const showMenu = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.menu);
    };

    const showData = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.data);
    };

    onMounted(() => {
      if (props.itemType === PersonUnitDataItem.unitApplication) {
        Promise.all([
          unitApplicationController.needUnitApplication(
            props.itemId.toString(),
          ),
        ]).then(() => {
          // redirect to the person page
          ctx.root.$router.push({
            name: NamedRts.personDashboardUnitApplication,
            params: {
              personId: unitApplication.value.personId.toString(),
              unitApplicationId: unitApplication.value.id.toString(),
            },
          });
        });
      } else if (
        props.itemType === PersonUnitDataItem.unitApplicationPlacement
      ) {
        Promise.all([
          unitApplicationPlacementForDisplayController.needUnitApplicationPlacementForDisplay(
            props.itemId.toString(),
            UAPLevel.placement,
          ),
        ]).then(() => {
          // redirect to the person page
          ctx.root.$router.push({
            name: NamedRts.personDashboardUnitApplicationPlacement,
            params: {
              personId: unitApplicationPlacement.value.personId.toString(),
              unitApplicationId: unitApplicationPlacement.value.unitApplicationId.toString(),
              unitApplicationPlacementId: unitApplicationPlacement.value.id.toString(),
            },
          });
        });
      } else {
        // this should be the person id
        // just reroute to the page
        ctx.root.$router.push({
          name: NamedRts.personDashboardPersonShow,
          params: {
            personId: props.itemId.toString(),
          },
        });
      }
    });

    return {
      isAdmin,
      dataLoaded,
      showMenu,
      showData,
      sidebarMenuClassObject,
      dataColClassObject,
      routeParams,
      person,
      unitApplications,
    };
  },
});
