import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";

import {
  pinnedUnitApplicationPlacementController,
  PinnedUnitApplicationPlacementPartial,
} from "@/controllers/pinnedUnitApplicationPlacements";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "pinUnitApplicationPlacement",
  props: {
    unitApplicationPlacementId: {
      type: Number,
      required: true,
    },
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    onMounted(() => {
      Promise.all([
        pinnedUnitApplicationPlacementController.needPinnedUnitApplicationPlacement(
          props.unitApplicationPlacementId.toString(),
        ),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    const isApplicationPinned = (): boolean => {
      const item = $store.getters
        .pinnedUnitApplicationPlacement as PinnedUnitApplicationPlacementPartial;
      return (
        item.unitApplicationPlacementId === props.unitApplicationPlacementId
      );
    };

    const pinnedApplication = computed(() => {
      return $store.getters
        .pinnedUnitApplicationPlacement as PinnedUnitApplicationPlacementPartial;
    });

    const buttonText = computed(() => {
      if (isApplicationPinned()) {
        return "Unpin application placement";
      } else {
        return "Pin application placement";
      }
    });

    const toggleApplication = () => {
      if (isApplicationPinned()) {
        pinnedUnitApplicationPlacementController
          .deletePinnedUnitApplicationPlacement(pinnedApplication.value)
          .then(() => {
            ctx.root.$bvToast.toast(
              "Application placement Unpinned.",
              TOAST_OPTIONS,
            );
          });
      } else {
        pinnedUnitApplicationPlacementController
          .createPinnedUnitApplicationPlacement({
            id: 0,
            unitApplicationPlacementId: props.unitApplicationPlacementId,
          })
          .then(() => {
            ctx.root.$bvToast.toast(
              "Application Placement Pinned.",
              TOAST_OPTIONS,
            );
          });
      }
    };

    return {
      dataLoaded,
      buttonText,
      toggleApplication,
    };
  },
});
