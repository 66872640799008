import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { preparatoryClassSeasonsController } from "@/controllers/preparatoryClassSeasons";
import { friendlyDate } from "@/utilities/displayHelpers";
import { NamedRts } from "@/router";
import { userSettingsController } from "@/controllers/userSettings";
import { UserSettingName, UserSetting } from "@/store/userSettings";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "preparatoryClassList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "description",
        sortable: true,
      },
      {
        key: "friendlyStartDate",
        sortable: true,
      },
      {
        key: "friendlyEndDate",
        sortable: true,
      },
      {
        key: "seasonIndicator",
        label: "Current",
        sortable: false,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin) {
      fieldsForTable = [
        {
          key: "description",
          sortable: true,
        },
        {
          key: "friendlyStartDate",
          sortable: true,
        },
        {
          key: "friendlyEndDate",
          sortable: true,
        },
        {
          key: "seasonIndicator",
          label: "Current",
          sortable: false,
        },
      ];
    }

    const preparatoryClassSeasonsForTable = computed(() => {
      return $store.getters.preparatoryClassSeasonsForTable;
    });

    const editRoute = (id: number) => {
      return {
        name: NamedRts.preparatoryClassSeasonForm,
        params: {
          preparatoryClassSeasonId: id.toString(),
        },
      };
    };

    onMounted(() => {
      Promise.all([
        preparatoryClassSeasonsController.needPreparatoryClassSeasons(),
        userSettingsController.needUserSetting(
          UserSettingName.currentPreparatoryClassSeason,
        ),
      ])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    const isCurrentSeason = (id: number) => {
      if (currentPreparatoryClassSeason.value.integerValue === id) {
        return true;
      } else {
        return false;
      }
    };

    const setCurrentSeason = (id: number) => {
      userSettingsController
        .createUserSetting({
          internalName: UserSettingName.currentPreparatoryClassSeason,
          integerValue: id,
          stringValue: "",
        })
        .then(_response => {
          ctx.root.$bvToast.toast("Current season updated", TOAST_OPTIONS);
        });
    };

    const currentPreparatoryClassSeason = computed(() => {
      return $store.getters.currentPreparatoryClassSeason as UserSetting;
    });

    return {
      setCurrentSeason,
      isCurrentSeason,
      currentPreparatoryClassSeason,
      dataLoaded,
      editRoute,
      fieldsForTable,
      friendlyDate,
      isAdmin,
      preparatoryClassSeasonsForTable,
    };
  },
});
