import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import CpsAutocompleteObj from "../Autocomplete/AutocompleteObj.vue";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import { NamedRts } from "@/router";

import {
  stateProvinceAutocomplete,
  buildSubmitButton,
  buildDeleteButton,
} from "@/utilities/formHelpers";
import {
  UnitClassification,
  UnitWorkType,
  UnitStatus,
  unitsController,
} from "@/controllers/units";
import { FormModes } from "@/main";
import { statesController } from "@/controllers/states";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "unitForm",
  components: {
    FormErrors,
    LoadingIndicator,
    CpsAutocompleteObj,
  },
  props: {
    unitId: {
      type: String,
      required: true,
      default: "0",
    },
  },
  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);

    const { submitting, submitButtonText } = buildSubmitButton();
    const { deleting, deleteButtonText } = buildDeleteButton();

    const refState: any = ref(null);
    const unitState = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const cancelRoute = {
      name: NamedRts.unitList,
    };

    const fd = reactive({
      address2: "",
      address: "",
      city: "",
      classification: UnitClassification.boys,
      endDate: "",
      id: 0,
      name: "",
      startDate: "",
      stateId: 0,
      stateName: "",
      status: UnitStatus.active,
      workType: UnitWorkType.rebuilding,
      zip: "",
    });

    const formMode = ref(FormModes.edit);

    const storeUnit = computed(() => {
      return $store.getters.unit;
    });

    const {
      statesProvincesForAutocomplete,
      loadStateProvinceAutocomplete,
    } = stateProvinceAutocomplete($store);

    onMounted(() => {
      const neededFormData = [statesController.needStatesProvinces()];

      if (props.unitId === "0") {
        // new form, no data needs to be retrieved
        // for the existing item
        Promise.all(neededFormData).then(() => {
          formMode.value = FormModes.new;
          dataLoaded.value = true;
        });
      } else {
        formMode.value = FormModes.edit;
        neededFormData.push(unitsController.needUnit(props.unitId));

        Promise.all(neededFormData).then(() => {
          fd.id = storeUnit.value.id;
          fd.name = storeUnit.value.name;
          fd.address2 = storeUnit.value.address;
          fd.address = storeUnit.value.address2;
          fd.city = storeUnit.value.city;
          fd.classification = storeUnit.value.classification;
          fd.endDate = storeUnit.value.endDate;
          fd.startDate = storeUnit.value.startDate;
          fd.stateId = storeUnit.value.stateId;
          fd.stateName = storeUnit.value.stateName;
          fd.status = storeUnit.value.status;
          fd.workType = storeUnit.value.workType;
          fd.zip = storeUnit.value.zip;
          dataLoaded.value = true;

          // lookup the state
          ctx.root.$nextTick(() => {
            loadStateProvinceAutocomplete(refState, fd.stateId);
          });
        });
      }
    });

    const updateState = (evt: AutocompleteResult): void => {
      fd.stateId = evt.id;
      fd.stateName = evt.label;
      unitState.id = evt.id;
      unitState.label = evt.label;
    };

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let submitAction;
      let toastMessage = "";

      if (formMode.value === FormModes.new) {
        toastMessage = `${fd.name} successfully created.`;
        submitAction = unitsController.createUnit;
      } else {
        toastMessage = `${fd.name} successfully updated.`;
        submitAction = unitsController.updateUnit;
      }

      submitAction(fd)
        .then(_response => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          ctx.root.$bvToast.toast(toastMessage, TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.unitList,
          });
        })
        .catch(errors => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const startDelete = () => {
      deleting.value = true;
      if (props.unitId === "0") {
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      unitsController
        .deleteUnit(fd)
        .then(() => {
          cancelDelete();
          ctx.root.$bvToast.toast("Unit deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          cancelDelete();
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    return {
      dataLoaded,
      cancelRoute,
      errorMessages,
      hasErrors,
      storeUnit,
      fd,

      submitButtonText,
      submitForm,
      submitting,

      performDelete,
      startDelete,
      deleteButtonText,
      deleting,
      cancelDelete,

      updateState,
      refState,
      unitState,
      statesProvincesForAutocomplete,
    };
  },
});
