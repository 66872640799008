import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { submittedUnitApplicationsController } from "@/controllers/submittedUnitApplications";
import { friendlyDate } from "@/utilities/displayHelpers";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "submittedUnitApplicationsList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const submittedUnitApplicationsForTable = computed(() => {
      return $store.getters.submittedUnitApplicationsForTable;
    });

    onMounted(() => {
      Promise.all([
        submittedUnitApplicationsController.needSubmittedUnitApplications(),
      ])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {
          dataLoaded.value = true;
        });
    });

    const computedRouteParams = (id: number) => {
      return {
        name: NamedRts.personUnitApplicationForm,
        params: {
          unitApplicationId: "0",
        },
        query: {
          submittedUnitApplicationId: id.toString(),
        },
      };
    };

    return {
      computedRouteParams,
      friendlyDate,
      dataLoaded,
      submittedUnitApplicationsForTable,
      isAdmin,
    };
  },
});
