import { StateProvince } from "@/controllers/states";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

interface StateProvinceLookup {
  [key: number]: StateProvince;
}

interface StateProvinceState {
  stateProvince: StateProvince;
  statesProvincesForTable: StateProvince[];
  statesProvincesForAutocomplete: AutocompleteResult[];
  statesProvinces: StateProvinceLookup;
}

const emptyStateProvince: StateProvince = {
  id: 0,
  name: "",
  fullName: "",
  abbreviation: "",
  countryName: "",
  countryId: 0,
};

const state: StateProvinceState = {
  stateProvince: emptyStateProvince,
  statesProvincesForTable: [],
  statesProvincesForAutocomplete: [],
  statesProvinces: {},
};

const mutations = {
  LOAD_STATE_PROVINCE(state: StateProvinceState, payload: StateProvince) {
    state.stateProvince = payload;
  },
  LOAD_STATES_PROVINCES_FOR_AUTOCOMPLETE(
    state: StateProvinceState,
    payload: AutocompleteResult[],
  ) {
    state.statesProvincesForAutocomplete = payload;
  },
  LOAD_STATES_PROVINCES_FOR_LOOKUP(
    state: StateProvinceState,
    payload: StateProvinceLookup,
  ) {
    state.statesProvinces = payload;
  },
  LOAD_STATES_PROVINCES_FOR_TABLE(
    state: StateProvinceState,
    payload: StateProvince[],
  ) {
    state.statesProvincesForTable = payload;
  },
};

const actions = {
  loadStateProvince(context: any, stateProvince: StateProvince) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_STATE_PROVINCE", stateProvince);
      resolve(stateProvince);
    });
  },
  loadStatesProvinces(context: any, statesProvinces: StateProvince[]) {
    return new Promise((resolve, _reject) => {
      const acItems: AutocompleteResult[] = statesProvinces.map(item => {
        return {
          label: item.fullName,
          id: item.id,
        };
      });

      const lookupItems = statesProvinces.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit("LOAD_STATES_PROVINCES_FOR_AUTOCOMPLETE", acItems);
      context.commit("LOAD_STATES_PROVINCES_FOR_TABLE", statesProvinces);
      context.commit("LOAD_STATES_PROVINCES_FOR_LOOKUP", lookupItems);
      resolve(acItems);
    });
  },
};

const getters = {
  stateProvince: (state: StateProvinceState) => {
    return state.stateProvince;
  },
  statesProvincesForTable: (state: StateProvinceState) => {
    return state.statesProvincesForTable;
  },
  statesProvincesForAutocomplete: (state: StateProvinceState) => {
    return state.statesProvincesForAutocomplete;
  },
  stateProvinceById: (state: StateProvinceState) => (id: number) => {
    return state.statesProvinces[id];
  },
};

const statesProvincesModule = {
  state,
  mutations,
  actions,
  getters,
};

export default statesProvincesModule;
