import { defineComponent } from "@vue/composition-api";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "landingPage",
  setup() {
    const routeParams = {
      publicPreparatoryClassApplicationForm: {
        name: NamedRts.publicPreparatoryClassApplicationForm,
      },
      publicBoysUnitApplicationForm: {
        name: NamedRts.publicUnitApplicationForm,
        params: {
          id: "boys",
        },
      },
      publicGirlsUnitApplicationForm: {
        name: NamedRts.publicUnitApplicationForm,
        params: {
          id: "girls",
        },
      },
    };

    return {
      routeParams,
    };
  },
});
