import { render, staticRenderFns } from "./ua-notes-modal.html?vue&type=template&id=4e1d2180&scoped=true&"
import script from "./ua-notes-modal.ts?vue&type=script&lang=js&"
export * from "./ua-notes-modal.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e1d2180",
  null
  
)

export default component.exports