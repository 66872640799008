import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { preparatoryClassApplicationController } from "@/controllers/preparatoryClassApplications";
import { NamedRts } from "@/router";

import { prettifyPCAStatus } from "@/utilities/displayHelpers";

export default defineComponent({
  name: "preparatoryClassApplicationList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const filter = ref("");
    const filterOn = ["fullName", "congregation", "ministerName", "status"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "fullName",
        sortable: true,
      },
      {
        key: "congregation",
        sortable: true,
      },
      {
        key: "status",
        sortable: true,
      },
      {
        key: "ministerName",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin.value) {
      fieldsForTable = [
        {
          key: "fullName",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "ministerName",
          sortable: true,
        },
        {
          key: "congregation",
          sortable: true,
        },
      ];
    }

    const preparatoryClassApplicationsForTable = computed(() => {
      return $store.getters.preparatoryClassApplicationsForTable;
    });

    const routeParams = {
      preparatoryClassApplicationForm: {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: "0",
        },
      },
    };

    const computedRouteParams = (id: number) => {
      return {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: id.toString(),
        },
      };
    };

    onMounted(() => {
      Promise.all([
        preparatoryClassApplicationController.needPreparatoryClassApplications(),
      ])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    return {
      computedRouteParams,
      dataLoaded,
      fieldsForTable,
      filter,
      filterOn,
      isAdmin,
      preparatoryClassApplicationsForTable,
      routeParams,
      prettifyPCAStatus,
    };
  },
});
