import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import {
  textDocumentsController,
  TextDocument,
} from "@/controllers/textDocuments";

import { NamedRts } from "@/router";
import LoadingIndicator from "@/components/Loading/Loading.vue";

export default defineComponent({
  name: "textDocumentList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const filter = ref("");
    const filterOn = ["documentName", "description"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const fieldsForTable = [
      {
        key: "documentName",
        sortable: true,
      },
      {
        key: "description",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    onMounted(() => {
      Promise.all([textDocumentsController.needTextDocuments()])
        .then((_response: TextDocument[]) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    const textDocumentsForTable = computed(() => {
      return $store.getters.textDocumentsForTable;
    });

    const editRoute = (id: number) => {
      return {
        name: NamedRts.textDocumentForm,
        params: {
          id: id.toString(),
        },
      };
    };

    return {
      isAdmin,
      dataLoaded,
      textDocumentsForTable,
      editRoute,
      fieldsForTable,
      filter,
      filterOn,
    };
  },
});
