import { ApiSessionResponse } from "@/controllers/sessions";
import { setSessionToken } from "@/clientStore";
import { UnitPosition } from "@/controllers/unitPositionTermLengths";

interface UserState {
  name: string;
  email: string;
  token: string;
  admin: boolean;
  responsibility: UserResponsibility;
  classification: string | UserClassification;
  isLoggedIn: boolean;
}

// These enums were defined in the controller,
// and then imported here, but that didn't work
// so the are defined here and then imported in
// the user controller
export const enum UserResponsibility {
  coordinator = "coordinator",
  recruiter = "recruiter",
  general = "general",
}

export const enum UserClassification {
  boys = "boys",
  girls = "girls",
  all = "all",
}

export const prettifiedUserResponsibility = {
  coordinator: "Coordinator",
  recruiter: "Recruiter",
  general: "General",
};

export const prettifiedUserClassification = {
  boys: "Boys",
  girls: "Girls",
  all: "All",
};

export const userResponsibilityReverseLookup = <
  { [key: string]: UserResponsibility }
>{
  coordinator: UserResponsibility.coordinator,
  recruiter: UserResponsibility.recruiter,
  general: UserResponsibility.general,
};

export const userClassificationReverseLookup = <
  { [key: string]: UserClassification }
>{
  boys: UserClassification.boys,
  girls: UserClassification.girls,
  all: UserClassification.all,
};

const state: UserState = {
  name: "",
  email: "",
  token: "",
  admin: false,
  responsibility: UserResponsibility.general,
  classification: UserClassification.all,
  isLoggedIn: false,
};

const mutations = {
  SET_NAME(state: UserState, payload: string) {
    state.name = payload;
  },
  SET_EMAIL(state: UserState, payload: string) {
    state.email = payload;
  },
  SET_TOKEN(state: UserState, payload: string) {
    state.token = payload;
  },
  SET_ADMIN(state: UserState, payload: boolean) {
    state.admin = payload;
  },
  SET_RESPONSIBILITY(state: UserState, payload: UserResponsibility) {
    state.responsibility = payload;
  },
  SET_CLASSIFICATION(state: UserState, payload: UserClassification) {
    state.classification = payload;
  },
  SET_SESSION_STATUS(state: UserState, payload: boolean) {
    state.isLoggedIn = payload;
  },
};

const actions = {
  loadSessionData(context: any, session: ApiSessionResponse) {
    return new Promise((resolve, _reject) => {
      context.commit("SET_NAME", session.name);
      context.commit("SET_EMAIL", session.email);
      context.commit("SET_TOKEN", session.token);
      context.commit("SET_ADMIN", session.admin);
      context.commit("SET_RESPONSIBILITY", session.responsibility);
      context.commit("SET_CLASSIFICATION", session.classification);
      if (session.token === "") {
        context.commit("SET_SESSION_STATUS", false);
      } else {
        context.commit("SET_SESSION_STATUS", true);
      }
      setSessionToken(session.token);
      resolve(session);
    });
  },
};

const getters = {
  name: (state: UserState) => {
    return state.name;
  },
  userClassification: (state: UserState) => {
    return state.classification;
  },
  userResponsibility: (state: UserState) => {
    return state.responsibility;
  },
  isLoggedIn: (state: UserState) => {
    return state.isLoggedIn;
  },
  isAdmin: (state: UserState) => {
    return state.admin;
  },
  userUnitApplicationPosition: (state: UserState): UnitPosition => {
    if (state.classification === UserClassification.boys) {
      return UnitPosition.boysUnitVolunteer;
    } else {
      return UnitPosition.girlsUnitVolunteer;
    }
  },
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
