import { defineComponent } from "@vue/composition-api";
import { UnitApplicationStatus } from "@/controllers/unitApplications";

export default defineComponent({
  name: "uaStatus",
  components: {},
  props: {
    uaStatus: {
      type: String,
      required: true,
    },
  },
  setup(props, _ctx) {
    const iconString = () => {
      if (props.uaStatus === UnitApplicationStatus.available) {
        return "circle";
      } else if (props.uaStatus === UnitApplicationStatus.pending) {
        return "spinner";
      } else if (props.uaStatus === UnitApplicationStatus.teaching) {
        return "chalkboard";
      } else if (
        props.uaStatus === UnitApplicationStatus.untilVolunteerNotifies
      ) {
        return "stop-circle";
      } else if (props.uaStatus === UnitApplicationStatus.onHold) {
        return "ban";
      } else if (props.uaStatus === UnitApplicationStatus.placed) {
        return "check-circle";
      } else if (props.uaStatus === UnitApplicationStatus.unavailable) {
        return "stop";
      } else {
        return "exclamation-triangle";
      }
    };

    const bgClass = () => {
      if (props.uaStatus === UnitApplicationStatus.available) {
        return {
          "text-success": true,
        };
      } else if (props.uaStatus === UnitApplicationStatus.pending) {
        return {
          "text-warning": true,
        };
      } else if (props.uaStatus === UnitApplicationStatus.teaching) {
        return {
          "text-success": true,
        };
      } else if (
        props.uaStatus === UnitApplicationStatus.untilVolunteerNotifies
      ) {
        return {
          "text-danger": true,
        };
      } else if (props.uaStatus === UnitApplicationStatus.onHold) {
        return {
          "text-danger": true,
        };
      } else if (props.uaStatus === UnitApplicationStatus.placed) {
        return {
          "text-success": true,
        };
      } else if (props.uaStatus === UnitApplicationStatus.unavailable) {
        return {
          "text-secondary": true,
        };
      } else {
        return {
          "text-secondary": true,
        };
      }
    };

    return {
      iconString,
      bgClass,
    };
  },
});
