import { defineComponent, ref, PropType } from "@vue/composition-api";
import {
  UnitApplication,
  UnitApplicationNote,
  UnitApplicationPreference,
} from "@/controllers/unitApplications";

import { friendlyDate, prettifyUAStatus } from "@/utilities/displayHelpers";

import UaStatus from "@/components/UAStatus/UAStatus.vue";
import UaNotesModal from "@/components/UANotesModal/UANotesModal.vue";
import UaPreferencesModal from "@/components/UAPreferencesModal/UAPreferencesModal.vue";

import { NamedRts } from "@/router";

import { PersonUnitDataItem } from "@/components/PersonUnitData/person-unit-data";
import parseISO from "date-fns/parseISO";
import { personAgeToday } from "@/utilities/displayHelpers";

export default defineComponent({
  name: "applicationListBody",
  components: {
    UaStatus,
    UaNotesModal,
    UaPreferencesModal,
  },
  props: {
    applications: {
      type: Array as PropType<UnitApplication[]>,
      required: true,
    },
    // available Date
    // and otherPeopleCongregationIds
    // are not used in all the places where this component
    // is used
    availableDate: {
      type: String,
      required: false,
    },
    otherPeopleCongregationIds: {
      type: Set,
      required: false,
    },
    // this is used to build the route,
    listMode: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    // notes modal
    const notesModalVisible = ref(false);
    const notesModalTitle = ref("");
    const notesModalContent = ref<UnitApplicationNote[]>([]);

    const showNotesModal = (ua: UnitApplication) => {
      notesModalTitle.value = ua.fullName;
      notesModalContent.value = [...ua.unitApplicationNotes];
      selectedId.value = ua.id;
      notesModalVisible.value = true;
    };

    const closeNotesModal = () => {
      notesModalVisible.value = false;
    };

    // check whether the person is available for the date
    // that is passed into the component
    const isAvailable = (ua: UnitApplication) => {
      if (props.availableDate) {
        return ua.availableDate <= props.availableDate;
      } else {
        return false;
      }
    };

    const congregationOverlap = (ua: UnitApplication) => {
      if (props.otherPeopleCongregationIds) {
        return props.otherPeopleCongregationIds.has(ua.congregationId);
      } else {
        return false;
      }
    };

    // preferences modal
    const preferencesModalVisible = ref(false);
    const preferencesModalTitle = ref("");
    const preferencesModalContent = ref<UnitApplicationPreference[]>([]);

    const showPreferencesModal = (ua: UnitApplication) => {
      preferencesModalTitle.value = ua.fullName;
      preferencesModalContent.value = [...ua.unitApplicationPreferences];
      selectedId.value = ua.id;
      preferencesModalVisible.value = true;
    };

    const closePreferencesModal = () => {
      preferencesModalVisible.value = false;
    };

    const selectedId = ref(-1);

    const selectedRowClass = (ua: UnitApplication) => {
      if (ua.id === selectedId.value) {
        return {
          "cps-selected-row": true,
        };
      } else {
        return {
          "cps-selected-row": false,
        };
      }
    };

    const routeBuilder = (ua: UnitApplication) => {
      if (props.listMode === "replacementPicker") {
        return {
          name: NamedRts.unitFindReplacementApplicationShow,
          params: {
            unitApplicationId: ua.id.toString(),
          },
        };
      } else {
        return {
          name: NamedRts.personUnitData,
          params: {
            itemType: PersonUnitDataItem.unitApplication,
            itemId: ua.id,
          },
        };
      }
    };

    const localPersonAge = (unitApplication: UnitApplication) => {
      const birthDate = parseISO(unitApplication.birthDate);
      return personAgeToday(birthDate);
    };

    return {
      dataLoaded,
      friendlyDate,
      prettifyUAStatus,
      localPersonAge,

      showNotesModal,
      notesModalVisible,
      notesModalTitle,
      notesModalContent,
      closeNotesModal,

      showPreferencesModal,
      preferencesModalVisible,
      preferencesModalTitle,
      preferencesModalContent,
      closePreferencesModal,

      selectedRowClass,

      routeBuilder,
      isAvailable,
      congregationOverlap,
    };
  },
});
