import {
  ref,
  onMounted,
  defineComponent,
  nextTick,
} from "@vue/composition-api";

export default defineComponent({
  name: "listFilterInput",
  setup(_props, ctx) {
    let timeoutRef: number | null | undefined = null;
    const filter = ref("");
    const firstInput = ref<null | HTMLElement>(null);

    const filterOnInput = ({ target }: { target: HTMLInputElement }) => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }

      filter.value = target.value;
      timeoutRef = setTimeout(() => {
        ctx.emit("filter-changed", filter.value);
      }, 800);
    };

    const clearFilter = () => {
      filter.value = "";
      ctx.emit("filter-changed", "");
    };

    onMounted(() => {
      nextTick(() => {
        if (firstInput.value) {
          firstInput.value.focus();
        }
      });
    });

    return {
      filter,
      filterOnInput,
      clearFilter,
      firstInput,
    };
  },
});
