import {
  PreparatoryClassApplication,
  PreparatoryClassApplicationStatus,
  PCAPlacementConfirmedMethod,
  PCAPlacementPacketSentByMethod,
  PreparatoryClassApplicationFlag,
} from "@/controllers/preparatoryClassApplications";
import { PreparatoryClassClassification } from "@/controllers/preparatoryClasses";

interface PreparatoryClassApplicationLookup {
  [key: number]: PreparatoryClassApplication;
}

interface PreparatoryClassApplicationsState {
  preparatoryClassApplication: PreparatoryClassApplication;
  preparatoryClassApplicationsForTable: PreparatoryClassApplication[];
  preparatoryClassApplications: PreparatoryClassApplicationLookup;
}

const emptyPreparatoryClassApplication: PreparatoryClassApplication = {
  id: 0,
  fullName: "",
  congregation: "",
  personId: 0,
  placementPacketSentDate: "",
  classification: PreparatoryClassClassification.boys,
  submittedDate: "",
  status: PreparatoryClassApplicationStatus.pending,
  ministerName: "",
  ministerEmailAddress: "",
  ministerPhoneNumber: "",
  placementDate: "",
  placementConfirmedDate: "",
  placementConfirmedMethod: PCAPlacementConfirmedMethod.emailLink,
  placementPacketSentByMethod: PCAPlacementPacketSentByMethod.email,
  placementPreparatoryClassId: 0,
  flag: PreparatoryClassApplicationFlag.normal,
  preparatoryClassApplicationPreferences: [],
};

const state: PreparatoryClassApplicationsState = {
  preparatoryClassApplication: emptyPreparatoryClassApplication,
  preparatoryClassApplicationsForTable: [],
  preparatoryClassApplications: {},
};

const mutations = {
  LOAD_PREPARATORY_CLASS_APPLICATION(
    state: PreparatoryClassApplicationsState,
    payload: PreparatoryClassApplication,
  ) {
    state.preparatoryClassApplication = payload;
  },
  LOAD_PREPARATORY_CLASS_APPLICATIONS_FOR_TABLE(
    state: PreparatoryClassApplicationsState,
    payload: PreparatoryClassApplication[],
  ) {
    state.preparatoryClassApplicationsForTable = payload;
  },

  LOAD_PREPARATORY_CLASS_APPLICATIONS_FOR_LOOKUP(
    state: PreparatoryClassApplicationsState,
    payload: PreparatoryClassApplicationLookup,
  ) {
    state.preparatoryClassApplications = payload;
  },
};

const actions = {
  loadPreparatoryClassApplications(
    context: any,
    preparatoryClassApplications: PreparatoryClassApplication[],
  ) {
    return new Promise((resolve, _reject) => {
      const lookupItems = preparatoryClassApplications.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit(
        "LOAD_PREPARATORY_CLASS_APPLICATIONS_FOR_TABLE",
        preparatoryClassApplications,
      );
      context.commit(
        "LOAD_PREPARATORY_CLASS_APPLICATIONS_FOR_LOOKUP",
        lookupItems,
      );
      resolve(preparatoryClassApplications);
    });
  },

  loadPreparatoryClassApplication(
    context: any,
    preparatoryClassApplication: PreparatoryClassApplication,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit(
        "LOAD_PREPARATORY_CLASS_APPLICATION",
        preparatoryClassApplication,
      );
      resolve(preparatoryClassApplication);
    });
  },
};

const getters = {
  preparatoryClassApplication: (state: PreparatoryClassApplicationsState) => {
    return state.preparatoryClassApplication;
  },
  preparatoryClassApplicationsForTable: (
    state: PreparatoryClassApplicationsState,
  ) => {
    return state.preparatoryClassApplicationsForTable;
  },
  countOfPreparatoryClassApplicationsForTable: (
    state: PreparatoryClassApplicationsState,
  ) => {
    return state.preparatoryClassApplicationsForTable.length;
  },
  preparatoryClassApplicationById: (
    state: PreparatoryClassApplicationsState,
  ) => (id: number) => {
    return state.preparatoryClassApplications[id];
  },
};

const PreparatoryClassApplicationsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default PreparatoryClassApplicationsModule;
