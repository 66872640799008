import { defineComponent, PropType } from "@vue/composition-api";
import { UnitApplicationPreference } from "@/controllers/unitApplications";

export default defineComponent({
  name: "unitApplicationPreferencesModal",
  components: {},
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    content: {
      type: Array as PropType<UnitApplicationPreference[]>,
      required: true,
    },
  },
  setup(_props, ctx) {
    const cm = () => {
      ctx.emit("closeModal");
    };
    return {
      cm,
    };
  },
});
