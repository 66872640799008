import api from "@/api";
import store, { StoreActions } from "@/store";

export const enum MatchReplacementUnitApplicationStatus {
  matched = "matched",
  unmatched = "unmatched",
}

export const matchReplacementUnitApplicationStatusReverseLookup = <
  { [key: string]: MatchReplacementUnitApplicationStatus }
>{
  matched: MatchReplacementUnitApplicationStatus.matched,
  unmatched: MatchReplacementUnitApplicationStatus.unmatched,
};

export interface ApiMatchReplacementUnitApplicationParams {
  unit_application_id: number;
  unit_application_placement_id: number;
  status: MatchReplacementUnitApplicationStatus;
}

export interface ApiMatchReplacementUnitApplication {
  id: number;
  unit_application_id: number;
  unit_application_placement_id: number;
  status: string;
}

export interface MatchReplacementUnitApplication {
  id: number;
  unitApplicationId: number;
  unitApplicationPlacementId: number;
  status: MatchReplacementUnitApplicationStatus;
}

export interface ApiMatchReplacementUnitApplicationIndexParams {
  unit_application_id?: number;
  unit_application_placement_id?: number;
}

function apiToItem(
  p: ApiMatchReplacementUnitApplication,
): MatchReplacementUnitApplication {
  const st = matchReplacementUnitApplicationStatusReverseLookup[p.status];

  return {
    id: p.id,
    unitApplicationId: p.unit_application_id,
    unitApplicationPlacementId: p.unit_application_placement_id,
    status: st,
  };
}

async function createMatchReplacementUnitApplication(
  c: MatchReplacementUnitApplication,
) {
  const cParams: ApiMatchReplacementUnitApplicationParams = {
    unit_application_id: c.unitApplicationId,
    unit_application_placement_id: c.unitApplicationPlacementId,
    status: c.status,
  };

  return (
    api
      .postMatchReplacementUnitApplication(cParams)
      // .then((response: ApiMatchReplacementUnitApplication) => {
      //   return store.dispatch(
      //     StoreActions.loadMatchReplacementUnitApplication,
      //     apiToItem(response),
      //   );
      // })
      .catch(error => {
        return Promise.reject(error);
      })
  );
}

async function needMatchReplacementUnitApplications(
  p: ApiMatchReplacementUnitApplicationIndexParams,
) {
  return api
    .getMatchReplacementUnitApplications(p)
    .then((response: ApiMatchReplacementUnitApplication[]) => {
      const c = response.map(item => {
        return apiToItem(item);
      });
      return store.dispatch(
        StoreActions.loadMatchReplacementUnitApplications,
        c,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteMatchReplacementUnitApplication(
  c: MatchReplacementUnitApplication,
) {
  return api.deleteMatchReplacementUnitApplication(c.id);
}

export const matchReplacementUnitApplicationController = {
  createMatchReplacementUnitApplication,
  needMatchReplacementUnitApplications,
  deleteMatchReplacementUnitApplication,
};
