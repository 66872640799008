import api from "@/api";
import store, { StoreActions } from "@/store";

export interface ApiPreparatoryClassSeason {
  description: string;
  end_date: string;
  friendly_end_date: string;
  friendly_start_date: string;
  id: number;
  notes: string;
  start_date: string;
}

export interface ApiPreparatoryClassSeasonParams {
  description: string;
  end_date: string;
  notes: string;
  start_date: string;
}

export interface PreparatoryClassSeason {
  description: string;
  endDate: string;
  friendlyEndDate: string;
  friendlyStartDate: string;
  id: number;
  notes: string;
  startDate: string;
}

export function apiPreparatoryClassSeasonToPreparatoryClassSeason(
  p: ApiPreparatoryClassSeason,
): PreparatoryClassSeason {
  return {
    description: p.description,
    endDate: p.end_date,
    friendlyEndDate: p.friendly_end_date,
    friendlyStartDate: p.friendly_start_date,
    id: p.id,
    notes: p.notes,
    startDate: p.start_date,
  };
}

async function createPreparatoryClassSeason(c: PreparatoryClassSeason) {
  const cParams: ApiPreparatoryClassSeasonParams = {
    description: c.description,
    end_date: c.endDate,
    notes: c.notes,
    start_date: c.startDate,
  };
  return api
    .postPreparatoryClassSeason(cParams)
    .then((response: ApiPreparatoryClassSeason) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassSeason,
        apiPreparatoryClassSeasonToPreparatoryClassSeason(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClassSeasons() {
  await api
    .getPreparatoryClassSeasons()
    .then((response: ApiPreparatoryClassSeason[]) => {
      const c = response.map(item => {
        return apiPreparatoryClassSeasonToPreparatoryClassSeason(item);
      });
      return store.dispatch(StoreActions.loadPreparatoryClassSeasons, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needPreparatoryClassSeason(preparatoryClassSeasonId: string) {
  await api
    .getPreparatoryClassSeason(preparatoryClassSeasonId)
    .then((response: ApiPreparatoryClassSeason) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassSeason,
        apiPreparatoryClassSeasonToPreparatoryClassSeason(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updatePreparatoryClassSeason(c: PreparatoryClassSeason) {
  const cParams: ApiPreparatoryClassSeasonParams = {
    description: c.description,
    end_date: c.endDate,
    notes: c.notes,
    start_date: c.startDate,
  };
  return api
    .patchPreparatoryClassSeason(c.id, cParams)
    .then((response: ApiPreparatoryClassSeason) => {
      return store.dispatch(
        StoreActions.loadPreparatoryClassSeason,
        apiPreparatoryClassSeasonToPreparatoryClassSeason(response),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deletePreparatoryClassSeason(c: PreparatoryClassSeason) {
  return api
    .deletePreparatoryClassSeason(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const preparatoryClassSeasonsController = {
  createPreparatoryClassSeason,
  needPreparatoryClassSeason,
  needPreparatoryClassSeasons,
  updatePreparatoryClassSeason,
  deletePreparatoryClassSeason,
};
