import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { submittedPreparatoryClassApplicationsController } from "@/controllers/submittedPreparatoryClassApplications";
import { friendlyDate } from "@/utilities/displayHelpers";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "preparatoryClassList",
  components: {
    LoadingIndicator,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    const submittedPreparatoryClassApplicationsForTable = computed(() => {
      return $store.getters.submittedPreparatoryClassApplicationsForTable;
    });

    onMounted(() => {
      Promise.all([
        submittedPreparatoryClassApplicationsController.needSubmittedPreparatoryClassApplications(),
      ])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {
          dataLoaded.value = true;
        });
    });

    const computedRouteParams = (id: number) => {
      return {
        name: NamedRts.preparatoryClassApplicationForm,
        params: {
          preparatoryClassApplicationId: "0",
        },
        query: {
          submittedPreparatoryClassApplicationId: id.toString(),
        },
      };
    };

    return {
      computedRouteParams,
      friendlyDate,
      dataLoaded,
      submittedPreparatoryClassApplicationsForTable,
      isAdmin,
    };
  },
});
