import api from "@/api";
import store, { StoreActions } from "@/store";
import {
  ApiPreparatoryClass,
  apiPreparatoryClassToPreparatoryClass,
} from "@/controllers/preparatoryClasses";

async function needPreparatoryClasses(classification: string) {
  await api
    .getPublicPreparatoryClasses(classification)
    .then((response: ApiPreparatoryClass[]) => {
      const c = response.map(item => {
        return apiPreparatoryClassToPreparatoryClass(item);
      });
      return store.dispatch(StoreActions.loadPreparatoryClasses, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const publicPreparatoryClassesController = {
  needPreparatoryClasses,
};
