import api from "@/api";
import store, { StoreActions } from "@/store";

export const enum UnitWorkType {
  rebuilding = "rebuilding",
  humanitarian = "humanitarian",
  childCare = "child_care",
}

export const enum UnitStatus {
  active = "active",
  inactive = "inactive",
  all = "all",
}

export const enum UnitClassification {
  girls = "girls",
  boys = "boys",
  all = "all",
}

export const unitClassificationReverseLookup = <
  { [key: string]: UnitClassification }
>{
  girls: UnitClassification.girls,
  boys: UnitClassification.boys,
  all: UnitClassification.all,
};

export interface ApiUnit {
  id: number;
  name: string;
  classification: string;
  work_type: string;
  status: string;
  city: string;
  address: string;
  address2: string;
  state_id: number;
  state_name: string;
  start_date: string;
  end_date: string;
  zip: string;
}

export interface ApiUnitParams {
  name: string;
  classification: string;
  work_type: string;
  status: string;
  city: string;
  address: string;
  address2: string;
  state_id: number;
  start_date: string;
  end_date: string;
  zip: string;
}

export interface Unit {
  id: number;
  name: string;
  classification: UnitClassification;
  workType: UnitWorkType;
  status: UnitStatus;
  city: string;
  address: string;
  address2: string;
  stateId: number;
  stateName: string;
  startDate: string;
  endDate: string;
  zip: string;
}

export const prettifiedUnitWorkType = {
  humanitarian: "Humanitarian",
  rebuilding: "Rebuilding",
  child_care: "Child Care",
};

export function apiUnitToUnit(p: ApiUnit): Unit {
  const uc =
    p.classification === "boys"
      ? UnitClassification.boys
      : UnitClassification.girls;
  const wt =
    p.work_type === "humanitarian"
      ? UnitWorkType.humanitarian
      : p.work_type === "rebuilding"
      ? UnitWorkType.rebuilding
      : UnitWorkType.childCare;
  const us = p.status === "active" ? UnitStatus.active : UnitStatus.inactive;

  return {
    id: p.id,
    name: p.name,
    classification: uc,
    workType: wt,
    status: us,
    city: p.city,
    address: p.address,
    address2: p.address2,
    stateId: p.state_id,
    stateName: p.state_name,
    startDate: p.start_date,
    endDate: p.end_date,
    zip: p.zip,
  };
}

async function createUnit(c: Unit) {
  const cParams: ApiUnitParams = {
    name: c.name,
    classification: c.classification,
    work_type: c.workType,
    status: c.status,
    city: c.city,
    address: c.address,
    address2: c.address2,
    state_id: c.stateId,
    start_date: c.startDate,
    end_date: c.endDate,
    zip: c.zip,
  };
  return api
    .postUnit(cParams)
    .then((response: ApiUnit) => {
      return store.dispatch(StoreActions.loadUnit, apiUnitToUnit(response));
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitsList(classification: UnitClassification) {
  await api
    .getUnitsList(classification)
    .then((response: ApiUnit[]) => {
      const c = response.map(item => {
        return apiUnitToUnit(item);
      });
      return store.dispatch(StoreActions.loadUnits, c);
    })
    .then((response: any) => {
      return response;
    });
}

async function needUnits(status: UnitStatus) {
  await api
    .getUnits(status)
    .then((response: ApiUnit[]) => {
      const c = response.map(item => {
        return apiUnitToUnit(item);
      });
      return store.dispatch(StoreActions.loadUnits, c);
    })
    .then((response: any) => {
      return response;
    });
}

async function needUnit(unitId: string) {
  return api
    .getUnit(unitId)
    .then((response: ApiUnit) => {
      return store.dispatch(StoreActions.loadUnit, apiUnitToUnit(response));
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function updateUnit(c: Unit) {
  const cParams: ApiUnitParams = {
    name: c.name,
    classification: c.classification,
    work_type: c.workType,
    status: c.status,
    city: c.city,
    address: c.address,
    address2: c.address2,
    state_id: c.stateId,
    start_date: c.startDate,
    end_date: c.endDate,
    zip: c.zip,
  };
  return api
    .patchUnit(c.id, cParams)
    .then((response: ApiUnit) => {
      return store.dispatch(StoreActions.loadUnit, apiUnitToUnit(response));
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteUnit(c: Unit) {
  return api.deleteUnit(c.id).catch(error => {
    return Promise.reject(error);
  });
}

export const unitsController = {
  createUnit,
  needUnits,
  needUnitsList,
  needUnit,
  updateUnit,
  deleteUnit,
};
