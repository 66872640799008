import { v4 as uuidv4 } from "uuid";
import Vue, { PropOptions } from "vue";

export interface AutocompleteResult {
  label: string;
  id: number;
}

const emptyResult: AutocompleteResult = {
  label: "",
  id: 0,
};

export default Vue.extend({
  name: "CpsAutocompleteObj",
  props: {
    items: <PropOptions<AutocompleteResult[]>>{
      type: Array,
      required: false,
      default: () => [],
    },
    acResult: {
      type: Object,
      required: false,
      default: () => {},
    },
    inputLabel: {
      type: String,
      required: false,
      default: "",
    },
    helpTextLabel: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      currentResult: emptyResult,
      arrowCounter: -1,
      browserId: "",
      isOpen: false,
      results: [] as AutocompleteResult[],
      acInput: "",
    };
  },
  created() {
    this.acInput = this.acResult.label;
    this.browserId = `cps-${uuidv4()}`;
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    toggleResults(evt: any) {
      evt.preventDefault();
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        // assume if the user opened the list they want to see
        // all of the values
        this.results = this.items;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter(evt: any) {
      evt.preventDefault();
      let result: AutocompleteResult;
      if (this.arrowCounter === -1) {
        if (this.results.length > 0) {
          result = this.results[0];
        } else {
          result = emptyResult;
        }
      } else {
        result = this.results[this.arrowCounter];
      }
      this.setResult(result);
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    onChange() {
      this.isOpen = true;
      this.filterResults();
    },
    onTab() {
      // reset it to the current item
      this.acInput = this.currentResult.label;
      this.isOpen = false;
    },
    filterResults() {
      this.$nextTick(() => {
        this.results = this.items.filter(
          (item: AutocompleteResult) =>
            item.label.toLowerCase().indexOf(this.acInput.toLowerCase()) > -1,
        );
        this.arrowCounter = 0;
      });
    },
    setResultParent(result: AutocompleteResult) {
      this.currentResult = result;
      this.acInput = result.label;
    },
    setFocusParent() {
      // if next tick is necessary, the parent is supposed to take care of that
      const el = document.getElementById(this.browserId);
      if (el) {
        el.focus();
      }
    },
    setResult(result: AutocompleteResult) {
      this.currentResult = result;
      this.acInput = result.label;
      this.$emit("update:acResult", this.currentResult);
      this.isOpen = false;
      // move the focus to the input element
      const el = document.getElementById(this.browserId);
      if (el) {
        el.focus();
      }
    },
    handleClickOutside(evt: any) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
  },
});
