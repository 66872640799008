import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import CpsAutocompleteObj from "../Autocomplete/AutocompleteObj.vue";
import { statesController } from "@/controllers/states";
import { Country, countriesController } from "@/controllers/countries";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import { FormModes } from "@/main";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";

export default defineComponent({
  name: "stateProvinceForm",
  components: {
    FormErrors,
    LoadingIndicator,
    CpsAutocompleteObj,
  },
  props: {
    stateProvinceId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);
    const deleting = ref(false);

    const deleteButtonText = ref("Delete");
    const submitButtonText = ref("Submit");

    const refCountry: any = ref(null);

    const cancelRoute = {
      name: NamedRts.stateProvinceList,
    };

    const fd = reactive({
      id: 0,
      name: "",
      fullName: "",
      abbreviation: "",
      countryName: "",
      countryId: 0,
    });

    const formMode = ref(FormModes.edit);

    onMounted(() => {
      const neededFormData = [countriesController.needCountries()];

      if (props.stateProvinceId) {
        if (props.stateProvinceId === "0") {
          // new form, no data needs to be retrieved
          // for the existing item
          Promise.all(neededFormData)
            .then(_response => {
              formMode.value = FormModes.new;
              dataLoaded.value = true;
            })
            .catch((_error: any) => {});
        } else {
          formMode.value = FormModes.edit;
          neededFormData.push(
            statesController.needStateProvince(props.stateProvinceId),
          );
          Promise.all(neededFormData)
            .then((_response: any) => {
              fd.id = storeStateProvince.value.id;
              fd.name = storeStateProvince.value.name;
              fd.abbreviation = storeStateProvince.value.abbreviation;
              fd.countryId = storeStateProvince.value.countryId;
              dataLoaded.value = true;
              // lookup the country
              const t: Country = countryById(fd.countryId);
              if (t) {
                ctx.root.$nextTick(() => {
                  refCountry.value.setResultParent({
                    id: t.id,
                    label: t.name,
                  });
                });
              }
            })
            .catch((_error: any) => {});
        }
      } else {
        dataLoaded.value = true;
      }
    });

    const storeStateProvince = computed(() => {
      return $store.getters.stateProvince;
    });

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let submitAction;
      let toastMessage = "";

      if (formMode.value === FormModes.new) {
        toastMessage = `${fd.name} successfully created.`;
        submitAction = statesController.createStateProvince;
      } else {
        toastMessage = `${fd.name} successfully updated.`;
        submitAction = statesController.updateStateProvince;
      }

      submitAction(fd)
        .then(_response => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          ctx.root.$bvToast.toast(toastMessage, TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.stateProvinceList,
          });
        })
        .catch(errors => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        // it's a new item, just cancel
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      statesController
        .deleteStateProvince(fd)
        .then(_response => {
          cancelDelete();
          ctx.root.$bvToast.toast(fd.name + " deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          deleting.value = false;
          deleteButtonText.value = "Delete";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    const countryById = (id: number) => {
      return $store.getters.countryById(id);
    };

    const countryItems = computed(() => {
      return $store.getters.countriesForAutocomplete;
    });

    const countryAC = reactive<AutocompleteResult>({
      label: "",
      id: 0,
    });

    const updateCountry = (evt: AutocompleteResult): void => {
      const t: Country = countryById(evt.id);
      fd.countryId = t.id;
    };

    return {
      updateCountry,
      countryAC,
      countryItems,
      refCountry,
      cancelDelete,
      cancelRoute,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      fd,
      hasErrors,
      performDelete,
      startDelete,
      storeStateProvince,
      submitButtonText,
      submitForm,
      submitting,
    };
  },
});
