// 2020-04-03
// This controller is different then most of the others
// The data for the notes is not being stored in the store
// it is just being passed directly to the component
// the component is smart enough to know what to do with the notes
import api from "@/api";

export interface ApiUANote {
  id: number;
  content: string;
  user_name: string;
  time_ago_in_words: string;
  created_date: string;
  created_at: string;
  unit_application_id: number;
}

export interface ApiUnitApplicationNoteParams {
  unit_application_note: {
    unit_application_id: number;
    content: string;
  };
}

export interface ApiUnitApplicationNoteIndexParams {
  unit_application_id: number;
}

async function needUnitApplicationNotes(p: ApiUnitApplicationNoteIndexParams) {
  return api.getUnitApplicationNotes(p);
}

async function deleteUnitApplicationNote(noteId: number) {
  return api.deleteUnitApplicationNote(noteId);
}

async function createUnitApplicationNote(uaId: number, noteText: string) {
  const apiParams: ApiUnitApplicationNoteParams = {
    unit_application_note: {
      unit_application_id: uaId,
      content: noteText,
    },
  };

  return api.postUnitApplicationNote(apiParams);
}

export const unitApplicationNoteController = {
  needUnitApplicationNotes,
  createUnitApplicationNote,
  deleteUnitApplicationNote,
};
