import api from "@/api";
import store, { StoreActions } from "@/store";

export interface InstructorPreparatoryClassDetail {
  id: number;
  preparatoryClassId: number;
  instructorName: string;
  instructorId: number;
  congregationNameWithState: string;
  congregationId: number;
}

export interface ApiInstructorPreparatoryClassDetail {
  id: number;
  preparatory_class_id: number;
  instructor_name: string;
  instructor_id: number;
  congregation_name_with_state: string;
  congregation_id: number;
}

export interface ApiInstructorPreparatoryClassDetailParams {
  detail: {
    instructor_id: number;
    preparatory_class_id: number;
  };
}

export interface ApiInstructorPreparatoryClassDetailIndexParams {
  preparatory_class_id: number;
}

function apiInstructorPreparatoryClassDetailToDetail(
  p: ApiInstructorPreparatoryClassDetail,
): InstructorPreparatoryClassDetail {
  return {
    id: p.id,
    preparatoryClassId: p.preparatory_class_id,
    instructorName: p.instructor_name,
    instructorId: p.instructor_id,
    congregationNameWithState: p.congregation_name_with_state,
    congregationId: p.congregation_id,
  };
}

async function createInstructorPreparatoryClassDetail(
  p: InstructorPreparatoryClassDetail,
) {
  const apiParams: ApiInstructorPreparatoryClassDetailParams = {
    detail: {
      instructor_id: p.instructorId,
      preparatory_class_id: p.preparatoryClassId,
    },
  };

  return api
    .postInstructorPreparatoryClassDetail(apiParams)
    .then((response: ApiInstructorPreparatoryClassDetail[]) => {
      const items = response.map(item => {
        return apiInstructorPreparatoryClassDetailToDetail(item);
      });
      return store.dispatch(
        StoreActions.loadInstructorPreparatoryClassDetails,
        items,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needInstructorPreparatoryClassDetails(pcId?: number) {
  let apiParams: ApiInstructorPreparatoryClassDetailIndexParams;
  if (pcId) {
    apiParams = {
      preparatory_class_id: pcId,
    };

    return api
      .getInstructorPreparatoryClassDetails(apiParams)
      .then((response: ApiInstructorPreparatoryClassDetail[]) => {
        const items = response.map(item => {
          return apiInstructorPreparatoryClassDetailToDetail(item);
        });
        return store.dispatch(
          StoreActions.loadInstructorPreparatoryClassDetails,
          items,
        );
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

async function deleteInstructorPreparatoryClassDetail(
  c: InstructorPreparatoryClassDetail,
) {
  return api
    .deleteInstructorPreparatoryClassDetail(c.id)
    .then((response: ApiInstructorPreparatoryClassDetail[]) => {
      const items = response.map(item => {
        return apiInstructorPreparatoryClassDetailToDetail(item);
      });
      return store.dispatch(
        StoreActions.loadInstructorPreparatoryClassDetails,
        items,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const instructorPreparatoryClassDetailController = {
  createInstructorPreparatoryClassDetail,
  needInstructorPreparatoryClassDetails,
  deleteInstructorPreparatoryClassDetail,
};
