import api from "@/api";
import store, { StoreActions } from "@/store";
import {
  ApiUnitPositionTermLength,
  apiUnitPositionTermLengthToUnitPositionTermLength,
  UnitPosition,
} from "@/controllers/unitPositionTermLengths";

async function needUnitPositionTermLengths(position: UnitPosition) {
  await api
    .getPublicUnitPositionTermLengths(position)
    .then((response: ApiUnitPositionTermLength[]) => {
      const c = response.map(item => {
        return apiUnitPositionTermLengthToUnitPositionTermLength(item);
      });
      return store.dispatch(StoreActions.loadUnitPositionTermLengths, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const publicUnitPositionTermLengthsController = {
  needUnitPositionTermLengths,
};
