import {
  reactive,
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import ChildPreparatoryClassApplications from "@/components/PreparatoryClassApplication/ChildList.vue";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import CpsAutocompleteObj from "../Autocomplete/AutocompleteObj.vue";
import { AutocompleteResult } from "@/components/Autocomplete/autocomplete-obj";
import {
  PreparatoryClass,
  preparatoryClassesController,
} from "@/controllers/preparatoryClasses";
import { preparatoryClassReportsController } from "@/controllers/preparatoryClassReports";
import {
  placementPacketController,
  SendEmailMode,
} from "@/controllers/placementPacket";
import { pcaPlacementConfirmationReminderController } from "@/controllers/pcaPlacmentConfirmationReminder";
import { pcaMinisterUpdateController } from "@/controllers/pcaMinisterUpdate";
import { pcaMinisterReminderController } from "@/controllers/pcaMinisterReminder";
import { preparatoryClassStatisticsController } from "@/controllers/preparatoryClassStatistics";
import { TOAST_OPTIONS } from "@/constants";
import { preparatoryClassApplicationController } from "@/controllers/preparatoryClassApplications";
import {
  instructorPreparatoryClassDetailController,
  InstructorPreparatoryClassDetail,
} from "@/controllers/instructorPreparatoryClassDetails";
import { preparatoryClassInstructorsController } from "@/controllers/preparatoryClassInstructors";
import {
  courtesyCommitteeMemberController,
  CourtesyCommitteeMember,
} from "@/controllers/courtesyCommitteeMembers";
import { pcInstructorUpdateController } from "@/controllers/pcInstructorUpdate";
import { pcCommitteeEmailController } from "@/controllers/pcCommitteeEmail";
import PcNotes from "./Note/List.vue";
import PcTasks from "./Task/List.vue";

export default defineComponent({
  name: "preparatoryClassShow",
  components: {
    LoadingIndicator,
    PcNotes,
    PcTasks,
    ChildPreparatoryClassApplications,
    CpsAutocompleteObj,
  },
  props: {
    preparatoryClassId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const refInstructorInput = ref(null);

    const ccmPerson = reactive({
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      preparatoryClassId: 0,
    });

    const pci = reactive({
      instructorId: 0,
      preparatoryClassId: 0,
    });

    const addInstructorFormVisible = ref(false);
    const addCcmFormVisible = ref(false);

    onMounted(() => {
      if (props.preparatoryClassId) {
        Promise.all([
          preparatoryClassesController.needPreparatoryClass(
            props.preparatoryClassId,
          ),
          preparatoryClassApplicationController.needPreparatoryClassApplications(
            {
              preparatory_class_id: Number(props.preparatoryClassId),
            },
          ),
          preparatoryClassStatisticsController.needPreparatoryClassStatistics(),
          instructorPreparatoryClassDetailController.needInstructorPreparatoryClassDetails(
            Number(props.preparatoryClassId),
          ),
          courtesyCommitteeMemberController.needCourtesyCommitteeMembers(
            Number(props.preparatoryClassId),
          ),
          preparatoryClassInstructorsController.needPreparatoryClassInstructors(),
        ]).finally(() => {
          dataLoaded.value = true;
        });
      } else {
        dataLoaded.value = true;
      }
    });

    const formattedDates = () => {
      const formatPattern = "MMMM d, yyyy";
      try {
        const sDate = parseISO(sPC.value.startDate);
        const eDate = parseISO(sPC.value.endDate);
        return `${format(sDate, formatPattern)} - ${format(
          eDate,
          formatPattern,
        )}`;
      } catch {
        return "";
      }
    };

    const getPreparatoryClassPlacementsCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassPlacementsCount(id);
      } else {
        return 0;
      }
    };

    const getPreparatoryClassConfirmedPlacementsCount = (id: number) => {
      if (id) {
        return $store.getters.getPreparatoryClassConfirmedPlacementsCount(id);
      } else {
        return 0;
      }
    };

    const preparatoryClassApplicationsForTable = computed(() => {
      return $store.getters.preparatoryClassApplicationsForTable;
    });

    const preparatoryClassInstructorsForAutocomplete = computed(() => {
      return $store.getters.preparatoryClassInstructorsForAutocomplete;
    });

    const placements = () => {
      if (props.preparatoryClassId) {
        return getPreparatoryClassPlacementsCount(
          Number(props.preparatoryClassId),
        );
      } else {
        return 0;
      }
    };

    const confirmedPlacements = () => {
      if (props.preparatoryClassId) {
        return getPreparatoryClassConfirmedPlacementsCount(
          Number(props.preparatoryClassId),
        );
      } else {
        return 0;
      }
    };

    const sPC = computed(() => {
      return $store.getters.preparatoryClass as PreparatoryClass;
    });

    const sendPlacementPacket = () => {
      if (props.preparatoryClassId) {
        placementPacketController
          .sendPlacementPacketPreparatoryClass({
            preparatory_class_id: Number(props.preparatoryClassId),
            mode: SendEmailMode.production,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendPlacementConfirmationReminder = () => {
      if (props.preparatoryClassId) {
        pcaPlacementConfirmationReminderController
          .sendConfirmationReminder({
            preparatory_class_id: Number(props.preparatoryClassId),
            mode: SendEmailMode.production,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendMinisterUpdate = () => {
      if (props.preparatoryClassId) {
        pcaMinisterUpdateController
          .sendMinisterUpdate({
            preparatory_class_id: Number(props.preparatoryClassId),
            mode: SendEmailMode.production,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendMinisterReminder = () => {
      if (props.preparatoryClassId) {
        pcaMinisterReminderController
          .sendMinisterReminder({
            preparatory_class_id: Number(props.preparatoryClassId),
            mode: SendEmailMode.production,
          })
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendInstructorUpdate = (p: string) => {
      if (props.preparatoryClassId) {
        const params = {
          mode: SendEmailMode.preview,
          preparatory_class_id: Number(props.preparatoryClassId),
        };

        if (p === SendEmailMode.production) {
          params.mode = SendEmailMode.production;
        }

        pcInstructorUpdateController
          .sendInstructorUpdate(params)
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendCommitteeProtocolLetter = (p: string) => {
      if (props.preparatoryClassId) {
        const params = {
          mode: SendEmailMode.preview,
          preparatory_class_id: Number(props.preparatoryClassId),
        };

        if (p === SendEmailMode.production) {
          params.mode = SendEmailMode.production;
        }

        pcCommitteeEmailController
          .sendCommitteeProtocol(params)
          .then(response => {
            const message = response.message;
            ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
          });
      }
    };

    const sendProtocolLetter = (p: string) => {
      if (props.preparatoryClassId) {
        const params = {
          mode: SendEmailMode.preview,
          preparatory_class_id: Number(props.preparatoryClassId),
        };

        if (p === SendEmailMode.production) {
          params.mode = SendEmailMode.production;
        }

        pcCommitteeEmailController.sendClassProtocol(params).then(response => {
          const message = response.message;
          ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
        });
      }
    };

    const sendUpdateLetter = (p: string) => {
      if (props.preparatoryClassId) {
        const params = {
          mode: SendEmailMode.preview,
          preparatory_class_id: Number(props.preparatoryClassId),
        };

        if (p === SendEmailMode.production) {
          params.mode = SendEmailMode.production;
        }

        pcCommitteeEmailController.sendUpdate(params).then(response => {
          const message = response.message;
          ctx.root.$bvToast.toast(message, TOAST_OPTIONS);
        });
      }
    };

    const preparatoryClassInstructors = computed(() => {
      return $store.getters.instructorPreparatoryClassDetails;
    });

    const preparatoryClassCcm = computed(() => {
      return $store.getters.courtesyCommitteeMembers;
    });

    const submitAddCcm = (evt: any) => {
      evt.preventDefault();
      if (props.preparatoryClassId) {
        ccmPerson.preparatoryClassId = Number(props.preparatoryClassId);
      }

      courtesyCommitteeMemberController
        .createCourtesyCommitteeMember(ccmPerson)
        .then(_response => {
          (addCcmFormVisible.value = false),
            ctx.root.$bvToast.toast(
              "Committee successfully added!",
              TOAST_OPTIONS,
            );
          ccmPerson.firstName = "";
          ccmPerson.lastName = "";
          ccmPerson.email = "";
        });
    };

    const instructorAC = reactive({
      label: "",
      id: 0,
    });

    const submitAddInstructor = (evt: any) => {
      evt.preventDefault();
      if (props.preparatoryClassId) {
        instructorPreparatoryClassDetailController
          .createInstructorPreparatoryClassDetail({
            instructorId: pci.instructorId,
            preparatoryClassId: Number(props.preparatoryClassId),
            id: 0,
            instructorName: "",
            congregationNameWithState: "",
            congregationId: 0,
          })
          .then(_response => {
            (addInstructorFormVisible.value = false),
              ctx.root.$bvToast.toast(
                "Instructor successfully added!",
                TOAST_OPTIONS,
              );
          });
      }
    };

    const generatePeopleReport = () => {
      if (props.preparatoryClassId) {
        preparatoryClassReportsController
          .getPreparatoryClassPeopleReport(Number(props.preparatoryClassId))
          .then((response: any) => {
            const blob = response;
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Placements Report";
            link.click();
            // URL.revokeObjectURL(link.href);
          });
      }
    };

    const generateCertificates = () => {
      if (props.preparatoryClassId) {
        preparatoryClassReportsController
          .getPreparatoryClassCertificates(Number(props.preparatoryClassId))
          .then((response: any) => {
            const blob = response;
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Placements Report";
            link.click();
            // URL.revokeObjectURL(link.href);
          });
      }
    };

    const deleteInstructor = (instructor: InstructorPreparatoryClassDetail) => {
      instructorPreparatoryClassDetailController
        .deleteInstructorPreparatoryClassDetail(instructor)
        .then(_response => {
          ctx.root.$bvToast.toast(
            "Instructor successfully deleted!",
            TOAST_OPTIONS,
          );
        });
    };

    const deleteCcm = (ccm: CourtesyCommitteeMember) => {
      courtesyCommitteeMemberController
        .deleteCourtesyCommitteeMember(ccm)
        .then(_response => {
          ctx.root.$bvToast.toast(
            "Committee successfully deleted!",
            TOAST_OPTIONS,
          );
        });
    };

    const toggleAddInstructorForm = () => {
      addInstructorFormVisible.value = !addInstructorFormVisible.value;
    };

    const toggleAddCcmForm = () => {
      addCcmFormVisible.value = !addCcmFormVisible.value;
    };

    const updateInstructor = (evt: AutocompleteResult): void => {
      pci.instructorId = evt.id;
    };

    return {
      addCcmFormVisible,
      addInstructorFormVisible,
      ccmPerson,
      confirmedPlacements,
      dataLoaded,
      deleteCcm,
      deleteInstructor,
      formattedDates,
      generateCertificates,
      generatePeopleReport,
      instructorAC,
      placements,
      preparatoryClassApplicationsForTable,
      preparatoryClassCcm,
      preparatoryClassInstructors,
      preparatoryClassInstructorsForAutocomplete,
      refInstructorInput,
      sPC,
      sendCommitteeProtocolLetter,
      sendInstructorUpdate,
      sendMinisterReminder,
      sendMinisterUpdate,
      sendPlacementConfirmationReminder,
      sendPlacementPacket,
      sendProtocolLetter,
      sendUpdateLetter,
      submitAddCcm,
      submitAddInstructor,
      toggleAddCcmForm,
      toggleAddInstructorForm,
      updateInstructor,
    };
  },
});
