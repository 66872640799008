import {
  emptyUnitApplication,
  UnitApplication,
} from "@/controllers/unitApplications";

interface UnitApplicationLookup {
  [key: number]: UnitApplication;
}

interface UnitApplicationsState {
  unitApplication: UnitApplication;
  unitApplicationsForTable: UnitApplication[];
  unitApplications: UnitApplicationLookup;
}

const state: UnitApplicationsState = {
  unitApplication: emptyUnitApplication(),
  unitApplicationsForTable: [],
  unitApplications: {},
};

const mutations = {
  LOAD_UNIT_APPLICATION(
    state: UnitApplicationsState,
    payload: UnitApplication,
  ) {
    state.unitApplication = payload;
  },
  LOAD_UNIT_APPLICATIONS_FOR_TABLE(
    state: UnitApplicationsState,
    payload: UnitApplication[],
  ) {
    state.unitApplicationsForTable = payload;
  },

  LOAD_UNIT_APPLICATIONS_FOR_LOOKUP(
    state: UnitApplicationsState,
    payload: UnitApplicationLookup,
  ) {
    state.unitApplications = payload;
  },
};

const actions = {
  loadUnitApplications(context: any, unitApplications: UnitApplication[]) {
    return new Promise((resolve, _reject) => {
      const lookupItems = unitApplications.reduce((accum, item) => {
        return {
          ...accum,
          [item.id]: {
            ...item,
          },
        };
      }, {});

      context.commit("LOAD_UNIT_APPLICATIONS_FOR_TABLE", unitApplications);
      context.commit("LOAD_UNIT_APPLICATIONS_FOR_LOOKUP", lookupItems);
      resolve(unitApplications);
    });
  },

  loadUnitApplication(context: any, unitApplication: UnitApplication) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_UNIT_APPLICATION", unitApplication);
      resolve(unitApplication);
    });
  },
};

const getters = {
  unitApplication: (state: UnitApplicationsState) => {
    return state.unitApplication;
  },
  unitApplicationsForTable: (state: UnitApplicationsState) => {
    return state.unitApplicationsForTable;
  },
  unitApplicationById: (state: UnitApplicationsState) => (id: number) => {
    return state.unitApplications[id];
  },
};

const UnitApplicationsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default UnitApplicationsModule;
