import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import FormErrors from "@/components/FormErrors/FormErrors.vue";
import {
  preparatoryClassSeasonsController,
  PreparatoryClassSeason,
} from "@/controllers/preparatoryClassSeasons";
import { NamedRts } from "@/router";
import { TOAST_OPTIONS } from "@/constants";
import { FormModes } from "@/main";

export default defineComponent({
  name: "preparatoryClassSeasonForm",
  components: {
    FormErrors,
    LoadingIndicator,
  },
  props: {
    preparatoryClassSeasonId: String,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const errorMessages = ref<string[]>([]);
    const hasErrors = ref(false);
    const submitting = ref(false);
    const deleting = ref(false);

    const deleteButtonText = ref("Delete");
    const submitButtonText = ref("Submit");

    const cancelRoute = {
      name: NamedRts.preparatoryClassSeasonList,
    };

    const fd = reactive({
      description: "",
      endDate: "",
      startDate: "",
      id: 0,
      notes: "",
      friendlyEndDate: "",
      friendlyStartDate: "",
    });

    const formMode = ref(FormModes.new);

    onMounted(() => {
      if (props.preparatoryClassSeasonId) {
        if (props.preparatoryClassSeasonId === "0") {
          // new form, no data needs to be retrieved
          // for the existing item
          formMode.value = FormModes.new;
          dataLoaded.value = true;
        } else {
          formMode.value = FormModes.edit;
          Promise.all([
            preparatoryClassSeasonsController.needPreparatoryClassSeason(
              props.preparatoryClassSeasonId,
            ),
          ])
            .then((_response: any) => {
              fd.id = storePreparatoryClassSeason.value.id;
              fd.description = storePreparatoryClassSeason.value.description;
              fd.startDate = storePreparatoryClassSeason.value.startDate;
              fd.endDate = storePreparatoryClassSeason.value.endDate;
              fd.notes = storePreparatoryClassSeason.value.notes;
            })
            .catch((_error: any) => {})
            .finally(() => {
              dataLoaded.value = true;
            });
        }
      } else {
        dataLoaded.value = true;
      }
    });

    const submitForm = (evt: any) => {
      evt.preventDefault();
      // clear out the errors in case
      // some were set in an earlier submit
      hasErrors.value = false;
      errorMessages.value = [];
      submitting.value = true;
      submitButtonText.value = "Submitting...";

      let submitAction;
      let toastMessage = "";

      if (formMode.value === FormModes.new) {
        toastMessage = `${fd.description} successfully created.`;
        submitAction =
          preparatoryClassSeasonsController.createPreparatoryClassSeason;
      } else {
        toastMessage = `${fd.description} successfully updated.`;
        submitAction =
          preparatoryClassSeasonsController.updatePreparatoryClassSeason;
      }

      submitAction({
        description: fd.description,
        endDate: fd.endDate,
        friendlyEndDate: "",
        friendlyStartDate: "",
        id: fd.id,
        notes: fd.notes,
        startDate: fd.startDate,
      })
        .then(_response => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          ctx.root.$bvToast.toast(toastMessage, TOAST_OPTIONS);
          ctx.root.$router.push({
            name: NamedRts.preparatoryClassSeasonList,
          });
        })
        .catch(errors => {
          submitting.value = false;
          submitButtonText.value = "Submit";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const startDelete = () => {
      deleting.value = true;
      if (formMode.value === FormModes.new) {
        // it's a new item, just cancel
        ctx.root.$router.push(cancelRoute);
      } else {
        ctx.root.$bvModal.show("deleteModal");
        deleteButtonText.value = "Deleting...";
        deleting.value = true;
      }
    };

    const storePreparatoryClassSeason = computed(() => {
      return $store.getters.preparatoryClassSeason as PreparatoryClassSeason;
    });

    const performDelete = () => {
      hasErrors.value = false;
      errorMessages.value = [];
      preparatoryClassSeasonsController
        .deletePreparatoryClassSeason(fd)
        .then(_response => {
          cancelDelete();
          ctx.root.$bvToast.toast(fd.description + " deleted", TOAST_OPTIONS);
          ctx.root.$router.push(cancelRoute);
        })
        .catch(errors => {
          deleting.value = false;
          deleteButtonText.value = "Delete";
          hasErrors.value = true;
          errorMessages.value = errors;
        });
    };

    const cancelDelete = () => {
      deleting.value = false;
      deleteButtonText.value = "Delete";
    };

    return {
      cancelDelete,
      cancelRoute,
      dataLoaded,
      deleteButtonText,
      deleting,
      errorMessages,
      fd,
      hasErrors,
      performDelete,
      startDelete,
      storePreparatoryClassSeason,
      submitButtonText,
      submitForm,
      submitting,
    };
  },
});
