import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "uapStatus",
  components: {},
  props: {
    uapStatus: {
      type: String,
      required: true,
    },
  },
  setup(props, _ctx) {
    const iconString = () => {
      if (props.uapStatus === "confirmed") {
        return "check-circle";
      } else if (props.uapStatus === "warning") {
        return "exclamation-triangle";
      } else {
        return "spinner";
      }
    };

    const bgClass = () => {
      if (props.uapStatus === "confirmed") {
        return {
          "text-success": true,
        };
      } else {
        return {
          "text-warning": true,
        };
      }
    };

    return {
      iconString,
      bgClass,
    };
  },
});
