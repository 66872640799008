import api from "@/api";
import store, { StoreActions } from "@/store";
import { ApiCountry, apiCountryToCountry } from "@/controllers/countries";

async function needCountries() {
  await api
    .getPublicCountries()
    .then((response: ApiCountry[]) => {
      const c = response.map(item => {
        return apiCountryToCountry(item);
      });
      return store.dispatch(StoreActions.loadCountries, c);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const publicCountriesController = {
  needCountries,
};
