import { defineComponent } from "@vue/composition-api";
import { ApplicationFormEvent } from "@/components/PreparatoryClassApplication/form";

export default defineComponent({
  name: "congregationsMinisters",
  props: {
    ministers: Array,
  },

  setup(_props, ctx) {
    const selectMinister = (p: any) => {
      ctx.emit(ApplicationFormEvent.selectCongregationMinister, p);
    };

    return {
      selectMinister,
    };
  },
});
