import api from "@/api";

interface ZipCodeResponse {
  zip: string;
}

async function zipForCity(city: string, stateId: string) {
  return api
    .getZipForCity(city, stateId)
    .then((response: ZipCodeResponse) => {
      return response.zip;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const zipForCityController = {
  zipForCity,
};
