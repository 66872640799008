import { defineComponent } from "@vue/composition-api";
import { friendlyDate } from "@/utilities/displayHelpers";

export default defineComponent({
  name: "unitApplicationNotes",
  props: {
    content: {
      type: String,
    },
    createdAt: {
      type: String,
    },
    timeAgoInWords: {
      type: String,
    },
    user: {
      type: String,
    },
  },

  setup(_props, _ctx) {
    return {
      friendlyDate,
    };
  },
});
