import {
  PinnedUnitApplicationFull,
  PinnedUnitApplicationPartial,
  emptyPinnedUnitApplicationPartial,
} from "@/controllers/pinnedUnitApplications";

interface PinnedUnitApplicationState {
  pinnedUnitApplication: PinnedUnitApplicationPartial;
  pinnedUnitApplications: PinnedUnitApplicationFull[];
}

const state: PinnedUnitApplicationState = {
  pinnedUnitApplication: emptyPinnedUnitApplicationPartial,
  pinnedUnitApplications: [],
};

const mutations = {
  LOAD_PINNED_UNIT_APPLICATION(
    state: PinnedUnitApplicationState,
    payload: PinnedUnitApplicationPartial,
  ) {
    state.pinnedUnitApplication = payload;
  },
  LOAD_PINNED_UNIT_APPLICATIONS(
    state: PinnedUnitApplicationState,
    payload: PinnedUnitApplicationFull[],
  ) {
    state.pinnedUnitApplications = payload;
  },
};

const actions = {
  loadPinnedUnitApplications(
    context: any,
    pinnedApplications: PinnedUnitApplicationFull[],
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PINNED_UNIT_APPLICATIONS", pinnedApplications);
      resolve(pinnedApplications);
    });
  },
  loadPinnedUnitApplication(
    context: any,
    pinnedApplication: PinnedUnitApplicationPartial,
  ) {
    return new Promise((resolve, _reject) => {
      context.commit("LOAD_PINNED_UNIT_APPLICATION", pinnedApplication);
      resolve(pinnedApplication);
    });
  },
};

const getters = {
  pinnedUnitApplication: (state: PinnedUnitApplicationState) => {
    return state.pinnedUnitApplication;
  },
  pinnedUnitApplications: (state: PinnedUnitApplicationState) => {
    return state.pinnedUnitApplications;
  },
};

const pinnedUnitApplicationsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default pinnedUnitApplicationsModule;
