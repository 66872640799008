import { render, staticRenderFns } from "./unit-application-placement-warnings.html?vue&type=template&id=18b86f87&scoped=true&"
import script from "./unit-application-placement-warnings.ts?vue&type=script&lang=js&"
export * from "./unit-application-placement-warnings.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b86f87",
  null
  
)

export default component.exports