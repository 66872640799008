import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { preparatoryClassInstructorsController } from "@/controllers/preparatoryClassInstructors";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import MailTo from "@/components/MailTo/MailTo.vue";
import Tel from "@/components/Tel/Tel.vue";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "instructorList",
  components: {
    LoadingIndicator,
    MailTo,
    Tel,
  },

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const filter = ref("");
    const filterOn = ["name", "congregationNameWithState"];

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    let fieldsForTable = [
      {
        key: "name",
        sortable: true,
      },
      {
        key: "congregationNameWithState",
        sortable: true,
      },
      {
        key: "phoneNumber",
        sortable: true,
      },
      {
        key: "emailAddress",
        sortable: true,
      },
      {
        key: "status",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
      },
    ];

    if (!isAdmin.value) {
      fieldsForTable = [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "congregationNameWithState",
          sortable: true,
        },
        {
          key: "phoneNumber",
          sortable: true,
        },
        {
          key: "emailAddress",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
      ];
    }

    onMounted(() => {
      Promise.all([
        preparatoryClassInstructorsController.needPreparatoryClassInstructors(),
      ])
        .then((_response: any) => {
          dataLoaded.value = true;
        })
        .catch((_error: any) => {});
    });

    const editRoute = (pcInstructorId: number) => {
      return {
        name: NamedRts.instructorForm,
        params: {
          id: pcInstructorId.toString(),
        },
      };
    };

    const preparatoryClassInstructorsForTable = computed(() => {
      return $store.getters.preparatoryClassInstructorsForTable;
    });

    return {
      dataLoaded,
      editRoute,
      fieldsForTable,
      filter,
      filterOn,
      isAdmin,
      preparatoryClassInstructorsForTable,
    };
  },
});
